@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria__component-chart {
	.recharts-legend-wrapper {
		.asteria-component__graph-legend-prefix {
			> rect {
				rx: 4px;
				ry: 4px;
			}
		}
	}

	.recharts-tooltip-wrapper {
		.asteria-component__graph-tooltip-prefix {
			> rect {
				rx: 4px;
				ry: 4px;
			}
		}
	}
}
