@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__float {
	@apply z-50;

	&.asteria-component__float--position-absolute {
		@apply absolute;
	}

	&.asteria-component__float--position-fixed {
		@apply fixed;
	}

	&.asteria-component__float--vertical-align-top {
		@apply top-4;
	}

	&.asteria-component__float--vertical-align-center {
		@apply top-1/2;
		transform: translateY(-50%);
	}

	&.asteria-component__float--vertical-align-bottom {
		@apply bottom-4;
	}

	&.asteria-component__float--horizontal-align-left {
		@apply left-4;
	}

	&.asteria-component__float--horizontal-align-center {
		@apply left-1/2;
		transform: translateX(-50%);
	}

	&.asteria-component__float--horizontal-align-right {
		@apply right-4;
	}
}
