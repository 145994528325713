@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;
@use './styles/colors.scss' as *;
@use './styles/circle.scss' as *;
@use './styles/stepper.scss' as *;

/* -- Animations -- */
@keyframes progress-indeterminate {
	from {
		width: 0%;
	}
	to {
		width: 100% #{'/* rtl:ignore */'};
	}
}

@keyframes progress-bar-stripes {
	0% {
		background-position-x: 15px;
	}
}

@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}

.asteria-component__progress {
	&__status {
		-webkit-animation: 1s linear infinite progress-bar-stripes;
		animation: 1s linear infinite progress-bar-stripes;
	}

	&--variant-indeterminate &__status {
		-webkit-animation: 0.75s linear infinite progress-indeterminate;
		animation: 0.75s linear infinite progress-indeterminate;
	}
}

.skeleton-box {
	&::after {
		animation: shimmer 2s infinite;
	}
}

/* -- Progress -- */

.asteria-component__progress {
	@apply relative
		h-2
		w-full
		overflow-hidden
		rounded-full;

	&__status {
		@apply h-full
			rounded-full
			text-center;
	}

	.asteria-component__icon {
		@apply m-auto;
	}
}

/* -- Skeleton -- */

.asteria-transaction-group__loading {
	.asteria-transaction-group__loading__row {
		grid-template-columns: 8fr 1fr 1fr 2fr;
		@apply grid
			gap-3
			p-4;

		.asteria-transaction-group__loading__column {
			@apply grid;

			&:nth-of-type(2) {
				grid-template-columns: 40px 40px;
				@apply justify-center
					gap-4;
			}
			&:nth-of-type(3) {
				grid-template-columns: 20px 20px 20px;
				@apply justify-center
					gap-2;
			}
			&:nth-of-type(4) {
				grid-template-columns: 120px;
				@apply justify-end
					gap-4;
			}
		}
	}
}

.skeleton-box {
	@apply relative
		inline-block
		h-2
		w-full
		overflow-hidden
		rounded-lg;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0,
			rgba(255, 255, 255, 0.2) 20%,
			rgba(255, 255, 255, 0.5) 60%,
			rgba(255, 255, 255, 0)
		);
		-webkit-animation: shimmer 2s infinite;
		animation: shimmer 2s infinite;
		content: '';
	}
}
