@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-form-checkbox-normal-background: #ffffff;
	--color-form-checkbox-normal-border: #b2b2b2;
	--color-form-checkbox-normal-text: theme('colors.content.text.normal');
	--color-form-checkbox-normal-icon: #ffffff;

	--color-form-checkbox-hover-background: #ffffff;
	--color-form-checkbox-hover-border: #b2b2b2;
	--color-form-checkbox-hover-text: theme('colors.content.text.hover');
	--color-form-checkbox-hover-icon: #ffffff;

	--color-form-checkbox-focus-background: theme('colors.primary.normal');
	--color-form-checkbox-focus-border: theme('colors.primary.normal');
	--color-form-checkbox-focus-text: theme('colors.content.text.focus');
	--color-form-checkbox-focus-icon: #ffffff;

	--color-form-checkbox-disabled-background: theme('colors.gray.100');
	--color-form-checkbox-disabled-border: theme('colors.gray.300');
	--color-form-checkbox-disabled-text: theme('colors.content.text.muted');
	--color-form-checkbox-disabled-icon: theme('colors.icon.disabled');
}

.asteria-component__checkbox {
	.asteria-component__checkbox__inner {
		--color-alert-error-normal-border: theme('colors.alert.error.icon.normal.background');
		--color-alert-success-normal-border: theme('colors.alert.success.icon.normal.background');
		--color-alert-info-normal-border: theme('colors.alert.info.icon.normal.background');
		--color-alert-warning-normal-border: theme('colors.alert.warning.icon.normal.background');
	}
}
