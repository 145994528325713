@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--asteria-modal-size-sm: min(
		theme('screens.sm'),
		calc(100% - calc(theme('spacing.4') * 2))
	);

	--asteria-modal-size-md: min(
		theme('screens.md'),
		calc(100% - calc(theme('spacing.4') * 2))
	);

	--asteria-modal-size-lg: min(
		theme('screens.lg'),
		calc(100% - calc(theme('spacing.4') * 2))
	);

	--asteria-modal-size-xl: min(
		theme('screens.xl'),
		calc(100% - calc(theme('spacing.4') * 2))
	);

	--asteria-modal-size-xxl: min(
		theme('screens.2xl'),
		calc(100% - calc(theme('spacing.4') * 2))
	);
}

.asteria-component__backdrop {
	@apply fixed top-0 left-0 bottom-0 right-0 h-full w-full;

	z-index: var(--z-index__modal);

	&.asteria-component__backdrop--variant-modal {
		@apply flex items-center justify-center bg-modal-backdrop-normal-background;
	}
}

.asteria-component__modal {
	width: var(--asteria-modal-size-md);

	> .asteria-component__wrapper,
	> .asteria-component__form > .asteria-component__wrapper {
		@apply max-h-full;

		> .asteria-component__wrapper-header {
			.asteria-component__title {
				@apply text-modal-header-normal-title;
			}
		}

		.asteria-component__wrapper-content {
			&.asteria-component__wrapper-content--scroll {
				@media not (max-width: theme('screens.tablet')) {
					max-height: 60vh;
				}
			}
		}

		// > .asteria-component__wrapper-footer {
		// 	@apply overflow-hidden;

		// 	.asteria-component__wrapper-footer__section {
		// 		@apply overflow-hidden;

		// 		.asteria-component__button {
		// 			@apply overflow-hidden;

		// 			.asteria-component__typography {
		// 				@apply truncate;
		// 			}
		// 		}
		// 	}
		// }
	}

	/* --- Sizes --- */

	@media not (max-width: theme('screens.tablet')) {
		&.asteria--size-small,
		&.asteria--size-sm {
			width: var(--asteria-modal-size-sm);
		}

		&.asteria--size-medium,
		&.asteria--size-md {
		}

		&.asteria--size-large,
		&.asteria--size-lg {
			width: var(--asteria-modal-size-lg);
		}

		&.asteria--size-xl {
			width: var(--asteria-modal-size-xl);
		}

		&.asteria--size-xxl {
			width: var(--asteria-modal-size-xxl);
		}
	}
}

.asteria-component__background {
}

.asteria-modal-enter {
	@apply opacity-0;
}

.asteria-modal-enter-active {
	@apply opacity-100 transition-opacity;
}

.asteria-modal-appear {
	@apply opacity-0;
}

.asteria-modal-appear-active {
	@apply opacity-100 transition-opacity;
}

.asteria-modal-exit {
	@apply opacity-100 transition-opacity;
}

.asteria-modal-exit-active {
	@apply opacity-0;
}

@media (max-width: theme('screens.tablet')) {
	.asteria-component__modal {
		--border-radius-DEFAULT: 0;

		@apply flex h-full w-full;

		> .asteria-component__wrapper,
		> .asteria-component__form > .asteria-component__wrapper {
			@apply h-full w-full;

			> .asteria-component__wrapper-content,
			> .asteria-component__onboarding-wrapper {
				@apply flex-1 rounded-none;
			}
		}
	}
}
