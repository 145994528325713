@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__financial-page {
	&.asteria--variant-loan {
		@apply grid h-full items-start gap-4;

		grid-template-columns: auto 1fr;

		> .asteria-component__form {
			@apply contents;
		}

		.asteria-component__financial-page-section-wrapper {
			@apply bg-white p-4;

			&.asteria--variant-aside {
				@apply h-full;

				@include scroll();
			}

			&.asteria--variant-content {
				@apply grid h-full gap-6 overflow-hidden;

				grid-template-rows: auto 1fr;
			}
		}

		.asteria-component__financial-loan-graph-wrapper {
			@apply grid items-center gap-2;

			grid-template-columns: auto 1fr auto;

			.asteria-component__financial-loan-graph-content {
				@apply grid h-full gap-2;

				grid-template-rows: 1fr 1fr auto;
			}

			.asteria-button-left,
			.asteria-button-right {
				@apply bg-transparent;

				&.asteria--state-disabled {
					@apply pointer-events-none;
				}
			}
		}

		.asteria-component__financial-page-section {
			&.asteria--variant-aside {
				max-width: 300px;

				> .asteria-component__wrapper {
					@apply gap-2 bg-transparent p-0;

					> .asteria-component__wrapper-content,
					> .asteria-component__wrapper-footer {
						@apply bg-transparent p-0;
					}

					> .asteria-component__wrapper-content {
						@apply gap-4;
					}
				}

				.asteria-component__group {
					&.asteria--variant-range-controls {
						@apply gap-4;

						.asteria-component__input {
							&.asteria-component__input--type-number {
								width: 80px;

								&.asteria-component__input--has-postfix {
									.asteria-component__input__wrapper {
										@apply overflow-hidden;

										input {
											@apply w-0;
										}

										// Chrome, Safari, Edge, Opera
										input::-webkit-outer-spin-button,
										input::-webkit-inner-spin-button {
											@apply m-0 appearance-none;
										}

										// Firefox
										input[type='number'] {
											-moz-appearance: textfield;
										}
									}
								}
							}

							&.asteria-component__input--type-range {
								@apply flex-1;
							}
						}
					}

					&.asteria--variant-summary {
						@apply text-right;

						.asteria-component__typography-group {
							@apply flex flex-col gap-1;
						}

						.asteria--variant-title {
							@apply font-title;
						}

						.asteria--variant-value {
							@apply text-content-text-muted;
						}
					}
				}
			}

			&.asteria--variant-header {
			}

			&.asteria--variant-line-graph {
				@apply mb-4;
			}

			&.asteria--variant-bar-graph {
				@apply mt-4;
			}

			&.asteria--variant-transactions {
				@apply flex h-full flex-col gap-4;

				@include scroll();

				.asteria-component__message-box__wrapper {
					> .asteria-component__button {
						@apply pl-0;
					}
				}

				.asteria-component__financial-page-section-content {
					@apply flex flex-col gap-8;

					.asteria-component__financial-page-section-content-group {
						@apply flex flex-col gap-4;

						&.asteria--variant-monthly {
							.asteria-component__financial-page-section-content-group-section {
								> .asteria-component__typography {
									@apply flex items-center justify-start border-0 border-b border-solid border-border-normal px-4 py-2 text-content-text-muted;
								}

								.asteria-component__table {
									@apply grid;
									grid-template-columns: repeat(6, 1fr);

									> .asteria-component__table-row {
										@apply contents;
									}

									.asteria-component__table-cell {
										@apply border-0 border-b border-solid border-border-normal px-4;

										&.asteria--variant-end {
											@apply justify-end;
										}

										.asteria-component__typography {
											@apply whitespace-nowrap;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.asteria-component__loan-range {
	> .asteria-component__group {
		> .asteria-component__group {
			@apply flex-1 gap-0;
		}
	}
}

.asteria-component__select--dropdown {
	&.asteria--type-loan {
		.asteria-component__dropdown__item {
			.asteria-component__dropdown__item__postfix {
				@apply hidden;
			}
		}
	}
}
