@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__loading {
	.asteria-component__progress {
		@apply w-96;
	}
}
