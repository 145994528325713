@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__accordion-item {
	.asteria-component__accordion-item__label {
		@apply border-accordion-header-normal-border bg-accordion-header-normal-background;

		.asteria-component__typography {
			@apply text-accordion-header-normal-text;
		}

		.asteria-component__title {
			@apply text-accordion-header-normal-title;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-accordion-header-normal-icon;
			}
		}
	}

	.asteria-component__accordion-item__content {
		@apply border-accordion-body-normal-border bg-accordion-body-normal-background;

		.asteria-component__typography {
			@apply text-accordion-body-normal-text;
		}

		.asteria-component__title {
			@apply text-accordion-body-normal-title;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-accordion-body-normal-icon;
			}
		}
	}

	&:hover {
		.asteria-component__accordion-item__label {
			@apply border-accordion-header-hover-border bg-accordion-header-hover-background;

			.asteria-component__typography {
				@apply text-accordion-header-hover-text;
			}

			.asteria-component__title {
				@apply text-accordion-header-hover-title;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-accordion-header-hover-icon;
				}
			}
		}

		.asteria-component__accordion-item__content {
			@apply border-accordion-body-hover-border bg-accordion-body-hover-background;

			.asteria-component__typography {
				@apply text-accordion-body-hover-text;
			}

			.asteria-component__title {
				@apply text-accordion-body-hover-title;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-accordion-body-hover-icon;
				}
			}
		}
	}

	&.asteria--state-disabled {
		.asteria-component__accordion-item__label {
			@apply border-accordion-header-disabled-border bg-accordion-header-disabled-background;

			.asteria-component__typography {
				@apply text-accordion-header-disabled-text;
			}

			.asteria-component__title {
				@apply text-accordion-header-disabled-title;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-accordion-header-disabled-icon;
				}
			}
		}

		.asteria-component__accordion-item__content {
			@apply border-accordion-body-disabled-border bg-accordion-body-disabled-background;

			.asteria-component__typography {
				@apply text-accordion-body-disabled-text;
			}

			.asteria-component__title {
				@apply text-accordion-body-disabled-title;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-accordion-body-disabled-icon;
				}
			}
		}
	}

	&.asteria--state-active {
		.asteria-component__accordion-item__label {
			@apply border-accordion-header-focus-border bg-accordion-header-focus-background;

			.asteria-component__typography {
				@apply text-accordion-header-focus-text;
			}

			.asteria-component__title {
				@apply text-accordion-header-focus-title;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-accordion-header-focus-icon;
				}
			}
		}

		.asteria-component__accordion-item__content {
			@apply border-accordion-body-focus-border bg-accordion-body-focus-background;

			.asteria-component__typography {
				@apply text-accordion-body-focus-text;
			}

			.asteria-component__title {
				@apply text-accordion-body-focus-title;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-accordion-body-focus-icon;
				}
			}
		}
	}
}
