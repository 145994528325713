@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__wrapper-header {
	&.asteria-component__transactions__header {
		@apply rounded-none border-0 bg-transparent px-4 py-3;

		.asteria-component__wrapper-header__content {
			@apply gap-4;

			> .asteria-component__typography,
			> .asteria-component__typography-group
				> .asteria-component__typography {
				@apply font-medium text-list-header-button-normal-text;
			}
		}

		&:not(.asteria--state-active) {
			.asteria-component__wrapper-header__content {
				@apply justify-center;
			}
		}

		.asteria-component__wrapper--collapsible & {
			@apply cursor-pointer;
		}

		.asteria-component__transactions__header__notifications {
			@apply flex items-center justify-center gap-1;

			.asteria-component__transactions__header__notification {
				&.asteria-component__transactions__header__notification--prefix {
					@apply flex-none;

					> * {
						@apply relative;

						&:nth-child(1) {
							@apply left-0;
						}

						&:nth-child(2) {
							@apply -left-1;
						}

						&:nth-child(3) {
							@apply -left-2;
						}
					}
				}
			}
		}

		.asteria-component__transactions__header__actions {
			@apply flex gap-2;
		}

		.asteria-component__wrapper-header__postfix {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-list-header-button-normal-icon;
				}
			}
		}

		&.asteria--state-active {
			.asteria-component__wrapper-header__content {
				> .asteria-component__typography {
					@apply text-list-header-button-focus-text;
				}
			}

			.asteria-component__wrapper-header__postfix {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-list-header-button-focus-icon;
					}
				}
			}

			.asteria-component__button--variant-close {
				transform: rotate(180deg);
			}
		}
	}
}
