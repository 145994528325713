@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--datepicker-content-width: 350px;
	--datepicker-content-height: 500px;
}

.asteria-component__datepicker {
	input {
		@apply w-0;
	}

	> .asteria-component__input {
		&.asteria--size-lg {
			.asteria-component__label__wrapper {
				@apply h-12;
			}
		}
	}
}

.asteria-component__modal {
	&.asteria-component__datepicker-modal {
		--datepicker-content-width: auto;
		--datepicker-content-height: auto;

		.asteria-component__datepicker__content {
			@apply shadow-none;
		}
	}
}

.asteria-component__datepicker__content {
	@apply flex flex-col items-center justify-center overflow-hidden rounded bg-white shadow outline-none outline-0;

	width: var(--datepicker-content-width);
	max-height: var(--datepicker-content-height);

	.asteria-component__typography {
		@apply m-0;
	}

	&.asteria-component__datepicker__content--multiple {
		&.asteria-component__datepicker__content--multiple-double {
			width: calc(
				var(--datepicker-content-width) * 2 + theme('spacing.4')
			);
		}

		&.asteria-component__datepicker__content--multiple-triple {
			width: calc(
				var(--datepicker-content-width) * 3 + theme('spacing.4') * 2
			);
		}
	}

	.asteria-component__datepicker__content--layout {
		@apply grid w-full;

		&.asteria-component__datepicker__content--layout-double {
			@apply gap-4;
			grid-template-columns: repeat(2, 1fr);
		}

		&.asteria-component__datepicker__content--layout-triple {
			@apply gap-4;
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&.asteria--size-large,
	&.asteria--size-lg {
	}

	&.asteria--size-medium,
	&.asteria--size-md {
	}

	&.asteria--size-small,
	&.asteria--size-sm {
	}

	.asteria-component__datepicker__content__calendar {
		@apply box-border grid place-items-center justify-center gap-0 text-center;

		@include scroll();

		&:not(.asteria-component__datepicker__content__day__calendar) {
			.asteria-component__datepicker__content__calendar__item {
				@apply h-12 w-full;

				> span {
					@apply h-12 w-12;
				}
			}
		}

		.asteria-component__datepicker__content__calendar__item {
			@apply aspect-square h-auto truncate;

			&,
			span {
				@apply flex h-full w-full cursor-pointer items-center justify-center truncate rounded-full;
			}

			&.asteria--date-current {
				.asteria-component__typography {
					@apply font-semibold;
				}
			}

			&.asteria--state-highlight {
				&,
				span {
					@apply rounded-none;
				}
			}

			&.asteria--state-focus {
				&,
				span {
					@apply rounded-full;
				}

				&.asteria-component__datepicker-state--range-start {
					&,
					span {
						@apply rounded-r-none;
					}
				}

				&.asteria-component__datepicker-state--range-end {
					&,
					span {
						@apply rounded-l-none;
					}
				}
			}

			&.asteria--state-disabled {
				@apply cursor-default opacity-60;
			}
		}
	}
}

.asteria-component__datepicker__content__calendar__item {
	@apply bg-datepicker-cell-normal-background;

	span {
		.asteria-component__typography {
			@apply text-datepicker-cell-normal-text;
		}
	}

	&.asteria--date-past {
		@apply bg-datepicker-cell-past-background;

		span {
			.asteria-component__typography {
				@apply text-datepicker-cell-past-text;
			}
		}
	}

	&.asteria--date-current {
		@apply bg-datepicker-cell-current-background;

		span {
			.asteria-component__typography {
				@apply text-datepicker-cell-current-text;
			}
		}
	}

	&.asteria--date-future {
		@apply bg-datepicker-cell-future-background;

		span {
			.asteria-component__typography {
				@apply text-datepicker-cell-future-text;
			}
		}
	}

	&.asteria--date-holiday {
		@apply bg-datepicker-cell-holiday-background;

		span {
			.asteria-component__typography {
				@apply text-datepicker-cell-holiday-text;
			}
		}
	}

	&:not(.asteria--state-highlight):hover {
		span {
			@apply bg-datepicker-cell-hover-background;

			.asteria-component__typography {
				@apply text-datepicker-cell-hover-text;
			}
		}
	}

	&.asteria--state-highlight {
		@apply bg-datepicker-cell-highlight-background;

		span {
			.asteria-component__typography {
				@apply text-datepicker-cell-highlight-text;
			}
		}
	}

	&.asteria--state-focus {
		@apply bg-datepicker-cell-focus-background;

		span {
			.asteria-component__typography {
				@apply text-datepicker-cell-focus-text;
			}
		}
	}

	&.asteria--state-disabled {
		@apply bg-datepicker-cell-disabled-background;

		span {
			.asteria-component__typography {
				@apply text-datepicker-cell-disabled-text;
			}
		}
	}
}
