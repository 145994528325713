@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__modal {
	&.asteria--type-tags {
		.asteria-component__typography-group {
			@apply flex flex-col gap-2;

			.asteria-component__unordered {
				@apply flex flex-col gap-2;
			}
		}

		.asteria-component__message-box__wrapper {
			> .asteria-component__button.asteria-component__button--variant-link {
				@apply px-0;
			}
		}
	}
}
