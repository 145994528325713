@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@use './styles/grid.scss';

@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--wrapper-rounding: theme('borderRadius.md');
}

.asteria-component__wrapper {
	@apply relative box-border w-full flex-col border-0 border-solid border-wrapper-normal-border;

	border-radius: var(--wrapper-rounding);

	&.asteria-component__wrapper--border {
		@apply border;
	}

	> .asteria-component__wrapper__button-close {
		@apply absolute top-0 right-0;
	}

	&.asteria-component__wrapper--variant-custom {
		@apply overflow-hidden;
	}

	&.asteria-component__wrapper--variant-vertical {
		> * {
			&:not(.asteria-component__button) {
				// @apply pr-10;
			}

			&:not(.asteria-component__button):first-of-type {
				border-top-left-radius: var(--wrapper-rounding);
				border-top-right-radius: var(--wrapper-rounding);
			}

			&:not(.asteria-component__button):last-of-type {
				border-bottom-right-radius: var(--wrapper-rounding);
				border-bottom-left-radius: var(--wrapper-rounding);
			}

			&:not(.asteria-component__button):not(:first-of-type) {
				@apply border-t-0;
			}
		}

		> .asteria-component__wrapper-header {
			@apply border-0;
		}

		> .asteria-component__wrapper-header
			~ .asteria-component__wrapper-content {
			// > .asteria-component__wrapper-header ~ .asteria-component__wrapper-footer {
			@apply border-0 border-solid border-wrapper-normal-divider;
		}

		> .asteria-component__wrapper-content
			~ .asteria-component__wrapper-footer {
			@apply border-0 border-solid border-wrapper-normal-divider;
		}

		&.asteria-component__wrapper--divider {
			> .asteria-component__wrapper-header
				~ .asteria-component__wrapper-content {
				@apply border-t;
			}

			> .asteria-component__wrapper-content
				~ .asteria-component__wrapper-footer {
				@apply border-t;
			}
		}
	}

	&.asteria-component__wrapper--variant-horizontal {
		&.asteria-component__wrapper--close-button {
			> * {
				&:not(.asteria-component__button):last-of-type {
					@apply pr-14;
				}
			}
		}

		> .asteria-component__wrapper-header {
			@apply border-0;
		}

		> .asteria-component__wrapper-header
			~ .asteria-component__wrapper-content {
			@apply border-0 border-solid border-wrapper-normal-divider;
			&.asteria-component__wrapper--divider {
				@apply border-t;
			}
		}

		> .asteria-component__wrapper-header
			+ .asteria-component__wrapper-content
			+ .asteria-component__wrapper-footer {
			@apply border-0 border-solid border-wrapper-normal-divider;
			&.asteria-component__wrapper--divider {
				@apply border-t border-l;
			}
		}

		> .asteria-component__wrapper-content
			~ .asteria-component__wrapper-footer {
			@apply border-0 border-solid border-wrapper-normal-divider;
			&.asteria-component__wrapper--divider {
				@apply border-l;
			}
		}

		&.asteria-component__wrapper--divider {
			> .asteria-component__wrapper-header
				~ .asteria-component__wrapper-content {
				@apply border-t;
			}

			> .asteria-component__wrapper-header
				+ .asteria-component__wrapper-content
				+ .asteria-component__wrapper-footer {
				@apply border-t border-l;
			}

			> .asteria-component__wrapper-content
				~ .asteria-component__wrapper-footer {
				@apply border-l;
			}
		}

		// > .asteria-component__wrapper-content {
		// 	border-bottom-left-radius: var(--wrapper-rounding);
		// }

		> * {
			&:not(.asteria-component__button):first-of-type {
				&.asteria-component__wrapper-header {
					border-top-left-radius: var(--wrapper-rounding);
					border-top-right-radius: var(--wrapper-rounding);
				}

				&.asteria-component__wrapper-content {
					border-top-left-radius: var(--wrapper-rounding);

					& ~ .asteria-component__wrapper-footer {
						border-top-right-radius: var(--wrapper-rounding);
					}
				}
			}

			&:not(.asteria-component__button):last-of-type {
				&.asteria-component__wrapper-header,
				&.asteria-component__wrapper-content {
					border-bottom-right-radius: var(--wrapper-rounding);
					border-bottom-left-radius: var(--wrapper-rounding);
				}

				&.asteria-component__wrapper-footer {
					border-bottom-right-radius: var(--wrapper-rounding);

					& ~ .asteria-component__wrapper-content {
						border-bottom-left-radius: var(--wrapper-rounding);
					}
				}
			}
		}
	}

	&.asteria-component__wrapper--collapsible {
		&.asteria--state-closed {
			.asteria-component__wrapper-content {
				@apply hidden;
			}

			.asteria-component__wrapper-footer {
				@apply hidden;
			}
		}
	}

	&.asteria-component__wrapper--scroll {
		@include scroll();
	}

	> .asteria-component__wrapper-header {
		grid-area: wrapper-header;
	}

	> .asteria-component__wrapper-content {
		grid-area: wrapper-content;
	}

	> .asteria-component__wrapper-footer {
		grid-area: wrapper-footer;
	}
}
