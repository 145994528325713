@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__cashflow-graph-settings {
	.asteria-component__wrapper-content {
		@apply flex flex-col gap-8;
	}

	.asteria-component__select {
		@apply overflow-hidden;

		.asteria-component__typography {
			@apply overflow-hidden text-ellipsis;
		}
	}

	.asteria-component__cashflow-graph-settings__section {
		@apply gap-0;

		.asteria-component__typography-group {
			@apply mb-2 flex flex-col gap-2;
		}

		> .asteria-component__group {
			&:not(:last-child) {
				@apply mb-4;
			}
		}
	}
}
