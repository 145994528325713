@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--graph-default-credit-gap: 72px;
	--graph-stacked-credit-gap: 24px;
}

.asteria-graph-line-group-wrapper {
	@apply pointer-events-none;
	grid-row: 1;
	width: var(--graph-group-width, 150px);
	height: 100%;

	&.asteria-graph-line-group-wrapper-info {
		@apply z-10;
	}

	&.asteria-graph-line-group-wrapper-first {
		overflow: hidden;
	}
}

.asteria-graph-line-credit-box {
	@apply pointer-events-auto;
}

.asteria-graph-line-group {
	@apply relative h-full;

	line {
		@apply stroke-graph-line-normal-background;
		stroke-width: 2px;

		&:not(.asteria-graph-line-overdraft) {
			&.asteria-graph-line-below-zero {
				&.asteria--feature-below-zero {
					@apply stroke-graph-line-negative-background;

					&.asteria-component__line--fade-out {
						stroke: url('#fade-out');
					}
				}
			}
		}

		&.asteria-component__line--fade-out {
			&:not(.asteria-component__line--fade-out-next) {
				stroke: url('#fade-out');
			}
		}

		&.asteria-graph-line-overdraft {
			@apply stroke-graph-line-normal-credit;

			&.asteria-graph-line-vertical {
				stroke-width: 4px;
			}
		}
	}

	.asteria-graph-line-gradient-start,
	.asteria-graph-line-gradient-end {
		stop-color: theme('colors.graph.line.normal.background');

		line {
			&.asteria--feature-below-zero {
				&.asteria-graph-line-below-zero {
					stop-color: theme('colors.graph.line.negative.background');
				}
			}
		}
	}

	.asteria-overdraft-area {
		@apply pointer-events-auto transition-opacity;

		fill: rgba(125, 0, 0, 0.3);
	}

	.asteria-graph-group-background {
		position: absolute;
		top: 0px;
		left: 0px;
	}

	.asteria-graph-line {
		@apply pointer-events-auto z-10;

		position: absolute;
		top: 0;
		bottom: 0;

		max-height: 100%;
		max-width: 100%;
		height: 100%;
		transform: translateX(-50%);
		pointer-events: none;

		.asteria-overdraft-indicator {
			@apply fill-graph-line-normal-credit;
		}
	}

	.asteria-graph-line-group-spread-info {
		@apply absolute bottom-2 left-1/2 z-10 h-auto w-auto rounded-full p-2;
		transform: translateX(-50%);
	}

	&:not(.asteria-graph-line-group-forecast) {
		.asteria-graph-line-group-spread-info {
			@apply hidden;
		}
	}

	&.asteria-graph-line-group-forecast {
		.asteria-graph-line-group-spread-info {
			@apply opacity-0 transition-opacity;
		}

		&:hover {
			.asteria-graph-line-group-spread-info {
				@apply rounded-full opacity-100;
			}
		}
	}

	&.asteria-graph-line-group-history {
		.asteria-graph-group-background {
			@apply border-graph-group-historical-normal-border bg-graph-group-historical-normal-background;
		}

		&:hover,
		&.asteria-state-hover {
			.asteria-graph-group-background {
				@apply border-graph-group-historical-hover-border bg-graph-group-historical-hover-background;
			}
		}

		&:focus,
		&.asteria-state-active {
			.asteria-graph-group-background {
				@apply border-graph-group-historical-focus-border bg-graph-group-historical-focus-background;
			}
		}
	}

	&.asteria-graph-line-group-today {
		.asteria-graph-group-background {
			@apply border-graph-group-today-normal-border bg-graph-group-today-normal-background;
		}

		&:hover,
		&.asteria-state-hover {
			.asteria-graph-group-background {
				@apply border-graph-group-today-hover-border bg-graph-group-today-hover-background;
			}
		}

		&:focus,
		&.asteria-state-active {
			.asteria-graph-group-background {
				@apply border-graph-group-today-focus-border bg-graph-group-today-focus-background;
			}
		}
	}

	&.asteria-graph-line-group-forecast {
		.asteria-graph-group-background {
			@apply border-graph-group-forecast-normal-border bg-graph-group-forecast-normal-background;
		}

		&:hover,
		&.asteria-state-hover {
			.asteria-graph-group-background {
				@apply border-graph-group-forecast-hover-border bg-graph-group-forecast-hover-background;
			}
		}

		&:focus,
		&.asteria-state-active {
			.asteria-graph-group-background {
				@apply border-graph-group-forecast-focus-border bg-graph-group-forecast-focus-background;
			}
		}
	}

	.asteria--state-hidden {
		@apply hidden;
	}

	* {
		@apply transition-opacity;
	}

	.asteria--state-invisible {
		@apply opacity-50;
	}
}

.asteria-graph-line__spread {
	@apply absolute h-full w-full;
	transform: translateX(-50%);
}

/* START: Spread info */

.asteria-graph-spread-info {
	@apply pointer-events-auto absolute h-full w-full overflow-visible;

	z-index: 11;

	.asteria-graph-stacked & {
		@apply hidden;
	}

	.asteria-graph-spread-info-dots,
	.asteria-graph-spread-info-lines {
		@apply opacity-100 transition-opacity;
	}

	&:not(:hover) {
		.asteria-graph-spread-info-dots,
		.asteria-graph-spread-info-lines {
			@apply opacity-0;
		}
	}
}

.asteria-graph-spread-info-lines {
	@apply pointer-events-none absolute h-full w-full;
}

.asteria-graph-spread-info-dots {
	--dots-spacing: theme('spacing.1');

	@apply relative h-full w-full;

	.asteria-graph-dot-wrapper {
		@apply absolute left-1/2 -translate-x-1/2;

		top: calc(var(--y) - var(--dots-spacing));

		.asteria-graph-line-dot {
			@apply pointer-events-auto box-border h-3 w-3 translate-x-0 translate-y-0;

			@apply border-graph-dot-spread-normal-border bg-graph-dot-spread-normal-background;

			&:hover {
				@apply border-graph-dot-spread-hover-border bg-graph-dot-spread-hover-background;
			}
		}
	}
}

.asteria-graph-line-group.asteria-graph-line-group-lines.asteria--has-spread {
	.asteria-graph-spread-info {
		line {
			@apply stroke-graph-line-normal-vertical-spread;

			stroke-dasharray: 4px;
		}
	}
}

/* END: Spread info */

.asteria-graph-line-group {
	svg {
		// @apply pointer-events-none;

		polygon {
			// @apply pointer-events-none;
		}
	}

	.asteria-graph-line-credit-box {
		svg {
			@apply pointer-events-auto;
		}
	}

	.asteria-graph-line-group-spread-info {
		@apply pointer-events-auto;
	}
}

.asteria-graph-line-graph-component {
	.asteria-graph-line-group {
		@apply pointer-events-none;
		.asteria-graph-group-background {
			@apply pointer-events-auto;
		}
	}
}

.asteria-component__tooltip
	.asteria-component__typography.asteria-graph-spread-tooltip-line-title,
.asteria-graph-spread-tooltip-line-title {
	@apply mr-2;

	flex-grow: 1;
}

.asteria-graph-spread-tooltip-spread-line-text {
	text-align: right;
}

.asteria-temporary {
	display: none;
}

.asteria-graph-spread-tooltip-line {
	display: flex;
	min-width: 300px;

	.asteria-component__probability__wrapper {
		@apply ml-0 flex-grow p-0;
	}
}
