@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-forecast-actions {
			.asteria-component__card-content {
				@apply gap-8;
			}

			.asteria-component__button {
				&.asteria--type-redirect {
					@apply h-auto p-0;
				}
			}
		}
	}
}
