@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-view__welcome__questions {
	@apply flex h-full w-full flex-col gap-8;

	.asteria-component__input,
	.asteria-component__select {
		.asteria-component__label__wrapper {
			&.asteria-component__label__wrapper--direction-horizontal {
				@apply gap-4;

				.asteria-component__form__label {
					width: 150px;
				}
			}
		}
	}

	.asteria-view__welcome__questions__extension-wrapper {
		@apply relative box-border flex w-full flex-col;

		> .asteria-component__button {
			@apply absolute top-0 right-0;
		}
	}

	.asteria-component__group {
		&.asteria-component__group--direction-horizontal {
			@media (max-width: 500px) {
				@apply flex flex-col;
			}
		}
	}
}
