@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--progress__circlethickness: 8px;

	--progress__value: 0%;

	--progress__height: 106px;
	--progress__width: 106px;

	--progress__value__width: calc(
		var(--progress__width) - var(--progress__circlethickness) * 2
	);
	--progress__value__height: calc(
		var(--progress__height) - var(--progress__circlethickness) * 2
	);
}

.asteria-component__progress.asteria-component__progress--variant-circle {
	width: var(--progress__width);
	height: var(--progress__height);

	@apply grid
    cursor-default
		place-items-center
		rounded-full;

	.asteria-component__progress__value {
		width: var(--progress__value__width);
		height: var(--progress__value__height);

		@apply grid
      select-none
			place-items-center
			rounded-full
			font-body
			font-bold;
	}

	.asteria-component__text {
		@apply static
			top-auto
			left-auto
			m-0
			transform-none
			p-0
			text-center;
	}

	&-indeterminate {
		--progress__value: 50%;
		@apply animate-spin;
	}

	&.asteria--size-small,
	&.asteria--size-sm {
		--progress__height: 50px;
		--progress__width: 50px;
		--progress__circlethickness: 5px;

		--progress__value__width: calc(
			var(--progress__width) - var(--progress__circlethickness) * 2
		);
		--progress__value__height: calc(
			var(--progress__height) - var(--progress__circlethickness) * 2
		);

		@apply text-sm;

		.asteria-component__text {
			@apply text-sm;
		}
	}
}
