.asteria-view__welcome {
	&:not(.asteria-view__welcome--variant-modal) {
		@apply w-full;

		max-width: 720px;
	}

	.asteria-component__progress {
		&:not(.asteria-component__progress--variant-circle) {
			@apply h-2 rounded-full;
		}
	}

	.asteria-view__welcome__content {
		@apply mt-4 flex flex-col;
	}

	.asteria-view__welcome__footer {
		@apply mt-10 flex flex-col gap-10;

		.asteria-view__welcome__link {
			&:not(.asteria--state-completed) {
				@apply flex flex-row items-center justify-center;
			}

			> .asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply h-auto w-auto py-0;
				}
			}

			@media (max-width: 500px) {
				@apply flex-col gap-1;
			}
		}
	}

	&.asteria-view__welcome--page-quiz {
		.asteria-view__welcome__content {
			@apply items-start justify-start gap-6;
		}

		.asteria-view__welcome__footer {
			@apply mt-6 gap-6;
		}
	}

	&.asteria-view__welcome--page-overview {
		.asteria-view__welcome__content {
			@apply gap-10;
		}
	}

	&.asteria-view__welcome--page-linear {
		.asteria-view__welcome__content {
			@apply gap-10;
		}
	}
}

.asteria-view__welcome-wrapper {
	&.asteria--state-has-support {
		@apply flex flex-row gap-10;

		> .asteria-component__contenter {
			@apply mt-16;

			max-width: theme('spacing.96');

			.asteria-component__faq-contact-wrapper {
				@apply rounded-md;
			}
		}
	}
}
