@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__forecaster-page {
	&.asteria--type-category {
		.asteria-component__forecaster-page-section {
			&.asteria--type-satisfaction {
				&.asteria--state-open {
					.asteria-component__forecaster-page-section-content {
						@apply relative flex flex-col gap-4 border-2 border-solid p-4;

						background-color: var(
							--color-forecaster-page-category-section-probability-background
						);

						border-color: var(
							--color-forecaster-page-category-section-probability-border,
							theme('colors.border.normal')
						);

						> .asteria-component__button {
							@apply absolute top-4 right-4;
						}

						> .asteria-component__typography-group {
							@apply flex flex-col gap-1 text-center;
						}
					}
				}

				&.asteria--state-closed {
					> .asteria-component__chip {
						@apply mx-auto;
					}
				}
			}
		}
	}
}
