@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__datepicker__content {
	@apply p-2;

	.asteria-component__datepicker__content__slider {
		--color-button-icon-normal-border: transparent;
	}
}
