.asteria-view__welcome__help {
	> .asteria-component__wrapper-content {
		.asteria-component__wrapper {
			> .asteria-component__wrapper-content {
				> .asteria-component__group {
					@apply gap-6;

					> .asteria-component__group {
						@apply gap-2;

						> .asteria-component__typography {
							@apply font-label;
						}

						> .asteria-component__group {
							@apply gap-2 pl-8;
						}
					}
				}
			}
		}
	}
}
