@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__graph-lines {
	@apply absolute inset-0 flex flex-col justify-between;

	z-index: -1;

	.asteria-component__graph-line {
		@apply border-0 border-b-2 border-solid border-graph-yaxis-part-line-normal-background;

		&.asteria--variant-positive {
		}

		&.asteria--variant-negative {
		}

		&.asteria--variant-zero {
			@apply border-graph-yaxis-part-line-normal-zero;
		}
	}
}
