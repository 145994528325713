@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__transactions__modal--columns {
}

.asteria-component__transactions__columns {
	> .asteria-component__wrapper-content {
		@apply gap-4;

		> .asteria-component__group {
			@apply gap-1;
		}
	}
}

.asteria-component__transactions__columns__list {
}

.asteria-component__transactions__columns__list-item {
	@apply flex items-center border-0 border-b border-solid border-list-item-normal-border;

	@apply bg-list-dragging-normal-background;

	&:not(.sortableHelper):hover {
		@apply bg-list-dragging-hover-background;
	}

	&.sortableHelper {
		@apply bg-list-dragging-focus-background;
	}

	> .asteria-component__typography {
		@apply flex-1;
	}

	.asteria-component__transactions__columns__list-item__drag {
	}

	.asteria-component__transactions__columns__list-item__handler {
		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-primary-normal;
			}
		}
	}
}
