@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__datepicker__content {
	.asteria-component__datepicker__content__day {
		.asteria-component__datepicker__content__day__calendar {
			grid-template-columns: repeat(7, 1fr);
			.asteria-component__datepicker__content__day__calendar__item {
				&:not(.asteria--date-current-month) {
					.asteria-component__typography {
					}
				}
			}
		}

		.asteria-component__datepicker__content__day__header {
			@apply grid text-center;

			grid-template-columns: repeat(7, 1fr);

			@apply bg-datepicker-header-title-normal-background;
		}
	}
}
