@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@use './styles/colors.scss' as *;

.asteria-component__checkbox {
	&:not(.asteria--state-disabled) {
		.asteria-component__checkbox__inner {
			@apply cursor-pointer;
		}
	}

	.asteria-component__label__wrapper {
		&.asteria-component__label__wrapper--direction-vertical {
			.asteria-component__checkbox__inner {
				@apply flex-col-reverse;
			}
		}
	}

	.asteria-component__checkbox__inner {
		@apply box-border inline-flex items-baseline gap-2;

		input {
			@apply m-0 hidden cursor-pointer p-0;

			& + .asteria-component__icon {
				@apply box-border rounded border-2 border-solid p-1;
			}
		}

		.asteria-component__checkbox__icon {
			@apply aspect-square h-5 w-5 flex-none;

			.asteria-component__icon-content {
				@apply h-3 w-3 overflow-visible;
			}
		}
	}

	&.asteria--size-small,
	&.asteria--size-sm {
		.asteria-component__checkbox__inner {
			.asteria-component__checkbox__icon {
				@apply h-4 w-4 p-0;

				.asteria-component__icon-content {
					@apply h-2 w-2;
				}
			}
		}
	}

	&.asteria--size-large,
	&.asteria--size-lg {
		.asteria-component__checkbox__inner {
			.asteria-component__checkbox__icon {
				@apply h-8 w-8;

				.asteria-component__icon-content {
					@apply h-4 w-4;
				}
			}
		}
	}
}
