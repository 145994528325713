@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@use './styles/grid.scss' as *;
@use './styles/colors.scss' as *;

.asteria-component__navigation__item {
	@apply grid cursor-pointer border border-solid;

	* {
		@apply box-border;
	}

	.asteria-component__badge {
		@apply top-0 right-0;
	}

	&.asteria-component__navigation__item--no-label {
		@apply w-fit;
	}

	&.asteria-component__navigation__item--no-icon {
		.asteria-component__navigation__item__icon {
			&:not(.asteria-component__navigation__item__sub &) {
				@apply border border-solid border-border-normal;
			}
		}

		.asteria-component__navigation__item__label {
			@apply px-4;
		}
	}

	.asteria-component__navigation__item__icon,
	.asteria-component__navigation__item__label {
		@apply h-10;
	}

	.asteria-component__navigation__item__icon,
	.asteria-component__navigation__item__collapse {
		@apply aspect-square justify-center rounded-none border border-solid p-2;
	}

	.asteria-component__navigation__item__icon {
		@apply rounded;
	}

	.asteria-component__navigation__item__label {
		@apply flex items-center border border-solid;
	}
}

.asteria-component__navigation__item__sub {
	@apply mb-4;
}
