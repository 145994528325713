@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-notifications-button-hover-background: ;
	--color-notifications-button-hover-border: transparent;

	--color-notifications-button-focus-background: ;
	--color-notifications-button-focus-border: transparent;

	--color-notifications-button-normal-background: ;
	--color-notifications-button-normal-border: transparent;

	--color-notifications-button-text-hover-background: ;
	--color-notifications-button-text-hover-border: transparent;

	--color-notifications-button-text-focus-background: ;
	--color-notifications-button-text-focus-border: transparent;

	--color-notifications-button-text-normal-background: ;
	--color-notifications-button-text-normal-border: transparent;

	--color-notifications-button-icon-hover-background: ;
	--color-notifications-button-icon-hover-border: transparent;

	--color-notifications-button-icon-focus-background: ;
	--color-notifications-button-icon-focus-border: transparent;

	--color-notifications-button-icon-normal-background: ;
	--color-notifications-button-icon-normal-border: transparent;

	--color-notifications-item-header-normal-background: ;
	--color-notifications-item-header-normal-border: transparent;
	--color-notifications-item-header-normal-title: ;

	--color-notifications-item-header-focus-background: ;
	--color-notifications-item-header-focus-border: transparent;
	--color-notifications-item-header-focus-title: ;

	--color-notifications-item-header-hover-background: ;
	--color-notifications-item-header-hover-border: transparent;
	--color-notifications-item-header-hover-title: ;

	--color-notifications-item-header-icon-focus-background: ;
	--color-notifications-item-header-icon-focus-border: transparent;

	--color-notifications-item-header-icon-hover-background: ;
	--color-notifications-item-header-icon-hover-border: transparent;

	--color-notifications-item-header-icon-normal-background: ;
	--color-notifications-item-header-icon-normal-border: transparent;

	--color-notifications-item-body-focus-border: transparent;
	--color-notifications-item-body-focus-background: ;

	--color-notifications-item-body-hover-border: transparent;
	--color-notifications-item-body-hover-background: ;

	--color-notifications-item-body-normal-border: transparent;
	--color-notifications-item-body-normal-background: ;

	--color-notifications-item-footer-focus-border: transparent;
	--color-notifications-item-footer-focus-background: ;

	--color-notifications-item-footer-hover-border: transparent;
	--color-notifications-item-footer-hover-background: ;

	--color-notifications-item-footer-normal-border: transparent;
	--color-notifications-item-footer-normal-background: ;

	--color-notifications-item-wrapper-focus-background: ;
	--color-notifications-item-wrapper-focus-border: transparent;

	--color-notifications-item-wrapper-hover-background: ;
	--color-notifications-item-wrapper-hover-border: transparent;

	--color-notifications-item-wrapper-normal-background: ;
	--color-notifications-item-wrapper-normal-border: transparent;

	--color-notifications-wrapper-normal-background: ;
	--color-notifications-wrapper-normal-border: transparent;
}

.asteria-component__level.asteria-component__notifications {
	--dropdown__has-scroll__max-height: 480px;

	> .asteria-component__dropdown__list {
		@apply gap-0 px-4 py-6;

		> .asteria-component__notification__title {
			@apply px-4 pt-0 pb-2;
		}

		> .asteria-component__dropdown__item {
			> .asteria-component__dropdown__item__label {
				@apply rounded-md p-4 transition-colors ease-in-out;

				&:hover {
					background-color: var(--color-button-icon-hover-background);

					.asteria-component__wrapper.asteria-component__notification {
						> .asteria-component__button {
							@apply border-transparent bg-transparent;
						}
					}
				}
			}
		}
	}

	.asteria-component__wrapper.asteria-component__notification {
		@apply gap-0 pr-8;

		> .asteria-component__wrapper-header,
		> .asteria-component__wrapper-content,
		> .asteria-component__wrapper-footer {
			@apply p-0;
		}

		> .asteria-component__wrapper-content {
			.asteria-component__typography {
				@apply whitespace-normal;

				-webkit-line-clamp: 2;
				display: -webkit-box;
				-webkit-box-orient: vertical;
			}
		}

		> .asteria-component__wrapper-footer {
			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply mt-2 h-auto w-auto p-0;
				}
			}
		}

		> .asteria-component__button {
			@include asteria-btn-rounded;
		}
	}
}
