@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-under-construction {
			.asteria-component__placeholder-image {
				@apply h-48 bg-border-normal bg-cover bg-center bg-no-repeat;

				// background-image: radial-gradient(#fbf2ea 1px, transparent 2px);
				// background-size: 10px 10px;
			}

			.asteria-component__card-footer {
				.asteria-component__button {
					&.asteria-component__button--variant-link {
						@apply px-0;
					}
				}
			}
		}
	}
}

.asteria-component__under-construction-modal {
	.asteria-component__contenter {
		.asteria-component__list__header,
		.asteria-component__list__item {
			@apply border-b-0;
		}
	}

	.asteria-component__list__group {
		@apply flex flex-col gap-2;
	}

	.asteria-component__typography-group {
		@apply flex flex-col gap-4;
	}
}
