@import './mixins';
@import './constants';

.asteria-wrapper {
	&.asteria--feature-graph-large {
		.asteria-graph {
			.asteria-graph-area {
				@apply p-0;

				grid-auto-columns: calc(100% / 5);

				.asteria-graph-bar-group {
					width: unset;
				}
			}
		}

		.asteria-graph-line-group-wrapper {
			width: unset;
		}
	}
}

.asteria-component__graph-spread {
	&.asteria--variant-inner {
		.asteria-color__spread-normal {
			@apply fill-graph-line-normal-inner-spread;
			fill-opacity: 1;
		}

		.asteria-color__spread-normal-stripe {
			// @apply fill-graph-line-normal-inner-stripe;
			// fill-opacity: 1;
		}

		line.asteria-color__spread-normal-stripe {
			@apply stroke-graph-line-normal-inner-stripe;
			// fill-opacity: 1;
		}

		.asteria-color__spread-negative {
			@apply fill-graph-line-negative-inner-spread;
			fill-opacity: 1;
		}

		.asteria-color__spread-negative-stripe {
			// @apply fill-graph-line-negative-inner-stripe;
			// fill-opacity: 1;
		}

		line.asteria-color__spread-negative-stripe {
			@apply stroke-graph-line-negative-inner-stripe;
			// fill-opacity: 1;
		}
	}

	&.asteria--variant-outer {
		.asteria-color__spread-normal {
			@apply fill-graph-line-normal-spread;
			fill-opacity: 0.5;
		}

		.asteria-color__spread-normal-stripe {
			@apply fill-graph-line-normal-stripe;
			fill-opacity: 0.5;
			stroke-dasharray: none;
		}

		line.asteria-color__spread-normal-stripe {
			@apply stroke-graph-line-normal-stripe;
			stroke-opacity: 0.3;
			stroke-width: 2px;
		}

		.asteria-color__spread-negative {
			@apply fill-graph-line-negative-spread;
			fill-opacity: 0.5;
		}

		.asteria-color__spread-negative-stripe {
			// @apply fill-graph-line-negative-stripe;
			// fill-opacity: 0.5;
		}

		line.asteria-color__spread-negative-stripe {
			@apply stroke-graph-line-negative-stripe;
			stroke-opacity: 0.3;
			stroke-width: 2px;
		}
	}
}
