@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__chip {
	@apply border-chip-normal-border bg-chip-normal-background p-0 align-middle;

	.asteria-component__typography {
		@apply text-chip-normal-text;
	}

	.asteria-component__icon {
		.asteria-component__icon-content {
			@apply bg-chip-normal-icon;
		}
	}

	.asteria-component__chip__button,
	.asteria-component__chip__action {
		&.asteria-component__button {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-chip-normal-icon;
				}
			}

			&.asteria-component__button--variant-link {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-chip-normal-icon;
					}
				}
			}
		}
	}

	&.asteria-state-completed,
	&.asteria--state-clickable.asteria-state-completed {
		--color-probability-normal-background: white;

		@apply border-chip-state-success-border bg-chip-state-success-background;

		.asteria-component__typography {
			@apply text-chip-state-success-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-chip-state-success-icon;
			}
		}

		.asteria-component__chip__button,
		.asteria-component__chip__action {
			&.asteria-component__button {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-chip-state-success-icon;
					}
				}
			}
		}
	}

	&.asteria-component__chip--variant-flat,
	&.asteria-component__chip--variant-simple {
		@apply bg-chip-flat-background;

		.asteria-component__typography {
			@apply text-chip-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-chip-normal-icon;
			}
		}

		.asteria-component__chip__button,
		.asteria-component__chip__action {
			&.asteria-component__button {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-chip-normal-icon;
					}
				}
			}
		}
	}

	&.asteria--state-active,
	&.asteria--state-clickable.asteria--state-active {
		--color-probability-normal-background: white;

		@apply border-chip-focus-border bg-chip-focus-background;

		.asteria-component__typography {
			@apply text-chip-focus-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-chip-focus-icon;
			}
		}

		.asteria-component__chip__button,
		.asteria-component__chip__action {
			&.asteria-component__button {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-chip-focus-icon;
					}
				}
			}
		}
	}

	&.asteria--state-disabled,
	&.asteria--state-clickable.asteria--state-disabled {
		--color-probability-normal-background: white;

		@apply border-chip-disabled-border bg-chip-disabled-background;

		.asteria-component__typography {
			@apply text-chip-disabled-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-chip-disabled-icon;
			}
		}

		.asteria-component__chip__button,
		.asteria-component__chip__action {
			&.asteria-component__button {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-chip-disabled-icon;
					}
				}
			}
		}
	}

	&.asteria-state-removed,
	&.asteria--state-clickable.asteria-state-removed {
		--color-probability-normal-background: white;

		@apply border-chip-state-error-border bg-chip-state-error-background;

		.asteria-component__typography {
			@apply text-chip-state-error-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-chip-state-error-icon;
			}
		}

		.asteria-component__chip__button,
		.asteria-component__chip__action {
			&.asteria-component__button {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-chip-state-error-icon;
					}
				}
			}
		}
	}

	&.asteria-state-skipped,
	&.asteria--state-clickable.asteria-state-skipped {
		--color-probability-normal-background: white;

		@apply border-chip-state-warning-border bg-chip-state-warning-background;

		.asteria-component__typography {
			@apply text-chip-state-warning-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-chip-state-warning-icon;
			}
		}

		.asteria-component__chip__button,
		.asteria-component__chip__action {
			&.asteria-component__button {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-chip-state-warning-icon;
					}
				}
			}
		}
	}

	&.asteria--state-clickable:hover {
		--color-probability-normal-background: white;

		@apply border-chip-hover-border bg-chip-hover-background;

		.asteria-component__typography {
			@apply text-chip-hover-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-chip-hover-icon;
			}
		}

		.asteria-component__chip__button,
		.asteria-component__chip__action {
			&.asteria-component__button {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-chip-hover-icon;
					}
				}
			}
		}
	}
}
