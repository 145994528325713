@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--viewport-medium-height: max(50vh, 400px);
}

.asteria-component__cashflow {
	&:not(.asteria--feature-resizer) {
		@apply grid;

		grid-template-rows: 1fr auto;
		grid-template-columns: 1fr;
		grid-template-areas:
			'graphs'
			'transactions';

		.asteria-wrapper--split & {
			.asteria-cashflow-details {
				@apply mt-4;
			}
		}
	}

	&.asteria--size-medium {
		.asteria-wrapper--split & {
			grid-template-rows: var(--viewport-medium-height) 1fr;

			.asteria-cashflow-details {
				@apply mt-4 overflow-hidden;
			}
		}
	}

	&.asteria--size-large {
		.asteria-wrapper--split & {
			grid-template-rows: var(--viewport-height) 1fr;

			.asteria-cashflow-details {
				@apply mt-4;
			}
		}
	}
}
