@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

// * --- ClientDetails --- * //

.asteria-component__client-overview__details {
	@apply border-list-item-details-customer-body-normal-border
	bg-list-item-details-customer-body-normal-background;

	.asteria-component__client-overview__details__overview {
		@apply flex
      flex-col
      items-center
			justify-center
			gap-1
      border-list-item-details-customer-header-normal-border
      bg-list-item-details-customer-header-normal-background
			p-8;

		.asteria-component__text {
			@apply mb-0 text-center;
		}

		&.asteria--state-error {
			@apply bg-alert-error-normal-background;

			.asteria-component__title {
				@apply text-alert-error-normal-title;
			}

			.asteria-component__text {
				@apply text-alert-error-normal-text;
			}
		}
	}

	.asteria-component__client-overview__details__statistics {
		grid-template-columns: repeat(4, 1fr);

		@apply grid
				items-center
				justify-center
        pt-8
        pb-6
				text-center;

		.asteria-component__client-overview__details__statistics__item {
			@apply flex flex-col gap-2;

			&:not(:last-child) {
				@apply border-0
					border-r
					border-solid
					border-border-normal;
			}
		}
	}

	.asteria-component__client-overview__details__info {
		.asteria-component__client-overview__details__info__item {
			@apply grid gap-2;
			grid-template-columns: minmax(min-content, max-content) 1fr;

			.asteria-component__client-overview__details__info__item__value {
				@apply text-right;

				.asteria-component__typography {
					@apply font-semibold;
				}
			}

			.asteria-component__button--variant-link {
				@apply p-0;

				.asteria-component__text {
					@apply text-button-link-normal-text hover:text-button-link-hover-text;
				}
			}
		}
	}
}
