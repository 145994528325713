@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper.asteria-component__transactions {
	&,
	&.asteria--state-active {
		@apply border-transparent;
	}

	> .asteria-component__transactions__header {
		.asteria-component__transactions__header__actions {
			.asteria-component__chip {
				@include asteria-btn-tab;
			}
		}
	}

	.asteria-component__transactions__list {
		.asteria-component__list__item {
			--color-button-icon-normal-background: transparent;
			--color-button-icon-normal-border: transparent;

			--color-button-icon-hover-background: transparent;
			--color-button-icon-hover-border: transparent;

			--color-button-icon-focus-background: transparent;
			--color-button-icon-focus-border: transparent;

			.asteria-component__list__cell {
				&.asteria-component__transactions__cell--tags,
				&.asteria-component__transactions__cell--status,
				&.asteria-component__transactions__cell--currency {
					.asteria-component__transactions__cell__content {
						.asteria-component__chip {
							.asteria-component__typography {
								@apply text-sm;
							}
						}
					}
				}
			}
		}

		.asteria-component__list__header {
			.asteria-component__typography {
				&.asteria--type-total {
					@apply font-semibold;
				}
			}
		}
	}
}
