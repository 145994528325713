@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__actionbar {
	@apply flex items-end justify-between gap-3 rounded-lg border border-solid border-actionbar-normal-border bg-actionbar-normal-background p-4 shadow-md;

	.asteria-component__typography {
		@apply m-0 p-0;
	}

	.asteria-component__actionbar__section {
		@apply flex;

		&.asteria--variant-full {
			@apply flex-1;
		}

		&.asteria-component__actionbar__section--prefix {
			@apply self-center;
		}

		&.asteria-component__actionbar__section--content {
			@apply flex-1 flex-col gap-1;

			.asteria-component__title {
				@apply text-actionbar-header-normal-title;
			}
		}

		&.asteria-component__actionbar__section--actions {
			@apply items-center gap-4;
		}
	}
}

.asteria-component__actions {
	@apply flex flex-col gap-2;
}

.asteria-component__actionbar {
	&.asteria-component__actions-action {
		@apply items-start shadow-none;

		&.asteria--state-clickable {
			@apply cursor-pointer;
		}

		&.asteria--state-disabled {
			@apply pointer-events-none opacity-60;
		}
	}
}
