@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-graph-forecaster-pending-background: transparent;
	--color-graph-forecaster-focus-background: transparent;

	--color-selector-satisfaction-dot-normal-background: white;
	// --color-selector-satisfaction-dot-normal-border: ;
	--color-selector-satisfaction-dot-normal-icon: white;
	// --color-selector-satisfaction-dot-normal-line: ;

	--color-selector-satisfaction-dot-focus-background: var(--color-primary);
	--color-selector-satisfaction-dot-focus-border: var(--color-primary);
	// --color-selector-satisfaction-dot-focus-icon: ;

	// --color-selector-satisfaction-label-normal-icon: ;
	// --color-selector-satisfaction-label-normal-text: ;

	--color-selector-satisfaction-label-focus-icon: var(--color-primary);
	--color-selector-satisfaction-label-focus-text: var(--color-primary);

	--color-forecaster-page-category-section-probability-background: #fafafa;
	--color-forecaster-page-category-section-probability-border: var(--color-forecaster-page-category-section-probability-background);

	--forecaster-transactions-background-empty: var(--color-button-icon-hover-background);

	--forecaster-transactions-columns-7: minmax(min-content, max-content) 200px minmax(130px, 1fr) minmax(140px, 200px) minmax(100px, 140px) minmax(100px, 200px) minmax(min-content, max-content);
	--forecaster-transactions-columns-6: 200px minmax(130px, 1fr) minmax(140px, 200px) minmax(100px, 140px) minmax(100px, 200px) minmax(min-content, max-content);
	--forecaster-transactions-columns-5: minmax(130px, 1fr) minmax(140px, 200px) minmax(100px, 140px) minmax(100px, 200px) minmax(min-content, max-content);
}

.asteria-component__forecaster__view {
	@apply gap-0;
}

.asteria-component__forecaster-page {
	--color-teaser-normal-border: #eee;
	--color-teaser-normal-background: #eee;

	.asteria-component__wrapper.asteria-component__teaser {
		@apply mx-auto max-w-5xl gap-0;
	}

	&.asteria--type-home {
		.asteria-component__forecaster-page-section {
			&.asteria--type-graph {
				@apply relative px-0;

				margin-bottom: 60px;

				.forecaster__layout__header {
					@apply hidden;
				}

				.asteria-component__legends__wrapper {
					@apply absolute bottom-0 left-1/2 -translate-x-1/2;
				}

				.asteria-component__forecaster-page-section-content {
					@apply h-52 gap-4;
				}
			}

			&.asteria--type-actions {
				@apply mx-auto max-w-5xl;

				> .asteria-component__group {
					@apply flex flex-col;

					> .asteria-component__button {
						@apply px-0;

						@include asteria-btn-link;
					}
				}

				.asteria-component__actions {
					@apply grid;

					grid-template-columns: repeat(2, minmax(0, 1fr));

					.asteria-component__forecaster-actions-section {
						@apply flex flex-col;

						> .asteria-component__forecaster-actions-section-content {
							@apply flex-1;
						}
					}

					.asteria-component__forecaster-actions-section-content-placeholder {
						@apply flex flex-1 items-center justify-center rounded-lg text-center;

						background-color: var(--color-button-icon-hover-background);
					}
				}

				.asteria-component__forecaster-actions-section {
					> .asteria-component__forecaster-actions-section-title {
						> .asteria-component__group {
							@apply justify-start gap-1;

							> .asteria-component__button {
								@apply h-8 w-8 items-center justify-center gap-0 p-0;

								@include asteria-btn-link;
								@include asteria-btn-sm(false, true, true, md);
								> .asteria-component__typography {
									@apply hidden;
								}
							}
						}
					}
				}

				.asteria-component__actionbar.asteria-component__actions-action {
					.asteria-component__title {
						@apply text-title-xs;
					}

					&:hover {
						background-color: var(--color-button-icon-hover-background);
						border-color: var(--color-button-icon-hover-border);

						--color-actionbar-header-normal-title: black;
						--color-text-normal: black;
					}

					.asteria-component__actionbar__section.asteria-component__actionbar__section--actions {
						> .asteria-component__button {
							@apply h-10 w-10 items-center justify-center gap-0 rounded-full p-0;

							@include asteria-btn-primary;

							.asteria-component__typography {
								@apply hidden;
							}

							> .asteria-component__button {
								@apply h-full w-full;

								@include asteria-btn-md;
							}
						}
					}
				}
			}
		}
	}

	&.asteria--type-category {
		.asteria-component__forecaster-page-content {
			@apply px-0;
		}

		.asteria-component__forecaster-page-section {
			&.asteria--type-header {
				> .asteria-component__button.asteria-component__button--icon-action-arrow-left,
				.asteria-component__level-navigation .asteria-component__level-navigation-back {
					@include asteria-btn-rounded;
					@include asteria-btn-md;
				}

				.asteria-component__chip {
					@include asteria-btn-tab;

					.asteria-component__dropdown {
						--color-button-icon-normal-background: transparent;
						--color-button-icon-normal-border: transparent;

						--color-button-icon-hover-background: transparent;
						--color-button-icon-hover-border: transparent;

						--color-button-icon-focus-background: transparent;
						--color-button-icon-focus-border: transparent;
					}
				}

				.asteria-component__level-navigation-content {
					@apply gap-2;
				}

				.asteria-component__level-navigation-selector {
					> .asteria-component__button {
						@include asteria-btn-tab;

						&:hover {
							@apply border-chip-hover-border bg-chip-hover-background;

							.asteria-component__icon {
								.asteria-component__icon-content {
									@apply bg-chip-hover-icon;
								}
							}
						}

						&.asteria--state-active {
							@apply border-chip-focus-border bg-chip-focus-background;

							.asteria-component__icon {
								.asteria-component__icon-content {
									@apply bg-chip-focus-icon;
								}
							}
						}

						@apply pr-2;

						.asteria-component__icon {
							&,
							.asteria-component__icon-content {
								@apply h-3 w-3;
							}
						}
					}
				}
			}

			&.asteria--type-graph {
				.asteria-component__graph-lines {
					.asteria-component__graph-line {
						@apply border-b;
					}
				}

				.asteria-component__forecaster-graph-input {
					&.asteria--variant-history {
						.asteria-component__typography {
							@apply font-medium;
						}
					}
				}

				.asteria-component__forecaster-graph {
					&.asteria--variant-bars {
						.asteria-component__forecaster-graph-column {
							.asteria-component__forecaster-graph-bar-group {
								.asteria-component__forecaster-graph-bar {
									&:last-child,
									&.asteria-color__history {
										&.asteria--type-deposit {
											@apply rounded-t-md;
										}

										&.asteria--type-withdraw {
											@apply rounded-b-md;
										}
									}
								}

								&.asteria--type-deposit,
								&.asteria--type-withdraw {
									.asteria-component__forecaster-graph-button {
										.asteria-component__icon {
											&,
											.asteria-component__icon-content {
												@apply h-6 w-6;
											}
										}
									}
								}

								&.asteria--type-deposit {
									.asteria-component__forecaster-graph-button {
										.asteria-component__icon {
											.asteria-component__icon-content {
												@apply bg-primary-normal;
											}
										}

										&:hover {
											.asteria-component__icon {
												.asteria-component__icon-content {
													@apply bg-primary-focus;
												}
											}
										}
									}
								}

								&.asteria--type-withdraw {
									.asteria-component__forecaster-graph-button {
										.asteria-component__icon {
											.asteria-component__icon-content {
												@apply bg-secondary-normal;
											}
										}

										&:hover {
											.asteria-component__icon {
												.asteria-component__icon-content {
													@apply bg-secondary-focus;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			&.asteria--type-transactions {
				> .asteria-component__forecaster-transactions {
					@apply border-0;

					.asteria-component__forecaster-transactions-list {
						.asteria-component__forecaster-transactions-list-group {
							.asteria-component__forecaster-transactions-list-header {
								.asteria-component__forecaster-transactions-list-cell {
									&.asteria-component__forecaster-transactions-list-cell-actions {
										.asteria-component__button {
											@include asteria-btn-link;
											@include asteria-btn-sm;

											> .asteria-component__button {
												@include asteria-btn-sm;

												> .asteria-component__icon {
													&,
													.asteria-component__icon-content {
														@apply h-4 w-4;
													}
												}
											}
										}
									}

									&.asteria--type-total {
										@apply w-32 pr-4;

										.asteria-component__typography {
											@apply font-medium text-black;
										}
									}
								}
							}
						}
					}
				}
			}

			&.asteria--type-satisfaction {
				.asteria-component__forecaster-page-section-content {
					@apply rounded-md;

					.asteria-component__button--variant-satisfaction-close {
						@include asteria-btn-xs;
						@include asteria-btn-rounded;

						@apply bg-transparent;
					}
				}
			}
		}
	}
}
