@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__modal__graph-spread__wrapper {
	.asteria-component__wrapper-content {
		@apply flex flex-col gap-8;

		.asteria-component__contenter {
			@apply m-0 rounded border border-solid border-border-normal p-4;

			.asteria-component__contenter__inner {
				@apply gap-2;
			}

			.asteria-component__typography {
				@apply m-0;
			}

			.asteria-component__button {
				@apply m-0 mt-4 h-auto w-fit p-0;
			}
		}
	}
}
