.asteria-color__spread {
	--image: repeating-linear-gradient(-45deg, theme('colors.graph.line.normal.spread'), theme('colors.graph.line.normal.stripe') 3px, theme('colors.graph.line.normal.spread') 6px);

	background-image: repeating-linear-gradient(-45deg, theme('colors.graph.line.normal.spread'), theme('colors.graph.line.normal.stripe') 3px, theme('colors.graph.line.normal.spread') 6px);

	&.asteria-color__negative {
		--image: repeating-linear-gradient(-45deg, theme('colors.graph.line.negative.spread'), theme('colors.graph.line.negative.stripe') 3px, theme('colors.graph.line.negative.spread') 6px);

		background-image: repeating-linear-gradient(-45deg, theme('colors.graph.line.negative.spread'), theme('colors.graph.line.negative.stripe') 3px, theme('colors.graph.line.negative.spread') 6px);
	}
}

.asteria-color__forecast {
	--image: repeating-linear-gradient(135deg, transparent, transparent 2px, rgb(0 0 0 / 0.2) 3px, rgb(0 0 0 / 0.2) 5px, transparent 6px);
}

.asteria-color__total,
.asteria-color__history {
	--color: theme('colors.tags.total.background');
	--font-color: theme('colors.tags.total.invert');
	.asteria-component__prefix,
	&.asteria-component__prefix,
	&.asteria-component__graph-part-bar,
	&.asteria-component__bar-part {
		background-color: rgb(200, 200, 200);
	}
}

.asteria-color__paid {
	--color: theme('colors.tags.paid.background');
	--font-color: theme('colors.tags.paid.invert');
}

.asteria-color__deposit {
	--color: theme('colors.tags.deposit.background');
	--font-color: theme('colors.tags.deposit.invert');
}

.asteria-color__withdraw {
	--color: theme('colors.tags.withdraw.background');
	--font-color: theme('colors.tags.withdraw.invert');
}

.asteria-color__amortization {
	--color: theme('colors.tags.amortization.background');
	--font-color: theme('colors.tags.amortization.invert');
}

.asteria-color__interest {
	--color: theme('colors.tags.interest.background');
	--font-color: theme('colors.tags.interest.invert');
}

.asteria-color__account {
	&.asteria-color__paid {
		background-color: transparent;

		background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2212%22%3E%3Cpath%20d%3D%22M%200%205%20L%2012%205%22%20style%3D%22stroke%3A%20rgba%280%2C0%2C0%2C1%29%3Bstroke-width%3A%202px%3B%22%3E%3C/path%3E%3C/svg%3E');
		background-size: 9px;
		border-radius: 0;
		background-repeat: no-repeat;
		background-position: center center;
	}

	&.asteria-color__forecast {
		background-color: transparent;
		background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2212%22%3E%3Cpath%20d%3D%22M%200%205%20L%2012%205%22%20style%3D%22stroke%3A%20rgba%280%2C0%2C0%2C1%29%3Bstroke-width%3A%202px%3Bstroke-dasharray%3A%204%3B%22%3E%3C/path%3E%3C/svg%3E');
		background-size: 9px;
		border-radius: 0;
		background-repeat: no-repeat;
		background-position: center center;
	}
}

.asteria-color__credit {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 0 6px 10.4px;
	border-color: transparent transparent transparent theme('colors.graph.line.normal.credit');

	border-radius: 0;
}

.asteria-color__credit-area {
	--color: theme('colors.tags.credit-area.background');
	--font-color: theme('colors.tags.credit-area.invert');
}

.asteria-color__forecaster-adjusted {
	--color: theme('colors.tags.forecaster-adjusted.background');
	--font-color: theme('colors.tags.forecaster-adjusted.invert');
}

.asteria-color__invoices-customer {
	--color: theme('colors.tags.invoices-customer.background');
	--font-color: theme('colors.tags.invoices-customer.invert');
}

.asteria-color__invoices-supplier {
	--color: theme('colors.tags.invoices-supplier.background');
	--font-color: theme('colors.tags.invoices-supplier.invert');
}

.asteria-color__taxes-tax {
	--color: theme('colors.tags.taxes-tax.background');
	--font-color: theme('colors.tags.taxes-tax.invert');
}

.asteria-color__salaries-salary {
	--color: theme('colors.tags.salaries-salary.background');
	--font-color: theme('colors.tags.salaries-salary.invert');
}

.asteria-color__transaction-bankgiro {
	--color: theme('colors.tags.transaction-bankgiro.background');
	--font-color: theme('colors.tags.transaction-bankgiro.invert');
}

.asteria-color__transaction-plusgiro {
	--color: theme('colors.tags.transaction-plusgiro.background');
	--font-color: theme('colors.tags.transaction-plusgiro.invert');
}

.asteria-color__transaction-swish {
	--color: theme('colors.tags.transaction-swish.background');
	--font-color: theme('colors.tags.transaction-swish.invert');
}

.asteria-color__transaction-payment {
	--color: theme('colors.tags.transaction-payment.background');
	--font-color: theme('colors.tags.transaction-payment.invert');
}

.asteria-color__transaction-transfer {
	--color: theme('colors.tags.transaction-transfer.background');
	--font-color: theme('colors.tags.transaction-transfer.invert');
}

.asteria-color__transaction-misc {
	--color: theme('colors.tags.transaction-misc.background');
	--font-color: theme('colors.tags.transaction-misc.invert');
}

.asteria-color__deposit-misc {
	--color: theme('colors.tags.deposit-misc.background');
	--font-color: theme('colors.tags.deposit-misc.invert');
}

.asteria-color__withdraw-misc {
	--color: theme('colors.tags.withdraw-misc.background');
	--font-color: theme('colors.tags.withdraw-misc.invert');
}

.asteria-color__transaction-cash {
	--color: theme('colors.tags.transaction-cash.background');
	--font-color: theme('colors.tags.transaction-cash.invert');
}

.asteria-color__transaction-card {
	--color: theme('colors.tags.transaction-card.background');
	--font-color: theme('colors.tags.transaction-card.invert');
}

.asteria-color__invoice-invoice-client {
	--color: theme('colors.tags.invoice-invoice-client.background');
	--font-color: theme('colors.tags.invoice-invoice-client.invert');
}

.asteria-color__unpaid {
	--color: theme('colors.tags.unpaid.background');
	--font-color: theme('colors.tags.unpaid.invert');
}

.asteria-color__signed {
	--color: theme('colors.tags.signed.background');
	--font-color: theme('colors.tags.signed.invert');
}

.asteria-color__overdue {
	--color: theme('colors.tags.overdue.background');
	--font-color: theme('colors.tags.overdue.invert');
}

.asteria-color__invoices-credit {
	--color: theme('colors.tags.credit.background');
	--font-color: theme('colors.tags.credit.invert');
}

.asteria-color__unsent {
	--color: theme('colors.tags.unsent.background');
	--font-color: theme('colors.tags.unsent.invert');
}

.asteria-color__forecast-matched {
	--color: theme('colors.tags.forecast-matched.background');
	--font-color: theme('colors.tags.forecast-matched.invert');
}

.asteria-color__factoring {
	--color: theme('colors.tags.factoring.background');
	--font-color: theme('colors.tags.factoring.invert');
}

.asteria-color__internal-internal-transfers {
	--color: theme('colors.tags.internal-internal-transfers.background');
	--font-color: theme('colors.tags.internal-internal-transfers.invert');
}

.asteria-color__office-office-space {
	--color: theme('colors.tags.office-office-space.background');
	--font-color: theme('colors.tags.office-office-space.invert');
}

.asteria-color__office-office-electricity {
	--color: theme('colors.tags.office-office-electricity.background');
	--font-color: theme('colors.tags.office-office-electricity.invert');
}

.asteria-color__office-office-internet {
	--color: theme('colors.tags.office-office-internet.background');
	--font-color: theme('colors.tags.office-office-internet.invert');
}

.asteria-color__office-office-cleaning {
	--color: theme('colors.tags.office-office-cleaning.background');
	--font-color: theme('colors.tags.office-office-cleaning.invert');
}

.asteria-color__office-office-rent {
	--color: theme('colors.tags.office-office-rent.background');
	--font-color: theme('colors.tags.office-office-rent.invert');
}

.asteria-color__office-office-supplice {
	--color: theme('colors.tags.office-office-supplice.background');
	--font-color: theme('colors.tags.office-office-supplice.invert');
}

.asteria-color__office-office-mortgage {
	--color: theme('colors.tags.office-office-mortgage.background');
	--font-color: theme('colors.tags.office-office-mortgage.invert');
}

.asteria-color__office-office-renovation {
	--color: theme('colors.tags.office-office-renovation.background');
	--font-color: theme('colors.tags.office-office-renovation.invert');
}

.asteria-color__office-office-pets {
	--color: theme('colors.tags.office-office-pets.background');
	--font-color: theme('colors.tags.office-office-pets.invert');
}

.asteria-color__office-office-gardening {
	--color: theme('colors.tags.office-office-gardening.background');
	--font-color: theme('colors.tags.office-office-gardening.invert');
}

.asteria-color__office-office-security {
	--color: theme('colors.tags.office-office-security.background');
	--font-color: theme('colors.tags.office-office-security.invert');
}

.asteria-color__office-office-vacation {
	--color: theme('colors.tags.office-office-vacation.background');
	--font-color: theme('colors.tags.office-office-vacation.invert');
}

.asteria-color__office-office-sanitation {
	--color: theme('colors.tags.office-office-sanitation.background');
	--font-color: theme('colors.tags.office-office-sanitation.invert');
}

.asteria-color__office-office-services {
	--color: theme('colors.tags.office-office-services.background');
	--font-color: theme('colors.tags.office-office-services.invert');
}

.asteria-color__transport-transport-parking {
	--color: theme('colors.tags.transport-transport-parking.background');
	--font-color: theme('colors.tags.transport-transport-parking.invert');
}

.asteria-color__transport-transport-fuel {
	--color: theme('colors.tags.transport-transport-fuel.background');
	--font-color: theme('colors.tags.transport-transport-fuel.invert');
}

.asteria-color__transport-transport-services {
	--color: theme('colors.tags.transport-transport-services.background');
	--font-color: theme('colors.tags.transport-transport-services.invert');
}

.asteria-color__transport-transport-load {
	--color: theme('colors.tags.transport-transport-load.background');
	--font-color: theme('colors.tags.transport-transport-load.invert');
}

.asteria-color__transport-transport-cleaning {
	--color: theme('colors.tags.transport-transport-cleaning.background');
	--font-color: theme('colors.tags.transport-transport-cleaning.invert');
}

.asteria-color__transport-transport-publictransportation {
	--color: theme('colors.tags.transport-transport-publictransportation.background');
	--font-color: theme('colors.tags.transport-transport-publictransportation.invert');
}

.asteria-color__transport-transport-taxi {
	--color: theme('colors.tags.transport-transport-taxi.background');
	--font-color: theme('colors.tags.transport-transport-taxi.invert');
}

.asteria-color__transport-transport-motorbike {
	--color: theme('colors.tags.transport-transport-motorbike.background');
	--font-color: theme('colors.tags.transport-transport-motorbike.invert');
}

.asteria-color__transport-transport-other {
	--color: theme('colors.tags.transport-transport-other.background');
	--font-color: theme('colors.tags.transport-transport-other.invert');
}

.asteria-color__children-children-clothing {
	--color: theme('colors.tags.children-children-clothing.background');
	--font-color: theme('colors.tags.children-children-clothing.invert');
}

.asteria-color__children-children-toys {
	--color: theme('colors.tags.children-children-toys.background');
	--font-color: theme('colors.tags.children-children-toys.invert');
}

.asteria-color__children-children-activity {
	--color: theme('colors.tags.children-children-activity.background');
	--font-color: theme('colors.tags.children-children-activity.invert');
}

.asteria-color__children-children-care {
	--color: theme('colors.tags.children-children-care.background');
	--font-color: theme('colors.tags.children-children-care.invert');
}

.asteria-color__children-children-babysitting {
	--color: theme('colors.tags.children-children-babysitting.background');
	--font-color: theme('colors.tags.children-children-babysitting.invert');
}

.asteria-color__children-children-allowance {
	--color: theme('colors.tags.children-children-allowance.background');
	--font-color: theme('colors.tags.children-children-allowance.invert');
}

.asteria-color__children-children-school {
	--color: theme('colors.tags.children-children-school.background');
	--font-color: theme('colors.tags.children-children-school.invert');
}

.asteria-color__children-children-other {
	--color: theme('colors.tags.children-children-other.background');
	--font-color: theme('colors.tags.children-children-other.invert');
}

.asteria-color__health-health-pharmacy {
	--color: theme('colors.tags.health-health-pharmacy.background');
	--font-color: theme('colors.tags.health-health-pharmacy.invert');
}

.asteria-color__health-health-healthcare {
	--color: theme('colors.tags.health-health-healthcare.background');
	--font-color: theme('colors.tags.health-health-healthcare.invert');
}

.asteria-color__health-health-sports {
	--color: theme('colors.tags.health-health-sports.background');
	--font-color: theme('colors.tags.health-health-sports.invert');
}

.asteria-color__health-health-beauty {
	--color: theme('colors.tags.health-health-beauty.background');
	--font-color: theme('colors.tags.health-health-beauty.invert');
}

.asteria-color__health-health-hair {
	--color: theme('colors.tags.health-health-hair.background');
	--font-color: theme('colors.tags.health-health-hair.invert');
}

.asteria-color__health-health-spa {
	--color: theme('colors.tags.health-health-spa.background');
	--font-color: theme('colors.tags.health-health-spa.invert');
}

.asteria-color__health-health-optician {
	--color: theme('colors.tags.health-health-optician.background');
	--font-color: theme('colors.tags.health-health-optician.invert');
}

.asteria-color__health-health-other {
	--color: theme('colors.tags.health-health-other.background');
	--font-color: theme('colors.tags.health-health-other.invert');
}

.asteria-color__financial-financial-bank {
	--color: theme('colors.tags.financial-financial-bank.background');
	--font-color: theme('colors.tags.financial-financial-bank.invert');
}

.asteria-color__financial-financial-load {
	--color: theme('colors.tags.financial-financial-load.background');
	--font-color: theme('colors.tags.financial-financial-load.invert');
}

.asteria-color__financial-financial-latefees {
	--color: theme('colors.tags.financial-financial-latefees.background');
	--font-color: theme('colors.tags.financial-financial-latefees.invert');
}

.asteria-color__financial-financial-other {
	--color: theme('colors.tags.financial-financial-other.background');
	--font-color: theme('colors.tags.financial-financial-other.invert');
}

.asteria-color__shopping-shopping-multimedia {
	--color: theme('colors.tags.shopping-shopping-multimedia.background');
	--font-color: theme('colors.tags.shopping-shopping-multimedia.invert');
}

.asteria-color__shopping-shopping-clothing {
	--color: theme('colors.tags.shopping-shopping-clothing.background');
	--font-color: theme('colors.tags.shopping-shopping-clothing.invert');
}

.asteria-color__shopping-shopping-tailor {
	--color: theme('colors.tags.shopping-shopping-tailor.background');
	--font-color: theme('colors.tags.shopping-shopping-tailor.invert');
}

.asteria-color__shopping-shopping-books {
	--color: theme('colors.tags.shopping-shopping-books.background');
	--font-color: theme('colors.tags.shopping-shopping-books.invert');
}

.asteria-color__shopping-shopping-alcohol {
	--color: theme('colors.tags.shopping-shopping-alcohol.background');
	--font-color: theme('colors.tags.shopping-shopping-alcohol.invert');
}

.asteria-color__shopping-shopping-electronics {
	--color: theme('colors.tags.shopping-shopping-electronics.background');
	--font-color: theme('colors.tags.shopping-shopping-electronics.invert');
}

.asteria-color__shopping-shopping-tobacco {
	--color: theme('colors.tags.shopping-shopping-tobacco.background');
	--font-color: theme('colors.tags.shopping-shopping-tobacco.invert');
}

.asteria-color__shopping-shopping-flowers {
	--color: theme('colors.tags.shopping-shopping-flowers.background');
	--font-color: theme('colors.tags.shopping-shopping-flowers.invert');
}

.asteria-color__shopping-shopping-gifts {
	--color: theme('colors.tags.shopping-shopping-gifts.background');
	--font-color: theme('colors.tags.shopping-shopping-gifts.invert');
}

.asteria-color__shopping-shopping-jewelery {
	--color: theme('colors.tags.shopping-shopping-jewelery.background');
	--font-color: theme('colors.tags.shopping-shopping-jewelery.invert');
}

.asteria-color__shopping-shopping-other {
	--color: theme('colors.tags.shopping-shopping-other.background');
	--font-color: theme('colors.tags.shopping-shopping-other.invert');
}

.asteria-color__leisure-leisure-theather {
	--color: theme('colors.tags.leisure-leisure-theather.background');
	--font-color: theme('colors.tags.leisure-leisure-theather.invert');
}

.asteria-color__leisure-leisure-membership {
	--color: theme('colors.tags.leisure-leisure-membership.background');
	--font-color: theme('colors.tags.leisure-leisure-membership.invert');
}

.asteria-color__leisure-leisure-bar {
	--color: theme('colors.tags.leisure-leisure-bar.background');
	--font-color: theme('colors.tags.leisure-leisure-bar.invert');
}

.asteria-color__leisure-leisure-parties {
	--color: theme('colors.tags.leisure-leisure-parties.background');
	--font-color: theme('colors.tags.leisure-leisure-parties.invert');
}

.asteria-color__leisure-leisure-charities {
	--color: theme('colors.tags.leisure-leisure-charities.background');
	--font-color: theme('colors.tags.leisure-leisure-charities.invert');
}

.asteria-color__leisure-leisure-golfing {
	--color: theme('colors.tags.leisure-leisure-golfing.background');
	--font-color: theme('colors.tags.leisure-leisure-golfing.invert');
}

.asteria-color__leisure-leisure-outdoor {
	--color: theme('colors.tags.leisure-leisure-outdoor.background');
	--font-color: theme('colors.tags.leisure-leisure-outdoor.invert');
}

.asteria-color__leisure-leisure-art {
	--color: theme('colors.tags.leisure-leisure-art.background');
	--font-color: theme('colors.tags.leisure-leisure-art.invert');
}

.asteria-color__leisure-leisure-dance {
	--color: theme('colors.tags.leisure-leisure-dance.background');
	--font-color: theme('colors.tags.leisure-leisure-dance.invert');
}

.asteria-color__leisure-leisure-photo {
	--color: theme('colors.tags.leisure-leisure-photo.background');
	--font-color: theme('colors.tags.leisure-leisure-photo.invert');
}

.asteria-color__leisure-leisure-music {
	--color: theme('colors.tags.leisure-leisure-music.background');
	--font-color: theme('colors.tags.leisure-leisure-music.invert');
}

.asteria-color__leisure-leisure-hunting {
	--color: theme('colors.tags.leisure-leisure-hunting.background');
	--font-color: theme('colors.tags.leisure-leisure-hunting.invert');
}

.asteria-color__leisure-leisure-boat {
	--color: theme('colors.tags.leisure-leisure-boat.background');
	--font-color: theme('colors.tags.leisure-leisure-boat.invert');
}

.asteria-color__leisure-leisure-betting {
	--color: theme('colors.tags.leisure-leisure-betting.background');
	--font-color: theme('colors.tags.leisure-leisure-betting.invert');
}

.asteria-color__leisure-leisure-ski {
	--color: theme('colors.tags.leisure-leisure-ski.background');
	--font-color: theme('colors.tags.leisure-leisure-ski.invert');
}

.asteria-color__leisure-leisure-horse {
	--color: theme('colors.tags.leisure-leisure-horse.background');
	--font-color: theme('colors.tags.leisure-leisure-horse.invert');
}

.asteria-color__leisure-leisure-biking {
	--color: theme('colors.tags.leisure-leisure-biking.background');
	--font-color: theme('colors.tags.leisure-leisure-biking.invert');
}

.asteria-color__leisure-leisure-other {
	--color: theme('colors.tags.leisure-leisure-other.background');
	--font-color: theme('colors.tags.leisure-leisure-other.invert');
}

.asteria-color__education-education-loan {
	--color: theme('colors.tags.education-education-loan.background');
	--font-color: theme('colors.tags.education-education-loan.invert');
}

.asteria-color__education-education-fee {
	--color: theme('colors.tags.education-education-fee.background');
	--font-color: theme('colors.tags.education-education-fee.invert');
}

.asteria-color__education-education-equipment {
	--color: theme('colors.tags.education-education-equipment.background');
	--font-color: theme('colors.tags.education-education-equipment.invert');
}

.asteria-color__education-education-other {
	--color: theme('colors.tags.education-education-other.background');
	--font-color: theme('colors.tags.education-education-other.invert');
}

.asteria-color__travel-travel-hotel {
	--color: theme('colors.tags.travel-travel-hotel.background');
	--font-color: theme('colors.tags.travel-travel-hotel.invert');
}

.asteria-color__travel-travel-transport {
	--color: theme('colors.tags.travel-travel-transport.background');
	--font-color: theme('colors.tags.travel-travel-transport.invert');
}

.asteria-color__travel-travel-entertainment {
	--color: theme('colors.tags.travel-travel-entertainment.background');
	--font-color: theme('colors.tags.travel-travel-entertainment.invert');
}

.asteria-color__travel-travel-fooddrink {
	--color: theme('colors.tags.travel-travel-fooddrink.background');
	--font-color: theme('colors.tags.travel-travel-fooddrink.invert');
}

.asteria-color__travel-travel-other {
	--color: theme('colors.tags.travel-travel-other.background');
	--font-color: theme('colors.tags.travel-travel-other.invert');
}

.asteria-color__investment-investment-buffer {
	--color: theme('colors.tags.investment-investment-buffer.background');
	--font-color: theme('colors.tags.investment-investment-buffer.invert');
}

.asteria-color__investment-investment-pension {
	--color: theme('colors.tags.investment-investment-pension.background');
	--font-color: theme('colors.tags.investment-investment-pension.invert');
}

.asteria-color__investment-investment-securities {
	--color: theme('colors.tags.investment-investment-securities.background');
	--font-color: theme('colors.tags.investment-investment-securities.invert');
}

.asteria-color__investment-investment-children {
	--color: theme('colors.tags.investment-investment-children.background');
	--font-color: theme('colors.tags.investment-investment-children.invert');
}

.asteria-color__investment-investment-other {
	--color: theme('colors.tags.investment-investment-other.background');
	--font-color: theme('colors.tags.investment-investment-other.invert');
}

.asteria-color__food-food-groceries {
	--color: theme('colors.tags.food-food-groceries.background');
	--font-color: theme('colors.tags.food-food-groceries.invert');
}

.asteria-color__food-food-fastfood {
	--color: theme('colors.tags.food-food-fastfood.background');
	--font-color: theme('colors.tags.food-food-fastfood.invert');
}

.asteria-color__food-food-restaurants {
	--color: theme('colors.tags.food-food-restaurants.background');
	--font-color: theme('colors.tags.food-food-restaurants.invert');
}

.asteria-color__food-food-kiosk {
	--color: theme('colors.tags.food-food-kiosk.background');
	--font-color: theme('colors.tags.food-food-kiosk.invert');
}

.asteria-color__food-food-other {
	--color: theme('colors.tags.food-food-other.background');
	--font-color: theme('colors.tags.food-food-other.invert');
}

.asteria-color__insurance-insurance-building {
	--color: theme('colors.tags.insurance-insurance-building.background');
	--font-color: theme('colors.tags.insurance-insurance-building.invert');
}

.asteria-color__insurance-insurance-car {
	--color: theme('colors.tags.insurance-insurance-car.background');
	--font-color: theme('colors.tags.insurance-insurance-car.invert');
}

.asteria-color__insurance-insurance-life {
	--color: theme('colors.tags.insurance-insurance-life.background');
	--font-color: theme('colors.tags.insurance-insurance-life.invert');
}

.asteria-color__insurance-insurance-income {
	--color: theme('colors.tags.insurance-insurance-income.background');
	--font-color: theme('colors.tags.insurance-insurance-income.invert');
}

.asteria-color__insurance-insurance-accident {
	--color: theme('colors.tags.insurance-insurance-accident.background');
	--font-color: theme('colors.tags.insurance-insurance-accident.invert');
}

.asteria-color__insurance-insurance-other {
	--color: theme('colors.tags.insurance-insurance-other.background');
	--font-color: theme('colors.tags.insurance-insurance-other.invert');
}

.asteria-color__financial-financial-loan {
	--color: theme('colors.tags.financial-financial-loan.background');
	--font-color: theme('colors.tags.financial-financial-loan.invert');
}

.asteria-color__transport-transport-loan {
	--color: theme('colors.tags.transport-transport-loan.background');
	--font-color: theme('colors.tags.transport-transport-loan.invert');
}
