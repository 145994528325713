@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@import './styles/colors.scss';

.asteria-component__select {
	.asteria-component__label__wrapper {
		@apply flex flex-col gap-2;

		&.asteria-component__label__wrapper--direction-horizontal {
			@apply flex-row;
		}
	}

	.asteria-component__typography {
		@apply m-0 h-auto p-0;
	}

	.asteria-component__select__inner {
		@apply flex-1;

		.asteria-component__select--dropdown {
			> .asteria-component__button {
				@apply max-h-10 w-full rounded-lg border border-solid p-2;

				min-height: theme('spacing.10');

				.asteria-component__button--icon-only {
					min-height: theme('spacing.8');
					min-width: theme('spacing.8');

					&.asteria--size-medium,
					&.asteria--size-md {
						min-height: theme('spacing.10');
						min-width: theme('spacing.10');
					}

					.asteria-component__icon {
						&,
						.asteria-component__icon-content {
							@apply h-3 w-3;
						}
					}
				}

				&.asteria--size-small,
				&.asteria--size-sm {
					@apply max-h-8;

					min-height: theme('spacing.8');

					.asteria-component__icon {
						&,
						.asteria-component__icon-content {
							@apply h-2 w-2;
						}
					}
				}

				&.asteria--size-large,
				&.asteria--size-lg {
					@apply max-h-12 p-3;

					min-height: theme('spacing.12');
				}

				&:not(.asteria-component__button--icon-only) {
					&.asteria-component__button--icon-position-first {
						@apply pl-0;
					}

					&.asteria-component__button--icon-position-last {
						@apply pr-0;
					}
				}
			}
		}
	}
}
