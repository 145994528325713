.asteria-graph-area {
	@apply grid w-full grid-rows-graph;
	grid-auto-columns: max-content;

	.asteria-graph-bar-group {
		grid-row: 1;
	}

	&.asteria--feature-dragging {
		@apply cursor-grab;
	}
}

// .asteria-cashflow-graph {
//   &:not(.asteria-cashflow__mode-credit) {
//     .asteria-graph-area {
//       &:not(.asteria-graph-area-stacked) {
//         &.asteria-graph-area-lines {
//           grid-template-rows: 1fr;
//         }
//       }
//     }
//   }
// }
