@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-account {
			.recharts-legend-wrapper {
				.asteria-component__legends {
					@apply flex-wrap items-center justify-center gap-x-4 gap-y-2;
				}
			}

			.recharts-tooltip-wrapper {
				.asteria-component__chart-tooltip-line {
					@apply flex flex-row justify-between;
				}

				.asteria-component__chart-tooltip-label {
					@apply flex items-center gap-1;
				}

				.asteria-component__prefix {
					@apply flex w-3 items-center justify-center;
				}

				.asteria-component__text {
					&.asteria--type-title,
					&.asteria--type-total {
						@apply font-semibold;
					}
				}
			}
		}
	}
}
