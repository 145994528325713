@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__navigation__item {
	--width-navigation-icon: theme('spacing.10');
	--width-navigation-label: 1fr;
	--width-navigation-collapse: theme('spacing.8');

	@apply grid items-center gap-2;

	grid-template-areas: 'navigation-icon navigation-label navigation-collapse';

	grid-template-columns:
		var(--width-navigation-icon)
		var(--width-navigation-label)
		var(--width-navigation-collapse);

	.asteria-component__navigation__item__icon {
		grid-area: navigation-icon;
	}

	.asteria-component__navigation__item__label {
		grid-area: navigation-label;
	}

	.asteria-component__navigation__item__collapse {
		grid-area: navigation-collapse;
	}

	&.asteria-component__navigation__item--no-label {
		@apply gap-0;

		grid-template-areas: 'navigation-icon navigation-collapse';
		grid-template-columns:
			var(--width-navigation-icon)
			var(--width-navigation-collapse);

		// &.asteria-component__navigation__item--no-icon {
		// 	grid-template-areas: 'navigation-collapse';
		// 	grid-template-columns: var(--width-navigation-collapse);
		// }

		&.asteria-component__navigation__item--no-sub {
			grid-template-areas: 'navigation-icon';
			grid-template-columns: var(--width-navigation-icon);
		}
	}

	// &.asteria-component__navigation__item--no-icon {
	// 	grid-template-areas: 'navigation-label navigation-collapse';
	// 	grid-template-columns:
	// 		var(--width-navigation-label)
	// 		var(--width-navigation-collapse);

	// 	&.asteria-component__navigation__item--no-label {
	// 		grid-template-areas: 'navigation-collapse';
	// 		grid-template-columns: var(--width-navigation-collapse);
	// 	}

	// 	&.asteria-component__navigation__item--no-sub {
	// 		grid-template-areas: 'navigation-label';
	// 		grid-template-columns: var(--width-navigation-label);
	// 	}
	// }

	&.asteria-component__navigation__item--no-sub {
		grid-template-areas: 'navigation-icon navigation-label';
		grid-template-columns:
			var(--width-navigation-icon)
			var(--width-navigation-label);

		&.asteria-component__navigation__item--no-label {
			grid-template-areas: 'navigation-icon';
			grid-template-columns: var(--width-navigation-icon);
		}

		// &.asteria-component__navigation__item--no-icon {
		// 	grid-template-areas: 'navigation-label';
		// 	grid-template-columns: var(--width-navigation-label);
		// }
	}

	&.asteria-component__navigation__item--variant-stacked {
		@apply place-content-center place-items-center gap-1;

		.asteria-component__navigation__item__label {
			@apply h-auto;
		}

		grid-template-areas: 'navigation-icon navigation-collapse' 'navigation-label navigation-label';
		grid-template-columns: auto;
		grid-template-rows:
			var(--width-navigation-icon)
			minmax(min-content, max-content);

		&.asteria-component__navigation__item--no-label {
			grid-template-areas: 'navigation-icon navigation-collapse';
			grid-template-columns: auto;
			grid-template-rows: auto;

			// &.asteria-component__navigation__item--no-icon {
			// 	grid-template-areas: 'navigation-collapse';
			// }

			&.asteria-component__navigation__item--no-sub {
				grid-template-areas: 'navigation-icon';
			}
		}

		// &.asteria-component__navigation__item--no-icon {
		// 	grid-template-areas: 'navigation-collapse' 'navigation-label';
		// 	grid-template-columns: auto;
		// 	grid-template-rows:
		// 		var(--width-navigation-icon)
		// 		minmax(min-content, max-content);

		// 	&.asteria-component__navigation__item--no-label {
		// 		grid-template-areas: 'navigation-collapse';
		// 		grid-template-rows: auto;
		// 	}

		// 	&.asteria-component__navigation__item--no-sub {
		// 		grid-template-areas: 'navigation-label';
		// 		grid-template-rows: auto;
		// 	}
		// }

		&.asteria-component__navigation__item--no-sub {
			grid-template-areas: 'navigation-icon' 'navigation-label';
			grid-template-columns: auto;
			grid-template-rows:
				var(--width-navigation-icon)
				minmax(min-content, max-content);

			&.asteria-component__navigation__item--no-label {
				grid-template-areas: 'navigation-icon';
				grid-template-rows: auto;
			}

			// &.asteria-component__navigation__item--no-icon {
			// 	grid-template-areas: 'navigation-label';
			// 	grid-template-rows: auto;
			// }
		}
	}

	&.asteria-component__navigation__item__dropdown {
		@apply box-border flex w-full;

		> .asteria-component__dropdown {
			@apply box-border w-full;
		}
	}
}
