@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__form-v2__label {
	.asteria-component__form-v2__switch-wrapper {
		@apply contents;
	}
}

.asteria-component__form-v2__input-wrapper {
	&.asteria--state-active {
		.asteria-component__form-v2__input-wrapper__input {
			@apply rounded-full border-form-switch-focus-border bg-form-switch-focus-background;
		}
	}

	&.asteria--state-disabled {
		.asteria-component__form-v2__input-wrapper__input {
			@apply cursor-default border-form-switch-disabled-border bg-form-switch-disabled-background;
		}

		.asteria-component__form-v2__switch__track {
			@apply border-form-switch-track-disabled-border bg-form-switch-track-disabled-background;
		}

		.asteria-component__form-v2__switch__thumb__wrapper {
			@apply bg-form-switch-thumb-disabled-border;

			.asteria-component__form-v2__switch__thumb {
				@apply bg-form-switch-thumb-disabled-background;

				&.asteria--state-has-icon {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-form-switch-thumb-disabled-icon;
						}
					}
				}
			}
		}

		&.asteria--state-active {
			.asteria-component__form-v2__input-wrapper__input {
				@apply cursor-default border-form-switch-disabled-focus-border bg-form-switch-disabled-focus-background;
			}

			.asteria-component__form-v2__switch__track {
				@apply border-form-switch-track-disabled-focus-border bg-form-switch-track-disabled-focus-background;
			}

			.asteria-component__form-v2__switch__thumb__wrapper {
				@apply bg-form-switch-thumb-disabled-focus-border;

				.asteria-component__form-v2__switch__thumb {
					@apply bg-form-switch-thumb-disabled-focus-background;

					&.asteria--state-has-icon {
						.asteria-component__icon {
							.asteria-component__icon-content {
								@apply bg-form-switch-thumb-disabled-focus-icon;
							}
						}
					}
				}
			}
		}
	}
}

.asteria-component__form-v2__switch {
	--thumb-size: theme('spacing.4');
	--thumb-border-size: 2px;

	.asteria-component__form-v2__input-wrapper__inner {
		@apply w-fit;
	}

	.asteria-component__form-v2__input-wrapper__input {
		@apply relative box-border inline-block cursor-pointer rounded-full border-2 border-solid border-form-switch-normal-border bg-form-switch-normal-background p-0;
	}

	.asteria-component__form-v2__switch__thumb__wrapper {
		@apply absolute left-0 top-0 z-10 box-border flex items-center justify-center rounded-full bg-form-switch-thumb-normal-border;
		height: calc(var(--thumb-size) + var(--thumb-border-size) * 2);
		width: calc(var(--thumb-size) + var(--thumb-border-size) * 2);
		transition: 0.1s linear;

		.asteria-component__form-v2__switch__thumb {
			@apply flex items-center justify-center rounded-full bg-form-switch-thumb-normal-background;
			height: var(--thumb-size);
			width: var(--thumb-size);

			&.asteria--state-has-icon {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply h-2 w-2 bg-form-switch-thumb-normal-icon;
					}
				}
			}
		}
	}

	input {
		@apply hidden;

		&:checked {
			~ .asteria-component__form-v2__switch__thumb__wrapper {
				@apply bg-form-switch-thumb-focus-border;

				transition: 0.1s linear;

				left: calc(
					100% -
						calc(var(--thumb-size) + var(--thumb-border-size) * 2)
				);

				.asteria-component__form-v2__switch__thumb {
					@apply bg-form-switch-thumb-focus-background;

					&.asteria--state-has-icon {
						.asteria-component__icon {
							.asteria-component__icon-content {
								@apply bg-form-switch-thumb-focus-icon;
							}
						}
					}
				}
			}

			~ .asteria-component__form-v2__switch__track {
				@apply border-form-switch-track-focus-border bg-form-switch-track-focus-background;
			}
		}
	}

	// &:hover {
	// 	input {
	// 		~ .asteria-component__form-v2__switch__thumb__wrapper {
	// 			@apply bg-form-switch-thumb-hover-border;

	// 			.asteria-component__form-v2__switch__thumb {
	// 				@apply bg-form-switch-thumb-hover-background;

	// 				&.asteria--state-has-icon {
	// 					.asteria-component__icon {
	// 						.asteria-component__icon-content {
	// 							@apply bg-form-switch-thumb-hover-icon;
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}

	// 	&.asteria--state-disabled {
	// 		@apply pointer-events-none;

	// 		.asteria-component__form-v2__input-wrapper__input {
	// 			@apply border-form-switch-disabled-border bg-form-switch-disabled-background;
	// 		}

	// 		input {
	// 			~ .asteria-component__form-v2__switch__thumb__wrapper {
	// 				@apply bg-form-switch-thumb-disabled-border;

	// 				.asteria-component__form-v2__switch__thumb {
	// 					@apply bg-form-switch-thumb-disabled-background;

	// 					&.asteria--state-has-icon {
	// 						.asteria-component__icon {
	// 							.asteria-component__icon-content {
	// 								@apply bg-form-switch-thumb-disabled-icon;
	// 							}
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}

	// 		~ .asteria-component__form-v2__switch__track {
	// 			@apply border-form-switch-track-disabled-border bg-form-switch-track-disabled-background;
	// 		}
	// 	}
	// }

	.asteria-component__form-v2__switch__track {
		@apply box-border h-5 w-11 rounded-full border border-solid border-form-switch-track-normal-border bg-form-switch-track-normal-background;
	}

	&.asteria--size-sm {
		--thumb-size: theme('spacing.2');

		.asteria-component__form-v2__switch__track {
			@apply h-3 w-7;
		}
	}

	&.asteria--size-lg {
		--thumb-size: theme('spacing.6');

		.asteria-component__form-v2__switch__thumb__wrapper {
			.asteria-component__form-v2__switch__thumb {
				&.asteria--state-has-icon {
					.asteria-component__icon-content {
						@apply h-3 w-3;
					}
				}
			}
		}

		.asteria-component__form-v2__input-wrapper__input {
			@apply p-1;
		}

		.asteria-component__form-v2__switch__track {
			@apply h-5 w-12;
		}
	}
}
