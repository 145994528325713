@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__form-v2__label {
	@apply grid items-center;

	grid-template-areas:
		'input-label input-help'
		'input-inner input-inner'
		'input-error input-error';

	grid-template-columns: 1fr minmax(min-content, max-content);
	grid-template-rows:
		minmax(min-content, max-content)
		minmax(min-content, max-content)
		minmax(min-content, max-content);

	&.asteria--position-bottom {
		grid-template-areas:
			'input-inner input-inner'
			'input-label input-help'
			'input-error input-error';

		grid-template-columns: 1fr minmax(min-content, max-content);
		grid-template-rows:
			minmax(min-content, max-content)
			minmax(min-content, max-content)
			minmax(min-content, max-content);

		.asteria-component__form-v2__label-inner {
			@apply mb-0 mt-2;
		}
	}

	&.asteria--position-left {
		grid-template-areas:
			'x input-help'
			'input-label input-inner'
			'input-error input-error';

		grid-template-columns:
			minmax(min-content, max-content)
			1fr;

		grid-template-rows:
			minmax(min-content, max-content)
			minmax(min-content, max-content)
			minmax(min-content, max-content);

		.asteria-component__form-v2__label-inner {
			@apply mb-0 mr-2;
		}
	}

	&.asteria--position-right {
		grid-template-areas:
			'x input-help'
			'input-inner input-label'
			'input-error input-error';

		grid-template-columns: 1fr minmax(min-content, max-content);
		grid-template-rows:
			minmax(min-content, max-content)
			minmax(min-content, max-content)
			minmax(min-content, max-content);

		.asteria-component__form-v2__label-inner {
			@apply mb-0 ml-2;
		}
	}

	&.asteria--align-vertical-top {
	}

	&.asteria--align-vertical-center {
	}

	&.asteria--align-vertical-bottom {
	}

	.asteria-component__form-v2__label-inner {
		@apply mb-2 flex items-center gap-2;

		grid-area: input-label;

		.asteria-component__typography {
			@apply text-form-label-normal-text;
		}
	}

	.asteria-component__form-v2__label-tooltip {
	}

	.asteria-component__form-v2__input-wrapper {
		@apply contents;
	}

	&.asteria--state-w-fit {
		&.asteria--position-left,
		&.asteria--position-right {
			@apply w-fit;
		}
	}
}
