@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-form-input-normal-background: #ffffff;
	--color-form-input-normal-border: #b2b2b2;
	--color-form-input-normal-text: theme('colors.content.text.normal');
	--color-form-input-normal-placeholder: theme('colors.content.text.muted');
	--color-form-input-normal-icon: theme('colors.icon.normal');

	--color-form-input-hover-background: #ffffff;
	--color-form-input-hover-border: #b2b2b2;
	--color-form-input-hover-text: theme('colors.content.text.hover');
	--color-form-input-hover-placeholder: theme('colors.content.text.muted');
	--color-form-input-hover-icon: theme('colors.icon.normal');

	--color-form-input-focus-background: rgba(250, 250, 250, 1);
	--color-form-input-focus-border: #000000;
	--color-form-input-focus-text: theme('colors.content.text.focus');
	--color-form-input-focus-placeholder: theme('colors.content.text.muted');
	--color-form-input-focus-icon: #000000;

	--color-form-input-disabled-background: theme('colors.gray.100');
	--color-form-input-disabled-border: theme('colors.gray.300');
	--color-form-input-disabled-text: theme('colors.content.text.muted');
	--color-form-input-disabled-placeholder: theme('colors.content.text.muted');
	--color-form-input-disabled-icon: theme('colors.icon.disabled');
}

.asteria-component__input {
	&:not(.asteria-component__input--type-range) {
		.asteria-component__input__inner {
			@apply rounded-md;

			--color-alert-error-normal-border: theme('colors.alert.error.icon.normal.background');
			--color-alert-success-normal-border: theme('colors.alert.success.icon.normal.background');
			--color-alert-info-normal-border: theme('colors.alert.info.icon.normal.background');
			--color-alert-warning-normal-border: theme('colors.alert.warning.icon.normal.background');

			input,
			textarea {
				@apply font-light placeholder:font-light;
			}
		}

		&.asteria--size-lg {
			.asteria-component__typography {
				@apply text-lg;
			}

			.asteria-component__input__inner {
				input,
				textarea {
					@apply px-4 text-lg placeholder:text-lg;
				}
			}

			&.asteria--state-has-visible {
				.asteria-component__input__inner {
					&::after {
						@apply px-4 text-lg;
					}
				}
			}
		}

		&.asteria--size-md {
			.asteria-component__typography {
				@apply text-base;
			}

			.asteria-component__input__inner {
				input,
				textarea {
					@apply px-3 text-base placeholder:text-base;
				}
			}

			&.asteria--state-has-visible {
				.asteria-component__input__inner {
					&::after {
						@apply px-3 text-base;
					}
				}
			}
		}

		&.asteria--size-sm {
			.asteria-component__typography {
				@apply text-sm;
			}

			.asteria-component__input__inner {
				input,
				textarea {
					@apply px-3 text-sm placeholder:text-sm;
				}
			}

			&.asteria--state-has-visible {
				.asteria-component__input__inner {
					&::after {
						@apply px-3 text-sm;
					}
				}
			}
		}

		&.asteria-component__input--has-icon {
			&.asteria-component__input--icon-position-first {
				.asteria-component__input__inner {
					input,
					textarea {
						@apply pl-0;
					}
				}

				&.asteria--state-has-visible {
					.asteria-component__input__inner {
						&::after {
							@apply pl-0;
						}
					}
				}
			}

			&.asteria-component__input--icon-position-last {
				.asteria-component__input__inner {
					input,
					textarea {
						@apply pr-0;
					}
				}

				&.asteria--state-has-visible {
					.asteria-component__input__inner {
						&::after {
							@apply pr-0;
						}
					}
				}
			}
		}
	}
}
