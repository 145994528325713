@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-graph-bar-group-wrapper {
	grid-row: 1;

	&.asteria--type-history {
		@include apply-colors('graph-group-historical');
	}

	&.asteria--type-today {
		@include apply-colors('graph-group-today');
	}

	&.asteria--type-forecast {
		@include apply-colors('graph-group-forecast');
	}
}

.asteria-graph-bar-group {
	@apply relative grid h-full;
	@include apply-colors('graph-group');
	width: var(--graph-group-width, 150px);
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;

	z-index: 2;

	transform: scale(-1);

	.asteria-component__old__graph__bar {
		@apply row-span-full mx-2;

		&.asteria-deposit {
			@include apply-colors('graph-group-deposit');
			grid-column: 2;
		}

		&.asteria-withdraw {
			@include apply-colors('graph-group-withdraw');
			grid-column: 1;
		}
	}

	&.asteria-graph-bar-group-history {
		.asteria-component__old__graph__bar {
			&.asteria-deposit {
				@include apply-colors('graph-group-historical-deposit');
			}

			&.asteria-withdraw {
				@include apply-colors('graph-group-historical-withdraw');
			}
		}
	}

	&.asteria-graph-bar-group-today {
		.asteria-component__old__graph__bar {
			&.asteria-deposit {
				@include apply-colors('graph-group-today-deposit');
			}

			&.asteria-withdraw {
				@include apply-colors('graph-group-today-withdraw');
			}
		}
	}

	&.asteria-graph-bar-group-forecast {
		.asteria-component__old__graph__bar {
			&.asteria-deposit {
				@include apply-colors('graph-group-forecast-deposit');
			}

			&.asteria-withdraw {
				@include apply-colors('graph-group-forecast-withdraw');
			}
		}
	}

	.asteria-graph-bar-group-background {
		@apply col-span-full;
	}

	&.asteria-graph-bar-group__layout-stacked {
		grid-template-columns: 100%;
		grid-template-rows: 50% 50%;
		transform: scale(1);

		.asteria-component__old__graph__bar {
			@apply col-span-full m-0 px-2;

			&.asteria-deposit {
				@apply m-0;
				grid-row: 1;
				transform: scale(-1);
			}

			&.asteria-withdraw {
				@apply m-0;
				grid-row: 2;
			}

			&:hover {
				.asteria-bar-indicator {
					@apply hidden;
				}
			}
		}
	}
}
