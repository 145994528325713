@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__actions {
	&.asteria-component__forecaster-actions {
		@apply gap-4;
	}
}

.asteria-component__forecaster-actions-section {
	.asteria-component__forecaster-actions-section-title {
		@apply h-10;
	}

	.asteria-component__forecaster-actions-section-content {
		@apply flex flex-col gap-2;
	}

	.asteria-component__forecaster-actions-section-content-placeholder {
		@apply bg-white p-4;
	}
}
