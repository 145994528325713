@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__wizard {
	&.asteria-component__wrapper {
		@apply bg-wizard-normal-background;

		> .asteria-component__wrapper-header,
		> .asteria-component__wrapper-content,
		> .asteria-component__wrapper-footer {
			@apply bg-transparent;
		}
	}

	> .asteria-component__wizard__content {
		@apply flex-row items-center gap-2;

		> .asteria-component__wizard__inner {
			@apply flex flex-1 flex-col gap-4;

			.asteria-component__wizard-progress {
				@apply gap-0 text-center;

				> .asteria-component__group {
					grid-template-columns: 1fr;
				}

				&.asteria-component__wizard-progress--variant-arrows {
					> .asteria-component__group {
						grid-template-columns: auto 1fr auto;
					}
				}

				.asteria-component__wizard-progress__icon {
					@apply box-border flex h-8 w-8 items-center justify-center;

					.asteria-component__icon {
						@apply rounded-full p-1;
						@apply bg-alert-success-icon-normal-background;

						&,
						.asteria-component__icon-content {
							@apply h-4 w-4;
						}

						.asteria-component__icon-content {
							@apply bg-alert-success-normal-background;
						}
					}
				}
			}
		}
	}
}

.asteria-component__wizard__pagination {
	@apply flex flex-col items-center justify-center gap-2;

	.asteria-component__wizard__pagination__dots {
		@apply flex w-full flex-wrap items-center justify-center gap-2;

		.asteria-component__wizard__pagination__dot {
			@apply block h-3 w-3 rounded-full border border-solid;
			@apply border-wizard-pagination-dot-normal-border bg-wizard-pagination-dot-normal-background;

			transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;

			&.asteria--state-clickable {
				@apply cursor-pointer;
			}

			&:hover {
				@apply border-wizard-pagination-dot-hover-border bg-wizard-pagination-dot-hover-background;
			}

			&.asteria--state-active {
				@apply border-wizard-pagination-dot-focus-border bg-wizard-pagination-dot-focus-background;
			}
		}
	}

	&.asteria-component__wizard__pagination--type-details {
		> .asteria-component__wizard__pagination__inner {
			@apply flex flex-col gap-4;

			> .asteria-component__wizard__pagination__details {
				@apply flex items-center gap-4 border border-solid;
				@apply border-wizard-pagination-details-normal-border bg-wizard-pagination-details-normal-background;

				&.asteria--state-clickable {
					@apply cursor-pointer;
				}

				> .asteria-component__typography-group {
					> .asteria-component__typography {
						@apply text-wizard-pagination-details-normal-title;

						&.asteria--size-sm {
							@apply text-wizard-pagination-details-normal-subtitle;
						}
					}
				}

				> .asteria-component__wizard__pagination__details__index {
					@apply relative;

					> .asteria-component__wizard__pagination__details__index__content {
						@apply relative flex h-10 w-10 items-center justify-center rounded-full border border-solid;
						@apply border-wizard-pagination-details-counter-normal-border bg-wizard-pagination-details-counter-normal-background;

						z-index: 1;

						> .asteria-component__typography {
							@apply text-wizard-pagination-details-counter-normal-text;
						}
					}

					&::before {
						@apply absolute top-0 left-1/2 bottom-0 bg-wizard-pagination-details-counter-normal-border;

						content: '';
						z-index: 0;
						transform: translateX(-50%);
						width: 1px;
					}
				}

				&:not(:first-child) {
					> .asteria-component__wizard__pagination__details__index {
						&::before {
							@apply -top-3;
						}
					}
				}

				&:not(:last-child) {
					> .asteria-component__wizard__pagination__details__index {
						&::before {
							@apply -bottom-3;
						}
					}
				}

				&:hover {
					@apply border-wizard-pagination-details-hover-border bg-wizard-pagination-details-hover-background;

					> .asteria-component__typography-group {
						> .asteria-component__typography {
							@apply text-wizard-pagination-details-hover-title;

							&.asteria--size-sm {
								@apply text-wizard-pagination-details-hover-subtitle;
							}
						}
					}

					> .asteria-component__wizard__pagination__details__index {
						> .asteria-component__wizard__pagination__details__index__content {
							@apply border-wizard-pagination-details-counter-hover-border bg-wizard-pagination-details-counter-hover-background;

							> .asteria-component__typography {
								@apply text-wizard-pagination-details-counter-hover-text;
							}
						}
					}
				}

				&.asteria--state-active {
					@apply border-wizard-pagination-details-focus-border bg-wizard-pagination-details-focus-background;

					> .asteria-component__typography-group {
						> .asteria-component__typography {
							@apply text-wizard-pagination-details-focus-title;

							&.asteria--size-sm {
								@apply text-wizard-pagination-details-focus-subtitle;
							}
						}
					}

					> .asteria-component__wizard__pagination__details__index {
						> .asteria-component__wizard__pagination__details__index__content {
							@apply border-wizard-pagination-details-counter-focus-border bg-wizard-pagination-details-counter-focus-background;

							> .asteria-component__typography {
								@apply text-wizard-pagination-details-counter-focus-text;
							}
						}
					}
				}

				&.asteria--state-disabled {
					@apply border-wizard-pagination-details-disabled-border bg-wizard-pagination-details-disabled-background;

					> .asteria-component__typography-group {
						> .asteria-component__typography {
							@apply text-wizard-pagination-details-disabled-title;

							&.asteria--size-sm {
								@apply text-wizard-pagination-details-disabled-subtitle;
							}
						}
					}

					> .asteria-component__wizard__pagination__details__index {
						> .asteria-component__wizard__pagination__details__index__content {
							@apply border-wizard-pagination-details-counter-disabled-border bg-wizard-pagination-details-counter-disabled-background;

							> .asteria-component__typography {
								@apply text-wizard-pagination-details-counter-disabled-text;
							}
						}
					}
				}
			}
		}
	}
}
