@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__breadcrumbs {
	.asteria-component__button {
		&:not(.asteria-component__button--variant-select) {
			&.asteria-component__button--variant-link {
				&.asteria--size-lg,
				&.asteria--size-md,
				&.asteria--size-sm {
					@apply px-0;
				}
			}
		}
	}

	> .asteria-component__icon {
		&,
		.asteria-component__icon-content {
			@apply h-3 w-3;
		}

		--color-icon-hover: var(--color-icon-normal);
	}
}
