@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__typography {
	@apply m-0;

	&.asteria-component__typography--align-left {
		@apply text-left;
	}

	&.asteria-component__typography--align-center {
		@apply text-center;
	}

	&.asteria-component__typography--align-right {
		@apply text-right;
	}

	&.asteria-component__typography--align-justify {
		@apply text-justify;
	}

	&.asteria--type-separator {
		@apply flex items-center justify-center gap-4 whitespace-nowrap;

		&::before,
		&::after {
			@apply block w-full border-0 border-b border-solid border-border-normal;

			content: '';
		}
	}
}

.asteria-component__title {
	@apply font-title font-title-font-weight text-content-title-normal;

	&--size-page-title {
		@apply font-page-title text-page-title font-page-title-font-weight text-content-page-title;
	}

	&--size-title-1,
	&--size-xl,
	&--size-x-large,
	&--size-xlarge {
		@apply text-title-xl;
	}

	&--size-title-2,
	&--size-lg,
	&--size-large {
		@apply text-title-lg;
	}

	&--size-title-3,
	&--size-base,
	&--size-medium,
	&--size-md,
	&--size-default {
		@apply text-title-base;
	}

	&--size-title-4,
	&--size-sm,
	&--size-small {
		@apply text-title-sm;
	}

	&--size-title-5,
	&--size-xs,
	&--size-xsmall,
	&--size-x-small {
		@apply text-title-xs;
	}

	&--size-title-6,
	&--size-xxs,
	&--size-xx-small,
	&--size-xxsmall {
		@apply text-title-xxs;
	}
}

.asteria-component__text {
	@apply font-body text-base font-text-font-weight
    text-content-text-normal;

	&--size-xl,
	&--size-x-large,
	&--size-xlarge {
		@apply text-xl;
	}

	&--size-lg,
	&--size-large {
		@apply text-lg;
	}

	&--size-base,
	&--size-p,
	&--size-medium,
	&--size-md,
	&--size-default {
		@apply text-base;
	}

	&--size-sm,
	&--size-small {
		@apply text-sm;
	}

	&--size-xs,
	&--size-x-small,
	&--size-xsmall {
		@apply text-xs;
	}

	&--size-xxs,
	&--size-xx-small,
	&--size-xxsmall {
		@apply text-xxs;
	}

	&--weight-thin {
		@apply font-thin;
	}

	&--weight-extralight {
		@apply font-extralight;
	}

	&--weight-light {
		@apply font-light;
	}

	&--weight-normal,
	&--weight-regular {
		@apply font-normal;
	}

	&--weight-medium {
		@apply font-medium;
	}

	&--weight-semibold {
		@apply font-semibold;
	}

	&--weight-bold {
		@apply font-bold;
	}

	&--weight-black {
		@apply font-black;
	}
}

ul {
	@include reset-list;
}

.asteria-component__unordered {
	@apply m-0;

	.asteria-component__unordered__item {
		@apply flex flex-row items-baseline gap-4;

		&.asteria-component__unordered__item--icon {
			> .asteria-component__icon {
				&,
				.asteria-component__icon-content {
					height: var(--prefix__height, var(--prefix__size));
					width: var(--prefix__width, var(--prefix__size));
				}
			}
		}

		&:not(.asteria-component__unordered__item--icon) {
			&:before {
				@apply inline-block shrink-0 rounded-full bg-button-primary-normal-background;

				height: var(--prefix__height, var(--prefix__size));
				width: var(--prefix__width, var(--prefix__size));

				content: '';
			}
		}

		.asteria-component__typography {
			@apply m-0;
		}

		&.asteria-component__unordered__item--level-2 {
			@apply pl-7;
		}
	}
}
