@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__group {
	@apply grid gap-2;

	&.asteria--variant-flex {
		@apply flex flex-col;
	}

	&--direction-vertical {
		@apply grid-flow-row;

		// grid-auto-rows: 1fr;
	}

	&--direction-horizontal {
		@apply grid-flow-col;

		// grid-auto-columns: 1fr;

		&.asteria--variant-flex {
			@apply flex-row;
		}
	}

	&--vertical-align-baseline {
		@apply items-baseline;
	}

	&--vertical-align-top {
		@apply items-start;
	}

	&--vertical-align-center {
		@apply items-center;
	}

	&--vertical-align-bottom {
		@apply items-end;
	}

	&--horizontal-align-left {
		@apply justify-start;
	}

	&--horizontal-align-center {
		@apply justify-center;
	}

	&--horizontal-align-right {
		@apply justify-end;
	}

	&--horizontal-align-space-between {
		@apply justify-between;
	}
}
