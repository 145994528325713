@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-view__auth-modal {
	&.asteria--variant-2fa-deactivate {
	}

	&.asteria--variant-2fa-setup {
	}

	&.asteria--variant-2fa-verify {
	}
}

.asteria-component__auth-2fa {
	&.asteria--variant-deactivate,
	&.asteria--variant-setup,
	&.asteria--variant-verify {
		.asteria-component__wrapper-content {
			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply px-0;
				}
			}
		}

		.asteria-component__typography-group {
			@apply flex flex-col gap-2;
		}

		.asteria-component__alert {
			&.asteria--type-backup {
				@apply p-8;

				.asteria-component__group {
					@apply flex-1 gap-4;
				}
			}
		}

		.asteria-component__list {
			.asteria-component__list__item {
				&:not(:last-child) {
					@apply border-0 border-b border-solid border-border-normal bg-white px-4 py-2;
				}

				> * {
					@apply flex-1;
				}
			}
		}

		.asteria-component__section {
			&.asteria--type-code-reset {
				@apply mt-4 flex flex-col gap-4;
			}
		}
	}

	&.asteria--variant-deactivate {
	}

	&.asteria--variant-setup {
	}

	&.asteria--variant-verify {
	}
}
