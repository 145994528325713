@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	// Variant=Icon
	--color-button-icon-normal-background: #ffffff;
	--color-button-icon-normal-border: theme('colors.border.normal');
	--color-button-icon-normal-icon: theme('colors.icon.normal');

	--color-button-icon-hover-background: #f3e3e0;
	--color-button-icon-hover-border: #f3e3e0;
	--color-button-icon-hover-icon: theme('colors.icon.hover');

	--color-button-icon-focus-background: #f3e3e0;
	--color-button-icon-focus-border: #f3e3e0;
	--color-button-icon-focus-icon: theme('colors.icon.focus');

	// Variant=Primary
	--color-button-primary-normal-background: theme('colors.primary.normal');
	--color-button-primary-normal-border: theme('colors.primary.normal');
	--color-button-primary-normal-text: white;
	--color-button-primary-normal-icon: white;

	--color-button-primary-hover-background: theme('colors.primary.focus');
	--color-button-primary-hover-border: theme('colors.primary.focus');
	--color-button-primary-hover-text: white;
	--color-button-primary-hover-icon: white;

	--color-button-primary-focus-background: theme('colors.primary.focus');
	--color-button-primary-focus-border: theme('colors.primary.focus');
	--color-button-primary-focus-text: white;
	--color-button-primary-focus-icon: white;

	--color-button-primary-disabled-background: theme('colors.primary.disabled');
	--color-button-primary-disabled-border: theme('colors.primary.disabled');
	--color-button-primary-disabled-text: white;
	--color-button-primary-disabled-icon: white;

	// Variant=Secondary
	--color-button-secondary-normal-background: theme('colors.secondary.normal');
	--color-button-secondary-normal-border: theme('colors.secondary.normal');
	--color-button-secondary-normal-text: white;
	--color-button-secondary-normal-icon: white;

	--color-button-secondary-hover-background: theme('colors.secondary.focus');
	--color-button-secondary-hover-border: theme('colors.secondary.focus');
	--color-button-secondary-hover-text: white;
	--color-button-secondary-hover-icon: white;

	--color-button-secondary-focus-background: theme('colors.secondary.focus');
	--color-button-secondary-focus-border: theme('colors.secondary.focus');
	--color-button-secondary-focus-text: white;
	--color-button-secondary-focus-icon: white;

	--color-button-secondary-disabled-background: theme('colors.secondary.disabled');
	--color-button-secondary-disabled-border: theme('colors.secondary.disabled');
	--color-button-secondary-disabled-text: white;
	--color-button-secondary-disabled-icon: white;

	// Variant=Tertiary
	--color-button-tertiary-normal-background: theme('colors.tertiary.normal');
	--color-button-tertiary-normal-border: theme('colors.tertiary.normal');
	--color-button-tertiary-normal-text: #3c3c3c;
	--color-button-tertiary-normal-icon: #3c3c3c;

	--color-button-tertiary-hover-background: theme('colors.tertiary.focus');
	--color-button-tertiary-hover-border: theme('colors.tertiary.focus');
	--color-button-tertiary-hover-text: #3c3c3c;
	--color-button-tertiary-hover-icon: #3c3c3c;

	--color-button-tertiary-focus-background: theme('colors.tertiary.focus');
	--color-button-tertiary-focus-border: theme('colors.tertiary.focus');
	--color-button-tertiary-focus-text: #3c3c3c;
	--color-button-tertiary-focus-icon: #3c3c3c;

	--color-button-tertiary-disabled-background: theme('colors.tertiary.disabled');
	--color-button-tertiary-disabled-border: theme('colors.tertiary.disabled');
	--color-button-tertiary-disabled-text: #3c3c3c;
	--color-button-tertiary-disabled-icon: #3c3c3c;

	// Variant=Flat
	--color-button-flat-hover-background: #f0dfdc;
	--color-button-flat-focus-background: #f0dfdc;

	// Variant=Link
	--color-button-link-normal-background: transparent;
	--color-button-link-normal-border: transparent;
	--color-button-link-normal-text: theme('colors.link.normal');
	--color-button-link-normal-icon: theme('colors.link.normal');

	--color-button-link-hover-background: transparent;
	--color-button-link-hover-border: transparent;
	--color-button-link-hover-text: theme('colors.link.focus');
	--color-button-link-hover-icon: theme('colors.link.focus');

	--color-button-link-focus-background: transparent;
	--color-button-link-focus-border: transparent;
	--color-button-link-focus-text: theme('colors.link.focus');
	--color-button-link-focus-icon: theme('colors.link.focus');

	--color-button-link-disabled-background: transparent;
	--color-button-link-disabled-border: transparent;
	--color-button-link-disabled-text: theme('colors.link.disabled');
	--color-button-link-disabled-icon: theme('colors.link.disabled');

	// Variant=Tab
	--color-button-tab-normal-background: rgba(243, 243, 243, 1);
	--color-button-tab-normal-border: rgba(243, 243, 243, 1);
	--color-button-tab-normal-text: #6e6e73;
	--color-button-tab-normal-icon: #6e6e73;

	--color-button-tab-hover-background: #f3e3e0;
	--color-button-tab-hover-border: #f3e3e0;
	--color-button-tab-hover-text: #000;
	--color-button-tab-hover-icon: #6e6e73;

	--color-button-tab-focus-background: #f3e3e0;
	--color-button-tab-focus-border: #f3e3e0;
	--color-button-tab-focus-text: #000;
	--color-button-tab-focus-icon: #6e6e73;

	--color-button-tab-disabled-background: ;
	--color-button-tab-disabled-border: ;
	--color-button-tab-disabled-text: #6e6e73;
	--color-button-tab-disabled-icon: #6e6e73;

	// Variant=Href
	--color-button-href-normal-background: transparent;
	--color-button-href-normal-border: transparent;
	--color-button-href-normal-text: theme('colors.link.normal');
	--color-button-href-normal-icon: theme('colors.link.normal');

	--color-button-href-hover-background: transparent;
	--color-button-href-hover-border: transparent;
	--color-button-href-hover-text: theme('colors.link.focus');
	--color-button-href-hover-icon: theme('colors.link.focus');

	--color-button-href-focus-background: transparent;
	--color-button-href-focus-border: transparent;
	--color-button-href-focus-text: theme('colors.link.focus');
	--color-button-href-focus-icon: theme('colors.link.focus');

	--color-button-href-disabled-background: transparent;
	--color-button-href-disabled-border: transparent;
	--color-button-href-disabled-text: theme('colors.link.disabled');
	--color-button-href-disabled-icon: theme('colors.link.disabled');

	// Variant=Assist
	--color-button-assist-normal-background: white;
	--color-button-assist-normal-border: white;
	--color-button-assist-normal-text: theme('colors.link.normal');
	--color-button-assist-normal-icon: theme('colors.link.normal');

	--color-button-assist-hover-background: white;
	--color-button-assist-hover-border: white;
	--color-button-assist-hover-text: theme('colors.link.focus');
	--color-button-assist-hover-icon: theme('colors.link.focus');

	--color-button-assist-focus-background: white;
	--color-button-assist-focus-border: white;
	--color-button-assist-focus-text: theme('colors.link.focus');
	--color-button-assist-focus-icon: theme('colors.link.focus');

	--color-button-assist-disabled-background: white;
	--color-button-assist-disabled-border: white;
	--color-button-assist-disabled-text: theme('colors.link.disabled');
	--color-button-assist-disabled-icon: theme('colors.link.disabled');
}

.asteria-component__button {
	@apply transition-colors ease-in-out;

	.asteria-component__typography {
		@apply font-normal;
		@apply transition-colors ease-in-out;
	}

	.asteria-component__icon {
		.asteria-component__icon-content {
			@apply transition-colors ease-in-out;
		}
	}

	&:not(.asteria-component__button--variant-select) {
		&.asteria--size-lg {
			@include asteria-btn-lg;
		}

		&.asteria--size-md {
			@include asteria-btn-md;
		}

		&.asteria--size-sm {
			@include asteria-btn-sm;
		}

		&.asteria--size-xs {
			@include asteria-btn-xs;
		}

		&.asteria-component__button--variant-tab {
			@include asteria-btn-tab;
		}
	}

	&.asteria-component__button--icon-only {
		@apply rounded-full;

		&:not([class*='variant']):not(.asteria-component__button > .asteria-component__button),
		:is(.asteria-component__modal, .asteria-component__dialog, .asteria-component__sidepane) > .asteria-component__wrapper > .asteria-component__wrapper-header &,
		:is(.asteria-component__modal, .asteria-component__dialog, .asteria-component__sidepane) > .asteria-component__form > .asteria-component__wrapper > .asteria-component__wrapper-header & {
			@include asteria-btn-rounded;
		}
	}
}
