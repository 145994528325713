.asteria-component__badge {
	@apply box-border inline-flex aspect-square h-6 w-6 cursor-pointer items-center justify-center rounded-full text-xs font-light;

	.asteria-component__icon {
		&,
		.asteria-component__icon-content {
			@apply h-3 w-3;
		}
	}

	&.asteria--size-small,
	&.asteria--size-sm {
		@apply h-4 w-4 text-xs;

		.asteria-component__icon {
			&,
			.asteria-component__icon-content {
				@apply h-2 w-2;
			}
		}
	}

	&.asteria--size-large,
	&.asteria--size-lg {
		@apply h-8 w-8 text-base;

		.asteria-component__icon {
			&,
			.asteria-component__icon-content {
				@apply h-4 w-4;
			}
		}
	}
}

.asteria-component__badge {
	@apply bg-badge-normal-background text-badge-normal-text;

	.asteria-component__typography {
		@apply text-badge-normal-text;
	}

	.asteria-component__icon-content {
		@apply bg-badge-normal-icon;
	}

	&:hover {
		@apply bg-badge-hover-background text-badge-hover-text;

		.asteria-component__typography {
			@apply text-badge-hover-text;
		}

		.asteria-component__icon-content {
			@apply bg-badge-hover-icon;
		}
	}

	&.asteria--state-active {
		@apply bg-badge-focus-background text-badge-focus-text;

		.asteria-component__typography {
			@apply text-badge-focus-text;
		}

		.asteria-component__icon:hover {
			.asteria-component__icon-content {
				@apply bg-badge-focus-icon;
			}
		}
	}

	&.asteria--state-disabled {
		@apply bg-badge-disabled-background text-badge-disabled-text;

		.asteria-component__typography {
			@apply text-badge-disabled-text;
		}

		.asteria-component__icon:hover {
			.asteria-component__icon-content {
				@apply bg-badge-disabled-icon;
			}
		}
	}
}

.asteria-component__badge__wrapper {
	@apply relative;

	.asteria-component__badge {
		@apply absolute;
	}
}
