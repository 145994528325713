@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__auth-footer {
	@apply flex;
}

.asteria-component__auth-header {
	@apply flex;
}

.asteria-view__auth-wrapper {
	grid-template-rows: min-content minmax(max-content, 1fr) min-content;
}
