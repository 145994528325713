@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__alert {
	&.asteria-component__migration-alert {
		.asteria-component__wrapper {
			@apply items-center;

			> .asteria-component__wrapper-footer {
				> .asteria-component__wrapper-footer__section {
					@apply justify-end;
				}
			}
		}
	}
}
