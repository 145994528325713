@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-forecast-status {
			.asteria-component__card-section {
				&.asteria--variant-overview {
					.asteria-component__button {
						&.asteria-component__button--variant-link {
							@apply h-auto w-auto p-0;
						}
					}

					.asteria-component__card-section-list {
						@apply grid items-center gap-4;

						grid-template-columns: auto minmax(0, 1fr) auto auto auto;

						.asteria-component__card-section-list-row {
							@apply contents;

							.asteria-component__button {
								@apply truncate;
							}

							.asteria-component__typography {
								@apply truncate;
							}
						}
					}
				}

				&.asteria--variant-help {
					.asteria-component__button {
						@apply h-auto w-auto p-0;
					}
				}
			}
		}
	}
}
