@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-view__clients {
	@apply h-full;

	> .asteria-component__wrapper {
		@apply h-full overflow-visible;

		.asteria-component__page-header {
			@apply mb-4;
		}

		> .asteria-component__wrapper-header {
			@apply bg-transparent;
		}

		> .asteria-component__wrapper-content,
		> .asteria-component__wrapper-footer {
			@apply px-0;
		}
	}
}
