@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__prefix {
	@apply border-prefix-border bg-prefix-background;

	background-color: var(--color, initial);
	background-image: var(--image, initial);

	&.status-idle {
		@apply bg-status-idle;
	}

	&.status-importing {
		@apply bg-status-importing;
	}

	&.status-initiating {
		@apply bg-status-initiating;
	}

	&.status-error {
		@apply bg-status-error;
	}

	&.not-connected {
		@apply bg-status-disconnected;
	}

	&.disabled {
		@apply bg-status-disabled;
	}
}
