.asteria-component__color-picker {
	.asteria-component__dropdown__toggle {
		@apply gap-1 p-3;
	}

	.asteria-component__color-picker__item {
		@apply aspect-square;

		.asteria-component__dropdown__item__label {
			@apply relative flex aspect-square items-center justify-center;
		}

		.asteria-component__color-picker__item--active {
			@apply absolute;

			.asteria-component__icon-content {
				@apply h-2 w-2 bg-white;
			}

			&:not(.asteria--state-active):not(.asteria--state-disabled):hover {
				.asteria-component__icon-content {
					@apply bg-white;
				}
			}
		}

		&:not(.asteria--state-active):not(.asteria--state-disabled):hover {
			.asteria-component__color-picker__item--active {
				.asteria-component__icon-content {
					@apply bg-white;
				}
			}
		}
	}
}
