@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--fill-onboarding-landing-icon: theme('colors.icon.focus');

	--color-onboarding-box-normal-background: transparent;
	--color-onboarding-box-normal-border: theme('colors.border.normal');
	--color-onboarding-box-hover-background: #f5f5f5;
	--color-onboarding-box-hover-border: #f5f5f5;
}

.asteria-view__onboarding {
	--color-onboarding-splash-normal-border: #eee;
	--color-onboarding-splash-normal-background: #eee;
	--color-onboarding-splash-normal-icon: theme('colors.primary.normal');

	.asteria-component__onboarding__splash {
		.asteria-component__splash-header {
			@apply pb-0;
		}

		.asteria-component__button {
			&.asteria-component__button--variant-secondary {
				@include asteria-btn-primary;
			}
		}
	}
}

.asteria-component__onboarding-box {
	--color-alert-error-normal-border: theme('colors.alert.error.icon.normal.background');
	--color-alert-success-normal-border: theme('colors.alert.success.icon.normal.background');
	--color-alert-warning-normal-border: theme('colors.alert.warning.icon.normal.background');
	--color-alert-info-normal-border: theme('colors.alert.info.icon.normal.background');

	.asteria-component__dropdown {
		--color-button-icon-normal-background: transparent;
		--color-button-icon-normal-border: transparent;
	}
}

.asteria-component__onboarding-step {
	.asteria-component__button {
		&.asteria-component__onboarding-step__help {
			&.asteria-component__button--variant-assist {
				@include asteria-btn-sm(false, true, true);
				@apply px-0;
			}
		}
	}
}

.asteria-component__onboarding-loading-dialog {
	.asteria-component__wrapper {
		> .asteria-component__wrapper-header {
			&.asteria-component__wrapper-header--absolute-postfix {
				.asteria-component__wrapper-header__postfix--position-absolute {
					@apply top-2 right-2;

					> .asteria-component__button {
						@include asteria-btn-xs;
					}
				}
			}
		}
	}
}

.asteria-component__onboarding-action {
	--color-button-icon-normal-background: transparent;
	--color-button-icon-normal-border: transparent;
}
