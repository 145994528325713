@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__wrapper-footer {
	@media (max-width: 767px) {
		@apply flex flex-col-reverse items-center justify-center gap-4 bg-white px-5 py-5;

		.asteria-component__wrapper-footer__section {
			@apply w-full;
		}

		.asteria-component__button:not(.asteria-component__button--variant-select).asteria--size-sm,
		.asteria-component__button:not(.asteria-component__button--variant-select).asteria--size-md,
		.asteria-component__button:not(.asteria-component__button--variant-select).asteria--size-lg {
			@apply w-full
          justify-center;
		}
	}

	@media (min-width: theme('screens.md')) {
		@apply flex items-end justify-between gap-2 bg-wrapper-footer-normal-background p-6;
	}

	&.asteria--size-1 {
		.asteria-component__wrapper-footer__section {
			@apply flex-1;
		}
	}
}

.asteria-component__wrapper-footer__section {
	@apply order-first flex items-center gap-3;

	@media (max-width: theme('screens.md')) {
		@apply flex-col;
	}

	&.asteria--state-loading {
		@apply flex-1;
	}

	&.asteria-component__wrapper-footer__section--position-last {
		@apply order-last justify-end;
	}
}
