@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-categories {
			.asteria-component__card-content {
				flex-direction: row;
			}

			.asteria-component__pie-chart-wrapper {
				height: 200px;
			}
		}
	}

	.asteria--variant-categories {
		.asteria-component__chip {
		}
	}
}
