@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__form-v2__datepicker-content__calendar {
	@apply grid;

	.asteria-component__form-v2__datepicker-content__calendar-header,
	.asteria-component__form-v2__datepicker-content__calendar-content {
		@apply contents;
	}

	&.asteria-datepicker--type-day {
		grid-template-columns: repeat(7, 1fr);
	}

	&.asteria-datepicker--type-week,
	&.asteria-datepicker--type-month,
	&.asteria-datepicker--type-year {
		grid-template-columns: repeat(4, 1fr);
	}
}

.asteria-component__form-v2__datepicker-content__calendar-cell {
	@apply flex aspect-square items-center justify-center p-2;
}
