@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__group.asteria-component__selector-switch {
	@apply p-4;
}

.asteria-component__selector {
	&.asteria-component__forecaster-selector {
		&.asteria--variant-select {
			&.asteria--type-categories {
				.asteria-component__dropdown__toggle {
					.asteria-component__chip {
						@apply pointer-events-none;
					}
				}
			}
		}
	}
}

.asteria-component__selector-item {
	&.asteria-component__forecaster-selector-item {
		&.asteria--variant-create {
			.asteria-component__button {
				@apply w-full gap-2;

				.asteria-component__button {
					@apply h-auto w-auto;
				}
			}
		}

		&.asteria--variant-category {
			.asteria-component__chip {
				@apply pointer-events-none;
			}

			&:not(.asteria--state-probability),
			&.asteria--state-probability.asteria--state-check {
				.asteria-component__dropdown__item__postfix {
					.asteria-component__probability__wrapper {
						@apply hidden;
					}
				}
			}

			&:not(.asteria--state-check) {
				.asteria-component__dropdown__item__postfix {
					.asteria-component__button {
						&.asteria--action-check {
							@apply hidden;
						}
					}
				}
			}

			&:not(.asteria--state-editable) {
				.asteria-component__dropdown__item__postfix {
					.asteria-component__button {
						&.asteria--action-edit {
							@apply hidden;
						}
					}
				}
			}

			&.asteria--state-editable {
				&:not(:hover) {
					.asteria-component__dropdown__item__postfix {
						.asteria-component__button {
							&.asteria--action-edit {
								@apply hidden;
							}
						}
					}
				}

				&:hover {
					.asteria-component__dropdown__item__postfix {
						.asteria-component__probability__wrapper {
							@apply hidden;
						}

						.asteria-component__button {
							&.asteria--action-check {
								@apply hidden;
							}
						}
					}
				}
			}
		}
	}
}
