@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-forecaster-page-category-background: white;
	--color-forecaster-page-category-section-probability-background: #fafafa;
	--color-forecaster-page-category-section-probability-border: #f3f3f3;

	--color-forecaster-page-category-section-actions-sticky-background: white;
}

.asteria-component__forecaster-page {
	&.asteria--type-category {
		@apply relative flex flex-col gap-8;

		.asteria-component__forecaster-page-content {
			@apply relative flex flex-col gap-8 p-4;

			background-color: var(--color-forecaster-page-category-background);
		}

		.asteria-component__forecaster-page-content-main {
			@apply flex flex-col gap-8;
		}

		&.asteria--view-transactions {
			.asteria-component__forecaster-page-category-information:not(:empty),
			.asteria-component__forecaster-page-section.asteria--type-actions {
				@apply sticky bottom-0 mx-8 border border-solid border-border-normal shadow;

				background-color: var(
					--color-forecaster-page-category-section-actions-sticky-background
				);
			}

			.asteria-component__forecaster-page-section.asteria--type-actions {
				@apply p-8;
			}

			.asteria-component__forecaster-page-category-information:not(:empty) {
				@apply p-2;
			}
		}

		.asteria-component__forecaster-switcher {
			@apply h-12;
		}

		> .asteria-component__form {
			@apply contents;
		}

		.asteria-component__forecaster-page-section {
			&.asteria--type-header {
				@apply gap-4;

				&.asteria--variant-action {
					grid-template-columns: auto 1fr auto;
				}

				&.asteria--variant-category {
					grid-template-columns: 1fr auto;

					&.asteria--feature-forecaster-navigation-back-button-label {
						@apply gap-0;

						grid-template-areas:
							'navigation-back navigation-actions'
							'navigation-content navigation-content';

						grid-template-columns: 1fr auto;

						> .asteria-component__forecaster-page-section-content {
							@apply contents;
						}

						.asteria-component__level-navigation {
							@apply contents;

							.asteria-component__level-navigation-back-wrapper {
								grid-area: navigation-back;
							}

							.asteria-component__level-navigation-content {
								grid-area: navigation-content;
							}
						}

						.asteria-component__forecaster-switcher {
							grid-area: navigation-actions;
						}
					}
				}

				.asteria-component__typography-group {
					@apply flex flex-col gap-2;
				}

				.asteria-component__selector {
					@apply w-fit;

					.asteria-component__dropdown__toggle {
						@apply p-0;
					}

					.asteria-component__button {
						.asteria-utils-position-last {
							@apply m-0;
						}
					}
				}
			}

			&.asteria--type-graph {
				@apply relative;

				.asteria-component__forecaster-toggle {
					@apply absolute right-8 -top-4;

					.asteria-component__typography {
						@apply font-body;
					}
				}

				.asteria-component__forecaster-page-section-content {
					grid-template-columns: auto 1fr auto;

					.asteria-component__forecaster-graph {
						height: 40vh;
						min-height: 300px;
					}

					> .asteria-component__group {
						@apply h-full;

						grid-template-rows: 1fr auto;
					}
				}

				> .asteria-component__forecaster-axis {
					&.asteria--variant-x {
						&.asteria--state-has-y-axis {
							--button-spacing: calc(
								theme('spacing.8') + theme('spacing.2')
							);

							margin-left: 0;
							padding-left: calc(
								var(--button-spacing) +
									var(--forecaster-y-axis-width) +
									theme('spacing.4')
							);

							margin-right: var(--button-spacing);

							.asteria-component__forecaster-axis-prefix {
								max-width: calc(
									var(--button-spacing) +
										var(--forecaster-y-axis-width)
								);
							}
						}
					}
				}
			}

			&.asteria--type-transactions {
			}

			&.asteria--type-saving {
			}

			&.asteria--type-actions {
				@apply gap-4;
			}

			&.asteria--type-information {
				@apply mx-auto flex flex-col items-center justify-center text-center;

				max-width: 500px;
			}
		}
	}
}
