@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__snackbar--type-integration-token-copy {
	z-index: 99999;
}

.asteria-component__onboarding-actions {
	.asteria-component__onboarding-action-content {
		.asteria-component__progress.asteria-component__progress--variant-circle {
			--progress__height: theme('spacing.8');
			--progress__width: theme('spacing.8');

			--progress__circlethickness: theme('spacing.2');
			--color-progress-normal-background: theme(
				'colors.secondary.normal'
			);
			--progress__value__width: calc(
				var(--progress__width) - var(--progress__circlethickness)
			);
			--progress__value__height: calc(
				var(--progress__height) - var(--progress__circlethickness)
			);
		}
	}
}

.asteria-component__onboarding-connection-custom {
	.asteria-component__input:not(.asteria-component__input--type-range) {
		.asteria-component__input__inner {
			input,
			textarea {
				@apply text-sm;
			}
		}
	}
}
