@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__forecaster-clients__helper {
	.asteria-component__forecaster-selector__helper__element--type-check {
	}

	.asteria-component__forecaster-selector__helper__element--type-edit {
	}
}

.asteria-component__forecaster-clients__item {
	&:not(.asteria--state-hoverable) {
		&:not(:hover) {
			.asteria-component__forecaster-clients__helper {
				> * {
					@apply hidden;
				}
			}
		}
	}
}
