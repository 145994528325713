@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__loading {
	@apply absolute inset-1 z-40 flex flex-col items-center justify-center p-10;

	.asteria-component__typography {
		@apply m-0;
	}

	.asteria-component__progress {
		@apply my-2 w-full px-0;
	}

	&.asteria-component__loading--blur {
		backdrop-filter: blur(8px);
	}
}
