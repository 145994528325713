@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__resizer {
	@apply box-border grid h-full overflow-hidden;

	.asteria-component__resizer-handle {
		grid-area: resizer-handle;
	}

	.asteria-component__resizer-element {
		&.asteria--position-first {
			grid-area: resizer-first;
		}

		&.asteria--position-last {
			grid-area: resizer-last;
		}
	}

	&.asteria-component__resizer--direction-horizontal {
		grid-template-areas: 'resizer-first resizer-last';
		grid-template-columns: 1fr auto;

		.asteria-component__resizer-handle {
			@apply my-0 mx-4;

			> .asteria-component__button {
				@apply h-5 w-1 cursor-col-resize;
			}
		}

		&.asteria--state-active {
			grid-template-areas: 'resizer-first resizer-handle resizer-last';
			grid-template-columns: 1fr auto 1fr;
		}
	}

	&.asteria-component__resizer--direction-vertical {
		grid-template-areas: 'resizer-first' 'resizer-last';
		grid-template-rows: 1fr auto;

		.asteria-component__resizer-handle {
			@apply mx-0 my-4;

			> .asteria-component__button {
				@apply h-1 w-5 cursor-row-resize;
			}
		}

		&.asteria--state-active {
			grid-template-areas: 'resizer-first' 'resizer-handle' 'resizer-last';
			grid-template-rows: 1fr auto 1fr;
		}
	}

	&:not(.asteria--state-active) {
		> .asteria-component__resizer-handle {
			@apply hidden;
		}
	}
}

.asteria-component__resizer-element {
	@apply overflow-hidden;
}

.asteria-component__resizer-handle {
	@apply place-self-center;

	> .asteria-component__button {
		@apply block aspect-square rounded-full border-0 p-0 opacity-40 transition-all;

		content: '';
		background: #9f8c82;

		&:hover {
			background: #4c2d2c;
			@apply opacity-100;
		}

		&:focus,
		&:active {
			background: #4c2d2c;
			@apply opacity-100;
		}
	}
}
