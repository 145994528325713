@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--graph-yaxis-wrapper-width: 96px;
}

.asteria-graph-yaxis-lines {
	@apply flex flex-col justify-between;

	z-index: 1;

	.asteria-graph-yaxis-line {
		@apply border-0 border-b border-solid border-graph-yaxis-part-line-normal-background;

		&.asteria-graph-yaxis-line-zero {
			@apply border border-graph-yaxis-part-line-normal-zero;
		}
	}
}

.asteria-graph-yaxis-labels {
	@apply relative flex flex-col items-end;
	@include apply-colors('graph-yaxis-wrapper');
	width: var(--graph-yaxis-wrapper-width, 96px);

	.asteria-graph-yaxis-label {
		@apply flex shrink-0 grow flex-col justify-end;
		@include apply-colors('graph-yaxis-part');

		&:first-of-type {
			@apply grow-0;
		}

		.asteria-component__text {
			@apply text-graph-yaxis-part-normal-text;

			&:hover {
				@apply text-graph-yaxis-part-hover-text;
			}

			&:focus {
				@apply text-graph-yaxis-part-focus-text;
			}
		}
	}

	.asteria-prefix-y-axis {
		@apply absolute right-4 -top-4 text-graph-yaxis-prefix-normal-text;
	}
}
