@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-basic {
			.asteria-component__card-header {
				.asteria-component__wrapper-header__postfix {
					@media (max-width: theme('screens.tablet')) {
						@apply hidden;
					}
				}

				.asteria-component__typography {
					&.asteria-component__title {
						@media (max-width: theme('screens.tablet')) {
							@apply text-title-xs;
						}
					}
				}
			}

			.asteria-component__card-section {
				&.asteria--variant-card-rating {
					@apply absolute -bottom-5 h-10 bg-transparent;
					> .asteria-component__group {
						@apply rounded-lg border border-solid border-card-normal-border bg-white px-2 opacity-0;

						transition: all 0.2s linear;
					}

					&:hover {
						> .asteria-component__group {
							@apply opacity-100;
						}
					}

					> .asteria-component__feedback__rating {
						@apply z-50;
					}
				}
			}

			&.asteria--state-feedback {
				.asteria-component__card-section {
					&.asteria--variant-thumbs {
						.asteria-component__button {
							@apply scale-95 opacity-50;

							&.asteria--state-active,
							&:hover {
								@apply bg-transparent opacity-100;
							}

							&:hover {
								@apply scale-100;
							}
						}
					}
				}
			}
		}
	}
}
