@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__splash {
		@apply border-0 border-solid;
		@apply bg-splash-normal-background border-splash-normal-border;

		.asteria-component__wrapper-header {
			@apply bg-transparent p-4;
		}

		.asteria-component__wrapper-content {
			@apply bg-transparent p-4;

			.asteria-component__typography-group,
			.asteria-component__unordered {
				@apply flex flex-col gap-2;
			}
		}
	}
}
