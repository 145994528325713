@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__tabs {
	@apply flex flex-col;
	@include scroll();

	.asteria-component__tabs__navigation__wrapper {
		@apply flex;

		&.asteria-component__tabs__navigation__wrapper--variant-fluid {
			.asteria-component__tabs__navigation {
				@apply flex-1;

				.asteria-component__typography {
					@apply flex-1;
				}
			}
		}

		.asteria-component__tabs__navigation {
			@apply rounded-b-none text-center;

			.asteria-component__typography {
				@apply flex-1;
			}

			&:not(:first-child):not(:last-child) {
				@apply rounded-none;
			}

			&:first-child {
				@apply rounded-r-none;
			}

			&:last-child {
				@apply rounded-l-none;
			}
		}
	}

	.asteria-component__tabs__content__wrapper {
		@apply flex border border-solid border-tab-content-normal-border bg-tab-content-normal-background;

		@include scroll();

		// .asteria-component__text {
		// 	@apply text-tab-content-normal-text;
		// }

		.asteria-component__tabs__content {
			@apply box-border hidden flex-1 p-8;

			@include scroll();

			.asteria-component__typography {
				@apply m-0 h-auto p-0;
			}

			&.asteria--state-active {
				@apply flex flex-col gap-4;
			}
		}
	}
}
