@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__layout-overview__amount-tooltip {
	@apply flex flex-col gap-4 text-center;

	width: 340px;
}

.asteria-component__header-overview {
	.asteria-component__header-overview__title {
		// @apply cursor-pointer;
	}

	.asteria-component__text-amount {
		@apply text-right;

		.asteria-component__typography {
			@apply flex items-center justify-end gap-2;
		}
	}
}
