@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-taxes {
			.asteria-component__card-content-inner {
				> .asteria-component__button,
				.asteria-component__card-content-actions
					> .asteria-component__button {
					&.asteria-component__button--variant-link {
						@apply h-auto w-auto p-0;
					}
				}

				.asteria-component__table {
					@apply grid;

					grid-template-columns: minmax(0, 1fr) auto auto;

					> .asteria-component__table-row {
						@apply contents;

						> .asteria-component__table-cell {
							@apply h-auto border-0 border-b border-solid border-border-normal p-1;
						}
					}

					> .asteria-component__table-header {
						> .asteria-component__table-cell {
							.asteria-component__button {
								@apply h-auto w-auto p-0;

								&:not(.asteria--state-active) {
									> .asteria-component__button {
										@apply opacity-0;
									}
								}
							}

							&.asteria--variant-amount {
								@apply justify-end;
							}
						}
					}
				}
			}
		}
	}
}
