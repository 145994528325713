@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

//* --- Sidebar --- *//

.asteria-component__wrapper-content {
	&.asteria-component__transactions__details__sidebar__content {
		@apply p-0;
	}

	.asteria-component__transactions__details__sidebar__content__overview {
		@apply flex flex-col gap-1 border-list-item-details-normal-border bg-list-item-details-header-normal-background py-8 text-center;
	}

	.asteria-component__transactions__details__sidebar__content__feedback {
		// @apply mx-6 mb-6 text-center;
	}
}

//* --- Details --- *//

.asteria-component__transactions__details {
	@apply gap-6 p-8;

	@include scroll();

	&.asteria-component__transactions__details--direction-vertical {
		@apply flex-col;
	}

	&.asteria-component__transactions__details--direction-horizontal {
		@apply flex-row;
	}

	.asteria-component__transactions__details__section {
		> .asteria-component__list__header {
			@apply h-auto border-0;

			> .asteria-component__list__item {
				@apply h-auto w-full border-0 px-0;

				.asteria-component__typography {
					@apply text-content-title-normal;
				}
			}
		}

		.asteria-component__transactions__details__row {
			@apply grid overflow-hidden;

			grid-template-columns: 1fr 1fr;

			.asteria-component__typography {
				@apply overflow-hidden text-ellipsis whitespace-nowrap;
			}

			&.asteria-component__transactions__details__row--slug-tags {
				@apply h-auto items-baseline p-2;
			}

			.asteria-component__transactions__details__tags {
				@apply flex flex-wrap gap-1;
			}

			.asteria-component__button {
				@apply px-0;

				.asteria-component__text {
					@apply text-button-link-normal-text hover:text-button-link-hover-text;
				}
			}
		}
	}
}

.asteria-component__transaction-details__wrapper {
	.asteria-component__transaction-details__feedback-wrapper {
		@apply px-4;
	}

	&.asteria-component__modal {
		.asteria-component__transaction-details__feedback-wrapper {
			@apply pb-4;
		}
	}
}
