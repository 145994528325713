@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-tags-deposit-background: rgba(130, 208, 135, 1);
	--color-tags-withdraw-background: rgba(206, 140, 130, 1);

	--graph-group-width: calc(245px + theme('spacing.2'));

	--color-graph-historical-border: black;
	--color-graph-dot-normal-border: black;
	--color-graph-forecast-border: black;
	--color-graph-dot-normal-background: white;
	--color-graph-historical-background: white;

	--color-graph-today-border: black;
	--color-graph-dot-today-normal-background: black;

	--color-graph-dot-normal-text: black;

	--color-graph-part-line-background: theme('colors.border.normal');
	--color-graph-part-line-zero: #b7b7b7;

	--color-spread: rgb(130, 147, 208, 0.4);
	--color-stripe: rgb(130, 147, 208, 0);

	--color-graph-line-normal-credit: #9d471c;

	--graph-default-credit-gap: calc(50%);
}

.asteria-graph-bar-group {
	@apply px-4;
}

.asteria-graph-bar-group {
	.asteria-component__old__graph__bar {
		@apply m-0;

		&.asteria-deposit {
			@apply ml-1;
		}

		&.asteria-withdraw {
			@apply mr-1;
		}
	}
}

.asteria-graphs {
	.asteria-cashflow-graph-grouped {
		.asteria-graph-lines {
			@apply order-1;
		}

		.asteria-graph-line-graph-legends {
			@apply order-2;
		}

		.asteria-graph-revenue-graph {
			@apply order-3;
		}

		.asteria-graph-revenue-graph-legends {
			@apply order-4;
		}
	}

	.asteria-cashflow-graph-stacked {
		.asteria-component__legends__wrapper {
			@apply order-2;
		}

		.asteria-graph-stacked {
			@apply order-1;
		}
	}

	.asteria-component__legends__wrapper {
		@apply mt-4 justify-center;
	}

	.asteria-graph-xaxis {
		@apply top-2 gap-1;

		&.asteria-graph-xaxis-today {
			.asteria-graph-x-axis-label-text {
				@apply font-normal;
			}
		}

		.asteria-graph-x-axis-label-text {
			@apply rounded-full px-4 py-2;
		}

		&.asteria-state-active,
		&.asteria-state-hover {
			.asteria-graph-x-axis-label-text {
				background-color: #f3e3e0;
			}
		}
	}

	.asteria-graph-bar-group-wrapper {
		.asteria-graph-bar-main {
			@apply overflow-hidden rounded-md;
		}
	}
}

.asteria-color__spread {
	background-image: linear-gradient(theme('colors.graph.line.normal.spread'), theme('colors.graph.line.normal.spread'));

	&.asteria-color__negative {
		background-image: linear-gradient(theme('colors.graph.line.negative.spread'), theme('colors.graph.line.negative.spread'));
	}
}
