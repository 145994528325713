@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-view__welcome__loader {
	&.asteria-view__welcome__loader--variant-category {
	}

	&.asteria-view__welcome__loader--variant-graph {
		> .asteria-view__welcome__loader__content {
			@apply h-20 gap-6;

			.asteria-component__forecaster-graph-bar {
				@apply w-full rounded-t-md;
			}
		}
	}
}
