@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@keyframes pulseAnimation {
	0% {
		transform: scale(0.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
	}

	70% {
		transform: scale(1) translate(-50%, -50%);
		box-shadow: 0 0 0 6px rgba(255, 121, 63, 0);
	}

	100% {
		transform: scale(0.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
	}
}
