@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__card-slider {
	.asteria-component__button--variant-link {
		@apply h-auto p-0;
	}
}
