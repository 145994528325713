.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-forecast-actions {
			.asteria-component__forecast-actions__action {
				.asteria-component__chip {
					@apply rounded h-auto;

    				padding: 2px 4px;
					min-height: auto;
					border-color: var(--color);
				}
			}
		}
	}
}
