@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__teaser {
	&.asteria-component__wrapper {
		@apply border border-solid;

		.asteria-component__teaser-content {
			.asteria-component__button {
				@include asteria-btn-sm(false, true, false, md);

				&.asteria-component__button--variant-link {
					@apply px-0;
				}
			}
		}
	}
}
