@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-dropdown-normal-background: #fff;
	--color-dropdown-normal-border: transparent;

	--color-dropdown-item-normal-background: ;
	--color-dropdown-item-normal-border: transparent;
	--color-dropdown-item-normal-text: rgba(110, 110, 115, 1);
	--color-dropdown-item-normal-icon: rgba(110, 110, 115, 1);

	--color-dropdown-item-hover-background: rgba(243, 227, 224, 1);
	--color-dropdown-item-hover-border: transparent;
	--color-dropdown-item-hover-text: rgba(110, 110, 115, 1);
	--color-dropdown-item-hover-icon: rgba(225, 147, 142, 1);

	--color-dropdown-item-focus-background: rgba(243, 227, 224, 1);
	--color-dropdown-item-focus-border: transparent;
	--color-dropdown-item-focus-text: rgba(0, 0, 0, 1);
	--color-dropdown-item-focus-icon: rgba(225, 147, 142, 1);

	--color-dropdown-item-disabled-background: ;
	--color-dropdown-item-disabled-border: transparent;
	--color-dropdown-item-disabled-text: ;
	--color-dropdown-item-disabled-icon: ;
}

.asteria-component__dropdown__toggle {
	> .asteria-component__button--icon-only {
		@include asteria-btn-sm;
	}
}

.asteria-component__level.asteria-component__dropdown-anchor {
	@apply rounded-2xl;

	.asteria-component__dropdown__list {
		@apply gap-2 rounded-2xl p-2;

		box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.15);

		.asteria-component__dropdown__item {
			@apply rounded-xl transition-colors ease-in-out;

			.asteria-component__button {
				&.asteria-component__button--icon-only:not([class*='variant']):not(.asteria-component__button > .asteria-component__button) {
					@apply border-0 bg-transparent;
				}
			}
		}
	}
}

.asteria-component__scenario__add,
.asteria-component__level-navigation-selector,
.asteria-component__forecaster-selector {
	.asteria-component__button {
		&.asteria-component__button--variant-scenario-add,
		&.asteria-component__button--variant-tertiary {
			--color-dropdown-item-normal-text: var(--color-button-tertiary-normal-text);
			--color-dropdown-item-normal-icon: var(--color-button-tertiary-normal-icon);

			--color-dropdown-item-hover-text: var(--color-button-tertiary-hover-text);
			--color-dropdown-item-hover-icon: var(--color-button-tertiary-hover-icon);

			--color-dropdown-item-active-text: var(--color-button-tertiary-active-text);
			--color-dropdown-item-active-icon: var(--color-button-tertiary-active-icon);

			@include asteria-btn-link;
			@include asteria-btn-sm;

			.asteria-component__button {
				&.asteria-component__button--icon-only {
					@apply w-auto;

					.asteria-component__icon {
						&,
						.asteria-component__icon-content {
							@apply h-3 w-3;
						}
					}
				}
			}
		}
	}
}
