@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__message-box {
	@apply flex flex-col gap-4 rounded-none border border-solid p-4;
	@apply border-help-message-normal-border bg-help-message-normal-background;

	.asteria-component__wrapper-content,
	.asteria-component__wrapper-footer {
		@apply rounded-none bg-transparent p-0;
	}
}
