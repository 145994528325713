@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__navigation__item {
	@apply border-navigation-item-normal-border bg-navigation-item-normal-background;

	.asteria-component__navigation__item__icon {
		@apply border-navigation-item-icon-normal-border bg-navigation-item-icon-normal-background;

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-navigation-item-icon-normal-icon;
			}
		}
	}

	.asteria-component__navigation__item__collapse {
		@apply border-navigation-item-accordion-normal-border bg-navigation-item-accordion-normal-background;

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-navigation-item-accordion-normal-icon;
			}
		}
	}

	.asteria-component__navigation__item__label {
		@apply border-navigation-item-text-normal-border bg-navigation-item-text-normal-background;

		.asteria-component__typography {
			@apply text-navigation-item-text-normal-text;
		}
	}

	&:hover {
		@apply border-navigation-item-hover-border bg-navigation-item-hover-background;

		.asteria-component__navigation__item__icon {
			@apply border-navigation-item-icon-hover-border bg-navigation-item-icon-hover-background;

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-navigation-item-icon-hover-icon;
				}
			}
		}

		.asteria-component__navigation__item__label {
			@apply border-navigation-item-text-hover-border bg-navigation-item-text-hover-background;

			.asteria-component__typography {
				@apply text-navigation-item-text-hover-text;
			}
		}

		.asteria-component__navigation__item__collapse {
			@apply border-navigation-item-accordion-hover-border bg-navigation-item-accordion-hover-background;

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-navigation-item-accordion-hover-icon;
				}
			}
		}
	}

	&.asteria--state-active,
	&.asteria--state-sub-active {
		@apply border-navigation-item-focus-border bg-navigation-item-focus-background;

		.asteria-component__navigation__item__icon {
			@apply border-navigation-item-icon-focus-border;

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-navigation-item-icon-focus-icon;
				}
			}
		}

		.asteria-component__navigation__item__label {
			@apply border-navigation-item-text-focus-border bg-navigation-item-text-focus-background;

			.asteria-component__typography {
				@apply text-navigation-item-text-focus-text;
			}
		}

		.asteria-component__navigation__item__collapse {
			@apply border-navigation-item-accordion-focus-border bg-navigation-item-accordion-focus-background;

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-navigation-item-accordion-focus-icon;
				}
			}
		}
	}

	&.asteria--state-active {
		.asteria-component__navigation__item__icon {
			@apply bg-navigation-item-icon-focus-background;
		}
	}
}
