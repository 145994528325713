@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

// * --- Backdrop --- * //

svg {
	.asteria-component__tour__mask-background {
		@apply fill-tour-backdrop-background;

		&.asteria-component__tour__mask-background--transparent {
			@apply fill-transparent;
			// @apply hidden;
		}
	}

	.border {
		@apply stroke-tour-highlight-border
			stroke-2;

		fill: none;
		stroke-dasharray: 4px;
	}
}

// * --- Dialog --- * //

#asteria-tooltip-container {
	@apply outline-none outline-0 focus:outline-none;

	.asteria-component__tour {
		&__actions {
			@apply flex items-center justify-end gap-4;
		}

		&__tooltip {
			@apply rounded-md border border-solid shadow outline-none;
			@apply border-tour-normal-border bg-tour-normal-background;

			z-index: var(--z-index__tour);

			min-width: 400px;
			max-width: 400px;

			&.asteria-component__wrapper {
				> *:not(:last-of-type) {
					&.asteria-component__wrapper-content {
						@apply pb-2;
					}
				}

				&.asteria-component__wrapper--no-footer {
					> .asteria-component__wrapper-content {
						@apply rounded-b-md;
					}
				}
			}

			.asteria-component__wrapper-header {
				@apply items-start border border-solid pb-0;
				@apply border-tour-header-normal-border bg-tour-header-normal-background;

				.asteria-component__title {
					@apply text-tour-header-normal-title;
				}

				.asteria-component__wrapper-header__postfix {
					@apply right-4 top-6;
				}
			}
			.asteria-component__wrapper-content {
				@apply border border-solid;
				@apply border-tour-body-normal-border bg-tour-body-normal-background;
			}

			.asteria-component__wrapper-footer {
				@apply border border-solid py-2;

				@apply border-tour-footer-normal-border bg-tour-footer-normal-background;

				.asteria-component__button {
					// @apply w-auto px-0;

					&.asteria-component__button--icon-only {
						@apply h-10 w-10;

						&.asteria--size-sm {
							@apply h-8 w-8;
						}

						&.asteria--size-lg {
							@apply h-12 w-12;
						}
					}
				}

				.asteria-component__wrapper-footer__section {
					@apply justify-between;
				}
			}
		}

		&__navigation {
			@apply flex flex-col items-center gap-1;

			.asteria-component__tour__navigation-dots {
				@apply flex w-full flex-wrap items-center justify-center gap-2;
			}

			.asteria-component__tour__navigation-dot {
				@apply block
					h-3
					w-3
					cursor-pointer
					rounded-full
					border
					border-solid;

				@apply border-tour-indicator-normal-border bg-tour-indicator-normal-background;

				transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;

				&--active {
					@apply border-tour-indicator-focus-border bg-tour-indicator-focus-background;
				}
			}
		}
	}
}

.asteria-component__tour__tooltip {
	&.asteria-component__tour__tooltip--has-arrow {
		@apply relative;

		&::before {
			--size: theme('spacing.3');

			@apply absolute -z-10 border-0 border-solid;

			content: '';
			width: 0;
			height: 0;
		}

		&.asteria-component__tour__tooltip--position-east {
			&::before {
				@apply top-1/2;

				border-width: calc(var(--size) / 2) var(--size)
					calc(var(--size) / 2) 0;
				border-color: transparent theme('colors.tour.normal.background')
					transparent transparent;
				transform: translateY(-50%);
				left: calc(var(--size) * -1 + 2px);

				filter: drop-shadow(-2px 0 2px rgb(0 0 0 / 0.1));
			}
		}

		&.asteria-component__tour__tooltip--position-south {
			&::before {
				@apply left-1/2;

				border-width: 0 calc(var(--size) / 2) var(--size)
					calc(var(--size) / 2);
				border-color: transparent transparent
					theme('colors.tour.normal.background') transparent;

				transform: translateX(-50%);

				top: calc(var(--size) * -1 + 2px);

				filter: drop-shadow(0 -1px 2px rgba(0, 0, 0, 0.1));
			}
		}

		&.asteria-component__tour__tooltip--position-west {
			&::before {
				@apply top-1/2;

				border-width: calc(var(--size) / 2) 0 calc(var(--size) / 2)
					var(--size);
				border-color: transparent transparent transparent
					theme('colors.tour.normal.background');
				transform: translateY(-50%);
				right: calc(var(--size) * -1 + 2px);

				filter: drop-shadow(2px 0 1px rgb(0 0 0 / 0.1));
			}
		}

		&.asteria-component__tour__tooltip--position-north {
			&::before {
				@apply left-1/2;

				border-width: var(--size) calc(var(--size) / 2) 0
					calc(var(--size) / 2);
				border-color: theme('colors.tour.normal.background') transparent
					transparent transparent;

				transform: translateX(-50%);

				bottom: calc(var(--size) * -1 + 2px);

				filter: drop-shadow(0 4px 2px rgb(0 0 0 / 0.1));
			}
		}

		&.asteria-component__tour__tooltip--position-center {
			&::before {
				@apply hidden;
			}
		}

		&.asteria-component__tour__tooltip--position-east-north,
		&.asteria-component__tour__tooltip--position-west-north {
			&::before {
				transform: none;
				bottom: auto;
				top: var(--size);
			}
		}

		&.asteria-component__tour__tooltip--position-east-south,
		&.asteria-component__tour__tooltip--position-west-south {
			&::before {
				transform: none;
				top: auto;
				bottom: var(--size);
			}
		}

		&.asteria-component__tour__tooltip--position-south-east,
		&.asteria-component__tour__tooltip--position-north-east {
			&::before {
				transform: none;
				left: auto;
				right: var(--size);
			}
		}

		&.asteria-component__tour__tooltip--position-south-west,
		&.asteria-component__tour__tooltip--position-north-west {
			&::before {
				transform: none;
				right: auto;
				left: var(--size);
			}
		}
	}
}
