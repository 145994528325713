@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__error {
	@apply box-border grid w-full gap-8 p-3;

	grid-template-areas: 'error-alert error-alert' 'error-support error-faq';
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;

	&.asteria--state-sent {
		grid-template-areas: 'error-alert' 'error-faq';
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
	}

	> .asteria-component__alert {
		grid-area: error-alert;
	}

	.asteria-component__error-section {
		&.asteria-component__error-section--type-support {
			grid-area: error-support;
		}

		&.asteria-component__error-section--type-faq {
			grid-area: error-faq;
		}
	}
}

.asteria-component__error-section {
	> .asteria-component__wrapper-header,
	> .asteria-component__wrapper-content,
	> .asteria-component__wrapper-footer {
		@apply bg-transparent;
	}

	&.asteria-component__error-section--type-support {
		@apply rounded;

		> .asteria-component__wrapper-header {
			@apply pb-0;
		}

		> .asteria-component__wrapper-content {
			> .asteria-component__group {
				@apply gap-4;

				> .asteria-component__typography {
					@apply mb-4;
				}
			}
		}
	}

	&.asteria-component__error-section--type-faq {
		> .asteria-component__wrapper-header {
			@apply px-0 pb-1;
		}

		> .asteria-component__wrapper-content {
			@apply px-0;
		}
	}
}
