@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-view__welcome__wizard-step {
	@apply flex h-full w-full flex-col items-center justify-center;

	> .asteria-view__welcome__questions {
		@apply items-center justify-center;

		flex-flow: wrap;
	}

	&.asteria-view__welcome__wizard-step--variant-start {
		> .asteria-component__contenter {
			> .asteria-component__contenter__inner {
				@apply flex w-full flex-col gap-6;
			}
		}
	}

	&.asteria-view__welcome__wizard-step--variant-done {
		> .asteria-component__contenter {
			> .asteria-component__contenter__inner {
				@apply flex w-full flex-col items-center justify-center gap-8;

				> .asteria-view__welcome__wizard-step__progress {
					@apply flex w-full flex-col items-center justify-center gap-4;

					> .asteria-view__welcome__wizard-step__progress-icon {
						@apply box-border flex aspect-square h-8 w-8 items-center justify-center rounded-full;

						@apply bg-alert-success-icon-normal-background;

						.asteria-component__icon {
							.asteria-component__icon-content {
								@apply bg-alert-success-normal-background;
							}
						}
					}
				}
			}
		}
	}
}
