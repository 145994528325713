@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	// --color-alert-text: rgba(184, 50, 41, 1);
	--color-alert-icon: rgba(255, 255, 255, 1);
}

.asteria-component__alert {
	.asteria-component__alert__wrapper-header {
		.asteria-component__icon {
			&,
			.asteria-component__icon-content {
				@apply h-5 w-5;
			}
		}
	}
}
