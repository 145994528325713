@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-card-client-performance-highlight-hover-background: rgba(243, 227, 224, 1);
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-taxes {
			.asteria-component__taxes-breakdown {
				> .asteria-component__taxes-breakdown-item {
					&:first-child {
						@apply rounded-l;
					}

					&:last-child {
						@apply rounded-r;
					}
				}

				.asteria-component__taxes-breakdown-group {
					.asteria-component__taxes-breakdown-item {
					}

					&:first-child {
						.asteria-component__taxes-breakdown-item {
							&:first-child {
								@apply rounded-l;
							}
						}
					}

					&:last-child {
						.asteria-component__taxes-breakdown-item {
							&:last-child {
								@apply rounded-r;
							}
						}
					}
				}
				// .asteria-component__taxes-breakdown-item {
				// 	&:first-child {
				// 		@apply rounded-l;
				// 	}

				// 	&:last-child {
				// 		@apply rounded-r;
				// 	}
				// }
			}
		}

		&.asteria--variant-categories {
			.asteria-component__splash {
				--color-splash-normal-border: transparent;
				--color-splash-normal-background: #eee;

				--color-icon-normal: var(--color-icon-hover);

				.asteria-component__splash-header {
					@apply pb-0;
				}

				.asteria-component__button {
					&.asteria-component__button--variant-link {
						@apply px-0;
					}
				}
			}
		}
	}
}
