@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__time-selector-health {
	.asteria-component__skeleton {
		&.asteria--type-title {
			@apply h-8 items-center justify-center;
		}

		&.asteria--type-text {
			@apply h-6 items-center justify-center;
		}
	}
}
