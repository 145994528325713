@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__scenario {
	.asteria-component__dropdown__item {
		&.asteria-component__scenario__add {
			.asteria-component__button {
				@apply gap-2 p-0;

				> .asteria-component__button {
					@apply w-fit;
				}
			}
		}

		&:not(:hover) {
			.asteria-component__scenario__edit {
				@apply hidden;
			}
		}

		&:hover {
			.asteria-component__scenario__edit
				~ .asteria-component__scenario__check {
				@apply hidden;
			}
		}
	}
}

.asteria-component__settings {
	.asteria-component__message-box__wrapper {
		> .asteria-component__button.asteria-component__button--variant-link {
			@apply px-0;
		}
	}

	.asteria-component__section {
		&.asteria--type-user-2fa {
			@apply p-8;

			background-color: rgba(244, 244, 244, 1);

			> .asteria-component__group {
				@apply gap-4;

				grid-template-columns: auto 1fr;
			}

			.asteria-component__section-content {
				@apply flex flex-col gap-4;

				.asteria-component__title {
					@apply text-content-page-title;
				}

				> .asteria-component__icon {
					@apply h-8 w-8 rounded-full bg-alert-success-icon-normal-background;

					.asteria-component__icon-content {
						@apply h-4 w-4 bg-white;
					}
				}

				.asteria-component__button {
					&.asteria-component__button--variant-link {
						@apply px-0;
					}
				}

				.asteria-component__typography-group {
					@apply flex flex-col gap-1;

					.asteria-component__button {
						&.asteria-component__button--variant-link {
							@apply h-auto w-auto p-0;
						}
					}
				}
			}
		}
	}
}
