@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@use './styles/colors.scss';

.asteria-component__radio {
	.asteria-component__label__wrapper {
		&.asteria-component__label__wrapper--direction-vertical {
			.asteria-component__radio__inner {
				@apply flex-col-reverse items-center;

				.asteria-component__radio__icon {
					margin: unset;
				}
			}
		}
	}

	&:not(.asteria--state-disabled) {
		.asteria-component__radio__inner {
			@apply cursor-pointer;
		}
	}

	.asteria-component__radio__inner {
		@apply inline-flex items-baseline gap-2;

		input {
			@apply m-0 hidden p-0;
		}

		.asteria-component__radio__icon,
		span {
			@apply box-border flex aspect-square h-5 w-5 items-center justify-center rounded-full border-2 border-solid;
		}

		.asteria-component__radio__icon {
			.asteria-component__icon-content {
				@apply aspect-square h-auto w-3;
			}
		}

		span {
			&:before {
				@apply box-border block aspect-square w-3 rounded-full;
				content: '';
			}
		}
	}

	/* --- Sizes --- */

	&.asteria--size-small,
	&.asteria--size-sm {
		.asteria-component__radio__inner {
			.asteria-component__radio__icon,
			span {
				@apply h-4 w-4;
			}

			.asteria-component__radio__icon {
				.asteria-component__icon-content {
					@apply w-2;
				}
			}

			span {
				&:before {
					@apply w-2;
				}
			}
		}
	}

	&.asteria--size-large,
	&.asteria--size-lg {
		.asteria-component__radio__inner {
			.asteria-component__radio__icon,
			span {
				@apply h-8 w-8;
			}

			.asteria-component__radio__icon {
				.asteria-component__icon-content {
					@apply w-4;
				}
			}

			span {
				&:before {
					@apply w-4;
				}
			}
		}
	}
}
