@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	// --color-tooltip-normal-background: ;
	// --color-tooltip-normal-title: ;
	// --color-tooltip-normal-text: ;
	--color-tooltip-normal-border: transparent;
	// --color-tooltip-normal-icon: ;

	// --color-tooltip-alt-background: ;
	--color-tooltip-alt-title: white;
	--color-tooltip-alt-text: white;
	--color-tooltip-alt-border: transparent;
	--color-tooltip-alt-icon: white;
}
