@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__time-selector-popup {
	.asteria-component__time-selector-popup-custom {
		> .asteria-component__datepicker {
			> .asteria-component__input {
				@apply hidden;
			}
		}

		.asteria-component__chip {
			.asteria-component__icon {
				&.asteria-component__chip__prefix {
					&,
					.asteria-component__icon-content {
						@apply h-4 w-4;
					}
				}
			}
		}
	}
}
