@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__radio {
	.asteria-component__form__label {
		.asteria-component__typography {
			@apply text-form-radio-normal-text;
		}
	}

	.asteria-component__radio__inner {
		span {
			@apply border-form-radio-normal-border bg-form-radio-normal-background;

			&:before {
				@apply bg-form-radio-normal-icon;
			}
		}

		input {
			& + .asteria-component__icon {
				@apply border-form-radio-normal-border bg-form-radio-normal-background;

				.asteria-component__icon-content {
					@apply bg-form-radio-normal-icon;
				}
			}

			&:checked {
				& + span {
					@apply border-form-radio-focus-border bg-form-radio-focus-icon;

					&:before {
						@apply bg-form-radio-focus-background;
					}
				}

				& + .asteria-component__icon {
					@apply border-form-radio-focus-border bg-form-radio-focus-icon;

					.asteria-component__icon-content {
						@apply bg-form-radio-focus-background;
					}
				}
			}
		}
	}

	&:hover {
		.asteria-component__form__label {
			.asteria-component__typography {
				@apply text-form-radio-hover-text;
			}
		}

		.asteria-component__form__label {
			@apply border-form-label-hover-border bg-form-label-hover-background;

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-label-hover-icon;
				}
			}
		}

		.asteria-component__radio__inner {
			span {
				@apply border-form-radio-hover-border bg-form-radio-hover-background;

				&:before {
					@apply bg-form-radio-hover-icon;
				}
			}

			input {
				& + .asteria-component__icon {
					@apply border-form-radio-hover-border bg-form-radio-hover-background;

					.asteria-component__icon-content {
						@apply bg-form-radio-hover-icon;
					}
				}

				&:checked {
					& + span {
						@apply border-form-radio-focus-border bg-form-radio-focus-icon;

						&:before {
							@apply bg-form-radio-focus-background;
						}
					}

					& + .asteria-component__icon {
						@apply border-form-radio-focus-border bg-form-radio-focus-icon;

						.asteria-component__icon-content {
							@apply bg-form-radio-focus-background;
						}
					}
				}
			}
		}
	}

	&.asteria--state-active {
		.asteria-component__form__label {
			.asteria-component__typography {
				@apply text-form-radio-focus-text;
			}
		}

		.asteria-component__radio__inner {
			span {
				@apply border-form-radio-focus-border bg-form-radio-focus-icon;

				&:before {
					@apply bg-form-radio-focus-background;
				}
			}

			input {
				& + .asteria-component__icon {
					@apply border-form-radio-focus-border bg-form-radio-focus-icon;

					.asteria-component__icon-content {
						@apply bg-form-radio-focus-background;
					}
				}

				&:checked {
					& + span {
						@apply border-form-radio-focus-border bg-form-radio-focus-icon;

						&:before {
							@apply bg-form-radio-focus-background;
						}
					}

					& + .asteria-component__icon {
						@apply border-form-radio-focus-border bg-form-radio-focus-icon;

						.asteria-component__icon-content {
							@apply bg-form-radio-focus-background;
						}
					}
				}
			}
		}
	}

	&.asteria--state-disabled {
		.asteria-component__form__label {
			.asteria-component__typography {
				@apply text-form-radio-disabled-text;
			}
		}

		.asteria-component__radio__inner {
			span {
				@apply border-form-radio-disabled-border bg-form-radio-disabled-icon;

				&:before {
					@apply bg-form-radio-disabled-icon;
				}
			}

			input {
				& + .asteria-component__icon {
					@apply border-form-radio-disabled-border bg-form-radio-disabled-icon;

					.asteria-component__icon-content {
						@apply bg-form-radio-disabled-icon;
					}
				}

				&:checked {
					& + span {
						@apply border-form-radio-disabled-border bg-form-radio-disabled-icon;

						&:before {
							@apply bg-form-radio-disabled-background;
						}
					}

					& + .asteria-component__icon {
						@apply border-form-radio-disabled-border bg-form-radio-disabled-icon;

						.asteria-component__icon-content {
							@apply bg-form-radio-disabled-background;
						}
					}
				}
			}
		}
	}

	&.asteria--status-info {
		.asteria-component__form__label {
			.asteria-component__typography {
				@apply text-alert-info-normal-text;
			}
		}

		.asteria-component__radio__inner {
			span {
				@apply border-alert-info-normal-border bg-alert-info-normal-icon;

				&:before {
					@apply bg-alert-info-normal-icon;
				}
			}

			input {
				& + .asteria-component__icon {
					@apply border-alert-info-normal-border bg-alert-info-normal-icon;

					.asteria-component__icon-content {
						@apply bg-alert-info-normal-icon;
					}
				}

				&:checked {
					& + span {
						@apply border-alert-info-normal-border bg-alert-info-normal-icon;

						&:before {
							@apply bg-alert-info-icon-normal-background;
						}
					}

					& + .asteria-component__icon {
						@apply border-alert-info-normal-border bg-alert-info-normal-icon;

						.asteria-component__icon-content {
							@apply bg-alert-info-icon-normal-background;
						}
					}
				}
			}
		}
	}

	&.asteria--status-success {
		.asteria-component__form__label {
			.asteria-component__typography {
				@apply text-alert-success-normal-text;
			}
		}

		.asteria-component__radio__inner {
			span {
				@apply border-alert-success-normal-border bg-alert-success-normal-icon;

				&:before {
					@apply bg-alert-success-normal-icon;
				}
			}

			input {
				& + .asteria-component__icon {
					@apply border-alert-success-normal-border bg-alert-success-normal-icon;

					.asteria-component__icon-content {
						@apply bg-alert-success-normal-icon;
					}
				}

				&:checked {
					& + span {
						@apply border-alert-success-normal-border bg-alert-success-normal-icon;

						&:before {
							@apply bg-alert-success-icon-normal-background;
						}
					}

					& + .asteria-component__icon {
						@apply border-alert-success-normal-border bg-alert-success-normal-icon;

						.asteria-component__icon-content {
							@apply bg-alert-success-icon-normal-background;
						}
					}
				}
			}
		}
	}

	&.asteria--status-warning {
		.asteria-component__form__label {
			.asteria-component__typography {
				@apply text-alert-warning-normal-text;
			}
		}

		.asteria-component__radio__inner {
			span {
				@apply border-alert-warning-normal-border bg-alert-warning-normal-icon;

				&:before {
					@apply bg-alert-warning-normal-icon;
				}
			}

			input {
				& + .asteria-component__icon {
					@apply border-alert-warning-normal-border bg-alert-warning-normal-icon;

					.asteria-component__icon-content {
						@apply bg-alert-warning-normal-icon;
					}
				}

				&:checked {
					& + span {
						@apply border-alert-warning-normal-border bg-alert-warning-normal-icon;

						&:before {
							@apply bg-alert-warning-icon-normal-background;
						}
					}

					& + .asteria-component__icon {
						@apply border-alert-warning-normal-border bg-alert-warning-normal-icon;

						.asteria-component__icon-content {
							@apply bg-alert-warning-icon-normal-background;
						}
					}
				}
			}
		}
	}

	&.asteria--status-error {
		.asteria-component__form__label {
			.asteria-component__typography {
				@apply text-alert-error-normal-text;
			}
		}

		.asteria-component__radio__inner {
			span {
				@apply border-alert-error-normal-border bg-alert-error-normal-icon;

				&:before {
					@apply bg-alert-error-normal-icon;
				}
			}

			input {
				& + .asteria-component__icon {
					@apply border-alert-error-normal-border bg-alert-error-normal-icon;

					.asteria-component__icon-content {
						@apply bg-alert-error-normal-icon;
					}
				}

				&:checked {
					& + span {
						@apply border-alert-error-normal-border bg-alert-error-normal-icon;

						&:before {
							@apply bg-alert-error-icon-normal-background;
						}
					}

					& + .asteria-component__icon {
						@apply border-alert-error-normal-border bg-alert-error-normal-icon;

						.asteria-component__icon-content {
							@apply bg-alert-error-icon-normal-background;
						}
					}
				}
			}
		}
	}
}
