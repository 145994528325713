@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__legends {
	@apply flex gap-4;

	&.asteria-component__legends--direction-row {
		@apply flex-row justify-start;

		@include scroll('x');
	}

	&.asteria-component__legends--direction-column {
		@apply flex-col;
	}

	&.asteria-component__legends--selectable {
		&.asteria--state-active {
			.asteria-component__legend-item {
				&:not(.asteria--state-active) {
					@apply opacity-50;
				}
			}
		}
	}
}

.asteria-component__legends__wrapper {
	@apply flex w-fit;
}
