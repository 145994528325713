@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}

.asteria-component__transactions__shadow {
	@apply absolute inset-12 m-auto hidden justify-center rounded-full;

	.asteria-component__transactions__shadow__item {
		@apply aspect-square rounded-full;

		width: calc(50% / var(--size));
		height: 200px;
		background-color: var(--color);

		filter: blur(20px);
	}
}

.asteria-component__wrapper {
	&.asteria-component__transactions {
		@apply relative z-10 h-full border border-solid;

		@apply border-list-header-button-normal-border bg-list-header-button-normal-background;

		@include scroll();

		&:not(.asteria--state-active) {
			@apply h-fit;

			&:hover {
				@apply border-list-header-button-hover-border bg-list-header-button-hover-background;
			}
		}

		&.asteria--state-active {
			@apply border-list-header-button-focus-border bg-list-header-button-focus-background;
		}
	}
}

.asteria-component__transactions__modal {
}

.asteria-component__transactions__cell {
	.asteria-component__transactions__cell__content {
		&.asteria--state-loading {
			@apply w-full;
		}
	}

	.asteria-component__transactions__skeleton {
		@apply relative
		inline-block
		h-2
		w-full
		overflow-hidden
		rounded-lg;

		@apply bg-gray-100;

		&::after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transform: translateX(-100%);
			background-image: linear-gradient(
				90deg,
				rgba(255, 255, 255, 0) 0,
				rgba(255, 255, 255, 0.2) 20%,
				rgba(255, 255, 255, 0.5) 60%,
				rgba(255, 255, 255, 0)
			);
			-webkit-animation: shimmer 2s infinite;
			animation: shimmer 2s infinite;
			content: '';
		}
	}
}
