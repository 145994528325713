@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__wrapper {
	&.asteria-component__migration-splash {
		> .asteria-component__wrapper-header {
			@apply pb-0;
		}
	}
}
