@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;
@use './styles/colors.scss' as *;

.asteria-component__accordion {
	@apply flex flex-col;
}

.asteria-component__accordion-item {
	@apply flex flex-col;

	.asteria-component__accordion-item__label {
		@apply flex cursor-pointer items-baseline gap-3 border border-solid py-2 px-4;

		.asteria-component__typography {
			@apply m-0 flex-1 p-0 font-normal;
		}

		& + .asteria-component__accordion-item__content {
			@apply border-t-0;
		}
	}

	.asteria-component__accordion-item__content {
		@apply border border-solid py-2 px-4;
	}
}
