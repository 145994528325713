@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-view__welcome {
	@apply w-full;
}

.asteria-content-view-welcome {
	@apply items-center;

	.asteria-component__breadcrumbs {
		@apply hidden;
	}

	.asteria-component__actions {
		.asteria-component__actionbar.asteria-component__actions-action {
			@apply flex-row;

			&.asteria--state-clickable {
				background-color: var(--color-button-icon-normal-background);
				border-color: var(--color-button-icon-normal-border);

				&:hover {
					background-color: var(--color-button-icon-hover-background);
					border-color: var(--color-button-icon-hover-border);
				}
			}

			.asteria-component__actionbar__section {
				&.asteria-component__actionbar__section--content {
					.asteria-component__title {
						@apply text-title-xs;
					}
				}

				&.asteria-component__actionbar__section--actions {
				}
			}

			&.asteria-action--variant-integration {
				.asteria-component__actionbar__section {
					&.asteria-component__actionbar__section--actions {
						&.asteria-integration--status-error {
							.asteria-component__icon {
								@include asteria-btn-md(true, false);
								@include asteria-btn-primary;

								.asteria-component__icon-content {
									@apply h-4 w-4;
								}

								@apply bg-button-primary-normal-background;

								&:hover {
									@apply bg-button-primary-hover-background;
								}
							}

							> .asteria-component__group {
								@apply flex-col;

								> .asteria-component__typography {
									@apply hidden;
								}

								> .asteria-component__button {
									@apply p-0;
								}
							}
						}

						&.asteria-integration--status-importing {
							--spinner__width: theme('spacing.10');
							--spinner__height: theme('spacing.10');

							> .asteria-component__group {
								@apply flex-col;

								> .asteria-component__typography {
									@apply hidden;
								}

								> .asteria-component__button {
									@apply p-0;
								}
							}
						}

						&.asteria-integration--status-create {
							.asteria-component__typography {
								@apply hidden;
							}

							.asteria-component__button {
								@include asteria-btn-md(true, false);
								@include asteria-btn-primary;

								> .asteria-component__button {
									@include asteria-btn-md(true, false);
								}
							}
						}
					}
				}
			}

			&.asteria-action--variant-wizard {
				.asteria-component__actionbar__section {
					&.asteria-component__actionbar__section--actions {
						.asteria-component__typography {
							@apply hidden;
						}

						.asteria-component__button {
							@include asteria-btn-md(true, false);
							@include asteria-btn-primary;

							> .asteria-component__button {
								@include asteria-btn-md(true, false);
							}
						}
					}
				}
			}
		}
	}

	.asteria-view__welcome__footer {
		.asteria-view__welcome__link {
			&:not(.asteria--state-completed) {
				.asteria-component__button {
					@apply px-2;
				}
			}
		}
	}
}

.asteria-view__welcome__help {
	> .asteria-component__wrapper-content {
		.asteria-component__wrapper {
			> .asteria-component__wrapper-content {
				> .asteria-component__group {
					> .asteria-component__group {
						> .asteria-component__group {
							@apply pl-0;
						}
					}
				}
			}
		}
	}
}
