@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

@mixin asteria-btn-lg($square: false, $rounded: true, $noPaddings: false) {
	@apply h-12 px-4 py-2;

	@if $square {
		@apply w-12 p-0;
	}

	@if $rounded {
		@apply rounded-2xl;
	}

	&.asteria-component__button--icon-position-last {
		@apply pr-0;
	}

	&.asteria-component__button--icon-position-first {
		@apply pl-0;
	}

	.asteria-component__typography {
		@apply text-xl;
	}

	> .asteria-component__icon {
		&,
		.asteria-component__icon-content {
			@apply h-6 w-6;
		}
	}

	> .asteria-component__button {
		@include asteria-btn-md;

		> .asteria-component__icon {
			&,
			.asteria-component__icon-content {
				@apply h-4 w-4;
			}
		}
	}

	&.asteria-component__button--icon-only {
		@apply w-12 rounded-full p-0;
	}

	@if $noPaddings {
		@apply gap-2 px-0;

		&.asteria-component__button--icon-position-first {
			> .asteria-component__button--icon-only {
				@apply h-auto w-auto;
			}
		}
	}
}

@mixin asteria-btn-md($square: false, $rounded: true, $noPaddings: false) {
	@apply h-10 px-4 py-2;

	@if $square {
		@apply w-10 p-0;
	}

	@if $rounded {
		@apply rounded-2xl;
	}

	&.asteria-component__button--icon-position-last {
		@apply pr-0;
	}

	&.asteria-component__button--icon-position-first {
		@apply pl-0;
	}

	.asteria-component__typography {
		@apply text-lg;
	}

	> .asteria-component__icon {
		&,
		.asteria-component__icon-content {
			@apply h-4 w-4;
		}
	}

	> .asteria-component__button {
		@include asteria-btn-sm;

		// > .asteria-component__icon {
		// 	@apply h-3 w-3;
		// }
	}

	&.asteria-component__button--icon-only {
		@apply w-10 rounded-full p-0;
	}

	@if $noPaddings {
		@apply gap-2 px-0;

		&.asteria-component__button--icon-position-first {
			> .asteria-component__button--icon-only {
				@apply h-auto w-auto;
			}
		}
	}
}

@mixin asteria-btn-sm($square: false, $rounded: true, $noPaddings: false, $iconSize: sm) {
	@apply h-8 gap-1 px-2 py-1;

	@if $square {
		@apply w-8 p-0;
	}

	@if $rounded {
		@apply rounded-lg;
	}

	&.asteria-component__button--icon-position-last {
		@apply pr-0;
	}

	&.asteria-component__button--icon-position-first {
		@apply pl-0;
	}

	.asteria-component__typography {
		@apply text-sm;
	}

	> .asteria-component__icon {
		&,
		.asteria-component__icon-content {
			@apply h-4 w-4;
		}
	}

	> .asteria-component__button {
		@include asteria-btn-xs;

		@if $iconSize == sm {
			> .asteria-component__icon {
				&,
				.asteria-component__icon-content {
					@apply h-3 w-3;
				}
			}
		}

		@if $iconSize == md {
			> .asteria-component__icon {
				&,
				.asteria-component__icon-content {
					@apply h-4 w-4;
				}
			}
		}
	}

	&.asteria-component__button--icon-only {
		@apply w-8 rounded-full p-0;
	}

	@if $noPaddings {
		@apply gap-2 px-0;

		&.asteria-component__button--icon-position-first {
			> .asteria-component__button--icon-only {
				@apply h-auto w-auto;
			}
		}
	}
}

@mixin asteria-btn-xs($square: false, $rounded: true, $noPaddings: false) {
	@apply h-6 px-3 py-1;

	@if $square {
		@apply w-6 p-0;
	}

	@if $rounded {
		@apply rounded-sm;
	}

	&.asteria-component__button--icon-position-last {
		@apply pr-0;
	}

	&.asteria-component__button--icon-position-first {
		@apply pl-0;
	}

	.asteria-component__typography {
		@apply text-xs;
	}

	> .asteria-component__icon {
		&,
		.asteria-component__icon-content {
			@apply h-3 w-3;
		}
	}

	&.asteria-component__button--icon-only {
		@apply w-6 rounded-full p-0;
	}

	@if $noPaddings {
		@apply gap-2 px-0;

		&.asteria-component__button--icon-position-first {
			> .asteria-component__button--icon-only {
				@apply h-auto w-auto;
			}
		}
	}
}

@mixin asteria-btn-rounded {
	@apply rounded-full border border-solid;

	background-color: var(--color-button-icon-normal-background);
	border-color: var(--color-button-icon-normal-border);

	.asteria-component__icon-content {
		background-color: var(--color-button-icon-normal-icon);
	}

	&:hover {
		background-color: var(--color-button-icon-hover-background);
		border-color: var(--color-button-icon-hover-border);

		.asteria-component__icon-content {
			background-color: var(--color-button-icon-hover-icon);
		}
	}

	&.asteria--state-active {
		background-color: var(--color-button-icon-focus-background);
		border-color: var(--color-button-icon-focus-border);

		.asteria-component__icon-content {
			background-color: var(--color-button-icon-focus-icon);
		}
	}
}

@mixin asteria-btn-primary {
	$variants: 'secondary', 'tertiary', 'link', 'tab', 'href', 'assist', 'flat';

	@each $variant in $variants {
		--color-button-#{$variant}-normal-background: var(--color-button-primary-normal-background);
		--color-button-#{$variant}-normal-border: var(--color-button-primary-normal-border);
		--color-button-#{$variant}-normal-text: var(--color-button-primary-normal-text);
		--color-button-#{$variant}-normal-icon: var(--color-button-primary-normal-icon);

		--color-button-#{$variant}-hover-background: var(--color-button-primary-hover-background);
		--color-button-#{$variant}-hover-border: var(--color-button-primary-hover-border);
		--color-button-#{$variant}-hover-text: var(--color-button-primary-hover-text);
		--color-button-#{$variant}-hover-icon: var(--color-button-primary-hover-icon);

		--color-button-#{$variant}-focus-background: var(--color-button-primary-focus-background);
		--color-button-#{$variant}-focus-border: var(--color-button-primary-focus-border);
		--color-button-#{$variant}-focus-text: var(--color-button-primary-focus-text);
		--color-button-#{$variant}-focus-icon: var(--color-button-primary-focus-icon);

		--color-button-#{$variant}-disabled-background: var(--color-button-primary-disabled-background);
		--color-button-#{$variant}-disabled-border: var(--color-button-primary-disabled-border);
		--color-button-#{$variant}-disabled-text: var(--color-button-primary-disabled-text);
		--color-button-#{$variant}-disabled-icon: var(--color-button-primary-disabled-icon);
	}

	.asteria-component__typography {
		@apply no-underline;
	}
}

@mixin asteria-btn-secondary {
	$variants: 'primary', 'tertiary', 'link', 'tab', 'href', 'assist', 'flat';

	@each $variant in $variants {
		--color-button-#{$variant}-normal-background: var(--color-button-secondary-normal-background);
		--color-button-#{$variant}-normal-border: var(--color-button-secondary-normal-border);
		--color-button-#{$variant}-normal-text: var(--color-button-secondary-normal-text);
		--color-button-#{$variant}-normal-icon: var(--color-button-secondary-normal-icon);

		--color-button-#{$variant}-hover-background: var(--color-button-secondary-hover-background);
		--color-button-#{$variant}-hover-border: var(--color-button-secondary-hover-border);
		--color-button-#{$variant}-hover-text: var(--color-button-secondary-hover-text);
		--color-button-#{$variant}-hover-icon: var(--color-button-secondary-hover-icon);

		--color-button-#{$variant}-focus-background: var(--color-button-secondary-focus-background);
		--color-button-#{$variant}-focus-border: var(--color-button-secondary-focus-border);
		--color-button-#{$variant}-focus-text: var(--color-button-secondary-focus-text);
		--color-button-#{$variant}-focus-icon: var(--color-button-secondary-focus-icon);

		--color-button-#{$variant}-disabled-background: var(--color-button-secondary-disabled-background);
		--color-button-#{$variant}-disabled-border: var(--color-button-secondary-disabled-border);
		--color-button-#{$variant}-disabled-text: var(--color-button-secondary-disabled-text);
		--color-button-#{$variant}-disabled-icon: var(--color-button-secondary-disabled-icon);
	}

	.asteria-component__typography {
		@apply no-underline;
	}
}

@mixin asteria-btn-tertiary {
	$variants: 'primary', 'secondary', 'link', 'tab', 'href', 'assist', 'flat';

	@each $variant in $variants {
		--color-button-#{$variant}-normal-background: var(--color-button-tertiary-normal-background);
		--color-button-#{$variant}-normal-border: var(--color-button-tertiary-normal-border);
		--color-button-#{$variant}-normal-text: var(--color-button-tertiary-normal-text);
		--color-button-#{$variant}-normal-icon: var(--color-button-tertiary-normal-icon);

		--color-button-#{$variant}-hover-background: var(--color-button-tertiary-hover-background);
		--color-button-#{$variant}-hover-border: var(--color-button-tertiary-hover-border);
		--color-button-#{$variant}-hover-text: var(--color-button-tertiary-hover-text);
		--color-button-#{$variant}-hover-icon: var(--color-button-tertiary-hover-icon);

		--color-button-#{$variant}-focus-background: var(--color-button-tertiary-focus-background);
		--color-button-#{$variant}-focus-border: var(--color-button-tertiary-focus-border);
		--color-button-#{$variant}-focus-text: var(--color-button-tertiary-focus-text);
		--color-button-#{$variant}-focus-icon: var(--color-button-tertiary-focus-icon);

		--color-button-#{$variant}-disabled-background: var(--color-button-tertiary-disabled-background);
		--color-button-#{$variant}-disabled-border: var(--color-button-tertiary-disabled-border);
		--color-button-#{$variant}-disabled-text: var(--color-button-tertiary-disabled-text);
		--color-button-#{$variant}-disabled-icon: var(--color-button-tertiary-disabled-icon);
	}

	.asteria-component__typography {
		@apply no-underline;
	}
}

@mixin asteria-btn-flat {
	$variants: 'primary', 'secondary', 'link', 'tab', 'href', 'assist', 'flat';

	@each $variant in $variants {
		--color-button-#{$variant}-normal-background: var(--color-button-flat-normal-background);
		--color-button-#{$variant}-normal-border: var(--color-button-flat-normal-border);
		--color-button-#{$variant}-normal-text: var(--color-button-flat-normal-text);
		--color-button-#{$variant}-normal-icon: var(--color-button-flat-normal-icon);

		--color-button-#{$variant}-hover-background: var(--color-button-flat-hover-background);
		--color-button-#{$variant}-hover-border: var(--color-button-flat-hover-border);
		--color-button-#{$variant}-hover-text: var(--color-button-flat-hover-text);
		--color-button-#{$variant}-hover-icon: var(--color-button-flat-hover-icon);

		--color-button-#{$variant}-focus-background: var(--color-button-flat-focus-background);
		--color-button-#{$variant}-focus-border: var(--color-button-flat-focus-border);
		--color-button-#{$variant}-focus-text: var(--color-button-flat-focus-text);
		--color-button-#{$variant}-focus-icon: var(--color-button-flat-focus-icon);

		--color-button-#{$variant}-disabled-background: var(--color-button-flat-disabled-background);
		--color-button-#{$variant}-disabled-border: var(--color-button-flat-disabled-border);
		--color-button-#{$variant}-disabled-text: var(--color-button-flat-disabled-text);
		--color-button-#{$variant}-disabled-icon: var(--color-button-flat-disabled-icon);
	}

	.asteria-component__typography {
		@apply no-underline;
	}
}

@mixin asteria-btn-link {
	$variants: 'primary', 'secondary', 'tertiary', 'tab', 'href', 'assist', 'flat';

	@each $variant in $variants {
		--color-button-#{$variant}-normal-background: var(--color-button-link-normal-background);
		--color-button-#{$variant}-normal-border: var(--color-button-link-normal-border);
		--color-button-#{$variant}-normal-text: var(--color-button-link-normal-text);
		--color-button-#{$variant}-normal-icon: var(--color-button-link-normal-icon);

		--color-button-#{$variant}-hover-background: var(--color-button-link-hover-background);
		--color-button-#{$variant}-hover-border: var(--color-button-link-hover-border);
		--color-button-#{$variant}-hover-text: var(--color-button-link-hover-text);
		--color-button-#{$variant}-hover-icon: var(--color-button-link-hover-icon);

		--color-button-#{$variant}-focus-background: var(--color-button-link-focus-background);
		--color-button-#{$variant}-focus-border: var(--color-button-link-focus-border);
		--color-button-#{$variant}-focus-text: var(--color-button-link-focus-text);
		--color-button-#{$variant}-focus-icon: var(--color-button-link-focus-icon);

		--color-button-#{$variant}-disabled-background: var(--color-button-link-disabled-background);
		--color-button-#{$variant}-disabled-border: var(--color-button-link-disabled-border);
		--color-button-#{$variant}-disabled-text: var(--color-button-link-disabled-text);
		--color-button-#{$variant}-disabled-icon: var(--color-button-link-disabled-icon);
	}

	.asteria-component__typography {
		@apply underline;
	}
}

@mixin asteria-btn-tab($size: 'md') {
	@if $size == 'md' {
		@include asteria-chip-md;
	}

	@if $size == 'sm' {
		@include asteria-chip-sm;
	}

	@if $size == 'lg' {
		@include asteria-chip-lg;
	}

	@apply rounded-full;

	--color-chip-normal-background: #f3f3f3;
	--color-chip-normal-border: #f3f3f3;
	--color-chip-normal-text: #6e6e73;
	--color-chip-normal-icon: #6e6e73;

	--color-chip-hover-background: var(--color-button-icon-hover-background);
	--color-chip-hover-border: var(--color-button-icon-hover-border);
	--color-chip-hover-text: #000;
	--color-chip-hover-icon: var(--color-button-icon-hover-icon);

	--color-chip-focus-background: var(--color-button-icon-hover-background);
	--color-chip-focus-border: var(--color-button-icon-hover-border);
	--color-chip-focus-text: #000;
	--color-chip-focus-icon: var(--color-button-icon-hover-icon);

	&:not(:hover):not(.asteria--state-active) {
		@apply bg-chip-normal-background;
	}
}

@mixin asteria-chip-lg {
	@apply h-12 px-5 py-3;

	.asteria-component__typography {
		@apply text-lg;
	}

	.currency-flag {
		@apply w-7;
	}

	.asteria-component__icon {
		&,
		.asteria-component__icon-content {
			@apply h-4 w-4;
		}
	}
}

@mixin asteria-chip-md {
	@apply h-10 px-4 py-2;

	.currency-flag {
		@apply w-4;
	}

	.asteria-component__icon {
		&,
		.asteria-component__icon-content {
			@apply h-4 w-4;
		}
	}

	.asteria-component__typography {
		@apply m-0 text-sm;
	}

	.asteria-component__chip__button--dismiss {
		.asteria-component__icon {
			&,
			.asteria-component__icon-content {
				@apply h-2 w-2;
			}
		}
	}
}

@mixin asteria-chip-sm {
	@apply h-8 px-4 py-2;

	.asteria-component__icon {
		&,
		.asteria-component__icon-content {
			@apply h-3 w-3;
		}
	}

	.asteria-component__chip__button--dismiss {
		.asteria-component__icon {
			&,
			.asteria-component__icon-content {
				@apply h-2 w-2;
			}
		}
	}

	.asteria-component__typography {
		@apply text-xs;
	}
}
