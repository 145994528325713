@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__onboarding-step {
	&.asteria--variant-error {
		.asteria-component__onboarding-status-alert {
			&.asteria--status-error {
				.asteria-component__icon-content {
					@apply bg-alert-error-normal-icon;
				}
			}
		}
	}
}
