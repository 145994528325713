@mixin apply-list-button {
	.asteria-component__button--variant-primary {
		.asteria-component__text {
			@apply text-button-primary-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-button-primary-normal-icon;
			}
		}
	}

	.asteria-component__button--variant-secondary {
		.asteria-component__text {
			@apply text-button-secondary-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-button-secondary-normal-icon;
			}
		}
	}

	.asteria-component__button--variant-tertiary {
		.asteria-component__text {
			@apply text-button-tertiary-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-button-tertiary-normal-icon;
			}
		}
	}

	.asteria-component__button--variant-link,
	.asteria-component__button--variant-href {
		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-button-link-normal-icon;
			}
		}
	}

	.asteria-component__button--variant-assist {
		.asteria-component__text {
			@apply text-button-assist-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-button-assist-normal-icon;
			}
		}
	}

	.asteria-component__button--variant-tab {
		.asteria-component__typography {
			@apply text-button-tab-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-button-tab-normal-icon;
			}
		}
	}

	.asteria-component__button--variant-select {
		.asteria-component__typography {
			@apply text-form-select-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-form-select-normal-icon;
			}
		}
	}
}
