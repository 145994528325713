.asteria-component__forecaster__tooltip--history {
	min-width: 250px;
	@apply flex flex-col gap-2;

	.asteria-component__typography {
		@apply m-0;
	}

	.asteria-component__forecaster__tooltip--history__title {
		@apply text-center;
	}

	.asteria-component__forecaster__tooltip--history__subtitle {
		@apply flex items-center justify-center gap-2 text-center;
	}

	.asteria-component__forecaster__tooltip--history__content {
		@apply grid items-center gap-2;

		grid-template-columns: auto 1fr auto;

		> .asteria-component__forecaster__tooltip--history__item {
			@apply contents;

			> .asteria-component__forecaster__tooltip--history__item__details {
				@apply contents;
			}

			> .asteria-component__typography {
				@apply justify-self-end;
			}
		}
	}

	.asteria-component__forecaster__tooltip--history__item {
		@apply flex items-center justify-between gap-4;

		.asteria-component__forecaster__tooltip--history__item__details {
			@apply flex items-center gap-2;
		}

		&.asteria-component__forecaster__tooltip--history__item--total {
			@apply mt-0 pl-5;
		}
	}
}
