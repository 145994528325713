@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__financial-layout {
	@apply flex flex-col gap-4;

	.asteria-component__financial-page-navigation-back {
		@apply gap-0;

		> .asteria-component__title {
			@apply text-lg;
		}
	}
}

.asteria-component__financial-layout-header {
}

.asteria-component__financial-layout-content {
	@apply h-full overflow-hidden;

	grid-area: content;
}
