@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__financial-loan-graph.asteria-component__forecaster-graph.asteria--variant-lines {
	.asteria-component__forecaster-graph-column {
		div.asteria-component__forecaster-graph-column-content {
			.asteria-component__graph-dot {
				.asteria-component__graph-dot-shape {
					@apply cursor-default;
				}
			}
		}
	}
}

.asteria-component__loan-tooltip {
	&.asteria--type-dot {
		@apply w-56;

		.asteria-component__loan-tooltip-title {
			@apply text-center text-title-xxs;
		}

		.asteria-component__loan-tooltip-forecast {
			@apply gap-0;
		}
	}
}
