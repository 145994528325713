@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@import './mixins';

@import './forms/main.scss';
@import './layout/main.scss';
@import './views/main.scss';
@import './accordion.scss';
@import './alert.scss';
@import './badge.scss';
@import './breadcrumbs.scss';
@import './buttons.scss';
@import './card.scss';
@import './carousel.scss';
@import './chart.scss';
@import './chip.scss';
@import './dialog.scss';
@import './dropdown.scss';
@import './feedback.scss';
@import './graph.scss';
@import './icons.scss';
@import './loader.scss';
@import './login.scss';
@import './modal.scss';
@import './navigation.scss';
@import './notifications.scss';
@import './progress.scss';
@import './search.scss';
@import './sidepane.scss';
@import './splash.scss';
@import './support.scss';
@import './tabs.scss';
@import './teaser.scss';
@import './tooltip.scss';
@import './tour.scss';
@import './transactions.scss';
@import './typography.scss';
@import './wrapper.scss';

#{$global} {
	--color-background-normal: #fff;

	--color-border-normal: #e5e5e5;
	--color-border-hover: #e5e5e5;
	--color-border-focus: #e5e5e5;

	--color-primary: rgba(215, 108, 99, 1);
	--color-primary-focus: rgba(231, 100, 94, 1);
	--color-primary-disabled: rgba(237, 194, 191, 1);

	--color-secondary: rgba(80, 84, 94, 1);
	--color-secondary-focus: rgba(54, 59, 69, 1);
	--color-secondary-disabled: rgb(200, 200, 200);

	--color-tertiary: rgba(248, 249, 250, 1);
	--color-tertiary-focus: rgba(218, 223, 229, 1);
	--color-tertiary-disabled: rgba(250, 250, 250, 1);

	--color-link: rgba(169, 64, 4, 1);
	--color-link-focus: rgba(119, 46, 3, 1);
	--color-link-disabled: rgba(218, 170, 135, 1);

	--color-probability-wrapper-normal-border: transparent;

	--color-time-selector-input-hover-background: #f4e2df;
	--color-time-selector-input-hover-border: #f4e2df;

	--color-time-selector-month-hover-background: #f2eeee;
	--color-time-selector-month-hover-border: #f2eeee;
}

.asteria-wrapper {
	> .asteria-component__wrapper-content {
		@apply py-4 px-4;

		@media (min-width: 768px) {
			@apply px-16 pt-20;
		}
	}
}
/*

.asteria-component__notifications {
	.asteria-component__dropdown__list {
		--dropdown__has-scroll__max-height: 590px;
	}
}

.asteria-graph-bar-group {
	@apply px-8;
}

.asteria--state-highlight-color {
	color: var(--color-primary);
}

.asteria--state-highlight-background {
	background: var(--color-primary);
}

.asteria-wrapper {
	.asteria-content-view {
		&.asteria-content-view-cashflow {
		}
	}
}

.asteria-component__page-header {
	.asteria-component__page-header__content {
		.asteria-component__page-header__title {
			.asteria-component__typography {
				@apply font-light;
			}
		}
	}
}

.asteria-wrapper {
	&.asteria-wrapper--split {
		&.asteria-wrapper--view-cashflow {
			.asteria-content-view {
				@apply pb-0;
			}
		}
	}

	&.asteria-wrapper--size-small {
	}

	&.asteria-wrapper--view-cashflow {
		.asteria-cashflow-details {
			@apply flex flex-col;
		}

		&:not(.asteria-wrapper--split) {
			.asteria-component__resizer {
				@apply overflow-visible;

				.asteria-component__resizer-element {
					&.asteria--position-last {
						@apply overflow-visible;
					}
				}
			}
		}

		&.asteria-wrapper--split {
			.asteria-content-view {
				@apply pb-0;
			}

			.asteria-component__resizer {
				.asteria-component__resizer-element {
					&.asteria--position-last {
						@apply relative p-12 pb-0;

						.asteria-component__transactions__shadow {
							@apply flex;
						}
					}
				}
			}
		}
	}
}

*/
