@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-view__settings {
	.asteria-component__tabs__content__wrapper {
		.asteria-component__tabs__content {
			@apply px-0 pb-0;

			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@include asteria-btn-sm;
				}
			}

			.asteria-component__settings-section__currency,
			.asteria-component__settings-section__language {
				.asteria-component__typography {
					@apply text-base font-light text-content-text-normal;
				}

				&:hover {
					.asteria-component__typography {
						@apply text-content-text-hover;
					}
				}
			}

			.asteria-component__message-box__wrapper {
				.asteria-component__button {
					&.asteria-component__button--variant-link {
						@include asteria-btn-sm(false, true, true, md);
					}
				}
			}
		}
	}

	.asteria-component__section {
		&.asteria--type-user-2fa {
			@apply rounded p-8;

			background-color: rgba(243, 243, 243, 1);

			.asteria-component__section-content {
				@apply gap-0;

				> .asteria-component__icon {
					background-color: rgba(243, 227, 224, 1);

					.asteria-component__icon-content {
						background-color: rgba(225, 147, 142, 1);
					}
				}

				> .asteria-component__typography {
					@apply mb-4 text-title-xs;
				}

				> .asteria-component__typography-group {
					@apply mb-2 flex flex-col gap-2;
				}

				> .asteria-component__button {
					@include asteria-btn-md;

					&,
					> .asteria-component__typography {
						@apply no-underline;
					}
				}
			}
		}
	}
}
