@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-graph-group-background {
	@apply relative h-full w-full;

	-ms-grid-column: 1;
	-ms-grid-column-span: 10;
	-ms-grid-row: 1;
	-ms-grid-row-span: 10;

	grid-column-start: 1;
	grid-column-end: -1;
	grid-row-start: 1;
	grid-row-end: -1;
	// z-index: 1;
}
