@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__actionbar {
	&.asteria-view__welcome__action {
		@apply flex flex-col;

		.asteria-component__actionbar__section__title {
			.asteria-component__typography {
				@apply text-title-sm;
			}
		}

		.asteria-component__actionbar__section__content {
			.asteria-component__typography {
				@apply text-base;
			}
		}

		&.asteria-view__welcome__action {
			@apply items-start gap-4 shadow-none;

			&.asteria--state-clickable {
				@apply cursor-pointer;
			}

			.asteria-component__actionbar__section--actions {
				@apply flex flex-col items-center gap-1;

				.asteria-component__group {
					> .asteria-component__icon {
						@apply h-6 w-6 rounded-full;

						.asteria-component__icon-content {
							@apply h-3 w-3;
						}

						&.asteria-component__icon--help {
							@apply bg-alert-warning-icon-normal-background;
						}
					}

					> .asteria-component__button,
					> .asteria-component__button.asteria--size-sm {
						@apply rounded-full;

						&.asteria-component__button--icon-check {
							@apply h-6 w-6 bg-alert-success-icon-normal-background;

							.asteria-component__icon-content {
								@apply bg-white;
							}

							&:hover,
							&.asteria--state-active {
								@apply bg-alert-success-icon-normal-background;
							}
						}

						&.asteria--size-md {
							> .asteria-component__typography {
								@apply text-base;
							}
						}
					}

					> .asteria-view__welcome__action__button--create {
						@apply gap-2;

						> .asteria-component__button {
							@apply rounded-full bg-button-secondary-normal-background;

							.asteria-component__icon {
								&,
								.asteria-component__icon-content {
									@apply h-3 w-3;
								}

								.asteria-component__icon-content {
									@apply bg-white;
								}
							}
						}

						&:hover {
							> .asteria-component__button {
								@apply bg-button-secondary-hover-background;
							}
						}
					}
				}
			}

			&.asteria-action--variant-wizard {
				.asteria-view__welcome__action-progress {
					@apply mb-2 gap-2;

					.asteria-component__typography {
						@apply whitespace-nowrap;
					}
				}
			}

			&.asteria-action--variant-complete {
				@apply gap-4 p-6;

				.asteria-component__actionbar__section__content {
					.asteria-component__typography {
						@apply text-lg;
					}
				}

				.asteria-component__actionbar__section--prefix {
					@apply flex self-start;

					> .asteria-component__icon {
						@apply h-8 w-8 rounded-full;

						.asteria-component__icon-content {
							@apply h-3 w-3 bg-white;
						}

						&.asteria-component__icon--check {
							@apply bg-alert-success-icon-normal-background;
						}
					}
				}

				.asteria-view__welcome__action__content {
					.asteria-component__actionbar__section__content {
						@apply flex flex-col gap-8;

						> div:not([class]) {
							@apply hidden;
						}
					}
				}
			}
		}
	}
}
