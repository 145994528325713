@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__transactions__cell {
	@apply box-border flex h-10 w-full items-center overflow-hidden px-2;

	@apply gap-2 border-0 border-b border-solid;
	@apply border-list-item-normal-border bg-list-item-normal-background text-list-item-normal-text;

	.asteria--state-compact & {
		@apply h-8;
	}

	> .asteria-component__transactions__cell__content {
		@apply flex items-center gap-1 overflow-hidden;

		> .asteria-component__typography {
			@apply overflow-hidden text-ellipsis whitespace-nowrap;
		}
	}

	&.asteria-component__transactions__cell--selector {
	}

	&.asteria-component__transactions__cell--description {
	}

	&.asteria-component__transactions__cell--paymentDate {
	}

	&.asteria-component__transactions__cell--tags,
	&.asteria-component__transactions__cell--status,
	&.asteria-component__transactions__cell--currency {
		> .asteria-component__transactions__cell__content {
			> .asteria-component__chip {
				@apply overflow-hidden;

				> .asteria-component__typography {
					@apply overflow-hidden text-ellipsis whitespace-nowrap;
				}
			}
		}

		.asteria--state-compact & {
			.asteria-component__chip {
				.asteria-component__typography {
					@apply text-xs #{!important};
				}
			}
		}
	}

	&.asteria-component__transactions__cell--currency {
	}

	&.asteria-component__transactions__cell--status {
	}

	&.asteria-component__transactions__cell--risk {
	}

	&.asteria-component__transactions__cell--total {
		@apply justify-end;

		place-self: center flex-end;

		.asteria-component__typography-group {
			@apply flex flex-1 flex-col overflow-hidden text-right;

			> .asteria-component__typography {
				@apply overflow-hidden text-ellipsis whitespace-nowrap;
			}
		}

		.asteria--state-compact & {
			.asteria-component__typography {
				&.asteria-component__text--size-md {
					@apply text-xs #{!important};
				}

				&.asteria-component__text--size-sm {
					@apply text-xxs #{!important};
				}
			}
		}
	}

	&.asteria-component__transactions__cell--options {
		@apply px-0;
	}
}

.asteria-component__transactions__list {
	&.asteria--state-compact {
		.asteria-component__transactions__cell {
			&.asteria-component__transactions__cell--tags,
			&.asteria-component__transactions__cell--status,
			&.asteria-component__transactions__cell--currency {
				.asteria-component__chip {
					@apply h-6 py-0 px-2;
				}
			}
		}
	}
}
