@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@keyframes line_beacon_animation {
	0% {
		transform: scale(0.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
	}

	70% {
		transform: scale(1) translate(-50%, -50%);
		box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
	}

	100% {
		transform: scale(0.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
	}
}

.asteria-graph-line-dot {
	@apply cursor-pointer rounded-full border-2 border-solid;
	@include apply-colors('graph-dot');
	transform: translate(-50%, -50%);

	height: 12px;
	width: 12px;

	.asteria-graph-line-dot__beacon {
		@apply rounded-full;
		transform: scale(1) translate(-50%, -50%);
		animation: line_beacon_animation 2s infinite;
	}
}

.asteria-graph-line-info {
	@apply absolute right-0 h-0 w-0;
	pointer-events: all;

	:hover {
		.asteria-graph-line-dot {
			background-color: rgb(255, 182, 0);
		}
	}

	&.asteria-graph-line-info-risk {
		.asteria-graph-line-dot {
			@include apply-colors('graph-dot-alert');
			animation: line_beacon_animation 2s infinite;
		}
	}

	&.asteria-graph-line-info-below-zero {
		&.asteria--feature-below-zero {
			.asteria-graph-line-dot {
				@include apply-colors('graph-dot-negative');
			}
		}
	}

	.asteria-component__text {
		@apply pointer-events-none absolute cursor-pointer select-none whitespace-nowrap text-graph-dot-normal-text;
		transform: translateX(-50%);
		top: -25px;
	}

	&.asteria-graph-line-info-close-to-top {
		.asteria-component__text {
			top: 10px;
		}
	}
}

.asteria-graph-line-group-history {
	.asteria-graph-line-dot {
		@include apply-colors('graph-dot-historical');
	}
}

.asteria-graph-line-group-today {
	.asteria-graph-line-dot {
		@include apply-colors('graph-dot-today');
	}
}

.asteria-graph-line-group-forecast {
	.asteria-graph-line-dot {
		@include apply-colors('graph-dot-forecast');
	}
}
