@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria--state-hoverable {
	&:not(:hover) {
		.asteria-component__forecaster-selector__helper {
			> * {
				&:not(:first-child) {
					@apply hidden;
				}
			}
		}
	}

	&:hover {
		.asteria-component__forecaster-selector__helper {
			> * {
				&:first-child:not(:last-child) {
					@apply hidden;
				}
			}
		}
	}
}

.asteria-component__forecaster-selector__item {
	> .asteria-component__dropdown__item__label {
		> .asteria-component__button {
			@apply w-full gap-2;

			> .asteria-component__button {
				@apply h-auto w-auto;
			}
		}
	}
}
