@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-search-wrapper-normal-background: white;
	--color-search-wrapper-normal-border: var(--color-border-normal);

	--color-search-wrapper-hover-background: rgba(243, 227, 224, 1);
	--color-search-wrapper-hover-border: black;

	--color-search-wrapper-focus-background: rgba(243, 227, 224, 1);
	--color-search-wrapper-focus-border: black;

	--color-search-selector-normal-background: rgba(243, 243, 243, 1);
	--color-search-selector-normal-border: transparent;
	--color-search-selector-normal-icon: rgba(107, 107, 107, 1);
	--color-search-selector-normal-text: rgba(110, 110, 115, 1);

	--color-search-selector-hover-background: #f5c9c1;
	--color-search-selector-hover-border: transparent;
	--color-search-selector-hover-icon: rgba(107, 107, 107, 1);
	--color-search-selector-hover-text: rgba(0, 0, 0, 1);

	--color-search-selector-focus-background: rgba(243, 227, 224, 1);
	--color-search-selector-focus-border: transparent;
	--color-search-selector-focus-icon: rgba(107, 107, 107, 1);
	--color-search-selector-focus-text: rgba(0, 0, 0, 1);

	--color-search-selector-counter-normal-background: rgba(215, 108, 99, 1);
	--color-search-selector-counter-normal-text: white;

	--color-search-selector-counter-hover-background: rgba(215, 108, 99, 1);
	--color-search-selector-counter-hover-text: white;

	--color-search-selector-counter-focus-background: rgba(215, 108, 99, 1);
	--color-search-selector-counter-focus-text: white;

	--color-search-floating-normal-background: white;
	--color-search-floating-normal-border: transparent;

	--color-search-floating-hover-background: white;
	--color-search-floating-hover-border: transparent;

	--color-search-floating-focus-background: white;
	--color-search-floating-focus-border: transparent;
}

.asteria-component__search {
	--color-search-chip-background: var(--color-button-icon-hover-background);
	--color-search-chip-border: var(--color-button-icon-hover-border);

	.asteria-component__search-content {
		@apply w-full rounded-full border border-solid border-border-normal;
		@apply transition-colors ease-in-out;

		.asteria-component__chip {
			@apply transition-colors ease-in-out;

			background-color: var(--color-search-chip-background);
			border-color: var(--color-search-chip-border);

			.asteria-component__typography {
				@apply text-black;
			}

			.asteria-component__chip__button,
			.asteria-component__chip__action {
				&.asteria-component__button.asteria-component__button--variant-link {
					.asteria-component__icon {
						&,
						.asteria-component__icon-content {
							@apply h-2 w-2;
						}

						.asteria-component__icon-content {
							@apply bg-icon-focus;
						}
					}
				}
			}
		}

		&:hover {
			--color-form-input-normal-placeholder: black;

			--color-search-chip-background: white;
			--color-search-chip-border: transparent;
		}

		.asteria-component__search-reset {
			@apply mr-2;
		}

		// .asteria-component__input {
		// 	.asteria-component__input__inner {
		// 		> .asteria-component__button {
		// 			&.asteria-component__button--icon-magnifier {
		// 				.asteria-component__icon {
		// 					@apply h-6 w-6;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	}

	&.asteria--state-active {
		--color-form-input-normal-placeholder: black;

		--color-search-chip-background: white;
		--color-search-chip-border: transparent;
	}

	.asteria-component__search-floating {
		@apply rounded-2xl;

		box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.15);
	}

	.asteria-component__search-selectors {
		.asteria-component__search-selector {
			> .asteria-component__button {
				&.asteria--state-has-counter {
					> .asteria-component__button {
						.asteria-component__icon {
							&,
							.asteria-component__icon-content {
								@apply h-4 w-4;
							}
						}
					}
				}
			}
		}
	}
}

.asteria-component__level.asteria-component__search-selector {
	&.asteria--variant-client {
		.asteria-component__dropdown__item__postfix {
			.asteria-component__icon {
				&,
				.asteria-component__icon-content {
					@apply h-4 w-4;
				}
			}
		}
	}
}
