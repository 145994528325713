@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__migration-modal {
	.asteria-component__stepper-wrapper {
		.asteria-component__stepper-step-circle,
		.asteria-component__stepper-line {
			background-color: #f1edeb;
		}

		.asteria-component__stepper-step-content {
			@apply flex flex-col gap-1;
		}

		.asteria-component__stepper-step-subtext {
			@apply italic;
		}
	}
}

.asteria-component__migration-alert {
	&.asteria--variant-success {
		.asteria-component__contenter__inner {
			@apply items-start;
		}
	}
}
