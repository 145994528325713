@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__financial-page {
	&.asteria--variant-credit {
		@apply flex h-full flex-col;

		.asteria-component__financial-page-section {
			&.asteria--variant-header {
				@apply bg-white p-4;

				.asteria-component__financial-page-navigation {
					.asteria-component__financial-page-navigation-back {
						@apply h-auto w-auto gap-2 p-0;

						.asteria-component__button {
							@apply h-auto w-auto p-0;
						}
					}
				}
			}

			&.asteria--variant-graph {
				@apply flex-1 bg-white;

				.asteria-graphs {
					@apply px-4 pt-2 pb-4;

					.asteria-component__legends__wrapper {
						@apply absolute -top-2;
					}

					.asteria-graph-inner {
						@apply pb-0 pt-12;
					}

					.asteria-graph-yaxis-labels {
						.asteria-prefix-y-axis {
							@apply -top-7 -translate-y-1/2;
						}
					}
				}
			}

			&.asteria--variant-teaser {
				@apply mt-2;

				// @apply mx-auto w-full max-w-5xl;
			}

			&.asteria--variant-footer {
				@apply mt-2 bg-white;
				// @apply mx-auto w-full max-w-5xl;

				.asteria-component__actionbar {
					@apply shadow-none;
				}
			}
		}
	}
}
