@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-onboarding {
			.asteria-component__onboarding-outdated-line,
			.asteria-component__onboarding-error-line {
				&.asteria-component__text {
					> .asteria-component__button {
						@apply h-auto p-0;
						> .asteria-component__text {
							@apply text-base;
						}
					}
				}
			}

			.asteria-component__card-onboarding-integrations-list {
				@apply overflow-y-auto;

				@include scroll('y');
			}
		}
	}
}
