@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;
@use './styles/colors.scss' as *;

.asteria-component__alert-icon {
	@apply rounded-full p-2;

	&.asteria--status-error {
		@apply bg-alert-error-icon-normal-background;

		--color-icon-normal: white;
		--color-icon-hover: white;
		--color-icon-focus: white;
	}

	&.asteria--status-warning {
		@apply bg-alert-warning-icon-normal-background;

		--color-icon-normal: white;
		--color-icon-hover: white;
		--color-icon-focus: white;
	}

	&.asteria--status-info {
		@apply bg-alert-info-icon-normal-background;

		--color-icon-normal: white;
		--color-icon-hover: white;
		--color-icon-focus: white;
	}

	&.asteria--status-success {
		@apply bg-alert-success-icon-normal-background;

		--color-icon-normal: white;
		--color-icon-hover: white;
		--color-icon-focus: white;
	}
}

.asteria-component__alert {
	@apply rounded-lg border-0 border-solid p-4;

	&.asteria-component__alert-screen {
		@apply p-0;

		> .asteria-component__wrapper {
			@apply gap-0;

			> .asteria-component__wrapper-header {
				@apply p-4;
				@apply hidden;
			}

			> .asteria-component__wrapper-content {
				@apply px-4 py-4;
			}
		}
	}

	.asteria-component__alert__wrapper {
		@apply gap-2;

		.asteria-component__typography,
		.asteria-component__button.asteria-component__button--variant-link {
			@apply m-0 h-auto p-0;
		}

		.asteria-component__alert__wrapper-header {
			@apply m-0 p-0;

			.asteria-component__wrapper-header__content {
				@apply flex items-center gap-2;

				.asteria-component__icon {
					@apply rounded-full p-1;
				}

				.asteria-component__typography {
					@apply flex-1;
				}
			}
		}

		.asteria-component__alert__wrapper-content {
			@apply m-0 rounded-lg p-0;
		}

		.asteria-component__alert__wrapper-footer {
			@apply m-0 p-0;
		}
	}

	&.asteria-component__alert--no-title {
		.asteria-component__alert__wrapper-content {
			@apply ml-0 flex-row;

			> .asteria-component__icon {
				@apply mr-2 self-center;
			}
		}

		.asteria-component__wrapper {
			&:not(.asteria-component__wrapper--variant-horizontal) {
				.asteria-component__alert__wrapper-footer {
					@apply ml-5;
				}
			}
		}
	}
}
