@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__checkbox {
	.asteria-component__checkbox__inner {
		.asteria-component__typography {
			@apply text-form-checkbox-normal-text;
		}

		input {
			& + .asteria-component__icon {
				@apply border-form-checkbox-normal-border bg-form-checkbox-normal-background;

				.asteria-component__icon-content {
					@apply bg-form-checkbox-normal-icon;
				}
			}

			&:checked + .asteria-component__icon {
				@apply border-form-checkbox-focus-border bg-form-checkbox-focus-background;

				.asteria-component__icon-content {
					@apply bg-form-checkbox-focus-icon;
				}
			}
		}
	}

	&:hover {
		.asteria-component__form__label {
			@apply border-form-label-hover-border bg-form-label-hover-background text-form-label-hover-text;

			.asteria-component__typography {
				@apply text-form-label-hover-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-label-hover-icon;
				}
			}
		}

		.asteria-component__checkbox__inner {
			.asteria-component__typography {
				@apply text-form-checkbox-hover-text;
			}

			input {
				& + .asteria-component__icon {
					@apply border-form-checkbox-hover-border  bg-form-checkbox-hover-background;

					.asteria-component__icon-content {
						@apply bg-form-checkbox-hover-icon;
					}
				}

				&:checked + .asteria-component__icon {
					@apply border-form-checkbox-hover-border bg-form-checkbox-focus-background;

					.asteria-component__icon-content {
						@apply bg-form-checkbox-focus-icon;
					}
				}
			}
		}
	}

	&.asteria--state-active {
		.asteria-component__checkbox__inner {
			.asteria-component__typography {
				@apply text-form-checkbox-focus-text;
			}

			input {
				& + .asteria-component__icon {
					@apply border-form-checkbox-focus-border  bg-form-checkbox-focus-background;

					.asteria-component__icon-content {
						@apply bg-form-checkbox-focus-icon;
					}
				}

				&:checked + .asteria-component__icon {
					@apply border-form-checkbox-focus-border bg-form-checkbox-focus-background;

					.asteria-component__icon-content {
						@apply bg-form-checkbox-focus-icon;
					}
				}
			}
		}
	}

	&.asteria--state-disabled {
		.asteria-component__checkbox__inner {
			.asteria-component__typography {
				@apply text-form-checkbox-disabled-text;
			}

			input {
				& + .asteria-component__icon {
					@apply border-form-checkbox-disabled-border  bg-form-checkbox-disabled-background;

					.asteria-component__icon-content {
						@apply bg-form-checkbox-disabled-background;
					}
				}

				&:checked + .asteria-component__icon {
					@apply border-form-checkbox-disabled-border bg-form-checkbox-disabled-background;

					.asteria-component__icon-content {
						@apply bg-form-checkbox-disabled-icon;
					}
				}
			}
		}
	}

	&.asteria--status-info {
		.asteria-component__checkbox__inner {
			.asteria-component__typography {
				@apply text-alert-info-normal-text;
			}

			input {
				& + .asteria-component__icon {
					@apply border-alert-info-normal-border bg-white;

					.asteria-component__icon-content {
						@apply bg-alert-info-normal-icon;
					}
				}

				&:checked + .asteria-component__icon {
					@apply border-alert-info-normal-border bg-alert-info-icon-normal-background;

					.asteria-component__icon-content {
						@apply bg-alert-info-normal-icon;
					}
				}
			}
		}
	}

	&.asteria--status-success {
		.asteria-component__checkbox__inner {
			.asteria-component__typography {
				@apply text-alert-success-normal-text;
			}

			input {
				& + .asteria-component__icon {
					@apply border-alert-success-normal-border bg-white;

					.asteria-component__icon-content {
						@apply bg-alert-success-normal-icon;
					}
				}

				&:checked + .asteria-component__icon {
					@apply border-alert-success-normal-border bg-alert-success-icon-normal-background;

					.asteria-component__icon-content {
						@apply bg-alert-success-normal-icon;
					}
				}
			}
		}
	}

	&.asteria--status-warning {
		.asteria-component__checkbox__inner {
			.asteria-component__typography {
				@apply text-alert-warning-normal-text;
			}

			input {
				& + .asteria-component__icon {
					@apply border-alert-warning-normal-border bg-white;

					.asteria-component__icon-content {
						@apply bg-alert-warning-normal-icon;
					}
				}

				&:checked + .asteria-component__icon {
					@apply border-alert-warning-normal-border bg-alert-warning-icon-normal-background;

					.asteria-component__icon-content {
						@apply bg-alert-warning-normal-icon;
					}
				}
			}
		}
	}

	&.asteria--status-error {
		.asteria-component__checkbox__inner {
			.asteria-component__typography {
				@apply text-alert-error-normal-text;
			}

			input {
				& + .asteria-component__icon {
					@apply border-alert-error-normal-border bg-white;

					.asteria-component__icon-content {
						@apply bg-alert-error-normal-icon;
					}
				}

				&:checked + .asteria-component__icon {
					@apply border-alert-error-normal-border bg-alert-error-icon-normal-background;

					.asteria-component__icon-content {
						@apply bg-alert-error-normal-icon;
					}
				}
			}
		}
	}
}
