@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

/*
.asteria-component__support-button-wrapper {
	> .asteria-component__button {
		&.asteria--size-lg,
		&.asteria--size-md,
		&.asteria--size-sm {
			@include asteria-btn-md;

			// &.asteria-component__button--icon-position-first {
			// 	@apply pl-2;
			// }
		}

		&.asteria-component__button--variant-secondary {
			@apply rounded-full;

			&.asteria-component__button--icon-position-first {
				@apply p-1;
			}

			> .asteria-component__typography {
				@apply hidden;
			}
		}
	}
}
 */

.asteria-component__support {
	.asteria-component__form-wrapper {
		@apply rounded-none;
	}

	.asteria-component__faq__wrapper {
		.asteria-component__button {
			&.asteria-component__load-more {
				@include asteria-btn-sm;

				@apply px-0;
			}
		}
	}
}

.asteria-component__faq-contact-wrapper {
	@apply rounded-lg;

	.asteria-component__button {
		&.asteria-component__button--variant-href {
			@include asteria-btn-sm(false, true, true, md);
		}
	}
}
