@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__search-form {
	@apply contents;
}

.asteria-component__search {
	--color-form-input-normal-background: transparent;
	--color-form-input-normal-border: transparent;

	--color-form-input-hover-background: transparent;
	--color-form-input-hover-border: transparent;

	--color-form-input-focus-background: transparent;
	--color-form-input-focus-border: transparent;

	--color-form-input-disabled-background: transparent;
	--color-form-input-disabled-border: transparent;

	@apply relative grid items-center gap-2;

	grid-template-columns: 1fr auto;

	.asteria-component__search-content {
		@apply w-fit min-w-0 max-w-full;

		@apply border-search-wrapper-normal-border bg-search-wrapper-normal-background;

		&:hover {
			@apply border-search-wrapper-hover-border bg-search-wrapper-hover-background;
		}
	}

	&.asteria--state-active {
		.asteria-component__search-content {
			@apply border-search-wrapper-focus-border bg-search-wrapper-focus-background;
		}
	}

	.asteria-component__search-selectors {
		@apply flex gap-2;
	}

	.asteria-component__search-floating {
		@apply absolute left-0 right-0 rounded-lg border border-solid p-4 shadow-lg;
		@apply border-search-floating-normal-border bg-search-floating-normal-background;

		top: calc(100% + theme('spacing.2'));

		&.asteria--variant-examples {
			@apply flex flex-col gap-2;

			.asteria-component__search-floating-content {
				@apply flex flex-wrap gap-2;
			}
		}

		&.asteria--variant-results {
			@apply flex max-h-52 flex-col gap-2;

			@include scroll();

			> .asteria-component__chip {
				@apply w-fit;
			}
		}
	}

	&:not(.asteria--state-active) {
		.asteria-component__search-floating {
			@apply hidden;
		}
	}

	.asteria-component__search-input {
		@apply w-full;

		.asteria-component__slider {
			@apply mr-2;

			order: -1;
		}

		&.asteria-component__input:not(.asteria-component__input--type-textarea) {
			.asteria-component__input__inner {
				@apply max-h-fit items-center gap-0;
			}
		}
	}

	.asteria-component__search-reset {
		@apply invisible;
		&.asteria--state-has-filters {
			@apply visible;
		}
	}
}
