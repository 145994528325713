@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__feedback {
	&.asteria-component__wrapper {
		@apply border-feedback-normal-border bg-feedback-normal-background;

		.asteria-component__wrapper-header {
			@apply border-feedback-header-normal-border bg-feedback-header-normal-background;

			.asteria-component__title {
				@apply text-feedback-header-normal-title;
			}
		}

		.asteria-component__wrapper-content {
			@apply border-feedback-body-normal-border bg-feedback-body-normal-background;
		}
	}
}

.asteria-component__feedback__rating {
	.asteria-component__feedback__rating-values {
		.asteria-component__button {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-feedback-icon-normal-border;
				}
			}

			&:hover,
			&.asteria--state-active,
			&.asteria--state-hover {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-feedback-icon-focus-border;
					}
				}
			}
		}
	}
}
