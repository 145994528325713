@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-tab-content-normal-border: transparent;
}

.asteria-component__tabs {
	.asteria-component__tabs__navigation__wrapper {
		@apply gap-2;

		.asteria-component__tabs__navigation,
		.asteria-component__tabs__navigation:first-child,
		.asteria-component__tabs__navigation:last-child {
			@include asteria-btn-tab;

			@apply flex-none;
		}
	}
}
