@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--input-autocomplete-rounding: theme('borderRadius.lg');
}

.asteria-component__form-v2__input {
	.asteria-component__form-v2__input-wrapper__input {
		&.asteria--has-visible {
			input {
				@apply bg-transparent text-transparent;

				&::selection {
					@apply bg-transparent text-transparent;
				}

				&::-moz-selection {
					@apply bg-transparent text-transparent;
				}
			}
		}

		&::after {
			@apply text-form-input-normal-text;
		}
	}

	input {
		@apply text-form-input-normal-text;

		&::placeholder {
			@apply text-form-input-normal-placeholder;
		}
	}

	.asteria-component__form-v2__input-wrapper__element {
		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-form-input-normal-icon;
			}
		}
	}

	.asteria-component__form-v2__input-wrapper__inner {
		@apply border-form-input-normal-border bg-form-input-normal-background;
	}

	&:hover {
		input {
			@apply text-form-input-hover-text;

			&::placeholder {
				@apply text-form-input-hover-placeholder;
			}
		}

		.asteria-component__form-v2__input-wrapper__element {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-input-hover-icon;
				}
			}
		}

		.asteria-component__form-v2__input-wrapper__inner {
			@apply border-form-input-hover-border bg-form-input-hover-background;
		}
	}

	&.asteria--state-active {
		input {
			@apply text-form-input-focus-text;

			&::placeholder {
				@apply text-form-input-focus-placeholder;
			}
		}

		.asteria-component__form-v2__input-wrapper__element {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-input-focus-icon;
				}
			}
		}

		.asteria-component__form-v2__input-wrapper__inner {
			@apply border-form-input-focus-border bg-form-input-focus-background;
		}
	}

	&.asteria--state-disabled {
		input {
			@apply text-form-input-disabled-text;

			&::placeholder {
				@apply text-form-input-disabled-placeholder;
			}
		}

		.asteria-component__form-v2__input-wrapper__element {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-input-disabled-icon;
				}
			}
		}

		.asteria-component__form-v2__input-wrapper__inner {
			@apply border-form-input-disabled-border bg-form-input-disabled-background;
		}
	}

	&.asteria--status-info {
		input {
			@apply text-alert-info-normal-text;

			&::placeholder {
				@apply text-alert-info-normal-text;
			}
		}

		.asteria-component__form-v2__input-wrapper__element {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-info-normal-text;
				}
			}
		}

		.asteria-component__form-v2__input-wrapper__inner {
			@apply border-alert-info-normal-border;
		}
	}

	&.asteria--status-success {
		input {
			@apply text-alert-success-normal-text;

			&::placeholder {
				@apply text-alert-success-normal-text;
			}
		}

		.asteria-component__form-v2__input-wrapper__element {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-success-normal-text;
				}
			}
		}

		.asteria-component__form-v2__input-wrapper__inner {
			@apply border-alert-success-normal-border;
		}
	}

	&.asteria--status-warning {
		input {
			@apply text-alert-warning-normal-text;

			&::placeholder {
				@apply text-alert-warning-normal-text;
			}
		}

		.asteria-component__form-v2__input-wrapper__element {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-warning-normal-text;
				}
			}
		}

		.asteria-component__form-v2__input-wrapper__inner {
			@apply border-alert-warning-normal-border;
		}
	}

	&.asteria--status-error {
		input {
			@apply text-alert-error-normal-text;

			&::placeholder {
				@apply text-alert-error-normal-text;
			}
		}

		.asteria-component__form-v2__input-wrapper__element {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-error-normal-text;
				}
			}
		}

		.asteria-component__form-v2__input-wrapper__inner {
			@apply border-alert-error-normal-border;
		}
	}
}
