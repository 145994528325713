@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-salaries {
			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply p-0;
				}
			}

			.asteria-component__card-chart {
				width: 100%;
				height: theme('spacing.80');
			}

			.recharts-tooltip-wrapper {
				> div {
					&::before {
						@apply visible absolute bottom-0 right-1/2 z-50 h-2 w-2 bg-inherit;

						content: '';
						transform: translateY(50%) translateX(50%) rotate(45deg);
					}
				}

				.asteria-component__text {
					&.asteria--type-title,
					&.asteria--type-total {
						@apply font-semibold;
					}
				}
			}
		}
	}
}

.asteria-component__salaries-modal {
	.asteria-component__card-chart {
		width: 100%;
		height: theme('spacing.80');
		min-height: theme('spacing.80');
	}

	.recharts-tooltip-wrapper {
		> div {
			&::before {
				@apply visible absolute bottom-0 right-1/2 z-50 h-2 w-2 bg-inherit;

				content: '';
				transform: translateY(50%) translateX(50%) rotate(45deg);
			}
		}

		.asteria-component__text {
			&.asteria--type-title,
			&.asteria--type-total {
				@apply font-semibold;
			}
		}
	}

	.asteria-component__employees-list {
		@apply flex;

		.asteria-component__list__filter {
			.asteria-component__list__item {
				@apply w-full;

				.asteria-component__list__cell {
					@apply flex-1 cursor-pointer;

					&.asteria--type-salary {
						@apply flex items-end;

						> div {
							@apply justify-end;
						}
					}
				}
			}
		}

		> .asteria-component__list__item {
			@apply h-auto w-full border-b border-solid border-list-item-normal-border  py-1;

			.asteria-component__list__cell {
				@apply flex-1;

				&.asteria--type-salary {
					@apply text-right;
				}
			}
		}
	}
}
