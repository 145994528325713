.asteria-component__table {
	.asteria-component__table-header {
		@apply box-border border-0 border-b border-solid;
		@apply border-list-group-header-normal-border bg-list-group-header-normal-background;
	}

	.asteria-component__table-footer {
	}

	.asteria-component__table-row {
		@apply grid grid-flow-col items-center gap-2 border-0 border-b border-solid;
		@apply border-list-item-normal-border bg-list-item-normal-background;

		&.asteria-component__table-row--no-border {
			@apply border-0;
		}
	}

	.asteria-component__table-cell {
		@apply flex h-10 items-center gap-2 overflow-hidden;

		.asteria-component__typography {
			@apply overflow-hidden text-ellipsis;
		}
	}
}
