@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__faq__wrapper {
	@apply flex flex-col items-start gap-2 py-4;

	.asteria-component__accordion {
		@apply w-full;
		.asteria-component__accordion-item {
			.asteria-component__accordion-item__label,
			.asteria-component__accordion-item__content {
				@apply px-0;
			}
		}
	}
}

.asteria-component__button {
	&.asteria-component__load-more {
		@apply mt-2 flex px-0;
	}
}

.asteria-component__faq-contact-wrapper {
	@apply flex flex-col items-start gap-4 rounded-sm border border-solid border-border-normal p-6;

	> .asteria-component__title {
		--color-title: var(--color-page-title);
	}

	> .asteria-component__button--variant-href {
		@apply flex gap-2;

		> .asteria-component__button--icon-only {
			&.asteria--size-sm {
				@apply w-auto;
			}
		}
	}
}
