@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;
@use './styles/colors.scss' as *;

#{$global} {
	--prefix__size: theme('spacing.3');
}

.asteria-component__multi-prefix {
	@apply relative inline-flex flex-row-reverse;

	.asteria-component__prefix {
		@apply relative border border-solid border-white;

		&:not(:last-child) {
			margin-left: calc(
				calc(var(--prefix__width, var(--prefix__size)) / 1.6) * -1
			);
		}
	}
}

.asteria-component__prefix {
	@apply inline-block aspect-square rounded-full;

	height: var(--prefix__height, var(--prefix__size));
	width: var(--prefix__width, var(--prefix__size));

	&.asteria--size-sm {
		--prefix__size: theme('spacing.2');
	}

	&.asteria--size-lg {
		--prefix__size: theme('spacing.4');
	}

	&.asteria--type-account {
		@apply aspect-auto h-auto w-auto rounded-none;

		svg {
			height: 12px;
			width: 48px;

			line {
				stroke-width: 2px;
			}

			use#account-dot {
				stroke-width: 4px;
			}
		}

		&.asteria--variant-history {
			svg {
				line {
					@apply stroke-graph-line-normal-background;
				}

				use#account-dot {
					@apply fill-graph-dot-historical-normal-background stroke-graph-dot-historical-normal-border;
				}
			}

			&.asteria--variant-negative {
				svg {
					line {
						@apply stroke-graph-line-negative-background;
					}

					use#account-dot {
						@apply fill-graph-dot-negative-normal-background stroke-graph-dot-negative-normal-border;
					}
				}
			}
		}

		&.asteria--variant-future {
			svg {
				line {
					@apply stroke-graph-line-normal-forecast;
					stroke-dasharray: 4 3;
				}

				use#account-dot {
					@apply fill-graph-dot-forecast-normal-background stroke-graph-dot-forecast-normal-border;
				}
			}

			&.asteria--variant-negative {
				svg {
					line {
						@apply stroke-graph-line-negative-forecast;
					}

					use#account-dot {
						@apply fill-graph-dot-negative-normal-background stroke-graph-dot-negative-normal-border;
					}
				}
			}
		}
	}

	&.asteria-color__credit-area {
		@apply border border-solid border-graph-line-normal-credit;
	}
}
