@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--forecaster-y-axis-width: 70px;
}

.asteria-component__forecaster-graph {
	@apply grid h-full w-full gap-4;

	grid-template-areas: 'graph-content';
	grid-template-columns: 1fr;

	&.asteria--state-has-y-axis {
		@apply pt-4;

		grid-template-areas: 'graph-axis-y graph-content';
		grid-template-columns: var(--forecaster-y-axis-width) 1fr;
	}

	> .asteria-graph-y-axis {
		grid-area: graph-axis-y;
	}

	> .asteria-component__forecaster-graph-content {
		@apply relative isolate my-2 grid;

		grid-template-columns: repeat(calc(var(--size, 7) - 2), 1fr);

		grid-area: graph-content;
	}

	.asteria-component__legends__wrapper {
		@apply absolute -top-8;
	}

	* {
		@apply transition-opacity;
	}

	.asteria--state-hidden {
		@apply hidden;
	}

	.asteria--state-invisible {
		@apply opacity-50;
	}
}
