@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-clients-skeleton-normal-background: rgba(235, 231, 226, 1);
}

.asteria-component__clients-list {
	@apply gap-4;

	.asteria-component__datepicker {
		> .asteria-component__input {
			@apply hidden;
		}
	}
}

.asteria-component__clients-list__actions {
	.asteria-component__clients-list__action {
		&.asteria--type-selector {
		}

		&.asteria--type-sorting {
			.asteria-component__clients-list__dropdown {
				&.asteria--type-sorting {
					.asteria-component__typography-group {
						@apply flex items-baseline justify-center gap-1;

						.asteria-component__typography {
							&.asteria--type-label {
								@apply font-bold text-button-link-normal-text;
							}

							&.asteria--type-value {
								@apply font-body text-xs font-normal;
							}
						}
					}

					.asteria-component__button {
						@apply items-center;

						.asteria-component__button {
							@apply h-6 w-6;
						}
					}
				}
			}
		}
	}
}

.asteria-component__level.asteria-component__clients-list__dropdown {
	.asteria-component__dropdown__item {
		.asteria-component__dropdown__item__postfix {
			@apply transition-opacity;
		}

		&:not(.asteria--state-active) {
			.asteria-component__dropdown__item__postfix {
				@apply opacity-0;
			}
		}
	}
}

.asteria-component__list.asteria-component__clients-list__table {
	@apply grid items-center;

	grid-template-columns: 1fr auto auto;

	.asteria-component__clients-list-spacer {
		grid-column: span 3;
	}

	> .asteria-component__list__filter {
		@apply contents;

		.asteria-component__list__cell {
			@apply h-10 border-0 border-b border-solid border-border-normal;

			.asteria-component__typography {
				@apply font-button font-bold;
			}
		}
	}

	> .asteria-component__list__item {
		.asteria-component__list__cell {
			@apply h-12;
		}
	}

	.asteria-component__list__item {
		@apply contents;

		&:not(:last-child) {
			.asteria-component__list__cell {
				@apply border-0 border-b border-solid border-border-normal;
			}
		}

		&.asteria--variant-empty {
			.asteria-component__list__cell {
				grid-column: span 3;
			}
		}
	}

	.asteria-component__list__cell {
		@apply flex h-full items-center py-2 px-3;

		&.asteria--type-revenue {
			@apply justify-end text-right;
		}

		&.asteria--type-action {
			@apply p-0;
		}

		.asteria-component__skeleton {
			@apply flex h-2 w-full rounded-full;

			background-color: var(--color-clients-skeleton-normal-background);
		}
	}
}
