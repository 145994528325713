@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__flex-line {
	&.asteria--direction-vertical {
		> .asteria-component__flex-item-wrapper {
			&.asteria--animation-show {
				@apply overflow-hidden;
				animation: verticalShow 0.5s forwards;
			}

			&.asteria--animation-close {
				@apply overflow-hidden;
				animation: verticalClose 0.5s forwards;
			}
		}
	}

	&.asteria--direction-horizontal {
		> .asteria-component__flex-item-wrapper {
			&.asteria--animation-show {
				@apply overflow-hidden;
				animation: horizontalShow 0.5s forwards;
			}

			&.asteria--animation-close {
				@apply overflow-hidden;
				animation: horizontalClose 0.5s forwards;
			}
		}
	}
}
.asteria-component__flex-item-wrapper {
	@apply m-0 flex w-full flex-nowrap gap-0 p-0;

	flex: var(--size) 1;

	> .asteria-component__flex-item-content {
		@apply inline-flex w-full;
	}
}

@keyframes verticalClose {
	0% {
		max-height: 9999px;
	}
	5% {
		max-height: 400px;
	}
	100% {
		max-height: 0px;
	}
}

@keyframes horizontalClose {
	0% {
		flex-grow: 1;
	}
	100% {
		flex-grow: 0.0001;
	}
}

@keyframes verticalShow {
	0% {
		max-height: 0px;
	}
	95% {
		max-height: 400px;
	}
	100% {
		max-height: 9999px;
	}
}

@keyframes horizontalShow {
	0% {
		flex-grow: 0.0001;
	}
	100% {
		flex-grow: 1;
	}
}
