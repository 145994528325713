@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		@apply border border-solid;

		@apply border-card-normal-border bg-card-normal-background;

		&.asteria--state-pinned {
			@apply border-card-pinned-border bg-card-pinned-background;
		}

		> .asteria-component__wrapper-content {
			&.asteria-component__card-content {
				@apply pb-6;
			}
		}

		&:not(.asteria-component__wrapper--no-header) {
			> .asteria-component__wrapper-content {
				&.asteria-component__card-content {
					@apply pt-0;
				}
			}
		}

		&:not(.asteria-component__wrapper--no-footer) {
			> .asteria-component__wrapper-content {
				&.asteria-component__card-content {
					@apply pb-0;
				}
			}
		}
	}
}

.asteria-component__wrapper-header {
	&.asteria-component__card-header {
		@apply bg-transparent;

		.asteria-component__title {
			@apply text-card-normal-title;
		}
	}
}

.asteria-component__wrapper-content {
	&.asteria-component__card-content {
		@apply flex-1 gap-4 bg-transparent;
	}
}

.asteria-component__wrapper-footer {
	&.asteria-component__card-footer {
		@apply bg-transparent;
	}
}
