@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__graph-scrollbar {
	--width: 160px;
	--gap: calc(var(--graph-yaxis-wrapper-width, 96px) + theme('spacing.8'));

	--area-width: calc(100% - var(--gap) - var(--width));
	--step: calc(var(--area-width) / var(--size));

	@apply hidden;
	@apply pointer-events-none absolute bottom-1 rounded-full bg-black opacity-0 transition-all;

	left: calc(var(--gap) + calc(var(--step) * var(--position)));

	width: var(--width);
	height: 6px;

	&.asteria--variant-default {
		@apply left-1/2 -translate-x-1/2;
	}

	.asteria-graphs:hover & {
		@apply opacity-40;
	}
}

.asteria-resizer {
	display: flex;

	.asteria-resize-handle {
		overflow: auto;
		resize: vertical;
		display: block;
		height: 100%;
		width: 20px;
		position: relative;
		left: 50%;
		transform: translateX(-14px);
		z-index: 5;
		margin-bottom: 16px;
		min-height: 199px;
		height: 300px;

		&::-webkit-resizer {
			background-color: transparent;
		}

		&::-moz-resizer {
			background-color: transparent;
		}

		.asteria-resize-handle--handle {
			content: '';
			display: block;
			width: calc(100% + 0px);
			height: 4px;
			background: #9f8c82;
			position: absolute;
			bottom: 0;
			border-radius: 100px;
			cursor: row-resize !important;
			opacity: 0.4;
			padding: 0;
			min-height: unset;
			margin: 0;
			gap: 0;
			align-items: center;
			border-width: 0;
			border-style: solid;
			border-color: transparent;
			box-shadow: 0px 0px 0px transparent;

			&:focus,
			&:active {
				pointer-events: none;
			}
		}

		&:hover {
			.asteria-resize-handle--handle {
				background: #4c2d2c;
				opacity: 1;
			}
		}

		&:focus,
		&:active {
			.asteria-resize-handle--handle {
				background: #4c2d2c;
				opacity: 1;
				pointer-events: none;
			}
		}
	}
}

.asteria-cashflow-tooltip-row {
	@apply flex items-center gap-2;

	&.asteria-cashflow-tooltip-row-total {
		@apply flex;
	}
}

.asteria-cashflow-tooltip-section {
	.asteria-cashflow-tooltip-row {
		.asteria-cashflow-tooltip-forecast-diff {
			@apply ml-5 text-xs;
		}
	}
}

.asteria-graph-tooltip-wrapper {
	min-width: theme('spacing.80');
}

.asteria-graph-spread-tooltip-wrapper {
	@apply gap-4;

	.asteria-graph-spread-tooltip-info {
		@apply text-xs text-content-text-muted;
	}
}

.graph-tooltip-content {
	@apply flex flex-col gap-2;
}

.graph-tooltip-bottom-action {
	@apply mt-2;
}

.asteria-cashflow-tooltip-section {
	@apply flex flex-col;

	&.asteria-cashflow-tooltip-section-deposit,
	&.asteria-cashflow-tooltip-section-withdraw {
		.asteria-cashflow-tooltip-section-deposit,
		.asteria-cashflow-tooltip-section-withdraw {
			@apply gap-0;
		}

		.asteria-component__typography {
			&.asteria--state-empty {
				@apply mt-2;
			}
		}

		.asteria-cashflow-tooltip-section-inner-deposit,
		.asteria-cashflow-tooltip-section-inner-withdraw {
			@apply mt-2 gap-2;
		}

		.asteria-cashflow-tooltip-section-total {
			@apply mt-1;
		}
	}

	&.asteria-cashflow-tooltip-section-account {
		> .asteria-cashflow-tooltip-section-inner {
			@apply mt-3;
		}

		.asteria-cashflow-tooltip-section-account {
			> .asteria-cashflow-tooltip-section-inner {
				@apply m-0;
			}
		}
	}

	> .asteria-component__typography {
		@apply self-center;
	}

	> .asteria-cashflow-tooltip-row-subtext {
		@apply mt-2 self-center;
	}

	.asteria-cashflow-tooltip-section-inner {
		@apply flex flex-col gap-2;
	}

	.asteria-cashflow-tooltip-section-currencies,
	.asteria-cashflow-tooltip-section-inner-currencies {
		@apply text-xs;

		.asteria-component__typography {
			@apply text-xs;
		}
	}

	.asteria-cashflow-tooltip-total {
		@apply ml-auto;
	}

	.asteria-cashflow-tooltip-label {
		@apply ml-5;
	}

	.asteria-component__prefix + .asteria-cashflow-tooltip-label {
		@apply ml-0;
	}

	.currency-flag + .asteria-cashflow-tooltip-label {
		@apply ml-0;
	}

	.asteria-cashflow-tooltip-section-inner-currencies {
		@apply ml-5 flex-row gap-2;

		.asteria-cashflow-tooltip-row-currency {
			@apply gap-1;
		}

		.asteria-cashflow-tooltip-label {
			@apply hidden;
		}
	}

	.asteria-cashflow-tooltip-section-total {
		@apply gap-0;
		> .asteria-cashflow-tooltip-row-total {
			> .asteria-component__typography {
				@apply font-semibold;
			}
		}
	}
}

.asteria-cashflow-tooltip-section-old {
	@apply w-full;

	width: 100%;
	list-style: none;
	margin: 0px;
	padding: 0px;

	.asteria-cashflow-tooltip-section-inner {
		list-style: none;
		margin: 0px;
		padding: 0px;

		display: flex;
		flex-direction: column;
	}

	.asteria-cashflow-tooltip-section-inner-currencies {
		display: flex;
		flex-direction: row;

		> .asteria-cashflow-tooltip-row-currencies-risk {
			.asteria-cashflow-tooltip-label {
				flex-grow: 0 !important;
				min-width: auto !important;
			}
		}
	}
}

.graph-tooltip-content {
	@apply m-0 p-0;

	.asteria-cashflow-tooltip-section-inner {
		> .asteria-cashflow-tooltip-row-paid,
		> .asteria-cashflow-tooltip-section-paid {
			order: 5;
		}

		> .asteria-cashflow-tooltip-row-unpaid,
		> .asteria-cashflow-tooltip-section-unpaid {
			order: 4;
		}

		> .asteria-cashflow-tooltip-row-signed,
		> .asteria-cashflow-tooltip-section-signed {
			order: 3;
		}

		> .asteria-cashflow-tooltip-row-forecast,
		> .asteria-cashflow-tooltip-section-forecast {
			order: 2;
		}

		> .asteria-cashflow-tooltip-row-overdue,
		> .asteria-cashflow-tooltip-section-overdue {
			order: 1;
		}

		> .asteria-cashflow-tooltip-row-total,
		> .asteria-cashflow-tooltip-section-total {
			order: 99;
		}
	}

	&.asteria--state-below-zero {
		.asteria-color__risk {
			&.asteria-component__prefix {
				border-color: theme('colors.graph.dot.negative.normal.border');
			}
		}
	}
}

.asteria-cashflow-tooltip-section {
	> .asteria-cashflow-tooltip-row-account {
		> .asteria-cashflow-tooltip-label {
			@apply ml-0;
		}
	}
}

.asteria-cashflow-tooltip-section-currencies-risk {
	display: none;
}

.asteria-cashflow-tooltip-row-account-forecast-span {
	.asteria-component__probability__wrapper {
		@apply ml-5;
	}

	.asteria-cashflow-tooltip-label {
		display: none;
	}
}
