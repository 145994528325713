@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--dropdown__has-scroll__max-height: 300px;
}

.asteria-component__dropdown {
	@apply flex flex-col;

	&.asteria--size-sm {
		> .asteria-component__dropdown__toggle {
			.asteria-component__text {
				@apply text-sm;
			}
		}
	}

	&.asteria--size-lg {
		> .asteria-component__dropdown__toggle {
			.asteria-component__text {
				@apply text-sm;
			}
		}
	}
}

.asteria-component__dropdown__item {
	@apply box-border flex h-10 items-center;

	min-height: theme('spacing.12');

	&.asteria--size-sm {
		min-height: theme('spacing.10');
	}

	.asteria-component__typography {
		@apply overflow-hidden text-ellipsis whitespace-nowrap;
	}

	.asteria-component__dropdown__item__label {
		@apply w-full pr-4;

		padding-left: calc(
			theme('spacing.4') + calc(var(--level, 0) * theme('spacing.4'))
		);
	}

	&.asteria-component__dropdown__item--has-prefix {
		.asteria-component__dropdown__item__label {
			@apply pl-0;
		}
	}

	&.asteria-component__dropdown__item--has-postfix {
		.asteria-component__dropdown__item__label {
			@apply pr-0;
		}
	}

	&.asteria-component__dropdown__item--empty,
	&.asteria-component__dropdown__item--input {
		@apply px-4;

		.asteria-component__input {
			@apply w-full;
		}
	}

	.asteria-component__dropdown__item__prefix,
	.asteria-component__dropdown__item__postfix {
		@apply h-full;

		> * {
			@apply aspect-square h-full w-full;
		}
	}

	&:not(.asteria--state-disabled):not(.asteria-component__dropdown__item--empty):not(.asteria-component__dropdown__item--input) {
		@apply cursor-pointer;
	}

	&:not(.asteria-component__dropdown__item--no-colors):not(.asteria-component__dropdown__item--empty):not(.asteria-component__dropdown__item--input) {
		@apply bg-dropdown-item-normal-background text-dropdown-item-normal-text;

		.asteria-component__icon {
			&:not(.asteria-component__checkbox .asteria-component__icon) {
				.asteria-component__icon-content {
					@apply bg-dropdown-item-normal-icon;
				}
			}
		}

		.asteria-component__typography {
			@apply text-dropdown-item-normal-text;
		}

		&:hover {
			@apply bg-dropdown-item-hover-background;

			.asteria-component__icon {
				&:not(.asteria-component__checkbox .asteria-component__icon) {
					.asteria-component__icon-content {
						@apply bg-dropdown-item-hover-icon;
					}
				}
			}

			.asteria-component__typography {
				@apply text-dropdown-item-hover-text;
			}
		}

		&.asteria--state-active {
			@apply bg-dropdown-item-focus-background;

			.asteria-component__icon {
				&:not(.asteria-component__checkbox .asteria-component__icon) {
					.asteria-component__icon-content {
						@apply bg-dropdown-item-focus-icon;
					}
				}
			}

			.asteria-component__typography {
				@apply text-dropdown-item-focus-text;
			}
		}

		&.asteria--state-disabled {
			@apply bg-dropdown-item-disabled-background;

			.asteria-component__icon {
				&:not(.asteria-component__checkbox .asteria-component__icon) {
					.asteria-component__icon-content {
						@apply bg-dropdown-item-disabled-icon;
					}
				}
			}

			.asteria-component__typography {
				@apply text-dropdown-item-disabled-text;
			}
		}
	}
}

.asteria-component__dropdown__list {
	@apply m-0 flex flex-col border border-solid border-dropdown-normal-border bg-dropdown-normal-background shadow;

	&.asteria-component__wrapper--scroll {
		max-height: var(--dropdown__has-scroll__max-height);
		@include scroll();
	}

	&.asteria-component__wrapper--row {
		@apply flex-row;
	}
}

.asteria-component__dropdown-modal {
	--color-dropdown-normal-border: transparent;
	.asteria-component__dropdown__list {
		@apply shadow-none;
	}
}
