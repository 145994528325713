@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__form__label {
	@apply inline-flex items-center gap-2
        border-form-label-normal-border bg-form-label-normal-background;

	.asteria-component__typography {
		@apply m-0 font-label text-form-label-normal-text;
	}

	.asteria-component__input__label__tooltip {
		.asteria-component__icon-content {
			--color-form-label-normal-icon: var(--color-link);
		}

		&:hover {
			.asteria-component__icon-content {
				--color-form-label-normal-icon: var(--color-link-focus);
			}
		}
	}

	.asteria-component__icon {
		.asteria-component__icon-content {
			@apply bg-form-label-normal-icon;
		}
	}

	.asteria--status-info &,
	&.asteria--status-info {
		.asteria-component__typography {
			@apply text-alert-info-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-alert-info-icon-normal-background;
			}
		}
	}

	.asteria--status-success &,
	&.asteria--status-success {
		.asteria-component__typography {
			@apply text-alert-success-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-alert-success-icon-normal-background;
			}
		}
	}

	.asteria--status-warning &,
	&.asteria--status-warning {
		.asteria-component__typography {
			@apply text-alert-warning-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-alert-warning-icon-normal-background;
			}
		}
	}

	.asteria--status-error &,
	&.asteria--status-error {
		.asteria-component__typography {
			@apply text-alert-error-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-alert-error-icon-normal-background;
			}
		}
	}

	&.asteria--alert-active-normal,
	.asteria--alert-active-normal & {
		@apply border-form-label-focus-border bg-form-label-focus-background;

		.asteria-component__typography {
			@apply text-form-label-focus-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-form-label-focus-icon;
			}
		}
	}

	.asteria--alert-disabled-normal &,
	&.asteria--alert-disabled-normal {
		@apply border-form-label-disabled-border bg-form-label-disabled-background;

		.asteria-component__typography {
			@apply text-form-label-disabled-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-form-label-disabled-icon;
			}
		}
	}
}
