.asteria-component__forecaster-tooltip {
	&.asteria-component__forecaster-tooltip--type-account {
		min-width: 250px;

		> .asteria-component__wrapper {
			@apply gap-2;

			> .asteria-component__wrapper-header {
				@apply p-0;

				> .asteria-component__wrapper-header__content {
					@apply justify-center;
				}
			}

			> .asteria-component__wrapper-content {
				@apply p-0;
			}
		}

		&.asteria--direction-vertical {
			> .asteria-component__wrapper {
				> .asteria-component__wrapper-content {
					> .asteria-component__group {
						@apply grid items-center gap-0;

						grid-template-columns: auto 1fr auto;
						grid-template-rows: auto;

						.asteria-component__forecaster-tooltip__section {
							@apply contents;

							> .asteria-component__group {
								@apply contents;

								&:last-child {
									> * {
										@apply mb-2;
									}
								}
							}
						}

						> .asteria-component__group {
							@apply contents;
						}

						.asteria-component__forecaster-tooltip__section--type-spread {
							> .asteria-component__typography {
								grid-column: 2/4;
							}
						}

						.asteria-component__prefix,
						.asteria-component__forecaster-tooltip__section__empty {
							& + .asteria-component__typography {
								@apply mx-2;
							}
						}
					}
				}
			}
		}

		&.asteria--direction-horizontal {
			.asteria-component__forecaster-tooltip__section {
				@apply grid items-center;

				column-gap: theme('spacing.2');

				grid-template-columns: auto 1fr auto;

				> .asteria-component__group {
					@apply contents;
				}
			}
		}
	}
}
