@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-card-client-performance-highlight-normal-background: ;
	--color-card-client-performance-highlight-hover-background: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-client-performance {
			.asteria-component__table {
				&.asteria--type-client-details {
					@apply grid items-center;

					grid-template-columns: auto 1fr auto;

					.asteria-component__skeleton {
						@apply flex h-5 items-center justify-center;

						min-width: theme('spacing.24');
					}

					> .asteria-component__table-row {
						@apply contents;
					}

					&:not(.asteria--state-loading) {
						> .asteria-component__table-row {
							&.asteria-component__table-header {
								.asteria-component__table-cell {
									&.asteria--type-name {
										@apply px-4;
									}
								}
							}
						}
					}

					.asteria-component__table-cell {
						@apply h-auto px-2;

						&.asteria--type-counter {
							.asteria-component__skeleton {
								min-width: theme('spacing.10');
							}
						}

						&.asteria--type-name {
							.asteria-component__card-section {
								&.asteria--variant-extra {
									@media (max-width: theme('screens.tablet')) {
										@apply hidden;
									}
								}
							}
						}

						&.asteria--type-amount {
							@apply justify-end;

							&,
							.asteria-component__typography {
								@apply text-right;
							}
						}
					}
				}
			}

			.asteria-component__card-section {
				&.asteria--type-highlight {
					background-color: var(
						--color-card-client-performance-highlight-normal-background
					);

					&:hover {
						background-color: var(
							--color-card-client-performance-highlight-hover-background
						);
					}

					.asteria-component__button {
						&.asteria-component__button--variant-link {
							@apply w-full px-0;

							.asteria-component__typography {
								@apply overflow-hidden text-ellipsis;
							}
						}
					}

					// .asteria--section-icon {
					// 	@apply bg-alert-warning-normal-background;
					// }
				}

				&.asteria--type-switcher {
					.asteria-component__chip {
					}
				}

				&.asteria--type-chart {
					&.asteria--state-highlight {
						.asteria-component__card-chart-bar {
							&:not(.asteria--state-active) {
								@apply opacity-50;
							}
						}
					}

					&:hover {
						.asteria-component__card-chart-bar {
							&:not(:hover) {
								@apply opacity-50;
							}
						}
					}
				}
			}
		}

		&.asteria--variant-customer-performance,
		&.asteria--variant-supplier-performance {
			.asteria-component__client-statistics {
				@apply flex flex-col gap-4;
			}

			.asteria-component__client-box {
				@apply flex flex-col;

				> .asteria-component__button {
					@apply px-0;

					.asteria-component__text {
						@apply overflow-hidden text-ellipsis;
					}
				}
			}

			.asteria-component__client-performance-table {
				@apply grid items-center gap-1 overflow-hidden;

				grid-template-columns:
					theme('spacing.4')
					theme('spacing.4')
					1fr
					theme('spacing.10')
					theme('spacing.32');

				&.asteria--state-accuracy {
					grid-template-columns:
						theme('spacing.4')
						theme('spacing.4')
						1fr
						theme('spacing.10')
						theme('spacing.10')
						theme('spacing.32');
				}

				> .asteria-component__button {
					@apply overflow-hidden px-0;

					.asteria-component__text {
						@apply overflow-hidden text-ellipsis;
					}
				}
			}
		}
	}
}
