@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--forecaster-chip-success: rgba(98, 149, 52, 1);
	--forecaster-chip-skip: rgba(133, 78, 32, 1);
	--forecaster-chip-remove: rgba(184, 50, 41, 1);
}

.asteria-component__prefix {
	@apply rounded;
}

.asteria-component__chip {
}

.asteria-component__chip-group {
	--color-chip-normal-background: #f0f0f0;
	--color-chip-normal-border: #f0f0f0;
	--color-chip-normal-icon: var(--color-text);
	--color-chip-normal-text: var(--color-text);

	--color-chip-hover-background: rgba(243, 227, 224, 1);
	--color-chip-hover-border: rgba(243, 227, 224, 1);
	--color-chip-hover-icon: ;
	--color-chip-hover-text: ;

	--color-chip-focus-background: rgba(243, 227, 224, 1);
	--color-chip-focus-border: rgba(243, 227, 224, 1);
	--color-chip-focus-icon: ;
	--color-chip-focus-text: ;

	.asteria-component__chip {
		&:not(.asteria--state-active):not(:hover) {
			@apply bg-chip-normal-background;
		}
	}
}
