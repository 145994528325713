@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--gap-probability: 1px;
	--width-probability-medium: 10px;
}

.asteria-component__probability__wrapper {
	@apply flex items-center gap-2 border border-solid;
	@apply border-probability-wrapper-normal-border bg-probability-wrapper-normal-background;

	.asteria-component__typography {
		@apply m-0;
		@apply text-probability-wrapper-normal-text;
	}

	&:hover {
		@apply border-probability-wrapper-hover-border bg-probability-wrapper-hover-background;

		.asteria-component__typography {
			@apply text-probability-wrapper-hover-text;
		}
	}

	&.asteria--state-active {
		@apply border-probability-wrapper-focus-border bg-probability-wrapper-focus-background;

		.asteria-component__typography {
			@apply text-probability-wrapper-focus-text;
		}
	}
}

.asteria-component__probability {
	@apply flex h-4 flex-col-reverse;

	gap: var(--gap-probability);
	width: var(--width-probability-medium);

	&.asteria--size-large,
	&.asteria--size-lg {
		@apply h-5 w-3;
	}

	&.asteria--size-medium,
	&.asteria--size-md {
	}

	&.asteria--size-small,
	&.asteria--size-sm {
		@apply h-3 w-2;
	}

	&.asteria-component__probability--state-hidden {
		@apply invisible;
	}

	.asteria-component__probability__bar-line {
		@apply w-full shrink grow basis-0 rounded-sm bg-probability-normal-background;

		&.asteria-component__probability__bar-line--low {
			&.asteria-state-active {
				@apply bg-probability-bar-low-background;
			}
		}

		&.asteria-component__probability__bar-line--medium {
			&.asteria-state-active {
				@apply bg-probability-bar-medium-background;
			}
		}

		&.asteria-component__probability__bar-line--high {
			&.asteria-state-active {
				@apply bg-probability-bar-high-background;
			}
		}
	}
}

.asteria-component__select--no-probability-label {
	.asteria-component__dropdown {
		.asteria-component__probability__label {
			@apply hidden;
		}
	}
}
