@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__forecaster-axis {
	&.asteria--variant-x {
		&.asteria-component__financial-loan-axis {
			.asteria-component__forecaster-axis-prefix {
				left: calc(calc(theme('spacing.10') + theme('spacing.2')) * -1);
				// left: calc(calc(theme('spacing.10') + theme('spacing.4')) * -1);

				> div > .asteria-component__typography-group {
					@apply hidden;
				}
			}
		}
	}
}
