@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	// --color-selector-satisfaction-dot-normal-background: ;
	// --color-selector-satisfaction-dot-normal-border: ;
	// --color-selector-satisfaction-dot-normal-icon: ;
	// --color-selector-satisfaction-dot-normal-line: ;

	// --color-selector-satisfaction-dot-focus-background: ;
	// --color-selector-satisfaction-dot-focus-border: ;
	// --color-selector-satisfaction-dot-focus-icon: ;

	// --color-selector-satisfaction-label-normal-icon: ;
	// --color-selector-satisfaction-label-normal-text: ;

	// --color-selector-satisfaction-label-focus-icon: ;
	// --color-selector-satisfaction-label-focus-text: ;
}

.asteria-component__satisfaction-selector {
	.asteria-component__icon {
		@apply pointer-events-none;
	}

	&.asteria--state-open {
		@apply mx-auto grid;

		grid-template-columns: repeat(5, 1fr);
		max-width: 400px;

		.asteria-component__satisfaction-selector-column {
			@apply cursor-pointer pt-4;

			&:first-child {
				.asteria-component__satisfaction-selector-dot {
					&::before {
						@apply left-1/2;
					}
				}
			}

			&:last-child {
				.asteria-component__satisfaction-selector-dot {
					&::before {
						@apply right-1/2;
					}
				}
			}

			&:hover {
				.asteria-component__satisfaction-selector-dot-content {
					@apply h-4 w-4;

					background-color: var(
						--color-selector-satisfaction-dot-hover-background,
						rgb(255 255 255)
					);

					border-color: var(
						--color-selector-satisfaction-dot-hover-border,
						theme('colors.border.normal')
					);
				}

				.asteria-component__satisfaction-selector-icon {
					.asteria-component__icon-content {
						background-color: var(
							--color-selector-satisfaction-label-hover-icon,
							theme('colors.icon.hover')
						);
					}
				}

				.asteria-component__satisfaction-selector-label {
					color: var(
						--color-selector-satisfaction-label-hover-text,
						theme('colors.content.text.normal')
					);
				}
			}

			&.asteria--state-active {
				.asteria-component__satisfaction-selector-dot-content {
					@apply h-4 w-4;

					background-color: var(
						--color-selector-satisfaction-dot-focus-background,
						rgb(255 255 255)
					);

					border-color: var(
						--color-selector-satisfaction-dot-focus-border,
						theme('colors.border.normal')
					);
				}

				.asteria-component__satisfaction-selector-icon {
					.asteria-component__icon-content {
						background-color: var(
							--color-selector-satisfaction-label-focus-icon,
							theme('colors.icon.focus')
						);
					}
				}

				.asteria-component__satisfaction-selector-label {
					color: var(
						--color-selector-satisfaction-label-focus-text,
						theme('colors.content.text.normal')
					);
				}
			}
		}

		.asteria-component__satisfaction-selector-dot {
			@apply relative isolate mb-4 flex h-6 flex-col items-center justify-center;

			&::before {
				@apply absolute top-1/2 left-0 right-0 -z-10 -translate-y-1/2 border-0 border-b-2 border-solid;

				border-color: var(
					--color-selector-satisfaction-dot-normal-line,
					theme('colors.border.normal')
				);

				content: '';
			}
		}

		.asteria-component__satisfaction-selector-dot-content {
			@apply box-content h-2 w-2 rounded-full border-2 border-solid;

			background-color: var(
				--color-selector-satisfaction-dot-normal-background,
				rgb(0 0 0)
			);

			border-color: var(
				--color-selector-satisfaction-dot-normal-border,
				theme('colors.border.normal')
			);
		}

		.asteria-component__satisfaction-selector-icon-wrapper {
			@apply flex flex-col items-center justify-start gap-2 text-center;
		}

		.asteria-component__satisfaction-selector-icon {
			@apply h-6 w-6;

			.asteria-component__icon-content {
				background-color: var(
					--color-selector-satisfaction-label-normal-icon,
					theme('colors.icon.normal')
				);
			}
		}

		.asteria-component__satisfaction-selector-label {
			color: var(
				--color-selector-satisfaction-label-normal-text,
				theme('colors.content.text.normal')
			);
		}
	}

	&.asteria--state-closed {
		@apply mx-auto;

		.asteria-component__icon {
			&.asteria-component__satisfaction-selector-icon {
				&.asteria--variant-value {
					&,
					.asteria-component__icon-content {
						@apply h-6 w-6;
					}
				}

				&.asteria--variant-edit {
					&,
					.asteria-component__icon-content {
						@apply h-3 w-3;
					}
				}
			}
		}
	}
}
