@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-graph-y-axis {
	@apply flex h-full flex-col items-end;

	&.asteria-graph-y-axis--title {
		@apply relative;

		.asteria-graph-y-axis-label--title {
			@apply absolute -top-6;
		}
	}
}
