@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-form-wrapper-normal-border: transparent;
}

.asteria-component__wrapper {
	&.asteria-component__wrapper--variant-vertical {
		> .asteria-component__wrapper-header {
			~ .asteria-component__wrapper-content {
				@apply border-0;
			}
		}

		> .asteria-component__wrapper-footer {
			> .asteria-component__wrapper-footer__section {
				@apply flex-auto;
			}
		}
	}
}
