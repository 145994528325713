@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__forecaster-header {
	@apply bg-background-normal;

	z-index: 4;

	&.asteria--type-linear {
		.asteria-component__forecaster-header__progress-wrapper {
			@apply border-0 border-b border-solid border-border-normal py-2;

			grid-template-columns: 1fr 1fr;

			> .asteria-component__group {
				&.asteria--type-progress {
					.asteria-component__typography {
						@apply whitespace-nowrap;
					}

					.asteria-component__progress {
						@apply h-2 rounded-full;

						width: 184px;
					}
				}

				&.asteria--type-actions {
				}
			}
		}

		.asteria-component__forecaster-header__sections {
		}
	}

	.asteria-component__forecaster-header__content__navigation__sub {
		@apply grid items-center gap-4 pt-4;
		grid-template-columns: 1fr minmax(min-content, max-content);

		&.asteria-component__forecaster--left-categories {
			grid-template-columns:
				minmax(min-content, max-content)
				1fr
				minmax(min-content, max-content)
				minmax(min-content, max-content);
		}

		&.asteria-component__forecaster--type-overview {
			grid-template-columns: 1fr;
		}
	}

	.asteria-component__text-amount {
		@apply text-right;
	}

	&.asteria--feature-section-navigation {
		.asteria-component__page-header {
			&:not(.asteria-component__page-header--no-prefix) {
				grid-template-areas:
					'page-header-prefix page-header-title page-header-postfix'
					'page-header-prefix page-header-navigation page-header-navigation';

				.asteria-component__page-header__content {
					@apply contents;
				}
			}
		}
	}
}
