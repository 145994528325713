@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;
@use './styles/colors.scss' as *;

#{$global} {
	--input-autocomplete-rounding: theme('borderRadius.lg');
}

.asteria-component__input {
	--input-autocomplete-size: calc(theme('spacing.10') - 2px);

	&.asteria--state-has-visible {
		@apply relative;

		.asteria-component__input__inner {
			@apply relative;

			&::after {
				@apply pointer-events-none box-border block truncate p-2 font-body text-base text-form-input-normal-text opacity-100;
				@apply absolute inset-0 h-full;

				content: attr(data-visible);
			}
		}

		&.asteria--state-disabled {
			&:hover {
				.asteria-component__input__inner {
					&::after {
						@apply text-form-input-disabled-text;
					}
				}
			}

			.asteria-component__input__inner {
				&::after {
					@apply text-form-input-disabled-text;
				}
			}
		}

		&.asteria-component__input--has-icon {
			&.asteria-component__input--icon-position-first {
				.asteria-component__input__inner::after {
					@apply left-8;
				}
			}

			&.asteria-component__input--icon-position-last {
				.asteria-component__input__inner::after {
					@apply right-8;
				}
			}
		}
	}

	&:not(.asteria-component__input--type-textarea) {
		.asteria-component__input__inner {
			@apply max-h-10;
		}
	}

	&.asteria-component__input--type-hidden {
		@apply hidden;
	}

	&.asteria-component__input--type-textarea {
		.asteria-component__input__inner {
			@apply items-baseline;

			> .asteria-component__button--variant-input {
				@apply shrink-0;
			}
		}
	}

	.asteria-component__label__wrapper {
		@apply flex flex-col gap-2;

		&.asteria-component__label__wrapper--direction-horizontal {
			@apply flex-row items-baseline gap-8;
		}
	}

	.asteria-component__input__help {
		@apply justify-self-end;
	}

	&:not(.asteria-component__input--type-range) {
		.asteria-component__input__inner {
			@apply rounded-lg border border-solid;

			input,
			textarea {
				@apply box-border flex-1 overflow-hidden text-ellipsis border-0 bg-transparent p-2 font-body text-base outline-none outline-0;
				@apply placeholder:font-body placeholder:text-base placeholder:opacity-100;

				min-height: theme('spacing.10');
			}

			input {
				&:autofill,
				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus {
					border-radius: var(--input-autocomplete-rounding);
					height: var(--input-autocomplete-size);
					max-height: var(--input-autocomplete-size);
					min-height: var(--input-autocomplete-size);
				}
			}
		}
	}

	.asteria-component__input__inner {
		@apply box-border flex flex-1 items-center overflow-hidden border-0;

		textarea {
			@include scroll();
		}

		.asteria-component__input__wrapper {
			@apply flex flex-1 items-center gap-1;

			.asteria-component__input__wrapper__prefix--prefix {
				@apply flex items-center pl-2;
			}

			.asteria-component__input__wrapper__prefix--postfix {
				@apply flex items-center pr-2;
			}
		}

		.asteria-component__typography {
			@apply m-0 h-auto border-0 p-0;
		}

		.asteria-component__select {
			.asteria-component__typography {
				@apply m-0 h-auto border-0 p-0;
			}

			&.asteria-component__select--flat {
				.asteria-component__dropdown__toggle {
					@apply m-0 h-auto border-0 p-0 shadow-none;

					min-height: unset;
					max-height: unset;
				}

				&:hover {
					.asteria-component__select__inner {
						.asteria-component__select--dropdown {
							.asteria-component__button {
								@apply m-0 border-0 shadow-none;
							}
						}
					}
				}
			}
		}
	}

	/* --- Sizes --- */

	&.asteria--size-small,
	&.asteria--size-sm {
		--input-autocomplete-size: calc(theme('spacing.8') - 2px);

		&:not(.asteria-component__input--type-textarea) {
			.asteria-component__input__inner {
				@apply max-h-8;
			}
		}

		&.asteria-component__input--type-textarea {
			.asteria-component__input__inner {
			}
		}

		.asteria-component__typography {
			@apply text-sm;
		}

		.asteria-component__input__inner {
			input,
			textarea {
				@apply text-xs placeholder:text-xs autofill:text-sm;

				min-height: theme('spacing.8');
			}

			// .asteria-component__button.asteria-component__button--icon-only {
			// 	@apply m-0 h-3 w-3 p-0;
			// }
		}
	}

	&.asteria--size-large,
	&.asteria--size-lg {
		--input-autocomplete-size: calc(theme('spacing.12') - 2px);

		&:not(.asteria-component__input--type-textarea) {
			.asteria-component__input__inner {
				@apply max-h-12;
			}
		}

		> .asteria-component__typography {
			@apply text-lg;
		}

		.asteria-component__input__inner {
			.asteria-component__input__wrapper__prefix--prefix {
				@apply pl-3;
			}

			.asteria-component__input__wrapper__prefix--postfix {
				@apply pr-3;
			}

			input,
			textarea {
				@apply p-3 text-base placeholder:text-base autofill:text-base;

				min-height: theme('spacing.12');
			}

			// .asteria-component__button.asteria-component__button--icon-only {
			// 	@apply m-0 h-6 w-6 p-0;
			// }
		}
	}

	&.asteria-component__input--has-icon {
		&.asteria-component__input--icon-position-first {
			input,
			textarea,
			.asteria-component__input__wrapper__prefix--prefix {
				@apply pl-0;
			}
		}

		&.asteria-component__input--icon-position-last {
			input,
			textarea,
			.asteria-component__input__wrapper__prefix--postfix {
				@apply pr-0;
			}
		}
	}

	&.asteria-component__input--has-prefix {
		input,
		textarea {
			@apply pl-0;
		}
	}

	&.asteria-component__input--has-postfix {
		input,
		textarea {
			@apply pr-0;
		}
	}

	&.asteria--state-icon-only {
		.asteria-component__input__inner {
			.asteria-component__slider,
			.asteria-component__input__wrapper {
				@apply hidden;
			}
		}
	}
}

/* --- Range --- */
.asteria-component__input--type-range {
	--value: 50%;

	.asteria-component__input__wrapper {
		input {
			min-height: theme('spacing.2');
			@apply m-0 my-4 flex h-2 w-full cursor-pointer appearance-none items-center rounded-full p-0 outline-none;
		}

		input::-moz-range-thumb {
			@apply box-border h-4 w-4 appearance-none rounded-full border-0 p-0 shadow-md;
		}

		input::-webkit-slider-thumb {
			@apply box-border h-4 w-4 appearance-none rounded-full border-0 p-0 shadow-md;
		}
	}
}

/* --- Sizes --- */
.asteria-component__input {
	&.asteria--size-small,
	&.asteria--size-sm {
		&.asteria-component__input--type-range {
			.asteria-component__input__wrapper {
				input {
					min-height: theme('spacing.1');
					@apply h-1;
				}

				input::-moz-range-thumb {
					@apply h-4 w-4;
				}

				input::-webkit-slider-thumb {
					@apply h-4 w-4;
				}
			}
		}
	}

	&.asteria--size-large,
	&.asteria--size-lg {
		&.asteria-component__input--type-range {
			.asteria-component__input__wrapper {
				input {
					min-height: theme('spacing.4');
					@apply h-4 p-0;
				}

				input::-webkit-slider-thumb {
					@apply h-6 w-6;
				}

				input::-moz-range-thumb {
					@apply h-6 w-6;
				}
			}
		}
	}
}
