@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__wrapper-content {
	@apply flex flex-col bg-wrapper-content-normal-background py-4 px-6;

	&.asteria-component__wrapper-content--scroll {
		@include scroll();
	}

	/* --- Sizes --- */

	&.asteria--size-small,
	&.asteria--size-sm {
		.asteria-component__text {
			@apply text-sm;
		}
	}

	&.asteria--size-medium,
	&.asteria--size-md {
	}

	&.asteria--size-large,
	&.asteria--size-lg {
		.asteria-component__text {
			@apply text-lg;
		}
	}
}
