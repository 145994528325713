@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__financial-loan-selector {
	&.asteria--variant-time {
		.asteria-component__dropdown__item {
			&:not(.asteria--state-active) {
				.asteria-component__dropdown__item__postfix {
					@apply invisible;
				}
			}
		}
	}
}
