@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-carousel-dot-normal-background: transparent;
	--color-carousel-dot-normal-border: #636373;

	--color-carousel-dot-focus-background: #636373;
	--color-carousel-dot-focus-border: #636373;

	--color-carousel-dot-hover-background: #636373;
	--color-carousel-dot-hover-border: #636373;
}
