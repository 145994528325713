.asteria-component__switch {
	.asteria-component__base-switch__inner {
		--thumb-size: 20px;
		--thumb: #53a1ac;
		--thumb-highlight: hsl(0 0% 0% / 25%);

		--track-size: 54px;
		--track-padding: 2px;
		--track-inactive: #ffffff;
		--track-active: #53a1ac;
		--track-border-color: #53a1ac;
		--track-border-width: 2px;
		--track-border-style: solid;

		--thumb-color: var(--thumb);
		--thumb-color-active: #fff;
		--thumb-color-highlight: var(--thumb-highlight);
		--track-color-inactive: var(--track-inactive);
		--track-color-active: var(--track-active);

		--track-label-font-size: var(--text-small__font-size);

		--isLTR: 1;

		display: flex;
		align-items: center;
		gap: 2ch;
		justify-content: space-between;

		cursor: pointer;
		user-select: none;
		-webkit-tap-highlight-color: transparent;

		@media (prefers-color-scheme: dark) {
			& {
				--thumb: #53a1ac;
				--thumb-highlight: hsl(0 0% 100% / 25%);
				--track-inactive: #ffffff;
				--track-active: #53a1ac;
			}
		}

		&:dir(rtl) {
			--isLTR: -1;
		}

		&.-vertical {
			min-block-size: calc(
				var(--track-size) + calc(var(--track-padding) * 2)
			);

			& > input {
				transform: rotate(calc(90deg * var(--isLTR) * -1));
				touch-action: pan-x;
			}
		}

		& > input[type='checkbox'] {
			--thumb-position: 0%;
			--thumb-transition-duration: 0.25s;

			position: relative;
			opacity: 1;

			padding: var(--track-padding);
			background-color: var(--track-color-inactive);
			inline-size: var(--track-size);

			border-radius: var(--track-size);
			border-color: var(--track-border-color);
			border-width: var(--track-border-width);
			border-style: var(--track-border-style);

			appearance: none;
			pointer-events: none;
			touch-action: pan-y;
			outline-offset: 5px;
			box-sizing: content-box;

			flex-shrink: 0;
			display: grid;
			align-items: center;
			grid: [track] 1fr / [track] 1fr;

			position: relative;

			transition: background-color 0.25s ease;

			&::before {
				--highlight-size: 0;

				content: '';
				cursor: pointer;
				pointer-events: auto;
				grid-area: track;
				inline-size: var(--thumb-size);
				block-size: var(--thumb-size);
				background: var(--thumb-color);
				box-shadow: 0 0 0 var(--highlight-size)
					var(--thumb-color-highlight);
				border-radius: 50%;
				transform: translateX(var(--thumb-position));

				transition: transform var(--thumb-transition-duration) ease,
					box-shadow 0.25s ease;

				@media (prefers-reduced-motion: reduce) {
					transition: none;
				}
			}

			&:not(:disabled):hover::before {
				--highlight-size: 0.5rem;
			}

			&:checked {
				background: var(--track-color-active);
				--thumb-position: calc(
					(
							var(--track-size) - (var(--track-padding) * 2) -
								(var(--track-border-width) * 2) - 100%
						) * var(--isLTR)
				);
				&::before {
					background: var(--thumb-color-active);
				}
			}

			&:indeterminate {
				--thumb-position: calc(
					calc(
							calc(var(--track-size) / 2) -
								calc(var(--thumb-size) / 2)
						) * var(--isLTR)
				);
			}

			&:disabled {
				cursor: not-allowed;
				--thumb-color: transparent;
			}
		}

		& > .asteria-component__base-switch__value {
			position: absolute;
			width: calc(var(--track-size) - var(--thumb-size));
			height: var(--thumb-size);
			line-height: var(--thumb-size);
			text-align: center;
			font-size: var(--track-label-font-size);
			box-sizing: content-box;
			margin: 3px;
			transform: translateX(
				calc(
					var(--thumb-size) + var(--track-padding) +
						var(--track-border-width)
				)
			);
		}
		&
			> input[type='checkbox']:checked
			+ .asteria-component__base-switch__value {
			color: var(--thumb-color-active);
			transform: translateX(
				calc(var(--track-padding) + var(--track-border-width))
			);
		}
	}

	&.asteria--size-small {
		.asteria-component__base-switch__inner {
			--thumb-size: 16px;
			--track-size: 40px;
			--track-padding: 1px;
			--track-border-width: 1px;
			--track-label-font-size: var(--text-x-small__font-size);
		}
	}

	&.asteria--size-large {
		.asteria-component__base-switch__inner {
			--thumb-size: 32px;
			--track-size: 72px;
			--track-padding: 3px;
			--track-border-width: 3px;
			--track-label-font-size: var(--text-base__font-size);
		}
	}

	&--icon {
		.asteria-component__base-switch__inner {
			& > input[type='checkbox'] {
				&::after {
					content: '';
					grid-area: track;
					inline-size: var(--thumb-size);
					block-size: var(--thumb-size);
					transform: translateX(var(--thumb-position));
					mask-image: var(--thumb-icon);
					mask-position: center;
					mask-size: 50%;
					mask-repeat: no-repeat;
					background-color: var(--thumb-color-active);

					transition: transform var(--thumb-transition-duration) ease,
						box-shadow 0.25s ease;

					@media (prefers-reduced-motion: reduce) {
						transition: none;
					}
				}
				&:checked {
					&::after {
						background: var(--thumb-color);
						mask-image: var(--thumb-icon-active);
					}
				}
			}
		}
	}
}
