.asteria-graph-x-axis-label {
	position: relative;
	flex-basis: 0px;
	flex-shrink: 1;
	flex-grow: 1;
	text-align: center;
	.asteria-component__input {
		.asteria-component__input__inner {
			.asteria-component__input__wrapper {
				input {
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}
}

.asteria-graph-x-badge {
	position: absolute;
	top: 0px;
	right: 0px;
}
