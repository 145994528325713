@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__onboarding-step {
	&.asteria--variant-agreement {
		.w-3 {
			width: theme('spacing.3');
		}

		.h-3 {
			height: theme('spacing.3');
		}

		.asteria-component__accordion {
			.asteria-component__button {
				&.asteria-component__button--variant-link {
					@apply p-0;
				}
			}
		}
	}
}
