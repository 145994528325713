@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__list__filter {
	&.asteria-component__transactions__filters {
		.asteria-component__list__item {
			> .asteria-component__transactions__cell {
				@apply sticky top-0 z-10;

				> .asteria-component__button {
					@apply w-full overflow-hidden p-0;

					.asteria-component__text {
						@apply overflow-hidden text-ellipsis text-sm;

						.asteria--state-compact & {
							@apply text-xs;
						}
					}
				}

				&.asteria-component__transactions__cell--total {
					> .asteria-component__button {
						> .asteria-component__text {
							@apply w-full text-right;
						}
					}
				}

				&.asteria-component__transactions__cell--options {
					.asteria-component__button {
						.asteria-component__icon {
							@apply opacity-100;
						}
					}
				}

				&.asteria-component__transactions__cell--tags,
				&.asteria-component__transactions__cell--status,
				&.asteria-component__transactions__cell--currency {
					> .asteria-component__button {
						@apply pl-4;
					}
				}

				.asteria--state-compact & {
					&.asteria-component__transactions__cell--tags,
					&.asteria-component__transactions__cell--status,
					&.asteria-component__transactions__cell--currency {
						> .asteria-component__button {
							@apply pl-2;
						}
					}
				}
			}
		}
	}
}
