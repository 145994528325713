@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__forecaster-graph-bar {
	@keyframes graph-loading {
		0% {
			opacity: 0.1;
			height: 50%;
		}
		50% {
			opacity: 0.3;
			height: 25%;
		}
		100% {
			opacity: 0.1;
			height: 50%;
		}
	}

	@apply relative isolate mx-auto;

	background-color: var(--color, unset);
	background-image: var(--image, unset);

	width: min(80%, max(50%, 130px));

	&.asteria--state-loading {
		@apply h-full bg-black;

		animation: graph-loading 1s infinite ease-in-out;
	}

	.asteria-component__forecaster-graph-bar-handle {
		@apply absolute left-1/2 aspect-square h-5 w-5 -translate-y-1/2 -translate-x-1/2  cursor-ns-resize rounded-full border border-solid border-transparent bg-white p-1 shadow-md;

		z-index: 3;
	}

	&.asteria--type-withdraw {
		.asteria-component__forecaster-graph-bar-handle {
			@apply -bottom-6;
		}

		&.asteria--state-empty {
			.asteria-component__forecaster-graph-bar-handle {
				@apply -bottom-10;
			}
		}
	}

	&.asteria--type-deposit {
		.asteria-component__forecaster-graph-bar-handle {
			@apply top-0;
		}

		&.asteria--state-empty {
			.asteria-component__forecaster-graph-bar-handle {
				@apply -top-5;
			}
		}
	}
}
