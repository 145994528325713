@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__modal {
	--wrapper-rounding: theme('borderRadius.2xl');

	> .asteria-component__wrapper,
	> .asteria-component__form > .asteria-component__wrapper {
		> .asteria-component__wrapper-header {
			@apply items-center;

			> .asteria-component__wrapper-header__content {
				.asteria-component__typography {
					@apply font-normal;
				}
			}
		}

		&.asteria-component__wrapper--variant-vertical {
			> .asteria-component__wrapper-header {
				~ .asteria-component__wrapper-content {
					// @apply border-y;
				}
			}
		}
	}
	&.asteria-component__whats-new-modal {
		.asteria-component__image--swedbank {
			@apply hidden;
		}

		.asteria-component__wrapper-content {
			div:first-of-type {
				.asteria-component__title--size-md:last-of-type {
					font-size: 20px !important;
				}
			}
		}

		.asteria-component__button--variant-whats-new {
			@apply p-0;
		}
	}
}
