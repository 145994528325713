@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__auth-footer {
	@apply hidden;

	.asteria-component__auth-footer-content {
		min-height: theme('spacing.96');
	}

	.asteria-component__navigation-logo {
		@apply aspect-square h-10 w-10 cursor-pointer bg-contain bg-center bg-no-repeat;
		min-height: theme('spacing.10');
	}

	.asteria-component__auth-footer-links {
		.asteria-component__button {
			&.asteria-component__button--variant-href {
				@apply h-auto px-0 py-1;
			}
		}
	}

	.asteria-component__auth-footer-links-group {
		@apply flex flex-col items-start gap-2 md:w-full;
		min-width: theme('spacing.64');
	}

	.asteria-component__contenter {
		@apply mt-2;
	}
}

.asteria-component__auth-header {
	@apply hidden;

	.asteria-component__navigation-logo {
		@apply aspect-square h-10 w-10 cursor-pointer bg-contain bg-center bg-no-repeat;
		min-height: theme('spacing.10');
	}
}
