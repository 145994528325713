@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-content-view-statement {
	&.asteria-content-view {
		@apply overflow-hidden;
	}
}

.asteria-view__statement {
	.asteria-component__tabs__content__wrapper {
		.asteria-component__tabs__content {
			@apply px-0;
		}
	}

	.asteria-component__statement {
		.asteria-component__button {
			&.statement-download-button {
				@include asteria-btn-sm(false, true, true, md);
			}
		}

		.asteria-component__statement__table {
			.asteria-component__table-header {
				.asteria-component__text {
					@apply font-semibold;
				}
			}
		}
	}

	.asteria-component__statement__table {
		.asteria-component__table-cell {
			&.asteria--type-delta {
				.asteria-component__icon {
					&,
					.asteria-component__icon-content {
						@apply h-3 w-3;
					}
				}
			}
		}
	}
}
