@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__list {
	@apply flex flex-col;

	.asteria-component__radio .asteria-component__radio__inner,
	.asteria-component__checkbox .asteria-component__checkbox__inner {
		@apply items-center;
	}

	&.asteria--state-typography {
		.asteria-component__typography,
		.asteria-component__chip .asteria-component__typography,
		.asteria-component__chip.asteria--size-lg
			.asteria-component__typography {
			@apply text-sm;

			&.asteria-component__text--size-sm {
				@apply text-xs;
			}

			&.asteria-component__text--size-xs {
				@apply text-xxs;
			}
		}
	}

	.asteria-component__list__item,
	.asteria-component__list__header,
	.asteria-component__list__filter {
		@apply box-border h-10;
	}

	&.asteria--size-large,
	&.asteria--size-lg {
		&.asteria--state-typography {
			.asteria-component__typography,
			.asteria-component__chip .asteria-component__typography,
			.asteria-component__chip.asteria--size-lg
				.asteria-component__typography {
				@apply text-base;

				&.asteria-component__text--size-sm {
					@apply text-sm;
				}

				&.asteria-component__text--size-xs {
					@apply text-xs;
				}
			}
		}

		.asteria-component__list__item,
		.asteria-component__list__header,
		.asteria-component__list__filter {
			@apply box-border h-12;
		}
	}

	&.asteria--size-small,
	&.asteria--size-sm {
		&.asteria--state-typography {
			.asteria-component__typography,
			.asteria-component__chip .asteria-component__typography,
			.asteria-component__chip.asteria--size-lg
				.asteria-component__typography {
				@apply text-xs;

				&.asteria-component__text--size-sm,
				&.asteria-component__text--size-xs {
					@apply text-xxs;
				}
			}
		}

		.asteria-component__list__item,
		.asteria-component__list__header,
		.asteria-component__list__filter {
			@apply box-border h-8;
		}
	}
}

.asteria-component__list__header {
	@apply box-border flex items-center gap-2 border-0 border-b border-solid;
	@apply border-list-group-header-normal-border bg-list-group-header-normal-background;

	&.asteria--size-large,
	&.asteria--size-lg {
		@apply h-12;

		.asteria-component__list__item {
			@apply h-12;
		}
	}

	&.asteria--size-medium,
	&.asteria--size-md {
		@apply h-10;

		.asteria-component__list__item {
			@apply h-10;
		}
	}

	&.asteria--size-small,
	&.asteria--size-sm {
		@apply h-8;

		.asteria-component__list__item {
			@apply h-8;
		}
	}
}

.asteria-component__list__filter {
	@apply sticky top-0 flex h-10 items-center gap-2 border-0 border-b border-solid;
	@apply border-list-filter-normal-border bg-list-filter-normal-background;

	&.asteria--size-large,
	&.asteria--size-lg {
		@apply h-12;

		.asteria-component__list__item {
			@apply h-12;
		}
	}

	&.asteria--size-medium,
	&.asteria--size-md {
		@apply h-10;

		.asteria-component__list__item {
			@apply h-10;
		}
	}

	&.asteria--size-small,
	&.asteria--size-sm {
		@apply h-8;

		.asteria-component__list__item {
			@apply h-8;
		}
	}

	.asteria-component__button {
		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-list-filter-normal-icon;
			}
		}
	}

	.asteria-component__text {
		@apply text-list-filter-normal-text;
	}

	.asteria-component__list__cell {
		&:not(.asteria--state-active) {
			.asteria-component__button {
				.asteria-component__icon {
					@apply opacity-0;
				}
			}
		}

		&.asteria--state-active {
			.asteria-component__text {
				@apply font-medium text-list-filter-focus-text;
			}

			.asteria-component__button {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-list-filter-focus-icon;
					}
				}
			}
		}

		&:hover {
			.asteria-component__text {
				@apply text-list-filter-hover-text;
			}

			.asteria-component__button {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-list-filter-hover-icon;
					}
				}
			}

			.asteria-component__button {
				.asteria-component__icon {
					@apply opacity-100;
				}
			}
		}
	}
}

.asteria-component__list__item {
	@apply box-border flex items-center px-2;

	&.asteria--size-large,
	&.asteria--size-lg {
		@apply h-12;
	}

	&.asteria--size-medium,
	&.asteria--size-md {
		@apply h-10;
	}

	&.asteria--size-small,
	&.asteria--size-sm {
		@apply h-8;
	}
}

.asteria-component__list__group {
	@apply border-0 border-solid;
	@apply border-list-group-header-normal-border bg-list-group-header-normal-background;

	&.asteria-component__list__group--border-top {
		@apply border-t;
	}

	&.asteria-component__list__group--border-bottom {
		@apply border-b;
	}

	.asteria-component__list__header {
		@apply flex items-center gap-2 border-0 border-b border-solid;
		@apply border-list-group-header-normal-border bg-list-group-header-normal-background;

		.asteria-component__typography {
			@apply text-list-group-header-normal-title;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-list-group-header-normal-icon;
			}
		}

		.asteria-component__list__item {
			@apply bg-list-group-header-normal-background;

			.asteria-component__typography {
				@apply text-list-group-header-normal-title;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-list-group-header-normal-icon;
				}
			}
		}
	}

	.asteria-component__list__item {
		@apply gap-2 border-0 border-b border-solid;
		@apply border-list-item-normal-border bg-list-item-normal-background text-list-item-normal-text;

		&.asteria-component__list__item--no-border {
			@apply border-0;
		}

		.asteria-component__text {
			@apply text-list-item-normal-text;
		}

		.asteria-component__title {
			@apply text-list-item-normal-title;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-list-item-normal-icon;
			}
		}

		@include apply-list-button;
	}
}
