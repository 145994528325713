@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__financial-simulation-list {
		@apply gap-2 bg-white p-4;

		.asteria-component__wrapper-header,
		.asteria-component__wrapper-content,
		.asteria-component__wrapper-footer {
			@apply bg-transparent p-0;
		}

		.asteria-component__list {
			@apply grid;

			grid-template-columns: 1fr repeat(calc(var(--size, 3) - 1), auto);

			.asteria-component__list__header,
			.asteria-component__list__item {
				@apply contents;
			}

			.asteria-component__list__header {
				.asteria-component__list__cell {
					.asteria-component__typography {
						@apply font-button font-bold;
					}
				}
			}

			.asteria-component__list__cell {
				@apply flex h-10 flex-col justify-center border-0 border-b border-solid border-border-normal p-2;

				&.asteria--align-right {
					@apply items-end;
				}
			}
		}
	}
}
