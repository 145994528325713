@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-wrapper {
	@apply h-full;

	&.asteria--feature-transaction-scroll-auto.asteria-wrapper--size-large,
	&:not(.asteria--feature-transaction-scroll-auto):not(.asteria--feature-transaction-scroll-inner) {
		.asteria-content-view {
			&.asteria-content-view-cashflow {
				.asteria-component__cashflow {
					@apply overflow-visible;
				}
			}
		}
	}

	@media (max-height: 880px) {
		&.asteria--feature-transaction-scroll-auto {
			.asteria-content-view {
				&.asteria-content-view-cashflow {
					.asteria-component__cashflow {
						@apply overflow-visible;
					}
				}
			}
		}
	}
}
