@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-feedback-normal-background: #fafafa;
	--color-feedback-normal-border: transparent;
	--color-feedback-icon-normal-border: theme('colors.secondary.normal');
	--color-feedback-icon-focus-border: theme('colors.primary.normal');
	--color-feedback-modal-content: #eee;
	--color-feedback-modal-feedback: #eee; 

}

.asteria-component__feedback {
	--wrapper-rounding: theme('borderRadius.md');

	&.asteria-component__wrapper {
		> .asteria-component__wrapper-content {
			> .asteria-component__typography {
				@apply font-medium text-black;
			}

			> .asteria-component__wrapper__button-close {
				@include asteria-btn-xs;
				@include asteria-btn-rounded;

				@apply top-2 right-2 bg-transparent;
			}
		}
	}
}
