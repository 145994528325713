@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__forecaster__view {
	@apply relative grid min-h-full gap-4;

	.asteria-component__forecaster-v2 & {
		@apply flex min-h-0 flex-col;

		.asteria-component__forecaster-header.asteria--type-linear {
			@apply hidden;
		}
	}

	grid-template-rows: minmax(min-content, max-content) 1fr minmax(
			min-content,
			max-content
		);

	.forecaster__layout__content {
		--size: 5;

		&.asteria--feature-full-year {
			@media (min-width: 1537px) {
				--size: 9;
			}
		}

		> .asteria-component__group {
			@apply h-full gap-4;
		}

		&.asteria--direction-horizontal {
			&.asteria--state-open {
				@media (min-width: 1920px) {
					--size: 3;
				}

				--size: 2;
			}
		}

		&.asteria--state-open {
			.asteria-component__graph__tail {
				@apply hidden;
			}

			> .asteria-component__group {
				&.asteria-component__group--direction-vertical {
					grid-template-rows: 500px 1fr;
				}

				&.asteria-component__group--direction-horizontal {
					grid-template-columns: 2fr 3fr;
				}
			}
		}

		&:not(.asteria--direction-vertical) {
			.asteria-component__forecaster-layout__section {
				&.asteria-component__forecaster-layout__section--type-list {
					@apply relative overflow-hidden;

					.asteria-component__forecaster-transactions {
						@apply absolute;
					}
				}
			}
		}

		&.forecaster--state-transactions-full {
			> .asteria-component__group {
				&.asteria-component__group--direction-horizontal,
				&.asteria-component__group--direction-vertical {
					grid-template-rows: 1fr;
					grid-template-columns: 1fr;
				}
			}
		}
	}

	> .asteria-component__feedback {
		@apply text-center;
	}

	.asteria-component__page-header__title {
		@apply h-full overflow-hidden;

		grid-area: page-header-title;

		.asteria-component__typography {
			@apply h-full overflow-hidden text-ellipsis whitespace-nowrap;
		}
	}

	.asteria-component__page-header {
		.asteria-component__page-header__content {
			place-content: center flex-start;

			.asteria-component__page-header__title {
				@apply h-fit;

				.asteria-component__typography {
					@apply h-fit;
				}
			}
		}
	}

	.asteria-graph-navigation {
		@apply flex;
	}
}

.asteria-component__modal {
	&.asteria-component__forecaster-modal__wrapper {
		@apply h-full p-4;

		--asteria-modal-size-lg: 100%;

		--forecaster-transactions-columns-6: minmax(120px, 1fr)
			minmax(120px, 1fr) 120px minmax(min-content, max-content)
			minmax(130px, 1fr) minmax(min-content, max-content);

		--forecaster-transactions-columns-5: minmax(120px, 1fr) 120px
			minmax(min-content, max-content) minmax(130px, 1fr)
			minmax(min-content, max-content);

		.asteria-component__forecaster__footer {
			@apply rounded-none shadow-none;

			> .asteria-component__actionbar {
				@apply p-8;
			}
		}

		> .asteria-component__wrapper {
			@apply grid h-full;

			grid-template-columns: 1fr;
			grid-template-rows: auto 1fr;
			grid-template-areas: 'wrapper-header' 'wrapper-content';

			> .asteria-component__wrapper-content {
				@apply flex-1 p-0;

				&.asteria-component__wrapper-content--scroll {
					max-height: unset;
				}

				> .asteria-component__forecaster__view {
					@apply flex-1;

					> .asteria-component__forecaster-header,
					> .asteria-component__forecaster-header.asteria--feature-section-navigation {
						@apply relative overflow-hidden bg-transparent p-4;

						&.asteria--type-linear {
							@apply p-0 pb-4;

							.asteria-component__forecaster-header__progress-wrapper {
								@apply px-4 pr-2;
							}

							.asteria-component__forecaster-header__sections {
								@apply px-4;
							}
						}

						.asteria-component__page-header:not(.asteria-component__page-header--no-prefix) {
							@apply items-center;

							grid-template-areas: 'page-header-prefix page-header-navigation';
							grid-template-columns: auto 1fr;
							grid-template-rows: auto;

							.asteria-component__forecaster-navigation {
								@apply items-center;
							}
						}
					}

					.forecaster__layout__content {
						@apply p-4;

						&.asteria--direction-horizontal {
							&.asteria--state-open {
								@media (min-width: 1352px) {
									--size: 3;
								}
							}
						}

						&.asteria--state-open {
							> .asteria-component__group {
								&.asteria-component__group--direction-vertical {
									grid-template-rows: 300px 1fr;
								}

								&.asteria-component__group--direction-horizontal {
									grid-template-columns: 1fr 1fr;
								}
							}
						}
					}
				}
			}
		}

		@media (max-height: 900px) {
			@apply h-full;

			> .asteria-component__wrapper {
				@apply h-full;

				> .asteria-component__wrapper-content,
				> .asteria-component__wrapper-content.asteria-component__wrapper-content--scroll {
					max-height: unset;
				}
			}
		}
	}
}

.forecaster__layout__content {
	min-height: 400px;

	.asteria-component__forecaster-v2 & {
		min-height: auto;
	}
}
