@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--bar-spacing: 0px;
}

.asteria-component__forecaster-graph {
	&.asteria--variant-bars {
		.asteria-component__forecaster-graph-column {
			@apply flex flex-col;

			&.asteria--variant-combined {
				--bar-spacing: theme('spacing.1');
			}

			&:not(.asteria--variant-combined) {
				--bar-spacing: 2px;
			}

			.asteria-component__forecaster-graph-bar-group {
				@apply relative flex h-full;

				&.asteria--type-deposit {
					@apply flex-col-reverse;

					padding-bottom: var(--bar-spacing);

					.asteria-component__forecaster-graph-button {
						@apply bottom-2;
					}
				}

				&.asteria--type-withdraw {
					@apply flex-col;

					padding-top: var(--bar-spacing);

					.asteria-component__forecaster-graph-button {
						@apply top-2;
					}
				}

				.asteria-component__forecaster-graph-bar {
					height: calc(
						calc(var(--value) / var(--max) * 100%) -
							var(--bar-spacing)
					);

					&.asteria-color__history {
						@apply pointer-events-none absolute left-1/2 -translate-x-1/2;

						z-index: -1;
					}

					&:last-child,
					&.asteria-color__history {
						&.asteria--type-deposit {
							@apply rounded-t;
						}

						&.asteria--type-withdraw {
							@apply rounded-b;
						}
					}
				}

				.asteria-component__forecaster-graph-button {
					@apply absolute left-1/2 -translate-x-1/2;
				}

				.asteria-component__forecaster-graph-button,
				.asteria-component__forecaster-graph-button:hover {
					@apply rounded-full border-0 bg-transparent shadow-none;

					.asteria-component__icon-content {
						background-color: var(--color);
					}
				}
			}
		}
	}
}
