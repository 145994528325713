@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;
@use './styles/colors.scss' as *;

.asteria-component__notifications-mobile,
.asteria-component__notifications {
	.asteria-component__dropdown__list {
		@apply box-border h-auto w-96 p-4;

		.asteria-component__dropdown__item {
			@apply h-auto min-h-fit p-0;
		}

		.asteria-component__notification__title {
			@apply px-4;
		}
	}

	.asteria-component__dropdown__toggle {
		@apply box-border rounded-none border border-solid;

		.asteria-component__typography {
			@apply box-border border border-solid text-sm;
		}

		.asteria-component__icon {
			@apply border border-solid;
		}
	}
}

.asteria-component__notifications-mobile {
	.asteria-component__dropdown__list {
		@apply w-full border-transparent p-0;
	}
}

.asteria-component__notifications__wrapper {
	@apply inline-flex;

	.asteria-component__badge {
		@apply top-0 left-6 h-5 w-5;
	}
}

.asteria-component__notification {
	@apply relative;

	&.asteria-component__wrapper {
		@apply flex flex-col gap-2 pr-4;

		.asteria-component__wrapper-header,
		.asteria-component__wrapper-content,
		.asteria-component__wrapper-footer {
			@apply m-0 h-auto w-auto p-0;
		}

		.asteria-component__wrapper-header {
			@apply box-border flex items-center gap-2 border border-solid px-4 pt-2 pr-8;

			.asteria-component__typography {
				@apply flex-1;
			}
		}

		.asteria-component__wrapper-content {
			@apply box-border border border-solid px-4 py-2;

			.asteria-component__typography {
				@apply overflow-hidden text-ellipsis;
			}
		}

		.asteria-component__wrapper-footer {
			@apply box-border flex gap-4 border border-solid px-4 pb-4;
		}
	}

	.asteria-component__button {
		@apply p-0;

		&.asteria-close {
			@apply absolute top-0 right-0 m-0 h-auto p-2;
		}

		&[type='notification-action'] {
			@apply p-0;
		}
	}

	.asteria-component__probability {
		@apply m-0;
	}
}
