// @use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

// #{$global} {
//     --chip__background: #fff;
//     --chip__color: #000;
//     --chip__margin: 0;
//     --chip__padding: #{spacing(2)} #{spacing(3)};
//     --chip__gap: #{spacing(1)};
//     --chip__font-family: var(--text__font-family);
// 	--chip__font-weight: var(--text__font-weight);
// 	--chip__font-size: var(--text__font-size--base);
// 	--chip__border-width: 1px;
// 	--chip__border-style: solid;
// 	--chip__border-color: var(--color__grey-10);
//     --chip__border-radius: 100px;
// 	--chip__text-decoration: none;
//     --chip__state-hover__background: var(--color__grey-20);
//     --chip__state-hover__border-color: var(--chip__state-hover__background);
//     --chip__state-hover__color: var(--chip__color);
//     --chip__state-active__background: var(--color__grey-20);
//     --chip__state-active__border-color: var(--color__grey-20);
//     --chip__state-active__color: var(--chip__color);

//     --chip__icon__background: transparent;
//     --chip__icon__color: #000;
//     --chip__icon__border-width: 0;
//     --chip__icon__border-style: solid;
//     --chip__icon__border-color: transparent;
//     --chip__icon__border-radius: 0;
//     --chip__icon__margin: 0 0 0 auto;
//     --chip__icon__padding: 2px;
// }

// .asteria-component__chip {
// 	@include vertical-center();
// 	// @include horizontal-center();
// 	background-color: var(--chip__background);
// 	display: inline-flex;
// 	align-items: center;
// 	border: var(--chip__border-width) var(--chip__border-style) var(--chip__border-color);
// 	margin: var(--chip__margin);
// 	padding: var(--chip__padding);
// 	border-radius: var(--chip__border-radius);
// 	white-space: nowrap;
// 	gap: var(--chip__gap);
// 	box-sizing: border-box;

//     &.asteria--state-active {
// 		background-color: var(--chip__state-active__background);
//         border-color: var(--chip__state-active__border-color);
//         .asteria-component__text {
//             color: var(--chip__state-active__color);
//             text-decoration: var(--chip__state-active__text-decoration);
//         }
// 	}

// 	&:hover {
// 		background-color: var(--chip__state-hover__background);
//         border-color: var(--chip__state-hover__border-color);
//         .asteria-component__text {
//             color: var(--chip__state-hover__color);
//             text-decoration: var(--chip__state-hover__text-decoration);
//         }
// 	}

// 	&:not(:disabled):not(.disabled) {
// 		cursor: pointer;
// 	}

//     .asteria-component__text {
//         font-family: var(--chip__font-family);
//         font-weight: var(--chip__font-weight);
//         font-size: var(--chip__font-size);
//         line-height: var(--chip__line-height);
//         color: var(--chip__color);
//         margin: var(--chip__text__margin, 0px);
//         padding: var(--chip__text__padding, 0px);
//         text-decoration: var(--chip__text-decoration);
//     }

//     .asteria-component__icon {
//         @include vertical-center();
//         box-sizing: border-box;
//         background: var(--chip__icon__background);
//         border: var(--chip__icon__border-width) var(--chip__icon__border-style) var(--chip__icon__border-color);
//         border-radius: var(--chip__icon__border-radius);
//         margin: var(--chip__icon__margin);
//         padding: var(--chip__icon__padding);
//         svg {
//             fill: var(--chip__icon__color);
//         }
//     }

//     .asteria-component__prefix {
//         order: -1;
//     }
// }

// .asteria-component__prefix {
//     width: 10px;
//     height: 10px;
//     border-radius: 50%;
//     display: inline-block;
// }
.hideBackBtnOnLast {
	align-self: flex-end;
}
.asteria-component__divider {
	--color-grey: #d9d9d9;
	border-top: 1px solid var(--color-grey);
	width: 120%;
	margin-top: 30px;
}

.asteria-component__integration-guide {
	.asteria-component__contenter__inner {
		@apply flex flex-col gap-4;
	}
}
