@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__modal {
	&.asteria-component__financial-modal {
		> .asteria-component__form {
			@apply contents;
		}

		.asteria-component__message-box__wrapper {
			> .asteria-component__button {
				@apply px-0;
			}
		}
	}
}
