@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__group {
	&.asteria-component__wrapper-header {
		@apply flex gap-4 bg-wrapper-header-normal-background p-6;

		.asteria-component__typography,
		.asteria-component__button {
			@apply m-0 p-0;
		}

		.asteria-component__wrapper-header__content {
			@apply flex flex-1 items-center gap-2 overflow-hidden text-ellipsis;

			.asteria-component__typography {
				@apply overflow-hidden text-ellipsis;
			}

			.asteria-component__wrapper-header__content__logo {
				@apply w-8;
			}
		}

		.asteria-component__wrapper-header__prefix,
		.asteria-component__wrapper-header__postfix {
			@apply flex items-center;
		}

		&.asteria-component__wrapper-header--absolute-postfix {
			@apply pr-14;
			.asteria-component__wrapper-header__postfix--position-absolute {
				@apply absolute top-4 right-4;
			}
		}

		&.asteria-component__wrapper-header--absolute-prefix {
			@apply pl-14;
			.asteria-component__wrapper-header__prefix--position-absolute {
				@apply absolute top-4 left-4;
			}
		}
	}
}
