@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--snackbar-width: 500px;
}

.asteria-component__snackbar__wrapper {
	@apply flex flex-col;

	.asteria-component__snackbar__wrapper__list {
		@apply fixed top-auto bottom-auto left-auto right-auto
			flex flex-col gap-4;

		z-index: var(--z-index__snackbar);

		&.asteria-position--top-start {
			@apply top-4 left-4;
		}

		&.asteria-position--top-center {
			@apply top-4 left-1/2;
			transform: translateX(-50%);
		}

		&.asteria-position--top-end {
			@apply top-4 right-4;
		}

		&.asteria-position--middle-start {
			@apply top-1/2 left-4;
			transform: translateY(-50%);
		}

		&.asteria-position--middle-center {
			@apply top-1/2 left-1/2;
			transform: translateY(-50%) translateX(-50%);
		}

		&.asteria-position--middle-end {
			@apply top-1/2 right-4;
			transform: translateY(-50%);
		}

		&.asteria-position--bottom-start {
			@apply bottom-4 left-4;
		}

		&.asteria-position--bottom-center {
			@apply bottom-4 left-1/2;
			transform: translateX(-50%);
		}

		&.asteria-position--bottom-end {
			@apply bottom-4 right-4;
		}
	}

	.asteria-component__snackbar {
		max-width: var(--snackbar-width);
		min-width: var(--snackbar-width);
	}
}

.asteria-snackbar-enter {
	@apply opacity-0;
}

.asteria-snackbar-enter-active {
	@apply opacity-100 transition-opacity;
}

.asteria-snackbar-appear {
	@apply opacity-0;
}

.asteria-snackbar-appear-active {
	@apply opacity-100 transition-opacity;
}

.asteria-snackbar-exit {
	@apply opacity-100;
}

.asteria-snackbar-exit-active {
	@apply opacity-0 transition-opacity;
}
