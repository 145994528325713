@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-view__welcome__placeholder {
	@apply box-border place-content-stretch gap-4 rounded-md border border-solid border-border-normal p-6;

	.asteria-component__button.asteria-component__button--variant-link {
		@apply pl-0;
	}

	&.asteria-view__welcome__placeholder--variant-empty {
	}

	&.asteria-view__welcome__placeholder--variant-loading {
	}

	&.asteria-view__welcome__placeholder--variant-done {
		@apply flex items-start gap-4;

		> .asteria-view__welcome__placeholder-icon {
			@apply box-border flex aspect-square h-8 w-8 items-center justify-center rounded-full;

			@apply bg-alert-success-icon-normal-background;

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-success-normal-background;
				}
			}
		}

		> .asteria-view__welcome__placeholder-content {
			@apply flex flex-1 flex-col gap-4;

			.asteria-view__welcome__placeholder-content__categories {
				@apply flex flex-wrap gap-4;
			}
		}
	}
}

.asteria-component__wizard__step {
	.asteria-view__welcome__placeholder {
		@apply border-0 p-0;
	}
}
