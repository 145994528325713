@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-incoming {
			.asteria-component__card-chart {
				width: 100%;
				height: theme('spacing.80');
			}

			.asteria-component__card-chart-content {
				@apply grid gap-2;

				grid-template-areas: 'title chart' 'text chart';
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows:
					minmax(min-content, max-content)
					1fr;

				&.asteria--state-responsive {
					grid-template-columns: 1fr;
					grid-template-areas: 'title' 'chart' 'text';
					grid-template-rows: minmax(min-content, max-content) 1fr minmax(
							min-content,
							max-content
						);
				}

				> .asteria-component__title,
				.asteria-component__card-title {
					grid-area: title;
				}

				> .asteria-component__card-chart {
					grid-area: chart;
				}

				> .asteria-component__text {
					grid-area: text;
				}
			}

			.recharts-tooltip-wrapper {
				> div {
					&::before {
						@apply visible absolute bottom-0 right-1/2 z-50 h-2 w-2 bg-inherit;

						content: '';
						transform: translateY(50%) translateX(50%) rotate(45deg);
					}
				}

				.asteria-component__text {
					&.asteria--type-title,
					&.asteria--type-total {
						@apply font-semibold;
					}
				}
			}
		}
	}
}
