@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-form-label-normal-background: transparent;
	--color-form-label-normal-border: transparent;
	--color-form-label-normal-text: theme('colors.content.text.normal');
	--color-form-label-normal-icon: theme('colors.icon.normal');

	--color-form-label-hover-background: transparent;
	--color-form-label-hover-border: transparent;
	--color-form-label-hover-text: theme('colors.content.text.hover');
	--color-form-label-hover-icon: theme('colors.icon.hover');

	--color-form-label-focus-background: transparent;
	--color-form-label-focus-border: transparent;
	--color-form-label-focus-text: theme('colors.content.text.focus');
	--color-form-label-focus-icon: theme('colors.icon.focus');

	--color-form-label-disabled-background: transparent;
	--color-form-label-disabled-border: transparent;
	--color-form-label-disabled-text: theme('colors.content.text.muted');
	--color-form-label-disabled-icon: theme('colors.icon.disabled');
}
