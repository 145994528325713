@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__time-selector-month {
	.asteria-component__time-selector-month-balance {
		.asteria-component__skeleton {
			@apply h-9 w-20 items-center justify-center;
		}
	}
}
