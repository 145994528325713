@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-health {
			--color-graph-radial-background: #ebe7e2;

			.asteria-component__icon {
				&.asteria--variant-header {
					> .asteria-component__icon-content {
						background-color: var(--color-primary);
					}
				}
			}

      .asteria-component__carousel-content {
        .asteria-component__button {
          .asteria-component__typography {
            @apply break-words whitespace-normal;
          }
        }
      }

			.asteria-component__card-slider {
				@apply grid grid-cols-1;

				.asteria-component__button {
					&.asteria-component__button--variant-link {
						@apply flex w-full justify-center;

						.asteria-component__typography {
							@apply overflow-hidden text-ellipsis;
						}
					}
				}
			}
		}
	}
}
