@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-search-selector-normal-background: transparent;
	--color-search-selector-normal-text: theme('colors.content.text.normal');
	--color-search-selector-normal-icon: theme('colors.icon.normal');

	--color-search-selector-counter-normal-background: ;
	--color-search-selector-counter-normal-text: ;

	--color-search-selector-hover-background: transparent;
	--color-search-selector-hover-text: theme('colors.content.text.normal');
	--color-search-selector-hover-icon: theme('colors.icon.normal');

	--color-search-selector-focus-background: transparent;
	--color-search-selector-focus-text: theme('colors.content.text.normal');
	--color-search-selector-focus-icon: theme('colors.icon.normal');
}

.asteria-component__search-selector {
	.asteria-component__button.asteria-component__dropdown__toggle,
	.asteria-component__button.asteria-component__dropdown__toggle:not(.asteria-component__button--icon-only).asteria-component__button--icon-position-last,
	.asteria-component__button.asteria-component__dropdown__toggle:not(.asteria-component__button--variant-select) {
		@apply rounded-full p-2 pl-4;
		@apply border-search-selector-normal-border bg-search-selector-normal-background;

		.asteria-component__search-selector-count {
			@apply flex aspect-square h-6 w-6 items-center justify-center rounded-full;
			@apply bg-search-selector-counter-normal-background text-search-selector-counter-normal-text;
		}

		.asteria-component__typography {
			@apply font-body text-sm font-light;
			@apply text-search-selector-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-search-selector-normal-icon;
			}
		}

		&:hover {
			@apply border-search-selector-hover-border bg-search-selector-hover-background;

			.asteria-component__typography {
				@apply text-search-selector-hover-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-search-selector-hover-icon;
				}
			}

			.asteria-component__search-selector-count {
				@apply bg-search-selector-counter-hover-background text-search-selector-counter-hover-text;
			}
		}

		&.asteria--state-active {
			@apply border-search-selector-focus-border bg-search-selector-focus-background;

			.asteria-component__typography {
				@apply text-search-selector-focus-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-search-selector-focus-icon;
				}
			}

			.asteria-component__search-selector-count {
				@apply bg-search-selector-counter-focus-background text-search-selector-counter-focus-text;
			}
		}
	}

	&.asteria--variant-client {
		.asteria-component__search-selector-item {
			.asteria-component__dropdown__item__label {
				@apply overflow-hidden;

				.asteria-component__chip {
					@apply w-full overflow-hidden;
				}

				.asteria-component__typography {
					@apply overflow-hidden text-ellipsis;
				}
			}

			.asteria-component__dropdown__item__postfix {
				@apply transition-opacity;
			}

			&:not(:hover) {
				.asteria-component__dropdown__item__postfix {
					@apply opacity-0;
				}
			}
		}

		&.asteria-component__level {
			@apply w-72;
		}
	}
}

.asteria-component__search-selector-item {
	.asteria-component__chip,
	.asteria-component__checkbox {
		@apply pointer-events-none;
	}
}
