@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--forecaster-transactions-columns-7: minmax(min-content, max-content)
		minmax(130px, 210px) minmax(130px, 1fr) 140px
		minmax(min-content, max-content) 110px minmax(min-content, max-content);

	--forecaster-transactions-columns-6: minmax(130px, 210px) minmax(130px, 1fr)
		140px minmax(min-content, max-content) 110px
		minmax(min-content, max-content);

	--forecaster-transactions-columns-5: minmax(130px, 1fr) 140px
		minmax(min-content, max-content) 110px minmax(min-content, max-content);

	--forecaster-transactions-background-empty: #f3f7fb;
}

.asteria-component__wrapper {
	&.asteria-component__forecaster-transactions {
		@apply h-full rounded-md border border-solid;

		border-color: #eae6e2;

		> .asteria-component__wrapper-header {
			@apply bg-transparent p-4 pb-0;
		}

		> .asteria-component__wrapper-content {
			@apply bg-transparent p-4 pr-0;

			@include scroll('both');
		}

		.asteria-component__input {
			input {
				@apply w-0;
			}
		}

		.asteria-component__forecaster-transactions-list {
			.asteria-component__forecaster-transactions-list-item,
			.asteria-component__forecaster-transactions-list-header {
				@apply h-auto border-0 p-0;
			}
		}

		.asteria-component__forecaster-transactions-list {
			@apply grid;

			column-gap: theme('spacing.2');

			grid-template-columns: var(--columns);

			.asteria-component__forecaster-transactions-list-group {
				@apply contents;

				> * {
					&:not(.asteria-component__forecaster-transactions-list-header):not(.asteria-component__forecaster-transactions-list-item) {
						grid-column: 1 / calc(var(--size) + 1);
					}
				}

				> .asteria-component__forecaster-transactions-list-header {
					@apply contents;

					> .asteria-component__forecaster-transactions-list-item {
						grid-column: 1 / calc(var(--size));
					}
				}

				> .asteria-component__forecaster-transactions-list-item {
					&:not(.asteria--state-empty) {
						@apply contents;
					}

					&.asteria--state-empty {
						grid-column: 1 / calc(var(--size) + 1);
					}
				}

				&:not(:first-child) {
					> .asteria-component__forecaster-transactions-list-header {
						> .asteria-component__forecaster-transactions-list-item {
							@apply mt-6;
						}
					}
				}
			}
		}

		&.asteria-component__forecaster-transactions-full {
			@apply w-full border-0;
		}
	}
}

.asteria-component__forecaster-transactions-list-cell {
	@apply box-border overflow-hidden;

	padding: 2px; // TODO: Remove it and check Swedbank theme
	padding-bottom: theme('spacing.2');

	&.asteria--type-date {
		.asteria-component__input {
			&.asteria--state-has-visible {
				.asteria-component__input__inner {
					&::after {
						@apply flex items-center text-sm;
					}
				}
			}
		}

		.asteria-component__button {
			.asteria-component__icon {
				&,
				.asteria-component__icon-content {
					@apply h-4 w-4;
				}
			}
		}
	}

	&.asteria--type-total {
		.asteria-component__input {
			input {
				@apply text-right;
			}

			&.asteria--state-has-visible {
				.asteria-component__input__inner {
					&::after {
						@apply flex items-center justify-end text-right text-sm;
					}
				}
			}

			// Chrome, Safari, Edge, Opera
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				@apply m-0 appearance-none;
			}

			// Firefox
			input[type='number'] {
				-moz-appearance: textfield;
			}
		}
	}

	&.asteria--type-currency {
		.asteria-component__dropdown__toggle--input {
			min-width: var(--default-currency-width, 50px);
		}

		.currency-flag {
			@apply rounded-sm;
		}
	}

	.asteria-component__chip {
		@apply w-full overflow-hidden;

		.asteria-component__typography {
			@apply overflow-hidden text-ellipsis;
		}
	}

	.asteria-component__dropdown__toggle {
		.asteria-component__typography {
			@apply overflow-hidden text-ellipsis;
		}
	}
}

.asteria-component__forecaster-transactions-list-header {
	.asteria-component__forecaster-transactions-list-item {
		@apply flex items-center gap-2;
	}

	.asteria-component__forecaster-transactions-list-cell {
		&.asteria-component__forecaster-transactions-list-cell-title {
			@apply flex-1;
		}

		&.asteria--type-total {
			@apply w-24 text-right;

			.asteria-component__typography {
				@apply overflow-hidden text-ellipsis whitespace-nowrap;
			}
		}
	}
}

.asteria-component__forecaster-transactions-list-item {
	&.asteria--state-empty {
		background-color: var(--forecaster-transactions-background-empty);

		.asteria-component__group {
			@apply gap-0 py-10 px-6 text-center;
		}
	}
}
