@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__layout-navigation {
	@apply flex
			h-10
			items-center;

	.asteria-component__button {
		@apply p-0;
	}
}
