@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__currencies {
	--column-width-currency: 120px;
	--column-width-exposure: 1fr;
	--column-width-risk: 140px;
	--column-width-balance: 130px;
	--column-width-total: 110px;

	.asteria-component__message-box__wrapper {
		@apply mb-2 flex flex-col gap-2;

		> .asteria-component__button {
			@apply m-0 self-end;
		}
	}

	.asteria-component__list {
		&.asteria-component__currency-list {
			--progress__height: 22px;
			--progress__width: 22px;
			--progress__circlethickness: 2px;

			--progress__value__width: calc(
				var(--progress__width) - var(--progress__circlethickness) * 2
			);
			--progress__value__height: calc(
				var(--progress__height) - var(--progress__circlethickness) * 2
			);

			.asteria-component__button {
				@apply m-0 h-auto w-auto p-0;
			}

			.asteria-component__list__header {
				.asteria-component__list__item {
					@apply grid flex-1 gap-2;
					grid-template: 'list-currency list-exposure list-risk list-balance list-total';
					grid-template-columns:
						var(--column-width-currency) var(
							--column-width-exposure
						)
						var(--column-width-risk) var(--column-width-balance) var(--column-width-total);
				}

				.asteria-currency-cell {
					@apply box-border flex h-10 items-center;
				}

				.asteria-currency-cell-currency {
					grid-area: list-currency;
				}

				.asteria-currency-cell-exposure {
					min-width: 140px;
					grid-area: list-exposure;
				}

				.asteria-currency-cell-currency-risk {
					grid-area: list-risk;
				}

				.asteria-currency-cell-currency-balance {
					grid-area: list-balance;
				}

				.asteria-currency-cell-currency-total {
					grid-area: list-total;

					@apply justify-end text-right;
				}
			}

			.asteria-component__list__group {
				.asteria-list-item {
					@apply grid;
					grid-template: 'list-currency list-exposure list-risk list-balance list-total';
					grid-template-columns:
						var(--column-width-currency) var(
							--column-width-exposure
						)
						var(--column-width-risk) var(--column-width-balance) var(--column-width-total);

					.asteria-currency-cell {
						@apply box-border flex h-10 items-center gap-1;
					}

					.asteria-component__typography {
						@apply flex items-center;

						span {
							@apply ml-1;
						}
					}

					.asteria-currency-cell-currency {
						grid-area: list-currency;
					}

					.asteria-currency-cell-exposure {
						@apply grid gap-2;

						grid-template: 'list-exposure-deposit list-exposure-withdraw';
						grid-template-columns: 1fr 1fr;

						grid-area: list-exposure;
						min-width: 140px;

						.asteria-currency-cell-exposure-deposit {
							grid-area: list-exposure-deposit;
						}

						.asteria-currency-cell-exposure-withdraw {
							grid-area: list-exposure-withdraw;
						}
					}

					.asteria-currency-cell-currency-risk {
						grid-area: list-risk;

						@apply gap-2;
					}

					.asteria-currency-cell-currency-balance {
						grid-area: list-balance;

						@apply gap-2;
					}

					.asteria-currency-cell-currency-total {
						grid-area: list-total;
						@apply justify-end text-right;

						.asteria-component__typography {
							@apply justify-end whitespace-normal;
						}
					}
				}
			}
		}
	}
}
