@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	// --color-form-switch-normal-background: ;
	// --color-form-switch-normal-border: ;

	// --color-form-switch-hover-background: ;
	// --color-form-switch-hover-border: ;

	// --color-form-switch-focus-background: ;
	// --color-form-switch-focus-border: ;

	// --color-form-switch-disabled-background: ;
	// --color-form-switch-disabled-border: ;

	// --color-form-switch-focus-disabled-background: ;
	// --color-form-switch-focus-disabled-border: ;

	--color-form-switch-track-normal-background: white;
	--color-form-switch-track-normal-border: theme('colors.border.normal');

	--color-form-switch-track-focus-background: theme('colors.primary.normal');
	--color-form-switch-track-focus-border: theme('colors.primary.normal');

	--color-form-switch-track-disabled-background: white;
	--color-form-switch-track-disabled-border: theme('colors.border.normal');

	--color-form-switch-track-focus-disabled-background: theme('colors.border.normal');
	--color-form-switch-track-focus-disabled-border: theme('colors.border.normal');

	--color-form-switch-thumb-normal-background: theme('colors.primary.normal');
	--color-form-switch-thumb-normal-border: white;
	--color-form-switch-thumb-normal-icon: white;

	--color-form-switch-thumb-focus-background: white;
	--color-form-switch-thumb-focus-border: transparent;
	--color-form-switch-thumb-focus-icon: theme('colors.primary.normal');

	--color-form-switch-thumb-disabled-background: theme('colors.border.normal');
	--color-form-switch-thumb-disabled-border: transparent;
	--color-form-switch-thumb-disabled-icon: white;

	--color-form-switch-thumb-focus-disabled-background: white;
	--color-form-switch-thumb-focus-disabled-border: transparent;
	--color-form-switch-thumb-focus-disabled-icon: theme('colors.border.normal');
}

.asteria-component__form-v2__switch {
	--thumb-size: calc(theme('spacing.4') - 2px);
	--thumb-border-size: 2px;

	.asteria-component__form-v2__switch__thumb__wrapper {
		left: 4px;

		height: var(--thumb-size);
		width: var(--thumb-size);

		@apply top-1/2 -translate-y-1/2;
	}
}
