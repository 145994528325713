// @tailwind base;
// @tailwind components;
@tailwind utilities;

@import './mixins';
@import './constants';
@import './colors';

@import './carousel';
@import './graph';

:root {
	--asteria-selectors: #{$global};
	--browser-zoom: 1;
}

#{$global} {
	// zoom: calc(1 / var(--browser-zoom));
}

#{$global} {
	--scrollbar__background-color: transparent;
	--scrollbar__width: 14px;
	--scrollbar__border-radius: 100px;

	--scrollbar__track__background-color: transparent;
	--scrollbar__track__box-shadow: 0; //inset 0 0 6px rgba(0, 0, 0, 0.3)

	--scrollbar__thumb__background-color: #babac0;
	--scrollbar__thumb__border-radius: 100px;
	--scrollbar__thumb__border-width: 4px;
	--scrollbar__thumb__border-style: solid;
	--scrollbar__thumb__border-color: rgba(255, 255, 255, 0);

	--z-index__dialog: 996;
	--z-index__tour: 997;
	--z-index__modal: 998;
	--z-index__datepicker: 999;
	--z-index__snackbar: 1000;

	--color-form-input-range-normal-primary: var(--color-primary);
	--color-form-input-range-normal-thumb: var(--color-primary);

	--select-offset: 10px;

	--color-groove-button: black;
}

#{$global} {
	a,
	a:visited {
		color: var(--color-link);
	}

	a:focus,
	a:active,
	a:hover {
		color: var(--color-link-focus);
	}

	.currency-flag {
		max-width: 16px;
		max-height: 12px;
		min-height: 12px;
		background-size: 100% 100%;
	}
}

html {
	// box-sizing: border-box;
}

.wings-main {
	@apply overflow-hidden;
}

.asteria-scroll {
	@include scroll();
}

.asteria-wrapper *,
.asteria-wrapper *::before,
.asteria-wrapper *::after {
	&:not(svg, .asteria-component__icon-content, .asteria-component__icon) {
		box-sizing: inherit;
	}
}

.asteria-utils-position-first {
	@apply order-first mr-auto;
}

.asteria-utils-position-last {
	@apply order-last ml-auto;
}

.asteria-component__level,
#asteria-modals-container,
#asteria-snackbar-container,
#asteria-tour-portal {
	@apply box-border outline-none outline-0;

	* {
		&:not(svg, .asteria-component__icon-content, .asteria-component__icon) {
			@apply box-border outline-none outline-0;
		}
	}
}

.asteria-wrapper {
	@apply grid w-full bg-background-normal;

	grid-template-areas: 'top-navigation top-navigation top-navigation' 'left-navigation content details' 'bottom-navigation bottom-navigation bottom-navigation';
	grid-template-columns: minmax(min-content, max-content) 1fr minmax(min-content, max-content);
	grid-template-rows: minmax(min-content, max-content) 1fr minmax(min-content, max-content);

	* {
		&:not(svg, .asteria-component__icon-content, .asteria-component__icon) {
			@apply box-border antialiased outline-none outline-0;
		}
	}

	.asteria-content-view {
		@apply relative bg-background-normal p-0;
		grid-area: content;

		&.asteria-content-view-cashflow {
			@apply grid;

			column-gap: theme('spacing.4');
			// @apply gap-4;

			grid-template-areas:
				'overview overview'
				'search navigation'
				'content content';

			grid-template-columns: 1fr minmax(min-content, max-content);
			grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) 1fr;

			.asteria-component__migration-alert {
				grid-area: migration-alert;
			}

			.asteria-component__migration-banner {
				grid-area: migration-banner;
			}

			&.asteria--feature-navigation-content {
				grid-template-areas:
					'left-navigation overview overview'
					'left-navigation top-navigation top-navigation'
					'left-navigation search navigation'
					'left-navigation content content';

				grid-template-columns: minmax(min-content, max-content) 1fr minmax(min-content, max-content);
				grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) 1fr;
			}

			&.asteria--feature-migration-alert {
				grid-template-areas:
					'migration-alert migration-alert'
					'overview overview'
					'search navigation'
					'content content';
			}

			&.asteria--feature-migration-banner {
				grid-template-areas:
					'overview overview'
					'migration-banner migration-banner'
					'search navigation'
					'content content';

				&.asteria--feature-migration-alert {
					grid-template-areas:
						'migration-alert migration-alert'
						'migration-banner migration-banner'
						'overview overview'
						'search navigation'
						'content content';
				}
			}

			> .asteria-company-overview {
				@apply mb-4;
			}

			.asteria-component__cashflow {
				@apply overflow-hidden;
				grid-area: content;

				z-index: 1;
			}

			@media screen and (max-height: 800px) {
				&.asteria-content-view-cashflow {
					// grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) 1fr minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
				}
			}

			.asteria-company-overview {
				@apply flex w-full flex-col items-center gap-4;
				grid-area: overview;

				.asteria-component__header {
					@apply grow;
				}
			}
		}

		&.asteria-content-view-financial {
			.asteria-view__financial {
				@apply h-full;

				&.asteria--page-financial-loan {
					@apply overflow-hidden;
				}

				&.asteria--page-financial {
					@apply h-auto;
				}
			}
		}
	}

	&:not(.asteria-wrapper--split) {
		.asteria-component__transactions__wrapper,
		.asteria-component__transactions {
			@apply mt-4 h-auto overflow-visible;
		}

		.asteria-content-view-cashflow {
			.asteria-component__cashflow {
				@apply overflow-visible;
			}
		}
	}

	.asteria-cashflow-details {
		@apply flex flex-col gap-4;

		grid-area: 'transactions';
	}

	&.asteria-wrapper--split {
		&.asteria--feature-transaction-scroll-auto,
		&:not(.asteria--feature-transaction-scroll-auto).asteria--feature-transaction-scroll-inner {
			.asteria-content-view {
				&.asteria-content-view-cashflow {
					// grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) auto minmax(min-content, max-content) 1fr minmax(min-content, max-content);
				}

				.asteria-cashflow-details {
					height: 100%;

					.list-grid {
						overflow-y: auto;
					}
				}
			}
		}

		&.asteria--feature-transaction-scroll-auto.asteria-wrapper--size-large,
		&:not(.asteria--feature-transaction-scroll-auto):not(.asteria--feature-transaction-scroll-inner) {
			.asteria-content-view {
				&.asteria-content-view-cashflow {
					// grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) 1fr minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
				}

				.asteria-cashflow-details {
					height: auto;

					.list-grid {
						overflow-y: visible;
					}
				}
			}

			.asteria-component__resizer {
				@apply overflow-visible;
			}

			.asteria-component__resizer-element {
				&.asteria--position-end {
					@apply overflow-visible;
				}
			}
		}
	}

	.asteria-search {
		grid-area: search;
		z-index: 5;
	}

	.asteria-navigation {
		@apply flex items-center gap-2;
		grid-area: navigation;
	}

	.asteria-component__teaser {
		grid-area: teaser;
	}

	.asteria-suggestions {
		@apply py-4 px-0;

		grid-area: suggestions;

		> div {
			@include chip();
		}
	}

	&:not(.asteria-wrapper--split) {
		.asteria-graphs {
			@apply min-h-0 min-w-0;
		}
	}

	.asteria-graphs {
		@apply relative flex h-full flex-col;

		grid-area: graphs;

		overflow: hidden;

		.asteria-cashflow-graph {
			// @apply pb-8;
		}

		> div {
			@apply h-full;

			.asteria-resizer {
				@apply h-full;
			}
		}
	}

	.asteria-transactions {
		@include scroll();
	}

	.asteria-component__actionbar {
		&:not(.asteria-view__welcome__action) {
			grid-area: actions;
		}
	}

	.asteria-details {
		@apply w-fit;

		grid-area: details;
		min-width: 200px;
	}

	#asteria-sidepane-container {
		@apply z-30 flex;
		grid-area: details;

		.asteria-component__sidepane {
			@apply relative top-0 right-0;
		}
	}

	> .asteria-component__navigation {
		@apply pt-8;
	}

	> .asteria-component__wrapper-content {
		@apply py-8 px-4;
	}

	&.asteria-state--graph-bordered {
		.asteria-graphs {
			@apply rounded border border-solid border-border-normal;
		}

		.asteria-cashflow-graph {
			@apply p-4 py-8 px-2 pb-0;
		}

		.asteria-component__navigation {
			@apply border-0 border-none;
		}
	}
}

.asteria-information {
	@media (max-width: 600px) {
		@apply absolute inset-0 z-50 flex h-screen flex-col bg-white p-3 text-left;
	}

	@apply hidden;
}

.asteria-component__level {
	z-index: 99999;

	&.asteria-component__datepicker-level {
		&:not(.asteria--parent-modal) {
			z-index: var(--z-index__datepicker);
		}
	}
}
