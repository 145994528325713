@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__forecaster-page {
	&.asteria--type-home {
		.asteria-component__forecaster__view__graph_wrapper {
			@apply overflow-hidden;
		}

		.asteria-component__forecaster-page-section {
			&.asteria--type-graph {
				@apply p-4;

				.asteria-component__legends__wrapper {
					@apply h-8;
				}

				.asteria-component__forecaster-page-section-content {
					height: 140px;

					grid-template-columns: auto 1fr auto;
				}
			}

			&.asteria--type-actions {
				@apply my-6 flex flex-col gap-4;
			}
		}
	}
}
