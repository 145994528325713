@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-view__auth-modal {
	&.asteria--variant-2fa-setup {
	}
}

.asteria-component__auth-2fa {
	&.asteria--variant-setup {
		.asteria-component__icon {
			&.asteria--state-filled {
				@apply h-8 w-8 bg-icon-normal;

				.asteria-component__icon-content {
					@apply h-4 w-4 bg-white;
				}
			}

			&.asteria--state-rounded {
				@apply rounded-full;
			}
		}

		.asteria-view__auth-modal__setup-2fa__qr-code {
			@apply border border-solid border-border-normal bg-contain bg-center p-4;

			height: 150px;
			width: 150px;
		}

		.asteria-component__alert {
			&.asteria--type-secret {
				@apply pl-0;

				.asteria-component__typography {
					line-break: anywhere;
				}
			}
		}
	}
}
