@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__legend-item {
	@apply flex flex-shrink-0 items-center gap-2;

	@include apply-colors('graph-legends');

	transition: opacity 0.5s;

	.asteria-component__typography {
		@apply text-graph-legends-normal-text;

		&:hover {
			@apply text-graph-legends-hover-text;
		}

		&:focus {
			@apply text-graph-legends-focus-text;
		}
	}

	.asteria-color__forecast {
		--image: repeating-linear-gradient(
			135deg,
			#ededed,
			#ededed 2px,
			rgb(0 0 0 / 0.2) 3px,
			rgb(0 0 0 / 0.2) 5px,
			transparent 6px
		);
	}

	&.asteria--state-clickable {
		@apply cursor-pointer;

		&:hover {
			.asteria-component__text {
				@apply underline;
			}
		}
	}

	&.asteria--state-disabled {
		@apply pointer-events-none cursor-default opacity-50;
	}
}
