@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;
@use './styles/colors.scss' as *;

.asteria-component__chip {
	@apply m-0 box-border flex items-center gap-2 whitespace-nowrap rounded-full border border-solid;

	&:disabled,
	&.asteria--state-disabled {
		@apply pointer-events-none;
	}

	&:not(:disabled):not(.asteria--state-disabled) {
		@apply cursor-pointer;

		&:not(.asteria--state-clickable) {
			@apply cursor-default;
		}
	}

	&.spacing-b-2 {
		@apply mb-2;
	}

	&.asteria-component__chip--variant-flat,
	&.asteria-component__chip--variant-simple {
		@apply border-0;
	}

	&:not(.asteria-component__chip--variant-simple) {
		@apply h-10 px-4 py-2;

		&.asteria--size-xs {
			@apply h-6 px-3 py-1;
		}

		&.asteria--size-small,
		&.asteria--size-sm {
			@apply h-8 px-4 py-2;
		}

		&.asteria--size-large,
		&.asteria--size-lg {
			@apply h-12 px-5 py-3;
		}
	}

  &.asteria-component__chip--variant-beta {
		@apply rounded-md px-3;

		.asteria-component__typography {
			@apply font-semibold;
		}
	}

	&.asteria-component__chip--variant-fill {
		background-color: var(--color);
		--color-chip-normal-text: var(--font-color);
		--color-chip-hover-background: var(--color);
		.asteria-component__chip__prefix {
			display: none;
		}
	}

	.asteria-component__typography {
		@apply m-0 text-sm;
	}

	.asteria-component__button {
		@apply m-0 h-auto w-auto p-0 #{!important};
	}

	.asteria-component__icon {
		&,
		.asteria-component__icon-content {
			@apply h-auto w-4;
		}
	}

	.asteria-component__chip__button--dismiss {
		@apply order-last;

		.asteria-component__icon {
			&,
			.asteria-component__icon-content {
				@apply h-2 w-2;
			}
		}
	}

	.currency-flag {
		@apply w-4;

		aspect-ratio: 28 / 20;
	}

  &.asteria--size-xs {
		.asteria-component__icon {
			&,
			.asteria-component__icon-content {
				@apply h-2 w-2;
			}
		}

		.asteria-component__chip__button--dismiss {
			.asteria-component__icon {
				&,
				.asteria-component__icon-content {
					@apply h-2 w-2;
				}
			}
		}

		.asteria-component__typography {
			@apply text-xs;
		}
	}

	&.asteria--size-small,
	&.asteria--size-sm {
		.asteria-component__icon {
			&,
			.asteria-component__icon-content {
				@apply h-3 w-3;
			}
		}

		.asteria-component__chip__button--dismiss {
			.asteria-component__icon {
				&,
				.asteria-component__icon-content {
					@apply h-2 w-2;
				}
			}
		}

		.asteria-component__typography {
			@apply text-xs;
		}
	}

	&.asteria--size-large,
	&.asteria--size-lg {
		.asteria-component__typography {
			@apply text-lg;
		}

		.currency-flag {
			@apply w-7;
		}
	}

	.asteria-component__prefix {
		@apply flex-none;
	}
}
