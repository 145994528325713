@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__forecaster-settings-section {
	&.asteria--variant-reset {
		.asteria-component__button {
			&.asteria-component__button--variant-link {
				@apply px-0;
			}
		}
	}
}
