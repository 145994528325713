@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-view__streamline {
	@apply flex flex-col;

	.asteria-component__time-selector {
		z-index: 1;
	}

	.asteria-component__flex-line {
		.asteria-component__flex-item-wrapper {
			@apply h-full;

			.asteria-component__flex-item-content {
				@apply p-2;
			}

			.asteria-component__form {
				@apply h-full;

				.asteria-component__wrapper {
					@apply h-full;
				}
			}
		}
	}
}
