@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-invoices {
			.asteria-component__card-content {
				.asteria-component__button {
					&.asteria-component__button--variant-link {
						@apply h-auto w-auto p-0;
					}
				}
			}
		}
	}
}
