@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__forecaster-axis {
	&.asteria--variant-x {
		@apply relative isolate grid;

		grid-template-columns: repeat(calc(var(--size, 7) - 2), 1fr);

		&.asteria--state-has-y-axis {
			margin-left: calc(
				var(--forecaster-y-axis-width) + theme('spacing.4')
			);
		}

		.asteria-component__forecaster-axis-prefix {
			@apply absolute left-0;

			.asteria-component__typography {
				@apply overflow-hidden text-ellipsis whitespace-nowrap;
			}

			> div > .asteria-component__typography-group {
				@apply invisible;
			}

			.asteria-component__forecaster-axis-prefix-item {
				@apply text-left;

				.asteria-component__typography {
					&.asteria--variant-label {
						@apply font-button font-bold;
					}

					&.asteria--variant-amount {
						@apply text-content-text-muted;
					}
				}
			}
		}

		.asteria-graph-x-axis-label {
			@apply min-w-0 overflow-hidden;

			.asteria-component__typography-group {
				@apply mb-2;
			}

			.asteria-graph-x-axis-label--prefix {
				@apply text-xs text-graph-group-today-meta-normal-prefix;
			}

			.asteria-graph-x-axis-label--label {
				@apply font-button;
			}

			&.asteria--variant-history {
			}

			&.asteria--variant-current {
				.asteria-graph-x-axis-label--label {
					@apply font-bold text-graph-group-today-meta-normal-text;
				}
			}

			&.asteria--variant-future {
			}
		}

		.asteria-component__forecaster-graph-input {
			&.asteria--variant-history {
				@apply flex h-10 items-center justify-center;
				@apply mx-1;
			}

			input {
				@apply w-full;
			}
		}

		.asteria-component__input {
			@apply mx-1;

			&.asteria--state-has-visible {
				.asteria-component__input__inner {
					&::after {
						@apply flex items-center justify-center text-sm;
					}
				}
			}

			.asteria-component__input__wrapper {
				@apply w-full;
			}

			input {
				@apply text-center;
			}

			&:not(.asteria-component__input--type-range) {
				.asteria-component__input__inner {
					input,
					textarea {
						@apply text-sm;
					}
				}
			}

			// Chrome, Safari, Edge, Opera
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				@apply m-0 appearance-none;
			}

			// Firefox
			input[type='number'] {
				-moz-appearance: textfield;
			}
		}
	}
}
