@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__loan-tooltip {
	&.asteria--type-bar {
		@apply w-56;

		.asteria-component__loan-tooltip-title {
			@apply text-center text-base;
		}
	}
}
