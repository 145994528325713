@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-view__welcome__question {
	@apply flex w-full flex-col items-center justify-center gap-4;

	.asteria-view__welcome__question__content {
		@apply w-full;
	}

	.asteria-component__input {
		@apply m-auto w-1/2;
	}

	&.asteria-view__welcome__question--variant-bars {
		--max: 10000;
		--min: 0;

		--line1: 0.25;
		--line1Size: 0.25;
		--line2: 0.25;
		--line2Size: 0.25;
		--line3: 0.25;
		--line3Size: 0.25;
		--line4: 0.25;
		--line4Size: 0.25;

		@apply h-full;

		.asteria-view__welcome__question__content {
			@apply grid h-full w-full gap-8;

			grid-template-columns: repeat(var(--count), 1fr);

			.asteria-component__graph__bar {
				&.asteria-component__graph__bargroup {
					&.asteria-component__graph__bar--type-deposit {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
}
