.asteria-component__carousel {
	@apply border-carousel-wrapper-normal-border bg-carousel-wrapper-normal-background;
}

.asteria-component__carousel-content {
	@apply rounded-md border border-solid p-4 shadow;
	@apply border-carousel-content-normal-border bg-carousel-content-normal-background;
}

.asteria-component__carousel-buttons {
}

.asteria-component__carousel-button {
	@apply h-3 w-3 rounded-full border border-solid;

	@apply border-carousel-dot-normal-border bg-carousel-dot-normal-background;

	&.asteria--state-active {
		@apply border-carousel-dot-focus-border bg-carousel-dot-focus-background;
	}

	&:hover {
		@apply border-carousel-dot-hover-border bg-carousel-dot-hover-background;
	}
}
