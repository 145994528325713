@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-invoices {
			.asteria-component__card-content {
				flex-direction: row;
			}

			.asteria__component-chart__pie {
				.asteria-component__tooltip {
					// width: 300px;
				}
			}

			.asteria-component__card-invoices-content {
				@apply grid grid-cols-2;

				grid-template-areas: 'switcher chart' 'title chart' 'tags chart';
				grid-template-rows:
					minmax(min-content, max-content) minmax(
						min-content,
						max-content
					)
					1fr;

				&.asteria--state-responsive {
					@apply grid-cols-1;

					grid-template-areas: 'switcher' 'title' 'chart' 'tags';
					grid-template-rows:
						minmax(min-content, max-content) minmax(
							min-content,
							max-content
						)
						1fr minmax(min-content, max-content);

					.asteria-component__pie-chart-wrapper {
						height: theme('spacing.60');
					}
				}
			}

			.asteria-component__card-section {
				&.asteria--type-switcher {
					grid-area: switcher;
				}
			}

			.asteria-component__card-title {
				grid-area: title;
			}

			.asteria-component__tags-wrapper {
				grid-area: tags;

				.asteria-component__chip {
					@apply w-auto rounded-full;
				}
			}

			.asteria-component__pie-chart-wrapper {
				grid-area: chart;

				transition: max-height 0.3s ease-in;
				aspect-ratio: 1 / 1;
			}
		}
	}
}

.asteria--variant-invoices__total-tooltip {
	width: 300px;
}
