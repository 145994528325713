@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__support-button-wrapper {
	@apply fixed bottom-6 right-6;

	z-index: 31;

	.asteria-content-view.asteria--location-financial-credit & {
		@apply bottom-28;
	}

	> .asteria-component__button {
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

		@apply rounded-full;

		&:not(.asteria-component__button--icon-only) {
			&.asteria-component__button--icon-position-first {
				@apply pl-2;
			}

			&.asteria-component__button--icon-position-last {
				@apply pr-2;
			}
		}

		/*

		&.asteria-component__button--variant-secondary {
			&.asteria--size-lg {
				@apply pl-2 pr-6;

				.asteria-component__icon {
					@apply h-5 w-5;
				}
			}

			@apply rounded-full;
		}

		 */
	}
}
