@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--spinner__border-width: 3px;
	--spinner__width: 32px;
	--spinner__height: 32px;
}

.asteria-component__spinner {
	--progress__circlethickness: var(--spinner__border-width, 3px);

	--progress__value: 0%;

	--progress__width: var(--spinner__width, 32px);
	--progress__height: var(--spinner__height, 32px);

	--progress__value__width: calc(
		var(--progress__width) - var(--progress__circlethickness) * 2
	);

	--progress__value__height: calc(
		var(--progress__height) - var(--progress__circlethickness) * 2
	);
}
