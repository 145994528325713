@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--statement-label-width: 320px;
}

.asteria-component__statement {
	.asteria-component__statement__table {
		.asteria-component__table-group {
			@apply mt-4;
		}

		.asteria-component__table-row {
			grid-template-columns: var(--statement-label-width) repeat(
					3,
					130px 1fr
				);

			.asteria-component__table-cell {
				&.asteria--type-delta {
					@apply justify-start;

					.asteria-component__group {
						@apply w-20 justify-end;
					}
				}

				&.asteria--type-value {
					@apply justify-between;
				}

				.asteria-component__text {
					@apply whitespace-nowrap;
				}
			}
		}

		.asteria-component__table-header {
			.asteria-component__table-cell {
				&:not(:first-child) {
					grid-column: span 2;
				}
			}
		}
	}

	> .asteria-component__wrapper-content {
		@apply flex flex-col gap-4;
	}

	&.asteria--state-loading {
		@apply h-1/2 overflow-visible;
	}

	.asteria-component__tabs {
		@apply grid;
		grid-template-columns: 1fr min-content;

		.asteria-component__tabs__content__wrapper {
			grid-column: span 2;
		}
	}

	.statement-download-button {
		@apply h-6 py-0;
	}
}

.table-statement-delta-icon {
	&.table-statement--delta-positive {
		.asteria-component__icon-content {
			background-color: #729d50;
		}
	}

	&.table-statement--delta-equal {
		.asteria-component__icon-content {
			background-color: #005aa0;
		}
	}

	&.table-statement--delta-negative {
		.asteria-component__icon-content {
			background-color: #ad1015;
		}
	}
}
