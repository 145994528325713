@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__page-header {
	@apply grid w-full items-start gap-4;

	grid-template-areas: 'page-header-prefix page-header-content page-header-postfix';
	grid-template-columns:
		minmax(min-content, max-content)
		1fr
		minmax(min-content, max-content);

	&.asteria-component__page-header--no-prefix {
		grid-template-areas: 'page-header-content page-header-postfix';
		grid-template-columns: 1fr minmax(min-content, max-content);

		&.asteria-component__page-header--no-content {
			grid-template-areas: 'page-header-postfix';
			grid-template-columns: minmax(min-content, max-content);
		}

		&.asteria-component__page-header--no-postfix {
			grid-template-areas: 'page-header-content';
			grid-template-columns: 1fr;
		}
	}

	&.asteria-component__page-header--no-content {
		grid-template-areas: 'page-header-prefix page-header-postfix';
		grid-template-columns: minmax(min-content, max-content) minmax(
				min-content,
				max-content
			);

		&.asteria-component__page-header--no-prefix {
			grid-template-areas: 'page-header-postfix';
			grid-template-columns: minmax(min-content, max-content);
		}

		&.asteria-component__page-header--no-postfix {
			grid-template-areas: 'page-header-prefix';
			grid-template-columns: minmax(min-content, max-content);
		}
	}

	&.asteria-component__page-header--no-postfix {
		grid-template-areas: 'page-header-prefix page-header-content';
		grid-template-columns: minmax(min-content, max-content) 1fr;

		&.asteria-component__page-header--no-prefix {
			grid-template-areas: 'page-header-content';
			grid-template-columns: 1fr;
		}

		&.asteria-component__page-header--no-content {
			grid-template-areas: 'page-header-prefix';
			grid-template-columns: minmax(min-content, max-content);
		}
	}

	.asteria-component__page-header__content {
		@apply grid h-full items-center gap-2;

		grid-area: page-header-content;
		grid-template-areas:
			'page-header-title'
			'page-header-subtitle'
			'page-header-navigation';

		&.asteria-component__page-header__content--no-title {
			grid-template-areas:
				'page-header-subtitle'
				'page-header-navigation';

			&.asteria-component__page-header__content--no-subtitle {
				grid-template-areas: 'page-header-navigation';
			}

			&.asteria-component__page-header__content--no-navigation {
				grid-template-areas: 'page-header-subtitle';
			}
		}

		&.asteria-component__page-header__content--no-subtitle {
			grid-template-areas:
				'page-header-title'
				'page-header-navigation';

			&.asteria-component__page-header__content--no-title {
				grid-template-areas: 'page-header-navigation';
			}

			&.asteria-component__page-header__content--no-navigation {
				grid-template-areas: 'page-header-title';
			}
		}

		&.asteria-component__page-header__content--no-navigation {
			grid-template-areas:
				'page-header-title'
				'page-header-subtitle';

			&.asteria-component__page-header__content--no-title {
				grid-template-areas: 'page-header-subtitle';
			}

			&.asteria-component__page-header__content--no-subtitle {
				grid-template-areas: 'page-header-title';
			}
		}
	}

	.asteria-component__page-header__navigation {
		grid-area: page-header-navigation;
	}

	.asteria-component__page-header__prefix {
		@apply self-center;

		grid-area: page-header-prefix;
	}

	.asteria-component__page-header__postfix {
		@apply flex items-center gap-2;

		grid-area: page-header-postfix;
	}

	.asteria-component__page-header__title {
		@apply flex items-center gap-2;

		grid-area: page-header-title;
	}

	.asteria-component__page-header__subtitle {
		grid-area: page-header-subtitle;
	}
}
