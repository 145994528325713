@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__list__group {
	&.asteria-component__transactions__group {
		.asteria-component__list__header {
			&.asteria-component__transactions__group__header {
				> .asteria-component__list__item {
					@apply w-full;

					> *:first-child {
						@apply flex-1;
					}

					&.asteria--state-has-edit {
						@apply pr-0;
					}
				}
			}
		}
	}
}
