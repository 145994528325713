@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__currencies {
	.asteria-component__list {
		.asteria-component__list__item {
			.asteria-component__list__cell {
				&.asteria-currency-cell-currency-risk {
					@apply pointer-events-none;

					--color-button-icon-normal-background: transparent;
					--color-button-icon-normal-border: transparent;

					--color-button-icon-hover-background: transparent;
					--color-button-icon-hover-border: transparent;

					--color-button-icon-focus-background: transparent;
					--color-button-icon-focus-border: transparent;

					.asteria-component__button {
						@include asteria-btn-sm(true);

						@apply cursor-default;
					}
				}
			}
		}
	}
}
