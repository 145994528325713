@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-navigation-wrapper-normal-background: transparent;
	--color-navigation-wrapper-normal-border: transparent;

	--color-navigation-wrapper-focus-background: transparent;
	--color-navigation-wrapper-focus-border: transparent;

	--color-navigation-item-normal-background: transparent;
	--color-navigation-item-normal-border: transparent;

	--color-navigation-item-focus-background: rgba(243, 227, 224, 1);
	--color-navigation-item-focus-border: transparent;

	--color-navigation-item-hover-background: rgba(243, 227, 224, 1);
	--color-navigation-item-hover-border: transparent;

	--color-navigation-item-text-normal-background: transparent;
	--color-navigation-item-text-normal-border: transparent;
	--color-navigation-item-text-normal-text: rgba(110, 110, 115, 1);

	--color-navigation-item-text-focus-background: transparent;
	--color-navigation-item-text-focus-border: transparent;
	--color-navigation-item-text-focus-text: rgba(0, 0, 0, 1);

	--color-navigation-item-text-hover-background: transparent;
	--color-navigation-item-text-hover-border: transparent;
	--color-navigation-item-text-hover-text: rgba(0, 0, 0, 1);

	--color-navigation-item-icon-normal-background: transparent;
	--color-navigation-item-icon-normal-border: transparent;
	--color-navigation-item-icon-normal-icon: rgba(110, 110, 115, 1);

	--color-navigation-item-icon-focus-background: rgba(243, 227, 224, 1);
	--color-navigation-item-icon-focus-border: transparent;
	--color-navigation-item-icon-focus-icon: rgba(231, 100, 94, 1);

	--color-navigation-item-icon-hover-background: rgba(243, 227, 224, 1);
	--color-navigation-item-icon-hover-border: transparent;
	--color-navigation-item-icon-hover-icon: rgba(225, 147, 142, 1);

	--color-navigation-item-accordion-normal-icon: rgba(110, 110, 115, 1);
	--color-navigation-item-accordion-normal-border: transparent;
	--color-navigation-item-accordion-normal-background: transparent;

	--color-navigation-item-accordion-focus-background: transparent;
	--color-navigation-item-accordion-focus-border: transparent;
	--color-navigation-item-accordion-focus-icon: rgba(231, 100, 94, 1);

	--color-navigation-item-accordion-hover-background: transparent;
	--color-navigation-item-accordion-hover-border: transparent;
	--color-navigation-item-accordion-hover-icon: rgba(225, 147, 142, 1);

	// --color-navigation-item-sub-normal-background: ;
	// --color-navigation-item-sub-normal-border: transparent;

	// --color-navigation-item-sub-focus-background: ;
	// --color-navigation-item-sub-focus-border: transparent;
}

.asteria-component__navigation {
	@apply gap-1 border-0 border-r border-solid border-border-normal;

	.asteria-component__navigation-companies {
		--color-button-icon-normal-border: transparent;

		.asteria-component__navigation-companies-dropdown {
			> .asteria-component__button {
			}
		}
	}

	.asteria-component__navigation__item,
	.asteria-component__navigation__item__icon,
	.asteria-component__icon .asteria-component__icon-content {
		@apply transition-colors ease-in-out;
	}

	.asteria-component__navigation__item {
		@apply border-0;

		&:not(.asteria-component__navigation--collapse) {
			.asteria-component__navigation__item__label {
				@apply pr-4;
			}

			&:hover {
				--color-icon-hover: var(--color-navigation-item-icon-hover-icon);
			}
		}

		&.asteria-component__navigation--collapse {
			--color-navigation-item-normal-background: transparent;
			--color-navigation-item-normal-border: transparent;
			--color-navigation-item-focus-background: transparent;
			--color-navigation-item-focus-border: transparent;
			--color-navigation-item-hover-background: transparent;
			--color-navigation-item-hover-border: transparent;

			--color-navigation-item-icon-normal-background: var(--color-button-icon-normal-background);
			--color-navigation-item-icon-normal-border: var(--color-button-icon-normal-border);
			--color-navigation-item-icon-normal-icon: var(--color-button-icon-normal-icon);

			--color-navigation-item-icon-hover-background: var(--color-button-icon-hover-background);
			--color-navigation-item-icon-hover-border: var(--color-button-icon-hover-border);
			--color-navigation-item-icon-hover-icon: var(--color-button-icon-hover-icon);

			--color-navigation-item-icon-focus-background: var(--color-button-icon-focus-background);
			--color-navigation-item-icon-focus-border: var(--color-button-icon-focus-border);
			--color-navigation-item-icon-focus-icon: var(--color-button-icon-focus-icon);

			@apply mt-2 h-10;

			.asteria-component__badge__wrapper {
				@apply flex items-center justify-center;
			}

			.asteria-component__navigation__item__icon {
				@include asteria-btn-xs;
			}

			.asteria-component__navigation__item__label {
				.asteria-component__typography {
					@apply text-xs;
				}
			}
		}
	}

	&:not(.asteria-component__navigation--has-label) {
		.asteria-component__navigation__item {
			@apply rounded-full;
		}
	}

	&.asteria-component__navigation--has-label {
		.asteria-component__navigation__item {
			@apply rounded-xl;
		}
	}

	.asteria-component__navigation__logo {
		@apply h-8 w-8 cursor-pointer;
	}

	&.asteria-component__navigation--direction-vertical {
		.asteria-component__navigation__logo {
			@apply mx-auto mb-4;
		}
	}
}
