@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__clients-list__action {
	&.asteria--type-selector {
		.asteria-component__chip {
			@include asteria-btn-tab;
		}
	}
}

.asteria-component__client {
	.asteria-component__page-header {
		.asteria-component__page-header__prefix {
			.asteria-component__button {
				@include asteria-btn-md;
			}
		}
	}

	.asteria-component__clients-list {
		.asteria-component__clients-list__table {
			--color-button-icon-normal-background: transparent;
			--color-button-icon-normal-border: transparent;

			--color-button-icon-hover-background: transparent;
			--color-button-icon-hover-border: transparent;

			--color-button-icon-focus-background: transparent;
			--color-button-icon-focus-border: transparent;
		}
	}
}

.asteria-component__client-overview {
	.asteria-component__client-overview__details__overview {
		.asteria-component__button {
			&.asteria-component__button--variant-link {
				@include asteria-btn-sm;
			}
		}
	}
}
