@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-tour-border: transparent;
}

.asteria-component__tour__tooltip {
}

#asteria-tooltip-container {
	.asteria-component__tour__tooltip {
		.asteria-component__wrapper-header {
			@apply p-4;

			.asteria-component__wrapper-header__content {
				> .asteria-component__typography {
					@apply text-title-xs;
				}
			}

			&.asteria-component__wrapper-header--absolute-postfix {
				@apply pr-14;
			}

			.asteria-component__wrapper-header__postfix {
				@apply top-auto;

				> .asteria-component__wrapper__button-close {
					@include asteria-btn-rounded;
					@include asteria-btn-xs;
				}
			}
		}

		.asteria-component__wrapper-content {
			@apply px-4 py-0;
		}

		.asteria-component__wrapper-footer {
			@apply p-4;

			.asteria-component__wrapper-footer__section {
				> .asteria-component__button {
					@include asteria-btn-xs;
				}
			}
		}
	}
}
