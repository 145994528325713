@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--slider__width: 100%;
	--slider__height: 8px;
	--slider__border-radius: 4px;

	--slider__thumb__height: 18px;
	--slider__thumb__width: 18px;
	--slider__thumb__border-radius: 100%;
	--slider__thumb__border-style: solid;
	--slider__thumb__border-color: transparent;
	--slider__thumb__border-width: 0;
	--slider__thumb__background-color: #55a1ac;
	--slider__thumb__box-shadow: 0 0 0px 0px transparent;
	--slider__thumb__margin: #{spacing(1, xs)} 0 0 0;
	--slider__thumb__padding: 0;

	--slider__track__background-color: #c8e2e5;
	--slider__range__background-color: #55a1ac;

	--slider__value__margin: #{spacing(2) 0 0 0};
	--slider__value__padding: 0;
}

.asteria-component__slider {
	/* Removing the default appearance */
	position: relative;
	.asteria-component__slider__thumb,
	.asteria-component__slider__thumb::-webkit-slider-thumb {
		-webkit-appearance: none;
		-webkit-tap-highlight-color: transparent;
	}

	.asteria-component__slider__thumb {
		pointer-events: none;
		position: absolute;
		height: 0;
		width: var(--slider__width);
		outline: none;
	}

	.asteria-component__slider__thumb--zindex-3 {
		z-index: 3;
	}

	.asteria-component__slider__thumb--zindex-4 {
		z-index: 4;
	}

	.asteria-component__slider__thumb--zindex-5 {
		z-index: 5;
	}

	/* For Chrome browsers */
	.asteria-component__slider__thumb {
		&::-webkit-slider-thumb {
			background-color: var(--slider__thumb__background-color);
			border-color: var(--slider__thumb__border-color);
			border-style: var(--slider__thumb__border-style);
			border-width: var(--slider__thumb__border-width);
			border-radius: var(--slider__thumb__border-radius);
			box-shadow: var(--slider__thumb__box-shadow);
			height: var(--slider__thumb__height);
			width: var(--slider__thumb__width);
			margin: var(--slider__thumb__margin);
			padding: var(--slider__thumb__padding);
			cursor: pointer;
			pointer-events: all;
			position: relative;
		}
	}

	/* For Firefox browsers */
	.asteria-component__slider__thumb {
		&::-moz-range-thumb {
			background-color: var(--slider__thumb__background-color);
			border-color: var(--slider__thumb__border-color);
			border-style: var(--slider__thumb__border-style);
			border-width: var(--slider__thumb__border-width);
			border-radius: var(--slider__thumb__border-radius);
			box-shadow: var(--slider__thumb__box-shadow);
			height: var(--slider__thumb__height);
			width: var(--slider__thumb__width);
			margin: var(--slider__thumb__margin);
			padding: var(--slider__thumb__padding);
			cursor: pointer;
			pointer-events: all;
			position: relative;
		}
	}

	.asteria-component__slider__overlay {
		position: relative;
		width: var(--slider__width);

		.asteria-component__slider__overlay__track,
		.asteria-component__slider__overlay__range,
		.asteria-component__slider__overlay__left-value,
		.asteria-component__slider__overlay__right-value {
			position: absolute;
		}

		.asteria-component__slider__overlay__track,
		.asteria-component__slider__overlay__range {
			border-radius: var(--slider__border-radius);
			height: var(--slider__height);
		}

		.asteria-component__slider__overlay__track {
			background-color: var(--slider__track__background-color);
			width: 100%;
			z-index: 1;
		}

		.asteria-component__slider__overlay__range {
			background-color: var(--slider__range__background-color);
			z-index: 2;
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			.asteria-component__text {
				transform: translateY(24px);
			}
		}

		.asteria-component__slider__overlay__left-value,
		.asteria-component__slider__overlay__right-value {
			margin: var(--slider__value__margin);
			padding: var(--slider__value__padding);
			.asteria-component__text {
				// color: var(--slider__value__text__color);
				// font-size: var(--slider__value__text__font-size);
			}
		}

		.asteria-component__slider__overlay__left-value {
			left: 6px;
		}

		.asteria-component__slider__overlay__right-value {
			right: -4px;
		}
	}

	&.asteria-component__slider--variant--multi-range {
	}
}
