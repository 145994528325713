@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-form-select-normal-background: #ffffff;
	--color-form-select-normal-border: #b2b2b2;
	--color-form-select-normal-text: theme('colors.content.text.normal');
	--color-form-select-normal-icon: theme('colors.icon.normal');

	--color-form-select-hover-background: #ffffff;
	--color-form-select-hover-border: #b2b2b2;
	--color-form-select-hover-text: theme('colors.content.text.hover');
	--color-form-select-hover-icon: theme('colors.icon.normal');

	--color-form-select-focus-background: rgba(250, 250, 250, 1);
	--color-form-select-focus-border: #000000;
	--color-form-select-focus-text: theme('colors.content.text.focus');
	--color-form-select-focus-icon: #000000;

	--color-form-select-disabled-background: theme('colors.gray.100');
	--color-form-select-disabled-border: theme('colors.gray.300');
	--color-form-select-disabled-text: theme('colors.content.text.muted');
	--color-form-select-disabled-icon: theme('colors.icon.disabled');
}

.asteria-component__button {
	&.asteria-component__button--variant-select {
		@apply rounded-md;

		.asteria-component__typography {
			@apply font-light;
		}

		&.asteria--size-lg {
			@apply px-4;

			.asteria-component__typography {
				@apply text-lg;
			}
		}

		&.asteria--size-md {
			@apply px-3;

			.asteria-component__typography {
				@apply text-base;
			}
		}

		&.asteria--size-sm {
			@apply px-3;

			.asteria-component__typography {
				@apply text-sm;
			}
		}

		&.asteria-component__button--icon-position-last {
			@apply pr-0;
		}

		&.asteria-component__button--icon-position-first {
			@apply pl-0;
		}
	}
}

.asteria-component__select {
	.asteria-component__select__inner {
		--color-alert-error-normal-border: theme('colors.alert.error.icon.normal.background');
		--color-alert-success-normal-border: theme('colors.alert.success.icon.normal.background');
		--color-alert-info-normal-border: theme('colors.alert.info.icon.normal.background');
		--color-alert-warning-normal-border: theme('colors.alert.warning.icon.normal.background');

		.asteria-component__select--dropdown {
			> .asteria-component__button {
				@apply rounded-md;

				.asteria-component__typography {
					@apply font-light;
				}

				&.asteria--state-active {
					--color-form-select-normal-background: var(--color-form-select-focus-background);
					--color-form-select-normal-border: var(--color-form-select-focus-border);
					--color-form-select-normal-text: var(--color-form-select-focus-text);
					--color-form-select-normal-icon: var(--color-form-select-focus-icon);

					--color-form-select-hover-background: var(--color-form-select-focus-background);
					--color-form-select-hover-border: var(--color-form-select-focus-border);
					--color-form-select-hover-text: var(--color-form-select-focus-text);
					--color-form-select-hover-icon: var(--color-form-select-focus-icon);
				}
			}
		}
	}

	&.asteria--size-lg {
		.asteria-component__select__inner {
			.asteria-component__select--dropdown {
				> .asteria-component__button {
					@apply px-4;

					.asteria-component__typography {
						@apply text-lg;
					}
				}
			}
		}
	}

	&.asteria--size-md {
		.asteria-component__select__inner {
			.asteria-component__select--dropdown {
				> .asteria-component__button {
					@apply px-3;

					.asteria-component__typography {
						@apply text-base;
					}
				}
			}
		}
	}

	&.asteria--size-sm {
		.asteria-component__select__inner {
			.asteria-component__select--dropdown {
				> .asteria-component__button {
					@apply px-3;

					.asteria-component__typography {
						@apply text-sm;
					}
				}
			}
		}
	}
}
