@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__financial-page {
	&.asteria--variant-home {
		@apply flex flex-col gap-4;

		.asteria-component__financial-page-section {
			&.asteria--variant-placeholder {
			}

			&.asteria--variant-overdraft {
			}

			&.asteria--variant-loan {
			}
		}
	}
}
