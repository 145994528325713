@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-cashflow-graph-hide-account-balance {
	grid-template-rows: max-content 1fr;

	&:not(.asteria-cashflow-graph-stacked) {
		.asteria-graph-lines {
			@apply hidden;
		}
	}

	&.asteria-cashflow-graph-stacked {
		.asteria-graph-line-group-wrapper {
			@apply hidden;
		}
	}

	.asteria-graph-line-graph-legends {
		@apply hidden;
	}

	.asteria-graph {
		&.asteria-graph-grouped {
			.asteria-component__graph__teaser--forecaster {
				@apply right-12 bottom-16;
			}
		}
	}
}

.asteria-cashflow-graph-hide-revenue {
	&:not(.asteria-cashflow-graph-stacked) {
		.asteria-graph-revenue-graph {
			@apply hidden;
		}
	}

	.asteria-graph-revenue-graph-legends {
		@apply hidden;
	}
}

.asteria-cashflow-graph {
	@apply relative flex h-full w-full flex-col;

	.asteria-graph-navigation {
		@apply self-center p-4;
		@include apply-colors('graph-time-button');

		width: 40px;
	}

	.asteria-cashflow-graph-navigation {
		@apply pointer-events-none absolute z-10 flex h-full w-full items-center justify-between;

		.asteria-component__button {
			@apply pointer-events-auto bg-graph-navigation-normal-background;

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-graph-navigation-normal-icon;
				}
			}

			&:hover {
				@apply bg-graph-navigation-hover-background;

				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-graph-navigation-hover-icon;
					}
				}
			}
		}
	}

	.asteria-component__legends__wrapper {
		// @apply ml-36;

		width: calc(100% - theme('spacing.36'));
		margin-left: calc(
			var(--graph-yaxis-wrapper-width, 96px) + theme('spacing.8')
		);
	}

	.asteria-graph-lines,
	.asteria-graph-bars {
		@apply h-full grow;
	}
}

.asteria-graph {
	@apply mx-10 flex;

	.asteria-graph-inner {
		@apply grid w-full overflow-hidden border-graph-wrapper-normal-border bg-graph-wrapper-normal-background pt-12;
		// @apply relative grid h-full w-full border-graph-wrapper-normal-border bg-graph-wrapper-normal-background pt-8;

		> .asteria-component__graph__time-selector {
			@apply items-center justify-end;
		}
	}

	.asteria-graph-yaxis-labels {
		@apply pointer-events-none pr-4;
		grid-row: 1;
		grid-column: 1;
		margin-top: -11px;
		margin-bottom: -11px;

		.asteria-graph-yaxis-label-zero {
			@apply relative;
			top: 2px;
		}
	}

	.asteria-graph-yaxis-lines {
		@apply pointer-events-none pl-0;
		grid-column: 2;
		grid-row: 1;

		.asteria-graph-yaxis-line-zero {
			@apply relative z-10;
			position: relative;
			top: 2px;
		}
	}

	.asteria-graph-area {
		@apply pl-0 pr-20;
		grid-row: 1/-1;
		grid-column: 2;
		// height: 100%;
	}

	.asteria-component__graph__teaser--forecaster {
		@apply absolute top-auto right-auto left-auto bottom-auto z-20 h-auto;
	}

	&.asteria-graph-grouped {
		.asteria-component__graph__teaser--forecaster {
			@apply right-12 bottom-16;
		}
	}

	&.asteria-graph-stacked {
		.asteria-component__graph__teaser--forecaster {
			@apply bottom-16 right-12;
			transform: translateY(-50%);
		}
	}
}

.asteria-cashflow__mode-credit {
	.asteria-graph {
		&.asteria-graph-lines {
			.asteria-graph-inner {
				@apply grid-rows-graph;
			}
		}
	}
}

.asteria-cashflow-graph {
	&.asteria-cashflow-graph-stacked {
		.asteria-graph-line__spread {
			@apply pointer-events-none;

			polygon {
				@apply pointer-events-auto;
			}
		}

		.asteria-graph-spread-inner {
			polygon {
				@apply pointer-events-none;
			}
		}

		.asteria-graph-bar-group {
			@apply pointer-events-none;
		}
	}

	.asteria-graph {
		.asteria-graph-inner {
			@apply grid-rows-graph;
			grid-template-columns: max-content 1fr;
		}
	}

	&:not(.asteria-cashflow-graph-hide-revenue) {
		.asteria-graph {
			&.asteria-graph-lines {
				&:not(.asteria-graph-stacked) {
					.asteria-graph-inner {
						@apply pb-4;
						grid-template-rows: 1fr;

						.asteria-graph-yaxis-lines {
							@apply z-10;
						}

						.asteria-component__graph__time-selector {
							@apply hidden;
						}

						.asteria-graph-area {
							grid-template-rows: 1fr;
						}
					}
				}
			}
		}
	}
}

.asteria-graph-stacked {
	.asteria-graph-bar-group {
		&.asteria-graph-bar-group__layout-stacked {
			.asteria-component__old__graph__bar {
				&.asteria-withdraw {
					padding-top: calc(var(--graph-bar-offset, 0px) + 4px);
				}

				&.asteria-deposit {
					padding-top: var(--graph-bar-offset, 0px);
				}
			}
		}
	}
}

.asteria--feature-graph-spread-info-dots {
	.asteria-graph-line-group-wrapper-info {
		.asteria-graph-line-group-forecast {
			display: none;
		}
	}
}

.asteria--feature-disable-forecast-line {
	.asteria-graph-line-group-wrapper-info {
		.asteria-graph-line-group-forecast {
			display: none;
		}
	}

	.asteria-graph-line-group-wrapper {
		.asteria-graph-line-group-forecast {
			.asteria-graph-line
				line:not(.asteria-graph-line-overdraft):not(.asteria-component__line--fade-out) {
				display: none;
			}
		}
	}
}
