.asteria-component__sidepane {
	@apply fixed
		top-0
		right-0
		z-10
		h-full
		w-96
		overflow-hidden
		border-sidepane-normal-border
		bg-sidepane-normal-background
		shadow;

	> .asteria-component__wrapper {
		@apply h-full w-96;

		> .asteria-component__wrapper-header {
			@apply border-0 border-solid
			border-sidepane-header-normal-border
			bg-sidepane-header-normal-background;

			.asteria-component__title {
				@apply text-sidepane-normal-title;
			}
		}

		> .asteria-component__wrapper-content {
			@apply h-full
			border-0 border-solid
			border-sidepane-body-normal-border
			bg-sidepane-body-normal-background;
		}

		> .asteria-component__wrapper-footer {
			@apply rounded-none
			border-sidepane-footer-normal-border
			bg-sidepane-footer-normal-background;
		}
	}
}

/* --- Animations --- */

.asteria-sidepane-enter {
	@apply w-0;
}

.asteria-sidepane-enter-active {
	@apply w-96 transition-all duration-200 ease-linear;
}

.asteria-sidepane-exit {
	@apply w-96;
}

.asteria-sidepane-exit-active {
	@apply w-0 transition-all duration-200 ease-linear;
}
