@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__devtools-button {
	&:not(.asteria-component__level) {
		@apply fixed bottom-20 right-6 z-50;

		> .asteria-component__button {
			@apply opacity-20;

			&:hover {
				@apply opacity-100;
			}
		}
	}
}

.asteria-component__devtools-button__chips {
	@apply flex gap-2 p-2;

	max-width: 250px;

	@include scroll('x');
}
