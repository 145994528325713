@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__datepicker__content {
	.asteria-component__datepicker__content__header {
		@apply flex w-full items-center justify-evenly bg-datepicker-header-variant-normal-background py-4;

		div {
			@apply cursor-pointer;

			&:hover {
				@apply underline;
			}

			&.asteria--state-active {
				@apply underline;
			}
		}
	}
}
