.asteria-graph-y-axis-label {
	@apply flex items-end whitespace-nowrap text-right;
}

.asteria-graph-yaxis-labels,
.asteria-graph-y-axis-label {
	.asteria-component__typography {
		&:not(.asteria-prefix-y-axis) {
			@apply text-sm;
		}
	}
}

.asteria-component__legends {
	.asteria-component__legend-item {
		.asteria-component__typography {
			@apply text-sm;
		}
	}
}

.asteria-graph-y-axis-label-small {
	flex-basis: 0px;
	flex-shrink: 1;
	flex-grow: 1;
}

.asteria-graph-y-axis-label-medium {
	flex-basis: 0px;
	flex-shrink: 2;
	flex-grow: 2;
}

.asteria-graph-y-axis-label-large {
	flex-basis: 0px;
	flex-shrink: 4;
	flex-grow: 4;
}
