@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__notifications {
	.asteria-component__dropdown__toggle {
		@apply border-notifications-button-normal-border bg-notifications-button-normal-background;

		.asteria-component__typography {
			@apply border-notifications-button-text-normal-border bg-notifications-button-text-normal-background;
		}

		.asteria-component__icon {
			@apply border-notifications-button-icon-normal-border bg-notifications-button-icon-normal-background;
		}

		&.asteria--state-active {
			@apply border-notifications-button-focus-border bg-notifications-button-focus-background;

			.asteria-component__typography {
				@apply border-notifications-button-text-focus-border bg-notifications-button-text-focus-background;
			}

			.asteria-component__icon {
				@apply border-notifications-button-icon-focus-border bg-notifications-button-icon-focus-background;
			}
		}

		&:hover {
			@apply border-notifications-button-hover-border bg-notifications-button-hover-background;

			.asteria-component__typography {
				@apply border-notifications-button-text-hover-border bg-notifications-button-text-hover-background;
			}

			.asteria-component__icon {
				@apply border-notifications-button-icon-hover-border bg-notifications-button-icon-hover-background;
			}
		}
	}
}

.asteria-component__notification {
	@apply border-notifications-item-wrapper-normal-border bg-notifications-item-wrapper-normal-background;

	&.asteria-component__wrapper {
		.asteria-component__wrapper-header {
			@apply border-notifications-item-header-normal-border bg-notifications-item-header-normal-background;

			.asteria-component__title {
				@apply text-notifications-item-header-normal-title;
			}
		}

		.asteria-component__wrapper-content {
			@apply border-notifications-item-body-normal-border bg-notifications-item-body-normal-background;
		}

		.asteria-component__wrapper-footer {
			@apply border-notifications-item-footer-normal-border bg-notifications-item-footer-normal-background;
		}

		&:not(.asteria--type-empty) {
			&:hover {
				@apply border-notifications-item-wrapper-hover-border bg-notifications-item-wrapper-hover-background;

				.asteria-component__wrapper-header {
					@apply border-notifications-item-header-hover-border bg-notifications-item-header-hover-background;

					.asteria-component__title {
						@apply text-notifications-item-header-hover-title;
					}
				}

				.asteria-component__wrapper-content {
					@apply border-notifications-item-body-hover-border bg-notifications-item-body-hover-background;
				}

				.asteria-component__wrapper-footer {
					@apply border-notifications-item-footer-hover-border bg-notifications-item-footer-hover-background;
				}
			}
		}
	}

	.asteria-component__button {
		&.asteria-close {
			@apply border-notifications-item-header-icon-normal-border bg-notifications-item-header-icon-normal-background;

			&.asteria--state-active {
				@apply border-notifications-item-header-icon-focus-border bg-notifications-item-header-icon-focus-background;
			}

			&:hover {
				@apply border-notifications-item-header-icon-hover-border bg-notifications-item-header-icon-hover-background;
			}
		}
	}

	&.asteria--type-empty {
		.asteria-component__typography {
			@apply whitespace-normal;
		}
	}
}
