@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-page-title: #000000;
	--color-title: #000000;
	--color-title-normal: var(--color-title);

	--color-text: #6e6e73;
	--color-text-normal: var(--color-text);
	--color-text-hover: #000000;
	--color-text-focus: #000000;
}

.asteria-component__title {
	--font-page-title-weight: 300;
}
