.asteria-component__form {
	@apply flex w-full flex-col;

	.asteria-component__alert {
		.asteria-component__wrapper {
			.asteria-component__wrapper-content {
				@apply p-0;
			}
		}
	}
}

.asteria-component__wrapper {
	&.asteria-component__form-wrapper {
		@apply border border-solid border-form-wrapper-normal-border bg-form-wrapper-normal-background;

		.asteria-component__form-wrapper__group {
			@apply gap-4;
		}

		> .asteria-component__wrapper-content,
		> .asteria-component__wrapper-footer {
			@apply bg-transparent p-0;
		}

		> .asteria-component__wrapper-header {
			@apply px-8 pb-0;
		}

		> .asteria-component__wrapper-content {
			@apply gap-4 p-8;

			> .asteria-component__typography-group {
				@apply flex flex-col gap-2;
			}
		}

		> .asteria-component__wrapper-footer {
			@apply px-8 pb-8;
		}
	}
}

.asteria-component__wrapper,
.asteria-component__wrapper .asteria-component__form {
	.asteria-component__form-wrapper {
		> .asteria-component__wrapper-content {
			@apply p-0;
		}
	}
}
