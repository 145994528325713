@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__time-selector-input {
	@apply items-center gap-x-4 gap-y-1;

	grid-template-areas:
		'input-health input-amount input-icon'
		'input-date input-amount input-icon';

	grid-template-columns: auto 1fr auto;

	.asteria-component__time-selector-health {
		grid-area: input-health;
	}

	.asteria-component__typography {
		&.asteria--type-date {
			grid-area: input-date;
		}
	}

	.asteria-component__time-selector-input-section {
		&.asteria--type-amount {
			@apply flex justify-end;

			grid-area: input-amount;

			.asteria-component__skeleton {
				@apply h-8 w-20 items-center justify-center;
			}
		}
	}

	.asteria-component__icon {
		&.asteria--type-datepicker {
			grid-area: input-icon;
		}
	}

	@media (max-width: theme('screens.tablet')) {
		grid-template-areas:
			'input-health input-icon'
			'input-amount input-icon'
			'input-date input-icon';

		grid-template-columns: 1fr auto;

		.asteria-component__time-selector-input-section {
			&.asteria--type-amount {
				@apply justify-start;
			}
		}
	}
}
