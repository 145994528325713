@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__breadcrumbs {
	@apply mb-2;

	grid-area: breadcrumbs;

	.asteria-component__button.asteria-component__button--variant-link {
		@apply h-auto w-auto p-0;
	}

	> .asteria-component__icon {
	}

	> .asteria-component__typography {
	}
}
