@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__datepicker__content__repeatable {
	@apply w-full bg-datepicker-repeatable-normal-background p-4;
}

.asteria-component__datepicker-repeatable {
	.asteria-component__wrapper {
		> .asteria-component__wrapper-content {
			@apply box-border p-8;

			> .asteria-component__wrapper {
				> .asteria-component__wrapper-content {
					@apply box-border bg-datepicker-repeatable-modal-normal-background p-4;
				}
			}
		}
	}

	.asteria-component__typography {
		@apply overflow-hidden text-ellipsis;
	}

	.asteria-component__select {
		@apply overflow-hidden;

		padding-bottom: 2px;
		padding-left: 1px;
		padding-right: 2px;
	}
}

.asteria-component__datepicker-repeatable__day {
	@apply flex aspect-square h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white;

	&:hover {
		@apply bg-datepicker-cell-hover-background;
	}

	&.asteria--state-active {
		@apply bg-datepicker-cell-focus-background;

		.asteria-component__typography {
			@apply text-datepicker-cell-focus-text;
		}
	}
}
