@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@keyframes asteria-dot-pulse {
	0% {
		transform: scale(0.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 theme('colors.graph.dot.animation.pulse.0');
	}

	70% {
		transform: scale(1) translate(-50%, -50%);
		box-shadow: 0 0 0 10px theme('colors.graph.dot.animation.pulse.70');
	}

	100% {
		transform: scale(0.95) translate(-50%, -50%);
		box-shadow: 0 0 0 0 theme('colors.graph.dot.animation.pulse.100');
	}
}

.asteria-component__graph__dot {
	@apply aspect-square h-2 w-2 cursor-pointer rounded-full border border-solid border-graph-dot-normal-border bg-graph-dot-normal-background;

	transform: translate(-50%, -50%);

	&.asteria-component__graph__dot--animated {
		transform: scale(1) translate(-50%, -50%);
		animation: asteria-dot-pulse 2s infinite;
	}
}
