// TODO
// Sizing (padding, margin, transform, ?
// Layout
// Flex
// alt tooltip tex
// navbar vertical or nothing
//
// https://dev.to/nicolalc/a-mixin-to-rule-them-all-flexbox-driven-layout-mixin-1h43

@forward 'align';
@forward 'spacing';
@forward 'text';
@forward 'buttons';
@forward 'tailwind';

@use 'spacing' as *;

@mixin apply-colors($name, $background: true, $border: true) {
	@if $background and $border {
		@apply bg-#{$name}-normal-background border-#{$name}-normal-border;

		&:hover,
		&.asteria-state-hover,
		&.asteria--state-hover {
			@apply bg-#{$name}-hover-background border-#{$name}-hover-border;
		}

		&:focus,
		&.asteria-state-active,
		&.asteria--state-active {
			@apply bg-#{$name}-focus-background border-#{$name}-focus-border;
		}
	} @else if $background {
		@apply bg-#{$name}-normal-background;

		&:hover {
			@apply bg-#{$name}-hover-background;
		}

		&:focus {
			@apply bg-#{$name}-focus-background;
		}
	} @else if $border {
		@apply border-#{$name}-normal-border;

		&:hover {
			@apply border-#{$name}-hover-border;
		}

		&:focus {
			@apply border-#{$name}-focus-border;
		}
	}
}

@mixin pseudo($width: 100%, $height: 100%, $display: inline-block, $pos: absolute, $content: '') {
	content: $content;
	display: $display;
	position: $pos;
	@include box($width, $height);
}

// &:after {
//     @include pseudo(100px, 100px);
// }

@mixin last-item-end {
	> :last-child {
		margin-left: auto;
		order: 999;
	}
}

@mixin title-with-icon($position: before) {
	@if ($position ==before) {
		.asteria-component-text {
			margin-right: auto;
			flex: 1;
		}

		.asteria-component-icon {
			order: -1;
		}
	} @else if ($position ==after) {
		.asteria-component-text {
		}

		.asteria-component-icon {
			margin-right: auto;
		}
	} @else {
		.asteria-component-text {
			flex: 1;
		}

		.asteria-component-icon {
			margin-right: auto;
		}
	}
}

@mixin css3-prefix($prop, $value) {
	-webkit-#{$prop}: #{$value};
	-moz-#{$prop}: #{$value};
	-ms-#{$prop}: #{$value};
	-o-#{$prop}: #{$value};
	#{$prop}: #{$value};
}

// @include css3-prefix(transform, scale3d(2.5, 2, 1.5));

// @mixin vertical-center {
// 	position: relative;
// 	top: 50%;
// 	-ms-transform: translateY(-50%);
// 	-webkit-transform: translateY(-50%);
// 	transform: translateY(-50%);
// }

// @include vertical-center();

@mixin retina-image($image, $width, $height) {
	@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
		background-image: url($image);
		background-size: $width $height;
	}
}

// @include retina-image("my-image2x.png", 1000px, 500px);

@mixin abs-position($top, $right, $bottom, $left) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

// @include abs-position(100px, 100px, auto, auto);

@mixin arrow($direction: down, $size: 5px, $color: #555) {
	width: 0;
	height: 0;

	@if ($direction ==left) {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
	} @else if ($direction ==right) {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
	} @else if ($direction ==down) {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
	} @else {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
	}
}

// @include arrow();
// @include arrow(up, 10px, #efefef);

// @include text-shorten();

@mixin box-sizing($type) {
	-webkit-box-sizing: $type;
	-moz-box-sizing: $type;
	box-sizing: $type;
}

// @include box-sizing(border-box);

// @include font-size(14px);

@mixin x($x) {
	-webkit-transform: translateX($x);
	-moz-transform: translateX($x);
	-ms-transform: translateX($x);
	transform: translateX($x);
}

// @include x(50px);

@mixin y($y) {
	-webkit-transform: translateY($y);
	-moz-transform: translateY($y);
	-ms-transform: translateY($y);
	transform: translateY($y);
}

// @include y(50px);

// div @include vertical-align();

@mixin gradient($start-color, $end-color, $orientation) {
	background: $start-color;

	@if $orientation ==vertical {
		// vertical
		background: -moz-linear-gradient(top, $start-color 0%, $end-color 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $start-color), color-stop(100%, $end-color));
		background: -webkit-linear-gradient(top, $start-color 0%, $end-color 100%);
		background: -o-linear-gradient(top, $start-color 0%, $end-color 100%);
		background: -ms-linear-gradient(top, $start-color 0%, $end-color 100%);
		background: linear-gradient(to bottom, $start-color 0%, $end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=0);
	} @else if $orientation ==horizontal {
		// horizontal
		background: -moz-linear-gradient(left, $start-color 0%, $end-color 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%, $start-color), color-stop(100%, $end-color));
		background: -webkit-linear-gradient(left, $start-color 0%, $end-color 100%);
		background: -o-linear-gradient(left, $start-color 0%, $end-color 100%);
		background: -ms-linear-gradient(left, $start-color 0%, $end-color 100%);
		background: linear-gradient(to right, $start-color 0%, $end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
	} @else {
		// radial
		background: -moz-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
		background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $start-color), color-stop(100%, $end-color));
		background: -webkit-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
		background: -o-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
		background: -ms-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
		background: radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
	}
}

// @include gradient(#ff00ff, #ff00cc, vertical);

@mixin ghost-button($font, $font-size, $font-color, $border-size, $border-color, $padding, $transition-speed, $hover-color) {
	display: inline-block;
	text-decoration: none;
	text-transform: uppercase;
	font-family: $font;
	font-size: $font-size;
	color: $font-color;
	border: $border-size solid $border-color;
	padding: $padding;
	-webkit-transition: color $transition-speed, background $transition-speed;
	transition: color $transition-speed, background $transition-speed;

	&:hover {
		background: $border-color;
		color: $hover-color;
	}
}

// @include ghost-button(“Trebuchet”, 12px, #ffffff, 5px, #34dec6, 4px, 300ms, #000000);

@mixin break-point($point) {
	@if $point ==desktop {
		@media only screen and (max-width: 50em) {
			@content;
		}
	} @else if $point ==mobile {
		@media only screen and (max-width: 20em) {
			@content;
		}
	}
}

//  @include break-point(mobile) {}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
	::-webkit-scrollbar {
		width: $size;
		height: $size;
	}

	::-webkit-scrollbar-thumb {
		background: $foreground-color;
	}

	::-webkit-scrollbar-track {
		background: $background-color;
	}

	// For Internet Explorer
	body {
		scrollbar-face-color: $foreground-color;
		scrollbar-track-color: $background-color;
	}
}

// @include scrollbars(.5em, slategray);

@mixin scroll($direction: y) {
	@if $direction ==x {
		overflow-x: auto;
		overflow-y: hidden;
	} @else if $direction == both {
		overflow-x: auto;
		overflow-y: auto;
	} @else {
		overflow-x: hidden;
		overflow-y: auto;
	}

	&::-webkit-scrollbar {
		background-color: var(--scrollbar__background-color);
		border-radius: var(--scrollbar__border-radius);
		width: var(--scrollbar__width);
	}

	&::-webkit-scrollbar-track {
		background-color: var(--scrollbar__track__background-color);
		box-shadow: var(--scrollbar__track__box-shadow);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--scrollbar__thumb__background-color);
		border-radius: var(--scrollbar__thumb__border-radius);
		border-width: var(--scrollbar__thumb__border-width);
		border-style: var(--scrollbar__thumb__border-style);
		border-color: var(--scrollbar__thumb__border-color);
		background-clip: content-box;
		border-radius: 100px;
	}

	scrollbar-color: var(--scrollbar__thumb__background-color, --scrollbar__background-color);
	scrollbar-width: thin;
}

// @include ellipsis;

%clearfix {
	*zoom: 1;

	&:after {
		content: '';
		display: table;
		line-height: 0;
		clear: both;
	}
}

@mixin clearfix() {
	&:before,
	&:after {
		content: '';
		display: table;
	}

	&:after {
		clear: both;
	}
}

// @include clearfix();

@mixin hui_tooltip($content: attr(data-tooltip), $direction: top) {
	position: relative;

	&:before,
	&:after {
		display: none;
		z-index: 98;
	}

	&:hover {
		&:after {
			// for text bubble
			content: $content;
			display: block;
			position: absolute;
			height: 12px; // (makes total height including padding 22px)
			padding: 6px;
			font-size: 12px;
			white-space: nowrap;
			color: #fff;
			@include text-shadow(1px 1px #000);
			background-color: #222;
		}

		@if ($direction == 'top') {
			&:before {
				@include triangle(down, 6px, #222);
				top: -6px;
				margin-top: 0;
				left: 47%;
			}

			&:after {
				top: -28px;
				left: 47%;
				margin-left: -20px;
			}
		} @else if ($direction == 'bottom') {
			&:before {
				@include triangle(up, 6px, #222);
				top: auto;
				margin-top: 0;
				bottom: -6px;
				left: 47%;
			}

			&:after {
				bottom: -28px;
				left: 47%;
				margin-left: -20px;
			}
		}
	}
}

@mixin box-sizing($box-model) {
	-webkit-box-sizing: $box-model; // Safari <= 5
	-moz-box-sizing: $box-model; // Firefox <= 19
	box-sizing: $box-model;
}

// @include box-sizing(border-box);

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	border-radius: $radius;
	background-clip: padding-box;
	/* stops bg color from leaking outside the border: */
}

// @include border-radius(20px);
// @include border-radius(20px, 10px, 5px, 25px);

@mixin border-top-radius($radius) {
	-webkit-border-top-right-radius: $radius;
	border-top-right-radius: $radius;
	-webkit-border-top-left-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}

@mixin border-right-radius($radius) {
	-webkit-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;
	-webkit-border-top-right-radius: $radius;
	border-top-right-radius: $radius;
	background-clip: padding-box;
}

@mixin border-bottom-radius($radius) {
	-webkit-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;
	background-clip: padding-box;
}

@mixin border-left-radius($radius) {
	-webkit-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;
	-webkit-border-top-left-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}

@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// @include center-block;

// @include line-height(24);

// ==========================================================
//  CSS Grid Mixins (take one)
// ==========================================================

//  global grid variables (☠ = don't touch)
// $☠__ms-col-gap: null;
// $☠__ms-row-gap: null;
// $☠__ms-col-count: null;
// $☠__ms-row-count: null;

//
//  repeat function
//
// @function repeat($repeat, $stuff: 1fr) {
// 	$list: ();
// 	@for $i from 1 through $repeat {
// 		$list: append($list, $stuff, space);
// 	}
// 	@return $list;
// }

//
//  grid-column mixin
//
// @mixin grid-column($start: auto, $span: 1) {
// 	//  grid-column-gap using left margin
// 	@if $☠__ms-col-gap and not $☠__ms-col-count and $start != 1 {
// 		margin-left: $☠__ms-col-gap;
// 		@supports (grid-column-gap: 1px) {
// 			margin-left: 0;
// 		}
// 	}
// 	-ms-grid-column: if($☠__ms-col-count, $start + $start - 1, $start);
// 	-ms-grid-column-span: if($☠__ms-col-count, $span + $span - 1, $span);
// 	grid-column: #{$start}/#{$start + $span};
// 	@content;
// }

//
//  grid-row mixin
//
// @mixin grid-row($start: auto, $span: 1) {
// 	//  grid-row-gap using top margin
// 	@if $☠__ms-row-gap and not $☠__ms-row-count and $start != 1 {
// 		margin-top: $☠__ms-row-gap;
// 		@supports (grid-row-gap: 1px) {
// 			margin-top: 0;
// 		}
// 	}
// 	-ms-grid-row: if($☠__ms-row-count, $start + $start - 1, $start);
// 	-ms-grid-row-span: if($☠__ms-row-count, $span + $span - 1, $span);
// 	grid-row: #{$start}/#{$start + $span};
// 	@content;
// }

//
//  grid-cell mixin
//
// @mixin grid-cell($col-start: auto, $col-span: 1, $row-start: auto, $row-span: 1) {
// 	@include grid-column($col-start, $col-span);
// 	@include grid-row($row-start, $row-span);
// 	@content;
// }

//
//  grid-area mixin
//
// @mixin grid-area($area) {
// 	$area: map-get($grid-areas, $area);
// 	@include grid-column(nth($area, 1), nth($area, 2));
// 	@include grid-row(nth($area, 3), nth($area, 4));
// 	@content;
// }

//
//  grid mixin
//
// @mixin grid($grid-map) {
// 	$cols: map-get($grid-map, cols);
// 	$rows: map-get($grid-map, rows);
// 	$gap: map-get($grid-map, gap);
// 	$col-gap: map-get($grid-map, col-gap);
// 	$col-gap: if($col-gap, $col-gap, $gap);
// 	$row-gap: map-get($grid-map, row-gap);
// 	$row-gap: if($row-gap, $row-gap, $gap);

// 	//  if cols or rows are numbers convert to fraction lists
// 	@if $cols and length($cols) == 1 and unitless($cols) {
// 		$cols: repeat($cols);
// 	}
// 	@if $rows and length($rows) == 1 and unitless($rows) {
// 		$rows: repeat($rows);
// 	}

// 	//  ie does not support grid gap - why we insert the gap space as a row or colum
// 	//  note! the first loop is to ensure cols/rows are not a multidimensional list
// 	//  (when generated by the repeat function)
// 	$ms-cols: null;
// 	$ms-rows: null;
// 	@if $col-gap and $cols {
// 		$ms-cols: ();
// 		@each $col in $cols {
// 			$ms-cols: if(type-of($col) == list, join($ms-cols, $col), append($ms-cols, $col));
// 		}
// 		@for $i from 1 through length($ms-cols) - 1 {
// 			$ms-cols: set-nth($ms-cols, $i, nth($ms-cols, $i) $col-gap);
// 		}
// 		//  globalize ms col count (used by grid-column)
// 		$☠__ms-col-count: length($ms-cols) !global;
// 	}
// 	@if $row-gap and $rows {
// 		$ms-rows: ();
// 		@each $row in $rows {
// 			$ms-rows: if(type-of($row) == list, join($ms-rows, $row), append($ms-rows, $row));
// 		}
// 		@for $i from 1 through length($ms-rows) - 1 {
// 			$ms-rows: set-nth($ms-rows, $i, nth($ms-rows, $i) $row-gap);
// 		}

// 		//  globalize ms row count (used by grid-row)
// 		$☠__ms-row-count: length($ms-rows) !global;
// 	}
// 	//
// 	$☠__ms-col-gap: $col-gap !global;
// 	$☠__ms-row-gap: $row-gap !global;

// 	display: -ms-grid;
// 	display: grid;

// 	-ms-grid-columns: $ms-cols or $cols;
// 	-ms-grid-rows: $ms-rows or $rows;

// 	grid-template-columns: $cols;
// 	grid-template-rows: $rows;

// 	grid-column-gap: $col-gap;
// 	grid-row-gap: $row-gap;
// 	@content;
// }

// $grid: (
// 	cols: repeat(5, 1fr) 2fr,
// 	row-gap: 8px,
// 	col-gap: 16px,
// );

// https://codepen.io/jakob-e/pen/RZpdaE

//  Grid area map (col, col-span, row, row-span)
// area-51: (1, 2, 1, 2);
// @include grid($grid);
// @include grid-area(area-51);
// @include grid-column(1,6);
// @include grid-row(7,1);
// @include grid-cell(3, 1, 2, 1);
// @media (max-width: 480px){ @include grid-cell(11, 2, 1, 1); }
// @include grid((cols: repeat(12), col-gap: 16px, row-gap: 8px));
// @include grid((cols: repeat(4), col-gap: 16px, row-gap: 8px));

// Grid

@mixin grid($grid-template-columns: null, $grid-template-rows: null, $gap: null, $grid-auto-flow: null, $grid-template-areas: null) {
	display: grid;
	grid-template-columns: $grid-template-columns;
	grid-template-rows: $grid-template-rows;
	gap: $gap;
	grid-auto-flow: $grid-auto-flow;
	grid-template-areas: $grid-template-areas;
}

// @include grid(auto 1fr auto auto, 1fr, 20px, row, '. .s');

// @function spacing($scales...) {
// 	$spacing: 0;
// 	@each $scale in $scales {
// 		$spacing: $spacing + $scale;
// 	}
// 	@return $spacing;
// }

// var(--spacing) ganger scale dvs 1, blir 8px, 16 osv

@mixin reset-list() {
	padding-left: 0;
	list-style-type: none;
}

@mixin chip {
	@include vertical-center();
	background: var(--chip-bg, #eae7e4);
	border-radius: 50px;
	display: inline-flex;
	padding: spacing(1) spacing(4) spacing(1) spacing(4);
	justify-content: center;
	align-items: center;

	.asteria-component-typography {
		color: var(--chip-color, #000);
		margin: 0;
		padding: 0;
	}
}

// @include text(align center indent em(16) ls em(4) ws em(6));

@mixin position($position, $args: null) {
	position: $position;

	@if $args !=null {
		$top-value: get-match-value-of-keys($args, top t);
		$right-value: get-match-value-of-keys($args, right r);
		$bottom-value: get-match-value-of-keys($args, bottom b);
		$left-value: get-match-value-of-keys($args, left l);

		z-index: get-match-value-of-keys($args, z-index z);
		top: if($top-value, unitless-px($top-value), null);
		right: if($right-value, unitless-px($right-value), null);
		bottom: if($bottom-value, unitless-px($bottom-value), null);
		left: if($left-value, unitless-px($left-value), null);
	}
}

// @include position(absolute, t 0 r 0 b 0 l 0 z 1000);

@mixin relative($args: null) {
	@include position(relative, $args);
}

// @include relative(l 100 z 100);

@mixin fixed($args: null) {
	@include position(fixed, $args);
}

// @include fixed(t 0 l 0 r 0 z 1500);

@mixin sticky($args: null) {
	@include position(sticky, $args);
}

// @include sticky(t 100 z 1700);

@mixin static {
	@include position(static, null);
}

// @include static;

@mixin flex-container($args, $mode: flex) {
	display: if($mode ==flex, flex, inline-flex);
	@include flex-container-append($args);
}

@mixin flex-c($args, $mode: flex) {
	@include flex-container($args, $mode);
}

// @include inline-flex-container(justify-end items-start);
// @include flex-c(justify-end items-start, inline-flex);
// @include flex-container(column wrap justify-center items-center);

@mixin flex-item($args) {
	@if $args !=null {
		// align-self
		@each $keyword in self-auto self-normal self-start self-end self-center self-stretch self-baseline self-first-baseline self-last-baseline self-safe self-unsafe self-inherit self-initial self-unset {
			$self-value-index: index($args, $keyword);

			@if $self-value-index {
				$self-value: nth($args, $self-value-index);
				@include align-self($self-value, flex);
			}
		}

		@if is-include-items($args, auto initial none) {
			@if index($args, auto) {
				@include flex(auto);
			}

			@if index($args, initial) {
				@include flex(initial);
			}

			@if index($args, none) {
				@include flex(none);
			}
		}

		@if is-include-items($args, grow g shrink s basis b) {
			// flex-grow
			$grow-value: get-match-value-of-keys($args, grow g);
			// flex-shrink
			$shrink-value: get-match-value-of-keys($args, shrink s);
			// flex-basis
			$basis-value: get-match-value-of-keys($args, basis b);

			// flex
			@include flex(if($grow-value, $grow-value, 0) if($shrink-value, $shrink-value, 1) if($basis-value, $basis-value, auto));
		}

		// order
		$order-value: get-match-value-of-keys($args, order o);

		@if $order-value {
			@include order($order-value);
		}
	}
}

// @include flex-item(self-end s 0 o 10);
// @include flex-i(self-end s 0 o 10);

@mixin flex-container-append($args) {
	@if $args !=null {
		// flex-direction ------------------------------------------
		@each $direction in row row-reverse column column-reverse {
			$direction-index: index($args, $direction);

			@if $direction-index {
				flex-direction: nth($args, $direction-index);
			}
		}

		// flex-wrap -----------------------------------------------
		@each $wrap in nowrap wrap wrap-reverse {
			$wrap-index: index($args, $wrap);

			@if $wrap-index {
				flex-wrap: nth($args, $wrap-index);
			}
		}

		// justify-content ------------------------------------------
		@each $justifyContent in justify-start justify-center justify-end justify-left justify-right justify-normal justify-between justify-around justify-evenly justify-safe justify-unsafe justify-inherit justify-initial justify-unset {
			$justifyContent-index: index($args, $justifyContent);

			@if $justifyContent-index {
				$justifyContent-value: str-replace(nth($args, $justifyContent-index), 'justify-');
				@include justify-content($justifyContent-value, flex);
			}
		}

		// align-items ------------------------------------------------
		@each $alignItems in items-normal items-stretch items-start items-end items-center items-baseline items-first-baseline items-last-baseline items-safe items-unsafe items-inherit items-initial items-unset {
			$alignItems-index: index($args, $alignItems);

			@if $alignItems-index {
				$alignItems-value: str-replace(nth($args, $alignItems-index), 'items-');
				@include align-items($alignItems-value, flex);
			}
		}

		// align-content ------------------------------------------------
		@each $alignContent
			in content-start
			content-center
			content-end
			content-left
			content-right
			content-normal
			content-baseline
			content-first-baseline
			content-last-baseline
			content-between
			content-around
			content-evenly
			content-stretch
			content-safe
			content-unsafe
			content-inherit
			content-initial
			content-unset
		{
			$alignContent-index: index($args, $alignContent);

			@if $alignContent-index {
				$alignContent-value: str-replace(nth($args, $alignContent-index), 'content-');
				@include align-content($alignContent-value, flex);
			}
		}
	}
}

@mixin flex-c-a($args) {
	@include flex-container-append($args);
}

// @include flex-container-append(justify-evenly content-stretch);
// @include flex-c-a(justify-evenly items-stretch);

@mixin flex($value) {
	@if $value ==1 {
		flex: 1 1 0;
	}

	@if $value ==auto {
		flex: 1 1 auto;
	}

	@if $value ==initial {
		flex: 0 1 auto;
	}

	@if $value ==none {
		flex: 0 0 auto;
	}

	@if not is-valid-keywords($value, 1 auto initial none) {
		flex: $value;
	}
}

// @include flex(auto); // CSS 컴파일 결과 → flex: 1 1 auto

// https://yamoo9.gitbook.io/scss-mixins/mixins/box-alignment

@mixin reset-link() {
	text-decoration: none;
	color: inherit;
}

// @include reset-link;

@mixin reset-dl() {
	@include my(0);

	dd {
		margin-left: 0;
	}
}

// @include reset-dl;

@mixin reset-button() {
	user-select: none;
	cursor: pointer;
}

// @include reset-button;

@mixin ul-bullet {
	li {
		list-style: none;
		margin-left: 0;
		position: relative;
		padding-left: var(--spacing-4);
	}

	li:before {
		content: '';
		//background: url(../../../../../etc/designs/wcms/images/icons.svg#half-circle-view);
		background: var(--color__brand-primary);
		background-size: cover;
		position: absolute;
		width: 6px;
		height: 12px;
		border-bottom-right-radius: 90px;
		border-top-right-radius: 90px;
		top: 4px;
		left: 0;
	}
}
