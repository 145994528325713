@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	//#{spacing(0)}
}

.asteria-component__guide {
	img {
		max-width: 100%;
		width: 100%;
	}
	.asteria-component__text {
		&.asteria-component__steps__counter {
			--text__margin: #{spacing(1)} #{spacing(0)} #{spacing(0)} #{spacing(
					0
				)};
			text-align: center;
		}
	}
}
