@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__progress {
	&--variant-stepper {
		@apply bg-transparent;

		.asteria-component__progress__steps {
			@apply flex
			  h-full
			  flex-1;

			.asteria-component__progress__status {
				@apply w-full
          rounded-none;
			}
		}
	}
}
