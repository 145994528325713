.asteria-component__wrapper-content.asteria-content-view.asteria-content-view-cashflow {
	.asteria-company-overview {
		.asteria-component__page-header {
			@apply flex flex-col gap-0;

			.asteria-component__page-header__title {
				.asteria-component__typography {
					@apply text-title-xs;
				}
			}

			.asteria-component__page-header__postfix {
				@apply grid w-full;

				--grid-columns: 1fr;

				&.asteria--state-has-notifications.asteria--state-has-tour {
					--grid-columns: 1fr auto auto;
				}

				&.asteria--state-has-notifications,
				&.asteria--state-has-tour {
					--grid-columns: 1fr auto;
				}

				grid-template-columns: var(--grid-columns);

				.asteria-component__text-amount {
					@apply inline-flex items-center gap-2 text-left;

					.asteria-component__typography {
						@apply justify-start text-title-sm;
					}
				}
			}
		}
	}
}
