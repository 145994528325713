@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__form-v2__input-wrapper {
	@apply grid;

	grid-template-areas:
		'input-help'
		'input-inner'
		'input-error';

	grid-template-columns: 1fr;

	grid-template-rows:
		minmax(min-content, max-content)
		minmax(min-content, max-content)
		minmax(min-content, max-content);

	.asteria-component__form-v2__input-wrapper__element {
		@apply flex h-full items-center justify-center overflow-hidden;

		&.asteria--type-prefix {
			grid-area: input-prefix;
		}

		&.asteria--type-postfix {
			grid-area: input-postfix;
		}

		.asteria-component__button {
			@apply rounded-none;
		}
	}

	.asteria-component__form-v2__input-wrapper__help {
		grid-area: input-help;
	}

	.asteria-component__form-v2__input-wrapper__inner {
		@apply grid items-center;

		grid-template-areas: 'input-prefix input-input input-postfix';
		grid-template-columns:
			minmax(min-content, max-content)
			1fr
			minmax(min-content, max-content);

		grid-area: input-inner;
	}

	.asteria-component__form-v2__input-wrapper__input {
		@apply h-full;

		grid-area: input-input;

		&.asteria--has-visible {
			@apply relative;

			&::after {
				@apply pointer-events-none absolute inset-0 box-border block h-full w-full p-2 font-body text-base opacity-100;
				content: attr(data-visible);
			}
		}
	}

	.asteria-component__form-v2__error {
		grid-area: input-error;
	}
}
