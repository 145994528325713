@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@import './styles/colors.scss';

.asteria-component__feedback {
	&.asteria-component__wrapper {
		@apply border border-solid;

		> .asteria-component__wrapper-header {
			@apply pb-0;

			> .asteria-component__wrapper-header__content {
				@apply text-center;

				> .asteria-component__typography {
					@apply flex-1;
				}
			}
		}

		> .asteria-component__wrapper-content {
			@apply text-center;

			> .asteria-component__wrapper__button-close {
				@apply absolute top-0 right-0;
			}
		}
	}
}

.asteria-component__feedback__rating {
	.asteria-component__feedback__rating-values {
		@apply gap-0;

		.asteria-component__button {
			@apply w-10;

			.asteria-component__icon {
			}

			&:hover {
				transform: scale(1.2);
			}
		}
	}
}

.asteria-component__feedback__modal {
	&.asteria-component__feedback__modal--form {
		.asteria-component__wrapper-content {
			.asteria-component__feedback-modal-feedback{
			background-color: var(--color-feedback-modal-feedback);
		}
		
		.asteria-component__feedback-modal-content{
				background-color: var(--color-feedback-modal-content);
			}

			.asteria-component__typography {
				.asteria-component__feedback__contact {
					@apply w-auto p-0 h-auto;
				}
			}
		}
	}
}
