@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__splash {
		&.asteria--variant-whats-new {
			@apply mb-8 cursor-pointer border-0 border-solid py-4;
			@apply border-splash-normal-border bg-splash-normal-background;
			@apply flex-row;

			.asteria-component__button {
				@apply h-auto px-2 py-0;
				&.asteria-component__button--icon-close {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-icon-normal;
						}
					}
				}
			}

			> .asteria-component__splash__inner {
				@apply flex flex-grow flex-row;
			}
		}
	}
}

.asteria-component__indicator {
	@apply mr-2 inline-flex h-2.5 w-2.5 rounded-full bg-badge-normal-background;
	animation: news-pulse 2s infinite;
}

/* News indicator pulse animation */
@keyframes news-pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 6px rgba(239, 68, 68, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(239, 68, 68, 0);
	}
}

.news-indicator-pulse {
	animation: news-pulse 2s infinite;
}
