@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__datepicker__content {
	.asteria-component__datepicker__content__slider__wrapper {
		&.asteria-component__datepicker__content__slider__wrapper--split {
			@apply grid;
			grid-template-columns: repeat(2, 1fr);
		}
	}

	.asteria-component__datepicker__content__slider {
		@apply flex items-center justify-between;

		span {
			@apply flex overflow-hidden;
			.asteria-component__text {
			}
		}

		@apply bg-datepicker-header-pagination-normal-background;

		.asteria-component__typography {
			@apply text-datepicker-header-pagination-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-datepicker-header-pagination-normal-icon;
			}
		}
	}
}
