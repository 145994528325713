@mixin vertical-center {
	@apply flex items-center;
}

@mixin horizontal-center {
	@apply flex justify-center;
}

@mixin vertical-align {
	@apply relative top-1/2;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin stacked {
	@apply flex flex-col;
}

@mixin stacked {
	@apply flex flex-row;
}

// why 2 stacked?

@mixin column-reverse {
	@apply flex-col-reverse;
}

@mixin row-reverse {
	@apply flex-row-reverse;
}

@mixin space-between {
	@apply justify-between;
}

@mixin ghostVerticalAlign() {
	&:before {
		@apply inline-block h-full align-middle;

		content: '';
		width: 0.1px;
	}
}
