@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-form-radio-normal-background: #ffffff;
	--color-form-radio-normal-border: var(--color-form-input-normal-border);
	--color-form-radio-normal-icon: rgba(255, 255, 255, 0);

	--color-form-radio-hover-background: #ffffff;
	--color-form-radio-hover-border: var(--color-form-input-hover-border);
	--color-form-radio-hover-icon: rgba(255, 255, 255, 0);

	--color-form-radio-focus-background: var(--color-primary-focus);
	--color-form-radio-focus-border: var(--color-primary-focus);
	--color-form-radio-focus-icon: #ffffff;

	--color-form-radio-normal-text: var(--color-form-label-normal-text);
}
