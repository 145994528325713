@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__tooltip {
	@apply z-50 hidden rounded-md border border-solid border-tooltip-normal-border bg-tooltip-normal-background px-4 py-3;
	max-width: theme('spacing.96');

	filter: drop-shadow(0 0px 8px rgb(0 0 0 / 0.04))
		drop-shadow(0 0px 3px rgb(0 0 0 / 0.1));

	.asteria-component__icon {
		.asteria-component__icon-content {
			@apply bg-tooltip-normal-icon;
		}
	}

	&:not(.asteria--type-custom) {
		.asteria-component__typography {
			@apply m-0 h-auto p-0 text-sm;
		}
	}

	.asteria-component__title {
		@apply text-tooltip-normal-title;
	}

	.asteria-component__text {
		@apply text-tooltip-normal-text;
	}

	&.asteria-state-active {
		@apply block;
	}

	&.asteria-component__tooltip--variant-alt {
		@apply border-tooltip-alt-border bg-tooltip-alt-background p-2;

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-tooltip-alt-icon;
			}
		}

		.asteria-component__title {
			@apply text-tooltip-alt-title;
		}

		.asteria-component__text {
			@apply text-tooltip-alt-text;
		}

		.asteria-component__tooltip__arrow {
			@apply hidden;
		}
	}
}

/* --- Tooltip Arrow */

.asteria-component__tooltip__arrow,
.asteria-component__tooltip__arrow::before {
	@apply absolute h-2 w-2 bg-inherit;
}

.asteria-component__tooltip__arrow {
	@apply invisible;
}

.asteria-component__tooltip__arrow::before {
	@apply visible content-[''];
	transform: rotate(45deg);
}

.asteria-component__tooltip-anchor[data-popper-placement^='top'] {
	.asteria-component__tooltip__arrow {
		@apply -bottom-1;
	}
}

.asteria-component__tooltip-anchor[data-popper-placement^='bottom'] {
	.asteria-component__tooltip__arrow {
		@apply -top-1;
	}
}

.asteria-component__tooltip-anchor[data-popper-placement^='left'] {
	.asteria-component__tooltip__arrow {
		@apply -right-1;
	}
}

.asteria-component__tooltip-anchor[data-popper-placement^='right'] {
	.asteria-component__tooltip__arrow {
		@apply -left-1;
	}
}

/* --- Animations --- */

.my-node-enter {
	@apply opacity-0;
}

.my-node-enter-active {
	@apply opacity-100 transition-opacity;
}

.my-node-appear {
	@apply opacity-0;
}

.my-node-appear-active {
	@apply opacity-100 transition-opacity;
}

.my-node-exit {
	@apply opacity-100;
}

.my-node-exit-active {
	@apply opacity-0 transition-opacity;
}
