@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-view__statement {
	@apply grid h-full gap-4;

	grid-template-rows: auto 1fr;

	> .asteria-component__wrapper {
		> .asteria-component__wrapper-header {
			@apply bg-transparent;
		}

		> .asteria-component__wrapper-content,
		> .asteria-component__wrapper-footer {
			@apply px-0;
		}
	}
}
