@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@use './styles/colors.scss' as *;

#{$global} {
	--input-autocomplete-rounding: theme('borderRadius.lg');
}

.asteria-component__form-v2__input {
	--input-autocomplete-size: calc(theme('spacing.10') - 2px);

	input {
		@apply m-0 box-border h-full w-full appearance-none border-0 bg-transparent p-2 font-body text-base outline-0;

		&::placeholder {
			@apply font-body text-base opacity-100;
		}

		&:autofill,
		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus {
			border-radius: var(--input-autocomplete-rounding);
			height: var(--input-autocomplete-size);
			max-height: var(--input-autocomplete-size);
			min-height: var(--input-autocomplete-size);
		}
	}

	/* State */

	.asteria-component__form-v2__input-wrapper__inner {
		@apply box-border rounded-lg border border-solid;
	}

	&.asteria--state-disabled {
		@apply pointer-events-none;
	}

	/* Size */

	.asteria-component__form-v2__input-wrapper__inner {
		@apply h-10;

		.asteria-component__form-v2__input-wrapper__element {
			min-width: theme('spacing.10');
		}
	}

	&.asteria--size-sm {
		--input-autocomplete-size: calc(theme('spacing.8') - 2px);

		input {
			@apply text-xs;

			&::placeholder {
				@apply text-xs;
			}

			&:autofill {
				@apply text-sm;
			}
		}

		.asteria-component__form-v2__input-wrapper__inner {
			@apply h-8;

			.asteria-component__form-v2__input-wrapper__element {
				min-width: theme('spacing.8');
			}
		}
	}

	&.asteria--size-lg {
		--input-autocomplete-size: calc(theme('spacing.12') - 2px);

		input {
			@apply p-3 text-base;

			&::placeholder {
				@apply text-base;
			}

			&:autofill {
				@apply text-base;
			}
		}

		.asteria-component__form-v2__input-wrapper__inner {
			@apply h-12;

			.asteria-component__form-v2__input-wrapper__element {
				min-width: theme('spacing.12');
			}
		}
	}
}
