@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__select {
	.asteria-component__typography {
		@apply text-form-select-normal-text;
	}

	.asteria-component__form__label {
		.asteria-component__typography {
			@apply text-form-label-normal-text;
		}
	}

	.asteria-component__select__inner {
		.asteria-component__select--dropdown {
			> .asteria-component__button {
				@apply border-form-select-normal-border bg-form-select-normal-background;
				@apply text-form-select-normal-text;

				.asteria-component__typography {
					@apply text-form-select-normal-text;
				}
			}
		}
	}

	&:hover {
		.asteria-component__form__label {
			@apply border-form-label-hover-border bg-form-label-hover-background;

			.asteria-component__typography {
				@apply text-form-label-hover-text;
			}
		}

		.asteria-component__typography {
			@apply text-form-select-hover-text;
		}

		.asteria-component__select__inner {
			.asteria-component__select--dropdown {
				> .asteria-component__button {
					@apply border-form-select-hover-border bg-form-select-hover-background;
					@apply text-form-select-hover-text;
				}
			}
		}
	}

	&.asteria--state-active {
		.asteria-component__typography {
			@apply text-form-select-focus-text;
		}

		.asteria-component__select__inner {
			.asteria-component__select--dropdown {
				> .asteria-component__button {
					@apply border-form-select-focus-border bg-form-select-focus-background;
					@apply text-form-select-focus-text;

					.asteria-component__typography {
						@apply text-form-select-focus-text;
					}
				}
			}
		}
	}

	&.asteria--status-info {
		.asteria-component__typography {
			@apply text-alert-info-normal-text;
		}

		.asteria-component__select__inner {
			.asteria-component__select--dropdown {
				> .asteria-component__button {
					@apply border-alert-info-normal-border text-alert-info-normal-text;

					.asteria-component__typography {
						@apply text-alert-info-normal-text;
					}
				}
			}
		}
	}

	&.asteria--status-success {
		.asteria-component__typography {
			@apply text-alert-success-normal-text;
		}

		.asteria-component__select__inner {
			.asteria-component__select--dropdown {
				> .asteria-component__button {
					@apply border-alert-success-normal-border text-alert-success-normal-text;

					.asteria-component__typography {
						@apply text-alert-success-normal-text;
					}
				}
			}
		}
	}

	&.asteria--status-warning {
		.asteria-component__typography {
			@apply text-alert-warning-normal-text;
		}

		.asteria-component__select__inner {
			.asteria-component__select--dropdown {
				> .asteria-component__button {
					@apply border-alert-warning-normal-border text-alert-warning-normal-text;

					.asteria-component__typography {
						@apply text-alert-warning-normal-text;
					}
				}
			}
		}
	}

	&.asteria--status-error {
		.asteria-component__typography {
			@apply text-alert-error-normal-text;
		}

		.asteria-component__select__inner {
			.asteria-component__select--dropdown {
				> .asteria-component__button {
					@apply border-alert-error-normal-border text-alert-error-normal-text;

					.asteria-component__typography {
						@apply text-alert-error-normal-text;
					}
				}
			}
		}
	}

	&.asteria--state-disabled {
		.asteria-component__typography {
			@apply text-form-select-disabled-text;
		}

		.asteria-component__select__inner {
			.asteria-component__select--dropdown {
				> .asteria-component__button {
					@apply border-form-select-disabled-border bg-form-select-disabled-background;
					@apply text-form-select-disabled-text;
				}

				.asteria-component__typography {
					@apply text-form-select-disabled-text;
				}
			}
		}
	}

	&:not(.asteria-component__select--variant-probability):not(.asteria-component__forecaster-selector) {
		.asteria-component__form__label {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-label-normal-icon;
				}
			}
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-form-select-normal-icon;
			}
		}

		.asteria-component__select__inner {
			.asteria-component__select--dropdown {
				> .asteria-component__button {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-form-select-normal-icon;
						}
					}
				}
			}
		}

		&:hover {
			.asteria-component__form__label {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-form-label-hover-icon;
					}
				}
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-select-hover-icon;
				}
			}
		}

		&.asteria--state-active {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-select-focus-icon;
				}
			}

			.asteria-component__select__inner {
				.asteria-component__select--dropdown {
					> .asteria-component__button {
						.asteria-component__icon {
							.asteria-component__icon-content {
								@apply bg-form-select-focus-icon;
							}
						}
					}
				}
			}
		}

		&.asteria--status-info {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-info-normal-text;
				}
			}

			.asteria-component__select__inner {
				.asteria-component__select--dropdown {
					> .asteria-component__button {
						.asteria-component__icon {
							.asteria-component__icon-content {
								@apply bg-alert-info-normal-text;
							}
						}
					}
				}
			}
		}

		&.asteria--status-success {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-success-normal-text;
				}
			}

			.asteria-component__select__inner {
				.asteria-component__select--dropdown {
					> .asteria-component__button {
						.asteria-component__icon {
							.asteria-component__icon-content {
								@apply bg-alert-success-normal-text;
							}
						}
					}
				}
			}
		}

		&.asteria--status-warning {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-warning-normal-text;
				}
			}

			.asteria-component__select__inner {
				.asteria-component__select--dropdown {
					> .asteria-component__button {
						.asteria-component__icon {
							.asteria-component__icon-content {
								@apply bg-alert-warning-normal-text;
							}
						}
					}
				}
			}
		}

		&.asteria--status-error {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-error-normal-text;
				}
			}

			.asteria-component__select__inner {
				.asteria-component__select--dropdown {
					> .asteria-component__button {
						.asteria-component__icon {
							.asteria-component__icon-content {
								@apply bg-alert-error-normal-text;
							}
						}
					}
				}
			}
		}

		&.asteria--state-disabled {
			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-select-disabled-icon;
				}
			}
		}
	}
}
