@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--integration-dialog-max-width: 400px;
}

.asteria-component__onboarding-loading-dialog {
	max-width: var(--integration-dialog-max-width);

	&.asteria-component__dialog--placement-bottom-right {
		@apply right-24 bottom-8;
	}

	&.asteria-component__dialog--placement-bottom {
		@apply bottom-6;
	}

	.asteria-component__wrapper {
		@apply p-0;

		> .asteria-component__wrapper-header {
			@apply pb-0 pt-4 pl-4 pr-6;

			&.asteria-component__wrapper-header--absolute-postfix {
				.asteria-component__wrapper-header__postfix--position-absolute {
					@apply top-1 right-1;
				}
			}
		}

		> .asteria-component__wrapper-content {
			@apply p-4 pt-2;

			.asteria-component__group {
				@apply gap-4;
			}
		}
	}

	.asteria-component__onboarding-dialog__section {
		&.asteria--type-progress {
			--progress__height: 32px;
			--progress__width: 32px;

			.asteria-component__progress.asteria-component__progress--variant-circle {
				--progress__circlethickness: 8px;
				--progress__value__width: calc(
					var(--progress__width) - var(--progress__circlethickness)
				);
				--progress__value__height: calc(
					var(--progress__height) - var(--progress__circlethickness)
				);
			}
		}

		&.asteria--type-content {
			@apply flex flex-1 flex-col gap-1;
		}
	}
}
