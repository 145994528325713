@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__forecaster-graph {
	&.asteria--variant-lines {
		.asteria-component__forecaster-graph-column {
			@apply relative;

			* {
				@apply transition-opacity;
			}

			.asteria-component__forecaster-graph-column-content {
				@apply h-full w-full;
			}

			svg.asteria-component__forecaster-graph-column-content {
				@apply absolute inset-0;

				line {
					vector-effect: non-scaling-stroke;

					&.asteria--type-history {
						@apply stroke-graph-line-normal-background;
						stroke-width: 2px;
					}

					&.asteria--type-future {
						@apply stroke-graph-line-normal-background;
						stroke-width: 2px;
						stroke-dasharray: 8px;
					}
				}
			}

			div.asteria-component__forecaster-graph-column-content {
				@apply relative z-10 overflow-visible;

				.asteria-component__graph-dot {
					@apply relative grow basis-full;

					&.asteria--state-has-label {
						top: calc(var(--top) - 7px);

						&.asteria--state-close-to-bottom {
							top: calc(var(--top) - 36px - 7px);

							.asteria-component__graph-dot-wrapper {
								@apply flex-col-reverse;
							}
						}
					}

					&:not(.asteria--state-has-label) {
						@apply -translate-y-1/2;

						top: calc(var(--top));
					}

					.asteria-component__graph-dot-wrapper {
						@apply flex flex-col;
					}

					.asteria-component__graph-dot-shape {
						@apply my-0 mx-auto box-content aspect-square cursor-pointer rounded-full border-2 border-solid bg-white;
						@include apply-colors('graph-dot');

						height: 10px;
						width: 10px;
					}

					&.asteria--state-current {
						.asteria-component__graph-dot-shape {
							@apply bg-graph-dot-today-normal-background;
						}
					}

					.asteria-component__graph-dot-label {
						@apply text-center;
					}
				}
			}
		}
	}
}
