@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__wrapper {
	&.asteria-component__card {
		&.asteria--variant-forecast {
			.asteria-component__card-section {
				&.asteria--variant-total {
					.asteria-component__button {
						&.asteria-component__button--variant-link {
							@apply px-0;
						}

						&.asteria-component__button--icon-only {
							@apply h-auto;
						}
					}

					.asteria-component__input {
						@apply overflow-hidden;

						> .asteria-component__label__wrapper {
							@apply flex-col-reverse items-start;
						}

						input {
							@apply w-full;
						}
					}
				}

				&.asteria--variant-details {
					@apply grid items-center gap-2;
					grid-template-columns: auto 1fr auto auto;

					> .asteria-component__typography,
					> .asteria-component__button {
						@apply col-span-full;
					}

					.asteria-component__card-section-row {
						@apply contents;

						> .asteria-component__button {
							&.asteria-component__button--variant-link {
								@apply px-0;
							}
						}
					}
					.asteria-component__input {
						@apply overflow-hidden;

						> .asteria-component__label__wrapper {
							@apply flex-col-reverse gap-0;
						}

						input {
							@apply w-full;
						}
					}

					.asteria-component__card-section-actions {
						.asteria-component__button {
							@apply h-auto w-auto p-0;
						}
					}

					.asteria-component__select {
						.asteria-component__select-error:empty {
							@apply hidden;
						}
					}
				}
			}
		}
	}
}

.asteria-component__card-selector {
	&.asteria--type-category {
		.asteria-component__dropdown__item {
			&.asteria--variant-create {
				.asteria-component__button {
					@apply w-full gap-2;

					> .asteria-component__button {
						@apply h-auto w-auto;
					}
				}
			}
		}

		.asteria-component__dropdown__item__postfix {
			.asteria--state-active.asteria--state-custom {
				&:not(:hover) {
					.asteria-component__button {
						&.asteria--type-edit {
							@apply hidden;
						}
					}
				}

				&:hover {
					.asteria-component__button {
						&.asteria--type-check {
							@apply hidden;
						}
					}
				}
			}
		}
	}
}
