@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__wrapper {
	&.asteria-component__teaser {
		@apply gap-1 border-0 border-solid p-4;
		@apply border-teaser-normal-border bg-teaser-normal-background;

		&.asteria--state-clickable {
			@apply cursor-pointer;
		}

		.asteria-component__wrapper-header,
		.asteria-component__wrapper-content {
			@apply bg-transparent p-0;
		}

		.asteria-component__button {
			&.asteria-component__button--variant-link {
				@apply gap-3 px-0;

				> .asteria-component__button {
					@apply h-auto w-auto;
				}
			}
		}

		.asteria-component__contenter__inner {
			@apply gap-2;
		}

		.asteria-component__typography-group {
			@apply flex flex-col gap-1;
		}
	}
}
