@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__flex-line {
	// transition: all 2s;
	@apply flex w-full flex-nowrap gap-0;
	flex: var(--size) 1;

	--child-size: var(--size);

	&:not(:has(.asteria-component__flex-item-wrapper)),
	&:empty {
		@apply hidden;
	}

	&.asteria--direction-horizontal {
		@apply flex-row;
	}

	&.asteria--direction-vertical {
		@apply flex-col;
	}
}
