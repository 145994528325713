@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__financial-page {
	&.asteria--variant-home {
		.asteria-component__splash {
			--color-splash-normal-border: transparent;
			--color-splash-normal-background: #eee;

			--color-icon-normal: var(--color-icon-hover);

			.asteria-component__splash-header {
				@apply pb-0;
			}
		}

		.asteria-component__teaser {
			@apply grid gap-0;

			grid-template-areas: 'teaser-title teaser-action' 'teaser-content teaser-action';
			grid-template-columns: minmax(0, 1fr) auto;

			column-gap: theme('spacing.4');
			row-gap: theme('spacing.1');

			&:hover {
				background-color: var(--color-button-icon-hover-background);
				border-color: var(--color-button-icon-hover-border);

				--color-actionbar-header-normal-title: black;
				--color-text-normal: black;
			}

			> .asteria-component__teaser-header {
				grid-area: teaser-title;
			}

			> .asteria-component__teaser-content {
				&,
				> .asteria-component__contenter__inner {
					@apply contents;
				}

				> .asteria-component__contenter__inner {
					> .asteria-component__typography {
						grid-area: teaser-content;
					}

					> div:not([class]) {
						@apply contents;
					}
				}
			}

			.asteria-component__button:not(.asteria-component__button--icon-only) {
				@apply h-10 w-10 items-center justify-center gap-0 rounded-full p-0;

				grid-area: teaser-action;

				@include asteria-btn-primary;

				.asteria-component__typography {
					@apply hidden;
				}

				> .asteria-component__button {
					@apply h-full w-full;

					@include asteria-btn-md;
				}
			}
		}

		.asteria-component__financial-simulation-list {
			.asteria-component__list__cell {
				&.asteria--variant-actions {
					.asteria-component__dropdown {
						--color-button-icon-normal-background: transparent;
						--color-button-icon-normal-border: transparent;

						--color-button-icon-hover-background: transparent;
						--color-button-icon-hover-border: transparent;

						--color-button-icon-focus-background: transparent;
						--color-button-icon-focus-border: transparent;

						> .asteria-component__button {
							@include asteria-btn-sm;
						}
					}
				}
			}

			.asteria-component__wrapper-footer {
				@apply px-2;

				.asteria-component__button {
					&.asteria-component__button--variant-tertiary {
						@include asteria-btn-link;
						@include asteria-btn-sm(false, true, true, md);
					}
				}
			}
		}
	}

	&.asteria--variant-loan {
		.asteria-component__financial-page-section {
			&.asteria--variant-aside {
				.asteria-component__chip {
					@include asteria-btn-tab('sm');
				}

				.asteria-component__input {
					.asteria-component__input__inner {
						input {
							@apply w-full;
						}
					}
				}
			}
		}

		.asteria-component__financial-page-section {
			&.asteria--variant-transactions {
				.asteria-component__button {
					&.asteria-component__button--variant-tertiary {
						@include asteria-btn-link;
						@include asteria-btn-sm(false, true, true, md);
					}
				}
			}
		}
	}

	&.asteria--variant-credit {
		.asteria-component__financial-page-section {
			&.asteria--variant-footer {
			}
		}
	}

	&.asteria--variant-credit,
	&.asteria--variant-loan {
		.asteria-component__financial-page-section {
			&.asteria--variant-header {
				.asteria-component__chip {
					@include asteria-btn-tab;
				}

				.asteria-component__button {
					&.asteria-component__button--variant-link {
						@include asteria-btn-sm(false, true, true, md);
					}
				}

				.asteria-component__financial-page-navigation {
					> .asteria-component__financial-page-navigation-back {
						@apply gap-2;

						.asteria-component__button {
							@include asteria-btn-md;
							@include asteria-btn-rounded;
							@apply h-10 w-10;
						}
					}
				}
			}
		}
	}
}

.asteria-component__financial-loan-graph-wrapper {
	.asteria-component__graph-lines {
		.asteria-component__graph-line {
			@apply border-b;
		}
	}
}

.asteria-component__select--dropdown {
	&.asteria--type-loan {
		.asteria-component__dropdown__item {
			.asteria-component__dropdown__item__postfix {
				@apply block;
			}
		}
	}
}
