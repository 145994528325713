@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@import './icons.scss';

#{$global} {
	--variable: ;
}

.asteria-component__icon {
	@apply m-0 box-border flex aspect-square h-4 w-4 items-center justify-center p-0;

	scale: 1;

	.asteria-component__icon-content {
		@apply h-full w-full items-center justify-center;
		@apply bg-icon-normal;

		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: contain;
		mask-image: var(--icon);

		&:hover {
			@apply bg-icon-hover;
		}

		&.asteria--state-active {
			@apply bg-icon-focus;
		}

		&.asteria--state-disabled {
			@apply bg-icon-disabled;
		}
	}

	&.asteria--size-xlarge,
	&.asteria--size-x-large,
	&.asteria--size-xl {
		&,
		.asteria-component__icon-content {
			@apply h-8 w-8;
		}
	}

	&.asteria--size-large,
	&.asteria--size-lg {
		&,
		.asteria-component__icon-content {
			@apply h-6 w-6;
		}
	}

	&.asteria--size-medium,
	&.asteria--size-md,
	&.asteria--size-default {
		&,
		.asteria-component__icon-content {
			@apply h-4 w-4;
		}
	}

	&.asteria--size-small,
	&.asteria--size-sm {
		&,
		.asteria-component__icon-content {
			@apply h-3 w-3;
		}
	}

	&.asteria--size-xsmall,
	&.asteria--size-x-small,
	&.asteria--size-xs {
		&,
		.asteria-component__icon-content {
			@apply h-2 w-2;
		}
	}

	&.asteria-component__icon--action-arrow-left,
	&.asteria-component__icon--action-arrow-right {
		@apply h-auto;

		aspect-ratio: 24/14;
	}
}
