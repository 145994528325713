@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__modal {
	&.asteria--type-scenario {
		.asteria-component__typography-group {
			@apply flex flex-col gap-2;

			.asteria-component__unordered {
				@apply flex flex-col gap-2;
			}
		}
	}
}
