@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--color-teaser-graph-forecaster-normal-background: rgba(255, 255, 255, 0.9);
}

.asteria-component__wrapper {
	&.asteria-component__graph__teaser--forecaster {
		@apply box-border rounded border border-solid border-border-normal bg-transparent;

		width: 300px;
		background: var(--color-teaser-graph-forecaster-normal-background);

		.asteria-component__wrapper-header,
		.asteria-component__wrapper-content,
		.asteria-component__wrapper-footer,
		.asteria-component__typography {
			@apply bg-transparent;
		}

		.asteria-component__wrapper-header {
			@apply pb-0;

			.asteria-component__wrapper-header__content {
				@apply justify-center;
			}

			.asteria-component__typography {
				@apply text-center;
			}
		}

		.asteria-component__wrapper-content {
			.asteria-component__typography {
				@apply text-center;
			}
		}

		.asteria-component__wrapper-footer {
			@apply flex items-center justify-center pt-0;
		}
	}
}
