@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__datepicker__content {
	.asteria-component__datepicker__content__month {
		@apply flex w-full flex-col overflow-hidden;

		.asteria-component__datepicker__content__month__calendar {
			&:not(.asteria-component__datepicker__content__month__calendar--behavior-scroll) {
				grid-template-columns: repeat(4, 1fr);
			}

			&.asteria-component__datepicker__content__month__calendar--behavior-scroll {
				@apply block;
			}

			.asteria-component__datepicker__content__month__calendar__values {
				&.asteria-component__datepicker__content__month__calendar__values--behavior-scroll {
					@apply grid place-items-center;
					grid-template-columns: repeat(4, 1fr);
				}
			}
		}

		.asteria-component__datepicker__content__month__header {
			@apply flex items-center justify-center text-center;
		}
	}
}
