@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-graph-xaxis {
	@apply relative flex flex-col items-center justify-end;
	@include apply-colors('graph-meta');
	padding-bottom: var(--graph-bar-indicator-size, 8px);

	&:not(.asteria--state-clickable) {
		@apply cursor-default;
	}

	&.asteria--state-clickable {
		@apply cursor-pointer;
	}

	@apply pointer-events-none;

	.asteria-graph-x-axis-label-text {
		@apply pointer-events-auto;
	}

	&.asteria-graph-xaxis-no-children-hover,
	&.asteria-graph-xaxis-no-children-active {
		.asteria-indicator__wrapper {
			@apply flex;
		}
	}

	&.asteria-graph-xaxis-history {
		.asteria-graph-x-axis-label-text {
			@apply text-graph-group-historical-meta-normal-text;
		}

		.asteria-time-divider {
			@apply text-graph-group-historical-meta-normal-prefix;
		}

		&.asteria--state-hoverable:hover {
			.asteria-graph-x-axis-label-text {
				@apply text-graph-group-historical-meta-hover-text;
			}

			.asteria-time-divider {
				@apply text-graph-group-historical-meta-hover-prefix;
			}
		}

		&:focus {
			.asteria-graph-x-axis-label-text {
				@apply text-graph-group-historical-meta-focus-text;
			}

			.asteria-time-divider {
				@apply text-graph-group-historical-meta-focus-prefix;
			}
		}

		@include apply-colors('graph-group-historical-meta');
	}

	&.asteria-graph-xaxis-today {
		.asteria-graph-x-axis-label-text {
			@apply font-bold text-graph-group-today-meta-normal-text;
		}

		.asteria-time-divider {
			@apply text-graph-group-today-meta-normal-prefix;
		}

		&.asteria--state-hoverable:hover {
			.asteria-graph-x-axis-label-text {
				@apply text-graph-group-today-meta-hover-text;
			}

			.asteria-time-divider {
				@apply text-graph-group-today-meta-hover-prefix;
			}
		}

		&:focus {
			.asteria-graph-x-axis-label-text {
				@apply text-graph-group-today-meta-focus-text;
			}

			.asteria-time-divider {
				@apply text-graph-group-today-meta-focus-prefix;
			}
		}

		@include apply-colors('graph-group-today-meta');
	}

	&.asteria-graph-xaxis-forecast {
		.asteria-graph-x-axis-label-text {
			@apply text-graph-group-forecast-meta-normal-text;
		}

		.asteria-time-divider {
			@apply text-graph-group-forecast-meta-normal-prefix;
		}

		&:hover {
			.asteria-graph-x-axis-label-text {
				@apply text-graph-group-forecast-meta-hover-text;
			}

			.asteria-time-divider {
				@apply text-graph-group-forecast-meta-hover-prefix;
			}
		}

		&:focus {
			.asteria-graph-x-axis-label-text {
				@apply text-graph-group-forecast-meta-focus-text;
			}

			&:focus {
				@apply text-graph-group-forecast-meta-focus-prefix;
			}
		}

		@include apply-colors('graph-group-forecast-meta');
	}

	.asteria-indicator__wrapper {
		bottom: 0px;
		top: auto;
		transform: translateX(-50%);
	}
}

.asteria-graph-area-stacked {
	.asteria-graph-xaxis {
		&.asteria-state-hover {
			.asteria-indicator__wrapper {
				@apply flex;
			}
		}
	}

	.asteria-component__old__graph__bar {
		.asteria-indicator__wrapper {
			@apply hidden;
		}
	}
}
