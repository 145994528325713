@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__wrapper {
	&.asteria-component__wrapper--variant-vertical {
		@apply flex;
	}

	&.asteria-component__wrapper--variant-horizontal {
		@apply grid;

		grid-template: 'wrapper-header wrapper-header' 'wrapper-content wrapper-footer';
		grid-template-rows: auto 1fr;

		&.asteria-component__wrapper--no-header {
			grid-template: 'wrapper-content wrapper-footer';
			grid-template-rows: 1fr auto;

			&.asteria-component__wrapper--no-content {
				grid-template: 'wrapper-footer';
				grid-template-rows: auto;
			}

			&.asteria-component__wrapper--no-footer {
				grid-template: 'wrapper-content';
				grid-template-rows: auto;
			}
		}

		&.asteria-component__wrapper--no-content {
			grid-template: 'wrapper-header' 'wrapper-footer';
			grid-template-rows: auto auto;

			&.asteria-component__wrapper--no-header {
				grid-template: 'wrapper-footer';
				grid-template-rows: auto;
			}

			&.asteria-component__wrapper--no-footer {
				grid-template: 'wrapper-header';
				grid-template-rows: auto;
			}
		}

		&.asteria-component__wrapper--no-footer {
			grid-template: 'wrapper-header' 'wrapper-content';
			grid-template-rows: auto 1fr;

			&.asteria-component__wrapper--no-header {
				grid-template: 'wrapper-content';
				grid-template-rows: 1fr;
			}

			&.asteria-component__wrapper--no-content {
				grid-template: 'wrapper-header';
				grid-template-rows: auto;
			}
		}
	}
}
