@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-view__welcome__wizard {
	.asteria-component__wizard__content {
		@apply box-border gap-4 rounded-md border border-solid border-border-normal p-6;

		.asteria-component__wizard__inner {
			> .asteria-component__wizard__step {
				@include scroll();

				&:not(.asteria-view__welcome__wizard__step--type-linear) {
					height: 400px;
				}

				&.asteria-view__welcome__wizard__step--type-linear {
					@apply flex flex-col gap-10;
				}
			}
		}
	}

	.asteria-component__wrapper-footer {
		@apply px-0;
	}

	.asteria-component__button {
		@apply whitespace-normal;
	}
}
