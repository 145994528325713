@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__navigation__badge-tooltip {
	max-width: 300px;

	.asteria-component__typography {
		@apply text-center;
	}
}

.asteria-component__navigation-companies-onboarding {
	@apply flex flex-col gap-2 p-2;

	.asteria-component__button {
		&,
		> .asteria-component__typography {
			@apply truncate;
		}
	}
}

.asteria-component__navigation-companies-dropdown {
	&.asteria-component__level {
		width: 250px;

		.asteria-component__dropdown__item {
			.asteria-component__dropdown__item__label {
				@apply truncate;
			}

			&:not(.asteria--state-active) {
				.asteria-component__dropdown__item__postfix {
					@apply invisible;
				}
			}
		}
	}
}

.asteria-component__navigation {
	@include scroll();
	@apply box-border border border-solid border-navigation-wrapper-normal-border bg-navigation-wrapper-normal-background p-4;

	z-index: 41;

	.asteria-component__navigation__item__sub {
		@apply border border-solid border-navigation-item-normal-border bg-navigation-item-sub-normal-background;
	}

	.asteria-component__navigation-companies {
		order: -4;

		.asteria-component__navigation-companies-dropdown {
			> .asteria-component__button {
				@apply truncate p-0;

				> .asteria-component__button {
					@apply m-0;
				}

				.asteria-component__typography {
					@apply truncate;

					max-width: 150px;
				}
			}
		}
	}

	.asteria-component__navigation--streamline {
		order: -4;
	}

	.asteria-component__navigation--cashflow {
		order: -3;
	}

	.asteria-component__navigation--onboarding {
		order: -2;
	}

	.asteria-component__navigation--forecaster {
		order: -1;
	}

	.asteria-component__navigation--settings {
		order: 1;
	}

	.asteria-component__navigation--collapse {
		order: 2;
	}

	.asteria-component__navigation--help {
		order: 998;

		~ .asteria-component__navigation--help {
			@apply hidden;
		}
	}

	.asteria-component__navigation--logout {
		order: 999;
	}

	&.asteria-component__navigation--direction-horizontal {
		@apply relative flex flex-row items-center justify-center gap-2 overflow-visible;
		grid-area: top-navigation;

		.asteria-component__navigation--help {
			@apply ml-auto;
		}

		.asteria-wrapper--view-welcome & {
			.asteria-component__navigation--help {
				@apply ml-0;
			}

			.asteria-component__navigation--settings {
				order: 3;

				@apply ml-auto;
			}
		}

		.asteria-component__navigation__item__sub {
			@apply absolute inset-0 top-full m-0 flex h-full items-center justify-center gap-2;
		}

		.asteria-component__navigation__item {
			&.asteria-component__navigation__item__dropdown {
				@apply w-auto;
			}
		}
	}

	&.asteria-component__navigation--direction-vertical {
		@apply mr-4 flex flex-col;
		grid-area: left-navigation;

		.asteria-component__navigation--help {
			@apply mt-auto;
		}

		.asteria-wrapper--view-welcome & {
			.asteria-component__navigation--help {
				@apply mt-0;
			}

			.asteria-component__navigation--settings {
				order: 3;

				@apply mt-auto;
			}
		}

    @media (max-width: 767px) {
      // Temporary fix for mobile, overwrite 'asteria-component__navigation--direction-vertical' as its declared in the classnames, bad bad :(
      @apply
        p-4
        overflow-x-auto
        flex-row
        h-fit
        absolute
        bottom-0
        bg-white
        border-0
        border-t
        border-solid
        border-border-normal
        w-screen;

        .asteria-component__navigation-companies,
        .asteria-component__navigation__logo,
        .asteria-component__navigation--cashflow,
        .asteria-component__navigation--forecaster,
        .asteria-component__navigation--financial,
        .asteria-component__navigation--collapse {
          @apply hidden;
        }

        .asteria-component__navigation--help {
          @apply
            w-auto;
        }
    }
	}

	&.asteria-component__navigation--variant-stacked {
		@apply gap-2;
	}

	&.asteria-component__navigation--direction-vertical {
		&:not(.asteria-component__navigation--variant-stacked) {
			&.asteria-component__navigation--has-collapse {
				.asteria-component__navigation__item {
					&:not(.asteria-component__navigation__item__dropdown) {
						grid-template-areas: 'navigation-label navigation-collapse';

						grid-template-columns:
							var(--width-navigation-label)
							var(--width-navigation-collapse);

						.asteria-component__navigation__item__label {
							@apply pr-0;
						}
					}
				}
			}

			&.asteria-component__navigation--has-icon {
				.asteria-component__navigation__item {
					&:not(.asteria-component__navigation__item__dropdown) {
						grid-template-areas: 'navigation-icon navigation-label';

						grid-template-columns:
							var(--width-navigation-icon)
							var(--width-navigation-label);

						.asteria-component__navigation__item__label {
							@apply pl-0;
						}
					}
				}
			}

			&.asteria-component__navigation--has-collapse {
				&.asteria-component__navigation--has-icon {
					.asteria-component__navigation__item {
						&:not(.asteria-component__navigation__item__dropdown) {
							grid-template-areas: 'navigation-icon navigation-label navigation-collapse';

							grid-template-columns:
								var(--width-navigation-icon)
								var(--width-navigation-label)
								var(--width-navigation-collapse);
						}
					}
				}
			}
		}

		&.asteria-component__navigation--variant-stacked {
			.asteria-component__navigation__item__label
				+ .asteria-component__navigation__item__collapse {
				@apply absolute top-1 right-6;
			}

			.asteria-component__navigation__item {
				@apply relative;
			}

			&.asteria-component__navigation--has-icon {
				.asteria-component__navigation__item:not(.asteria-component__navigation__item--no-label):not(.asteria-component__navigation__item__dropdown) {
					grid-template-areas: 'navigation-icon' 'navigation-label';
					grid-template-columns: auto;
					grid-template-rows:
						var(--width-navigation-icon)
						minmax(min-content, max-content);

					&.asteria-component__navigation__item--no-label {
						grid-template-areas: 'navigation-icon';
						grid-template-rows: auto;
					}
				}
			}
		}
	}
}

/* Navigation - Logo */

.asteria-component__navigation {
	.asteria-component__navigation__logo {
		@apply order-first aspect-square h-10 w-10 bg-contain bg-center bg-no-repeat;
		min-height: theme('spacing.10');
	}

	&.asteria-component__navigation--direction-horizontal {
		.asteria-component__navigation__logo {
			@apply mr-2;
		}
	}

	&.asteria-component__navigation--direction-vertical {
		.asteria-component__navigation__logo {
			@apply mb-2;
		}
	}

	&.asteria-component__navigation--variant-stacked {
		&.asteria-component__navigation--direction-vertical {
			&.asteria-component__navigation--has-label {
				.asteria-component__navigation__logo {
					@apply mx-auto;
				}
			}
		}
	}
}
