@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__input {
	&.asteria--state-has-visible {
		.asteria-component__input__inner,
		&:hover .asteria-component__input__inner,
		&.asteria--state-active .asteria-component__input__inner {
			.asteria-component__input__wrapper {
				input {
					@apply bg-transparent text-transparent;

					&::selection {
						@apply bg-transparent text-transparent;
					}

					&::-moz-selection {
						@apply bg-transparent text-transparent;
					}
				}
			}
		}
	}

	.asteria-component__form__label {
		> .asteria-component__typography {
			@apply text-form-label-normal-text;
		}

		.asteria-component__icon {
			.asteria-component__icon-content {
				@apply bg-form-label-normal-icon;
			}
		}
	}

	.asteria-component__input__inner {
		@apply border-form-input-normal-border bg-form-input-normal-background;

		.asteria-component__typography {
			@apply text-form-input-normal-text;
		}

		input,
		textarea {
			@apply bg-transparent;
			@apply text-form-input-normal-text;

			&::placeholder {
				@apply text-form-input-normal-placeholder;
			}
		}

		.asteria-component__button {
			&.asteria-component__button--variant-input {
				.asteria-component__icon {
					.asteria-component__icon-content {
						@apply bg-form-input-normal-icon;
					}
				}
			}
		}
	}

	&:hover {
		.asteria-component__form__label {
			> .asteria-component__typography {
				@apply text-form-label-hover-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-label-hover-icon;
				}
			}
		}

		.asteria-component__input__inner {
			@apply border-form-input-hover-border bg-form-input-hover-background;

			.asteria-component__typography {
				@apply text-form-input-hover-text;
			}

			input,
			textarea {
				@apply bg-transparent;
				@apply text-form-input-hover-text;

				&::placeholder {
					@apply text-form-input-hover-placeholder;
				}
			}

			.asteria-component__button {
				&.asteria-component__button--variant-input {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-form-input-hover-icon;
						}
					}
				}
			}
		}
	}

	&.asteria--state-active {
		.asteria-component__form__label {
			> .asteria-component__typography {
				@apply text-form-label-focus-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-label-focus-icon;
				}
			}
		}

		.asteria-component__input__inner {
			@apply border-form-input-focus-border bg-form-input-focus-background;

			.asteria-component__typography {
				@apply text-form-input-focus-text;
			}

			input,
			textarea {
				@apply bg-transparent;
				@apply text-form-input-focus-text;

				&::placeholder {
					@apply text-form-input-focus-placeholder;
				}
			}

			.asteria-component__button {
				&.asteria-component__button--variant-input {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-form-input-focus-icon;
						}
					}
				}
			}
		}
	}

	&.asteria--state-disabled {
		.asteria-component__form__label {
			> .asteria-component__typography {
				@apply text-form-label-disabled-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-form-label-disabled-icon;
				}
			}
		}

		.asteria-component__input__inner {
			@apply border-form-input-disabled-border bg-form-input-disabled-background;

			.asteria-component__typography {
				@apply text-form-input-disabled-text;
			}

			input,
			textarea {
				@apply bg-transparent;
				@apply text-form-input-disabled-text;

				&::placeholder {
					@apply text-form-input-disabled-placeholder;
				}
			}

			.asteria-component__button {
				&.asteria-component__button--variant-input {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-form-input-disabled-icon;
						}
					}
				}
			}
		}
	}

	&.asteria--status-info {
		.asteria-component__form__label {
			> .asteria-component__typography {
				@apply text-alert-info-normal-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-info-normal-text;
				}
			}
		}

		.asteria-component__input__inner {
			@apply border-alert-info-normal-border;

			.asteria-component__typography {
				@apply text-alert-info-normal-text;
			}

			input,
			textarea {
				@apply text-alert-info-normal-text;

				&::placeholder {
					@apply text-alert-info-normal-text;
				}
			}

			.asteria-component__button {
				&.asteria-component__button--variant-input {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-alert-info-normal-text;
						}
					}
				}
			}
		}
	}

	&.asteria--status-success {
		.asteria-component__form__label {
			> .asteria-component__typography {
				@apply text-alert-success-normal-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-success-normal-text;
				}
			}
		}

		.asteria-component__input__inner {
			@apply border-alert-success-normal-border;

			.asteria-component__typography {
				@apply text-alert-success-normal-text;
			}

			input,
			textarea {
				@apply text-alert-success-normal-text;

				&::placeholder {
					@apply text-alert-success-normal-text;
				}
			}

			.asteria-component__button {
				&.asteria-component__button--variant-input {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-alert-success-normal-text;
						}
					}
				}
			}
		}
	}

	&.asteria--status-warning {
		.asteria-component__form__label {
			> .asteria-component__typography {
				@apply text-alert-warning-normal-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-warning-normal-text;
				}
			}
		}

		.asteria-component__input__inner {
			@apply border-alert-warning-normal-border;

			.asteria-component__typography {
				@apply text-alert-warning-normal-text;
			}

			input,
			textarea {
				@apply text-alert-warning-normal-text;

				&::placeholder {
					@apply text-alert-warning-normal-text;
				}
			}

			.asteria-component__button {
				&.asteria-component__button--variant-input {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-alert-warning-normal-text;
						}
					}
				}
			}
		}
	}

	&.asteria--status-error {
		.asteria-component__form__label {
			> .asteria-component__typography {
				@apply text-alert-error-normal-text;
			}

			.asteria-component__icon {
				.asteria-component__icon-content {
					@apply bg-alert-error-normal-text;
				}
			}
		}

		.asteria-component__input__inner {
			@apply border-alert-error-normal-border;

			.asteria-component__typography {
				@apply text-alert-error-normal-text;
			}

			input,
			textarea {
				@apply text-alert-error-normal-text;

				&::placeholder {
					@apply text-alert-error-normal-text;
				}
			}

			.asteria-component__button {
				&.asteria-component__button--variant-input {
					.asteria-component__icon {
						.asteria-component__icon-content {
							@apply bg-alert-error-normal-text;
						}
					}
				}
			}
		}
	}
}

/* --- Range --- */

.asteria-component__input {
	&.asteria-component__input--type-range {
		.asteria-component__input__inner {
			@apply bg-transparent shadow-none;
		}

		&:hover,
		&.asteria--state-active,
		&.asteria--state-disabled {
			.asteria-component__input__inner {
				@apply bg-transparent shadow-none;
			}
		}

		&:hover {
			.asteria-component__input__inner {
				@apply bg-transparent shadow-none;
			}

			&.asteria-component__input--type-range {
				.asteria-component__input__wrapper {
					input {
						background: linear-gradient(
							90deg,
							theme('colors.form.input.range.hover.primary') 0%,
							theme('colors.form.input.range.hover.primary')
								var(--value),
							theme('colors.form.input.range.hover.secondary')
								var(--value),
							theme('colors.form.input.range.hover.secondary')
						);
					}

					input::-moz-range-thumb {
						@apply bg-form-input-range-hover-thumb;
					}

					input::-webkit-slider-thumb {
						@apply bg-form-input-range-hover-thumb;
					}
				}
			}
		}

		&.asteria--state-active {
			&.asteria-component__input--type-range {
				.asteria-component__input__wrapper {
					input {
						background: linear-gradient(
							90deg,
							theme('colors.form.input.range.focus.primary') 0%,
							theme('colors.form.input.range.focus.primary')
								var(--value),
							theme('colors.form.input.range.focus.secondary')
								var(--value),
							theme('colors.form.input.range.focus.secondary')
						);
					}

					input::-moz-range-thumb {
						@apply bg-form-input-range-focus-thumb;
					}

					input::-webkit-slider-thumb {
						@apply bg-form-input-range-focus-thumb;
					}
				}
			}
		}

		&.asteria--state-disabled {
			&.asteria-component__input--type-range {
				.asteria-component__input__wrapper {
					input {
						background: linear-gradient(
							90deg,
							theme('colors.form.input.range.disabled.primary') 0%,
							theme('colors.form.input.range.disabled.primary')
								var(--value),
							theme('colors.form.input.range.disabled.secondary')
								var(--value),
							theme('colors.form.input.range.disabled.secondary')
						);
					}

					input::-moz-range-thumb {
						@apply bg-form-input-range-disabled-thumb;
					}

					input::-webkit-slider-thumb {
						@apply bg-form-input-range-disabled-thumb;
					}
				}
			}
		}

		.asteria-component__input__wrapper {
			@apply bg-transparent;

			input {
				background: linear-gradient(
					90deg,
					theme('colors.form.input.range.normal.primary') 0%,
					theme('colors.form.input.range.normal.primary') var(--value),
					theme('colors.form.input.range.normal.secondary')
						var(--value),
					theme('colors.form.input.range.normal.secondary')
				);
			}

			input::-moz-range-thumb {
				@apply bg-form-input-range-normal-thumb;
			}

			input::-webkit-slider-thumb {
				@apply bg-form-input-range-normal-thumb;
			}
		}
	}
}
