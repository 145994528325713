@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__input__search {
	&.asteria--state-filled {
		.asteria-component__input__inner {
			.asteria-component__input__wrapper {
				input {
					@apply pl-2;
				}
			}
		}
	}

	.asteria-component__slider {
		@apply order-first;
	}

	.asteria-component__input__inner {
		@apply border-form-input-search-normal-border bg-form-input-search-normal-background;

		.asteria-component__input__search__clear {
			@apply border-form-input-search-clear-normal-border bg-form-input-search-clear-normal-background;
		}
	}

	&:hover {
		.asteria-component__label__wrapper {
			.asteria-component__input__inner {
				@apply border-form-input-search-hover-border bg-form-input-search-hover-background;

				.asteria-component__input__search__clear {
					@apply border-form-input-search-clear-hover-border bg-form-input-search-clear-hover-background;
				}
			}
		}
	}

	&.asteria--state-active {
		.asteria-component__input__inner {
			@apply border-form-input-search-focus-border bg-form-input-search-focus-background;

			.asteria-component__input__search__clear {
				@apply border-form-input-search-clear-focus-border bg-form-input-search-clear-focus-background;
			}
		}
	}
}
