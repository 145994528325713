@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--variable: ;
}

.asteria-component__onboarding-box {
	.asteria-component__onboarding-box-status {
		.asteria-component__progress.asteria-component__progress--variant-circle {
			--progress__height: theme('spacing.8');
			--progress__width: theme('spacing.8');

			--progress__circlethickness: theme('spacing.2');

			--color-progress-normal-background: theme(
				'colors.secondary.normal'
			);
			--progress__value__width: calc(
				var(--progress__width) - var(--progress__circlethickness)
			);
			--progress__value__height: calc(
				var(--progress__height) - var(--progress__circlethickness)
			);
		}
	}

	&.asteria--status-connected {
		@apply border-alert-success-normal-border bg-alert-success-normal-background;

		.asteria-component__onboarding-box-status {
			.asteria-component__icon {
				> .asteria-component__icon-content {
					@apply bg-white;
				}
			}
		}
	}

	&.asteria--status-error {
		@apply border-alert-error-normal-border bg-alert-error-normal-background;

		.asteria-component__onboarding-box-status {
			.asteria-component__icon {
				> .asteria-component__icon-content {
					@apply bg-white;
				}
			}
		}
	}

	&.asteria--status-disabled {
		@apply opacity-60;
	}

	&.asteria--status-outdated,
	&.asteria--status-disabled {
		@apply border-alert-warning-normal-border bg-alert-warning-normal-background;

		.asteria-component__onboarding-box-status {
			.asteria-component__icon {
				> .asteria-component__icon-content {
					@apply bg-white;
				}
			}
		}
	}

	&.asteria--status-importing,
	&.asteria--status-initiating {
		background-color: #f5f5f5;

		.asteria-component__button {
			&.asteria-component__button--variant-link {
				@apply px-0;
			}
		}

		.asteria-component__progress--variant-circle {
			.asteria-component__progress__value {
				background-color: #f5f5f5;
			}
		}
	}

	.asteria-component__onboarding-box-header {
		.asteria-component__onboarding-box-logo {
			@apply mix-blend-multiply;
		}
	}

	.asteria-component__onboarding-box-content {
	}
}
