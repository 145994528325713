@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--background-gradient: repeating-linear-gradient(
		135deg,
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0) 1px,
		rgba(255, 255, 255, 0.2) 2px,
		rgba(255, 255, 255, 0.2) 4px,
		rgba(0, 0, 0, 0) 4.5px
	);

	--skeleton-gradient: linear-gradient(
		90deg,
		rgba(#fff, 0) 0,
		rgba(#fff, 0.2) 20%,
		rgba(#fff, 0.5) 60%,
		rgba(#fff, 0)
	);
}

.asteria-component__progress {
	--color: theme('colors.progress.normal.background');

	@apply bg-progress-indicator-normal-background
		marker:bg-progress-normal-background;

	&__status {
		background-color: var(--color, initial);
		background-image: var(--image, initial);
	}

	&--variant-circle {
		background: conic-gradient(
			var(--color, initial) 0% var(--progress__value),
			theme('colors.progress.indicator.normal.background')
				var(--progress__value) 100%
		);

		// background-color: var(--color, initial);
		// background-image: var(--image, initial);

		.asteria-component__progress__value {
			@apply bg-white;

			.asteria-component__text {
				@apply text-progress-normal-text;
			}
		}
	}

	&--variant-stepper {
		@apply bg-transparent;

		.asteria-component__progress__steps {
			.asteria-component__progress__status {
				&.asteria-component__progress__status--empty,
				&.asteria-component__progress__status--current {
					@apply bg-progress-indicator-normal-background;
				}
			}
		}
	}
}

.skeleton-box {
	@apply bg-skeleton-normal-background;

	&::after {
		background-image: var(--skeleton-gradient);
	}
}
