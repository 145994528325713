@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts/Inter/inter.css';

body {
	@apply m-0 font-sans;
	@include scroll();
}

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#root {
	height: 100%;
}

.site-id {
	position: fixed;
	// display: block;
	bottom: 4px;
	left: 4px;
	opacity: 0.2;
	z-index: 1;
	&:hover {
		opacity: 1;
	}
}

.webapp {
	height: 100%;

	.wings-main {
		display: flex;
		height: 100%;
	}
}
