@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

.asteria-component__dialog {
	@apply fixed
		w-full
		max-w-md
		rounded-lg
		border
		border-solid
		border-dialog-normal-border
		bg-dialog-normal-background
		shadow;

	z-index: var(--z-index__dialog);

	/* --- Placement --- */

	&--placement-top {
		transform: translateX(-50%);
		@apply top-4 left-1/2;

		&-left {
			@apply left-4 top-4;
		}
		&-right {
			@apply right-4 top-4;
		}
	}

	&--placement-middle {
		transform: translate(-50%, -50%);
		@apply top-1/2 left-1/2;

		&-left {
			transform: translateY(-50%);
			@apply top-1/2 left-4;
		}
		&-right {
			transform: translateY(-50%);
			@apply top-1/2 right-4;
		}
	}

	&--placement-bottom {
		transform: translateX(-50%);
		@apply bottom-4 top-auto left-1/2;

		&-left {
			@apply left-4 bottom-4 top-auto;
		}
		&-right {
			@apply right-4 bottom-4 top-auto;
		}
	}

	.asteria-component__wrapper {
		&-header {
			@apply border-dialog-header-normal-border
			bg-dialog-header-normal-background
			pb-0;

			.asteria-component__title {
				@apply text-dialog-header-normal-title;
			}
		}

		&-content {
			@apply border-dialog-body-normal-border
			bg-dialog-body-normal-background;
		}

		&-footer {
			@apply border-dialog-footer-normal-border
				bg-dialog-footer-normal-background;
		}
	}
}

/* --- Animations --- */

.asteria-dialog-enter {
	@apply opacity-0;
}
.asteria-dialog-enter-active {
	@apply opacity-100 transition-opacity duration-200 ease-linear;
}

.asteria-dialog-appear {
	@apply opacity-0;
}
.asteria-dialog-appear-active {
	@apply opacity-100 transition-opacity duration-200 ease-linear;
}

.asteria-dialog-exit {
	@apply opacity-100;
}
.asteria-dialog-exit-active {
	@apply opacity-0 transition-opacity duration-200 ease-linear;
}
