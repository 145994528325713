@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__form-v2__datepicker-content {
	@apply rounded bg-white shadow;
}

.asteria-component__form-v2__datepicker-content__navigation {
	@apply flex w-full items-center gap-2;
}

.asteria-component__form-v2__datepicker-content__navigation-item {
	@apply flex w-full flex-1 items-center;

	> .asteria-component__typography {
		@apply flex-1 text-center;
	}
}

.asteria-component__form-v2__datepicker-content__types {
	@apply grid items-center;
}
