@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
	--column-description-size: minmax(100px, 1fr);
	--column-size: minmax(auto, min-content);
}

.asteria-component__transactions__list {
	@apply box-border grid w-full overflow-hidden p-4 pt-0;

	@include scroll();

	grid-template-columns: var(--columns);
	place-items: center flex-start;

	> .asteria-component__list {
		display: contents;

		> .asteria-component__list__filter,
		> .asteria-component__list__group {
			display: contents;

			> .asteria-component__list__item {
				display: contents;
			}

      &:last-of-type {
        // To add spacing for support button (fix)
        > .asteria-component__list__item {
          &:last-of-type {
            &::after {
              @apply block h-16 w-full relative;
              content:"";
            }
          }
        }
      }
		}

		> .asteria-component__list__group {
			> .asteria-component__list__header {
				display: contents;

				> .asteria-component__list__item {
					grid-column: 1 / span var(--count);

					// @apply sticky top-6;
				}
			}
      &:last-of-type {
        // To add spacing for support button (fix)
        > .asteria-component__list__item {
          &:last-of-type {
            &::after {
              @apply block h-16 w-full relative;
              content:"";
            }
          }
        }
      }
		}

		> .asteria--state-empty,
		.asteria-component__transactions-spacer {
			@apply w-full;

			grid-column: 1 / span var(--count);
		}
	}

	.asteria-component__list {
		.asteria-component__typography {
			&.asteria-component__text--size-xs {
				@apply text-xs;
			}
		}
	}

	.asteria-component__chip {
		&:not(.asteria--state-clickable:hover) {
			@apply border-transparent bg-transparent;
		}
	}
}
