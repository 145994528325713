@use '~@asteria/themes/base/mixins' as *;
@use '~@asteria/themes/base/constants' as *;

#{$global} {
}

.asteria-component__conversation-modal {
	.asteria-component__wrapper-content {
		@apply overflow-hidden p-0;

		.asteria-component__conversation-content {
			@apply flex flex-col;
			max-height: 60vh;

			@media (max-width: theme('screens.tablet')) {
				@apply max-h-screen;
			}
		}
	}
}

.asteria-component__conversation-content {
	@apply bg-message-content-background;

	.asteria-component__typography-group {
		@apply flex flex-col gap-2 bg-message-content-header-background py-4 text-center;
		.asteria-component__title {
			@apply text-message-content-header-title;
		}
	}

	.asteria-component__message-content {
		@apply p-6 pb-0;
		@include scroll('y');
		min-height: 100px;

		> .asteria-component__group {
			@apply gap-4;
		}
	}

	.asteria-component__message-group-header {
		@apply text-center font-bold uppercase;
	}

	.asteria-component__message-line-wrapper {
		&.asteria--type-user {
			> .asteria-component__text {
				@apply text-end;
			}
		}
	}

	.asteria-component__message-line {
		@apply grid gap-4 p-1;
		grid-template-columns: theme('spacing.10') auto;

		.asteria-component__message-bubble {
			@apply flex h-10 w-10 items-center justify-center rounded-full bg-message-admin-bubble-background text-message-admin-bubble-text;
		}

		.asteria-component__message-message {
			@apply relative flex flex-col gap-1 rounded-md bg-message-admin-background px-4 py-2 shadow-sm;

			> .asteria-component__typography {
				@apply text-message-admin-text;

				&.asteria-component__message-date {
					@apply text-message-admin-date-text;
				}
			}
		}

		&.asteria--type-admin {
			.asteria-component__message-message {
				&::before {
					--triangle-width: 10px;

					@apply absolute h-0 w-0;
					top: 15px;
					left: calc(var(--triangle-width) * -1);

					border-top: 5px solid transparent;
					border-right: var(--triangle-width) solid
						var(--color-message-admin-background);
					border-bottom: 5px solid transparent;

					content: '';
				}
			}
		}

		&.asteria--type-user {
			@apply justify-end;

			grid-template-columns: auto theme('spacing.10');

			.asteria-component__message-bubble {
				@apply order-last bg-message-user-bubble-background text-message-user-bubble-text;
			}

			.asteria-component__message-message {
				@apply bg-message-user-background;

				> .asteria-component__typography {
					@apply text-message-user-text;

					&.asteria-component__message-date {
						@apply text-message-user-date-text;
					}
				}

				&::after {
					--triangle-width: 10px;

					@apply absolute h-0 w-0;

					top: 15px;
					right: calc(var(--triangle-width) * -1);

					border-top: 5px solid transparent;
					border-left: var(--triangle-width) solid
						var(--color-message-user-background);
					border-bottom: 5px solid transparent;
					content: '';
				}
			}
		}
	}

	.asteria-component__message-date {
		@apply text-right;
	}

	.asteria-component__message-input {
		@apply p-6;

		.asteria-component__input {
			@apply flex-1;

			.asteria-component__input__inner {
				@apply rounded-full;

				input {
					@apply pl-5;
				}
			}

			.asteria-component__input__wrapper__prefix--postfix {
				@apply pr-2;

				> .asteria-component__button {
					@apply rounded-full;
				}
			}
		}
	}
}
